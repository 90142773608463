import { IExercise, IExerciseGroupType } from '@app-types/vm/vm.common.types';
import { append, insertAll, lensProp, over } from 'ramda';

import { ExerciseSelectionService } from '@app-services/exercise-selection-service/exercise-selection.service';
import { FitnessExeriseGroupType } from '@funxtion/ng-funxtion-api-client';
import { ITrainingPhase } from '@app-types/vm/vm.schema.types';
import { Inject, Injectable } from '@angular/core';
import { SchemaFactory } from 'src/app/factories/training/schema/schema.factory';
import { SchemaLensService } from '@app-services/vm-lens-services/schema-lens-service/schema-lens.service';
import { TrainingSchemaVMService } from '@app-services/vm/training-schema/training-schema-vm.service';
import { VM_FACTORY_SERVICE, VM_LENS_SERVICE, VM_SERVICE } from "@funxtion/portal/shared/injection-tokens/vm-tokens";

@Injectable({
    providedIn: 'root'
})
export class SchemaPhaseService {

    constructor(
        @Inject(VM_FACTORY_SERVICE) private schemaFactory: SchemaFactory,
        @Inject(VM_LENS_SERVICE) private lensService: SchemaLensService,
        @Inject(VM_SERVICE) private patchService: TrainingSchemaVMService,
        private exerciseSelectionService: ExerciseSelectionService,
    ) {
    }

    /**
     * Given a (optionally new) training phase this function will open
     * the dialog to add exercises and handle communicating this to the api
     */
    async addToTrainingPhase(trainingPhase: ITrainingPhase, groupType: IExerciseGroupType) {

        const trainingGroups = [];
        let position = trainingPhase.trainingGroups.length + 1;

        if (groupType.maxExercises !== 0) {
            const exerciseSelectionResult = await this.exerciseSelectionService.select({
                maxExerciseCount: groupType.maxExercises || Infinity, type: groupType.type,
                groupTypeName: groupType.name
            });

            if (!exerciseSelectionResult || !exerciseSelectionResult.confirmed) {
                return;
            }

        const { exercises } = exerciseSelectionResult;

            /**
             * If the groupType is single-execise we will make a new training group for each
             * exercise seperately
             */

            if (groupType.type === FitnessExeriseGroupType.EXERCISE) {
                exercises.forEach(exercise => {
                    trainingGroups.push(this._prepareTrainingGroup(groupType, [exercise], position++));
                })
            } else {
                trainingGroups.push(this._prepareTrainingGroup(groupType, exercises, position++));
            }
        } else {
            trainingGroups.push(this._prepareTrainingGroup(groupType, [], position++));
        }

        // If this is a complete new trainingphase we need to append it
        if (trainingPhase.trainingGroups.length === 0) {
            trainingPhase.trainingGroups = trainingGroups;

            return await this.patchService.transform(
                over(lensProp('trainingPhases'), append(trainingPhase)),
            );

        } else {
            // If not we will add all new trainingGroups to the existing phase
            return await this.patchService.transform(
                over(this.lensService.trainingGroupsCollectionLens(trainingPhase),
                    insertAll(trainingPhase.trainingGroups.length, trainingGroups)),
            );
        }
    }

    /**
     * Generates a new single traininggroup with 1 or more exercises in it
     */
    private _prepareTrainingGroup(groupType: IExerciseGroupType, exercises: IExercise[], position: number) {
        let exercisesMapped = null;
        if (exercises) {
            exercisesMapped = exercises.map((exercise, i) => this.schemaFactory.newTrainingExercise(exercise, i + 1))
        }

        const exerciseGroup = this.schemaFactory.newExerciseGroup(
            groupType,
            exercisesMapped,
        );

        return this.schemaFactory.newTrainingGroup(
            exerciseGroup,
            position,
        );
    }
}
