import { Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from "@angular/forms";
import { defaultActionIconConfig } from "@app-helpers/constants";
import { vmTrackFunction } from "@app-helpers/vm.helpers";
import { TargetTypeConstraint } from "@app-types/training/editor/training-exercise-form.types";
import { IEquipmentMeasurement, IExerciseSet, IExerciseSetTarget } from "@app-types/vm/vm.common.types";
import { GroupTargetType } from "@funxtion/ng-funxtion-api-client";
import { isNil, Lens, mergeDeepLeft, over, pathEq } from 'ramda';
import { debounceTime } from "rxjs/operators";
import { SchemaFactory } from "../../../../factories/training/schema/schema.factory";
import { DetailPaneSelectionService } from '@app-services/detail-pane-selection/detail-pane-selection.service';
import { VMService } from "@app-services/vm/vm.service";
import { VM_FACTORY_SERVICE, VM_SERVICE } from "@funxtion/portal/shared";


@Component({
    selector: 'app-exercise-set',
    templateUrl: './exercise-set.component.html',
    styleUrls: ['./exercise-set.component.scss'],
})
export class ExerciseSetComponent implements OnInit, OnChanges {

    // ------------------------------------------------------------------------------
    //      I/O
    // ------------------------------------------------------------------------------

    /**
     * The exercise set model to bind to the form
     */
    @Input()
    public exerciseSet: IExerciseSet;

    /**
     * A lens to the exercise-set within the current schema. This is provided as
     * input so that only the host has to carry the context of exercise set indexing.
     */
    @Input()
    public exerciseSetLens: Lens;

    /**
     * The constraints for target types: Absolute vs. Range
     */
    @Input()
    public targetTypeConstraints: TargetTypeConstraint[];

    /** The set of all available measurements to render input fields for */
    @Input()
    public availableMeasurements: IEquipmentMeasurement[];

    /** Optional: reference-number to show with the set name. If none is given, no number is shown. */
    @Input()
    public referenceNumber?: number;

    /** Whether this set should provide the self-copy button + events */
    @Input()
    public isCopyable: boolean;

    /** Whether this set should provide the self-deletion button + events */
    @Input()
    public isDeletable: boolean;

    /** Whether the 'rest after set' field should be used */
    @Input()
    public useRestAfterSet = true;

    /** Whether the 'set' layout should. In some cases there is only 1 set with 1 field, so a different layout is used  */
    @Input()
    public useSetLayout = true;


    /** Emits triggers for removal of the set */
    @Output()
    public delete = new EventEmitter<void>();

    /** Emits triggers for copying the set */
    @Output()
    public copy = new EventEmitter<void>();


    public setFormGroup: FormGroup;
    public scalarTarget = vmTrackFunction<IExerciseSetTarget>();
    defaultIconConfig = defaultActionIconConfig;
    public exerciseSetTargets: IExerciseSetTarget[];

    // ------------------------------------------------------------------------------
    //      Lifecycle
    // ------------------------------------------------------------------------------

    constructor(
        private detailPaneSelectionService: DetailPaneSelectionService,
        @Inject(VM_SERVICE) private patchService: VMService,
        @Inject(VM_FACTORY_SERVICE) private factoryService: SchemaFactory
    ) {

    }

    async ngOnInit() {
        await this.checkForMissingTargets();
        this.exerciseSetTargets = this.filterTargets(this.exerciseSet.targets);
        this.setUseRestAfterSet();
    }

    async ngOnChanges(changes: SimpleChanges) {
        await this.checkForMissingTargets();
        this.setUseRestAfterSet();
        if (changes.exerciseSet) {
            this.exerciseSetTargets = this.filterTargets(changes.exerciseSet.currentValue.targets);
        }
    }

    setUseRestAfterSet() {
        if (this.useRestAfterSet) {
            this.setFormGroup = new FormGroup({
                restAfterwardsInSeconds: new FormControl(this.exerciseSet.restAfterwardsInSeconds),
            });

            this.setFormGroup.valueChanges.pipe(debounceTime(1000)).subscribe((attributes) => {
                void this.patchService.transform(
                    over(this.exerciseSetLens, mergeDeepLeft(attributes)),
                );
            });
        }
    }

    /**
     * Checks if all available measurements are represented by a target model that is
     * referenced by this.exerciseSet. If not, the targets collection is extended with
     * a model for each missing measurement -- after which the view-model is patched
     * globally (causing re-init here).
     */
    async checkForMissingTargets() {

        const currentTargets = this.exerciseSet.targets;
        const allTargets = [];

        let targetsWereMissing = false;

        for (const measurement of this.availableMeasurements) {

            const matchingTarget = currentTargets.find(pathEq(['measurement', 'id'], measurement.id));

            targetsWereMissing = targetsWereMissing || isNil(matchingTarget);
            allTargets.push(matchingTarget || this.newExerciseSetTarget(measurement));
        }

        if (targetsWereMissing) {
            const newSchema = await this.patchService.transform(
                over(this.exerciseSetLens, mergeDeepLeft({ targets: allTargets })),
            );

            // Update the detailpaneselection manually as we've removed it's subscription
            this.detailPaneSelectionService.updateSelectionContentFromSchema(newSchema);
        }
    }

    // ------------------------------------------------------------------------------
    //      Computations
    // ------------------------------------------------------------------------------

    private newExerciseSetTarget(measurement: IEquipmentMeasurement): IExerciseSetTarget {

        const constraint = this.targetTypeConstraints.find((c) => c.measurement.id === measurement.id);
        const targetType = constraint ? constraint.targetType : GroupTargetType.ABSOLUTE;

        return this.factoryService.newExerciseSetTarget(measurement, targetType);
    }

    private filterTargets(targets: IExerciseSetTarget[]): IExerciseSetTarget[] {
        return targets.filter(target => this.availableMeasurements.map(measurement => measurement.slug).includes(target.measurement.slug));
    }
}
