import { AbstractModelMapper } from '@app-services/conversion-services/AbstractModelMapper';
import { AttributeMap, RelationMap } from '@app-types/vm-conversion/vm-conversion.types';
import { ITrainingExercise } from '@app-types/vm/vm.common.types';
import { Exercise, ExerciseSet, GroupExercise } from '@funxtion/ng-funxtion-api-client';


export class TrainingExerciseMapper extends AbstractModelMapper<ITrainingExercise, GroupExercise> {

    public readonly attributes: AttributeMap<ITrainingExercise, GroupExercise> = {
        description: 'description',
        position: 'position',
    };

    public readonly relationships: RelationMap<ITrainingExercise, GroupExercise>[] = [
        {
            mutable: false,
            type: 'one',
            vmKey: 'exercise',
            jamKey: 'exercise',
            jamClass: Exercise,
            includes: ['equipment.measurements'],
        },
        {
            mutable: true,
            type: 'many',
            vmKey: 'sets',
            jamKey: 'sets',
            jamClass: ExerciseSet,
            inverse: {
                key: 'exercise',
                type: 'one',
            },
        },
    ];
}
