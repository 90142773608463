<div class="image-list-item" [ngClass]="{
    indent: indent,
    superset: superset,
    multi: multi,
    selected: selected
 }">
    <app-stacked-image [imageUrl]="imageUrl"
                       [indent]="indent"
                       [multi]="multi"
                       [selected]="selected"
                       [size]="size"
                       [superset]="superset"
    ></app-stacked-image>

    <div class="text">{{ textContent }}</div>

</div>
