import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, NavigationExtras, Router, RouterStateSnapshot } from "@angular/router";
import { FunxtionApiClientService } from "@funxtion/ng-funxtion-api-client";

@Injectable({
    providedIn: "root",
})
export class MemberGuard implements CanActivate {
    constructor(
        private funxtion: FunxtionApiClientService,
        private router: Router,
    ) {
    }

    async canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Promise<boolean> {
        const navigationExtras: NavigationExtras = {};

        if (!this.funxtion.oauth.hasValidAccessToken()) {
            if (state.url) {
                navigationExtras.queryParams = { next: state.url };
            }
            this.router.navigate(["login"], navigationExtras);
            return false;
        }

        return true;
    }
}
