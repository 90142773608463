import { Component, Input, OnInit } from '@angular/core';
import { GroupExercise, TrainingGroup, Workout } from "@funxtion/ng-funxtion-api-client";
import { lensPath, path, sortBy, view } from "ramda";

import { DurationColumnPipe } from "@app-pipes/column-pipes/duration-column/duration-column.pipe";
import { TranslateService } from "@ngx-translate/core";

interface InfoLabel {
    label: string;
    props: { [k: string]: string };
}

@Component({
    selector: 'app-view-training-group-multi',
    templateUrl: './view-training-group-multi.component.html',
    styleUrls: ['../view-training-group-single/view-training-group-single.component.scss'],
    providers: [
        DurationColumnPipe
    ]
})
export class ViewTrainingGroupMultiComponent implements OnInit {

    exerciseLens = lensPath(['group', 'exercises', 0]);

    @Input()
    number: number = null;

    exercise: GroupExercise;

    private _group: TrainingGroup | Workout;
    @Input()
    get group(): TrainingGroup | Workout {
        return this._group;
    }
    set group(group: TrainingGroup | Workout) {
        this._group = group;
        this.exercise = view(this.exerciseLens, this._group);

        // @TODO not sure if this is the best place to sort, maybe we can do it with Rambda?
        if (this.exercise) {
            this.exercise.group.exercises = sortBy<GroupExercise>(path(['position']), this.exercise.group.exercises);
        }
    }

    @Input()
    hasNumber: boolean = true;
    get displayName(): string {
            if (this.group.name) {
                return this.group.name;
            }
            if (this.group.group && this.group.group.isSingle && this.group.group.exercises && this.group.group.exercises[0]) {
                return this.group.group.exercises[0].exercise.name;
            }
            return '';
    }

    // TODO Make my code great again
    get groupText(): any {
        const labels = [];
        const exercises = this.group.group.exercises.length;
        labels.push({ label: 'training.schemas.xExercises', props: { exercises } });
        if (this.group.group.type.requiresDurationInSeconds && this.group.group.durationInSeconds) {
            labels.push({ label: 'training.schemas.xDuration', props: { duration:
                    this.durationPipe.transform(this.group.group.durationInSeconds, false)
            }});
        }
        if (this.group.group.type.requiresNumberOfRounds && this.group.group.numberOfRounds) {
            labels.push({ label: 'training.schemas.xRounds', props: { rounds: this.group.group.numberOfRounds }});
        }
        // if (this.group.group.type.requiresRestAfterExerciseInSeconds && this.group.group.restAfterExerciseInSeconds) {
        //     labels.push({ label: 'training.schemas.xRestAfterExercise', props: { restAfter:
        //             this.durationPipe.transform(this.group.group.restAfterExerciseInSeconds, false)
        //     }});
        // }
        // if (this.group.group.type.requiresRestAfterSetInSeconds && this.group.group.restAfterSetInSeconds) {
        //     labels.push({ label: 'training.schemas.xRestAfterSet', props: { restAfter:
        //                 this.durationPipe.transform(this.group.group.restAfterSetInSeconds, false)
        //     }});
        // }
        if (this.group.group.type.requiresTargetRepetitions && this.group.group.targetRepetitions) {
            labels.push({ label: 'training.schemas.xTargetReps', props: { targetReps: this.group.group.targetRepetitions } });
        }
        const text = labels.map((label: InfoLabel) => {
            return this.translate.instant(label.label, label.props);
        });

        return text.join(', ');
    }

    constructor(
        private translate: TranslateService,
        private durationPipe: DurationColumnPipe
    ) {
    }

    ngOnInit() {
    }

}
