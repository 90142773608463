import { Pipe, PipeTransform } from '@angular/core';
import { compose, Lens, lensPath } from "ramda";

@Pipe({
    name: 'exerciseSetLens'
})
export class ExerciseSetLensPipe implements PipeTransform {

    transform(value: number, exerciseSetLens: Lens): Lens {
        return this.lensForTarget(value, exerciseSetLens);
    }

    lensForTarget(index: number, lens: Lens): Lens {
        return compose(lens, lensPath(['targets', Number(index)])) as Lens;
    }
}
