import { InputValues, ITargetControlsConfig } from "../TargetStrategy.types";
import { AbstractTargetStrategy } from "../AbstractTargetStrategy";


export class StringTargetStrategy extends AbstractTargetStrategy<string> {

    protected readonly _controlsConfig: ITargetControlsConfig = {
        columns: { absolute: 4, range: 4 },
        inputs: {
            value: { type: 'text' },
        },
    };

    protected toFormValues(parsed: string): InputValues {
        return { value: parsed };
    }

    protected fromModelAttribute(serialized: string): string | null {
        return serialized;
    }

    protected toModelAttribute(parsed: string): string {
        return parsed;
    }

    protected fromFormValues(values: InputValues) {
        return values.value;
    }

    protected defaultParsedValue(): string {
        return '';
    }
}
