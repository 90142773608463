<ng-container *ngIf="loaded; else loading">
    <router-outlet></router-outlet>
</ng-container>

<ng-template #loading>
    <div class="loading-screen">
        <div class="sidebar"></div>
        <div class="main">
            <mat-card class="container">

                <app-loading-spinner *ngIf="!error" message="{{ 'loading.dashboard' | translate }}"></app-loading-spinner>

                <ng-container *ngIf="error" [ngSwitch]="errorCode">
                    <div class="error-container">
                        <ng-container *ngSwitchCase="0">{{ "error.general.default" | translate }}</ng-container>
                        <ng-container *ngSwitchCase="500">{{ "error.general.server" | translate }}</ng-container>
                        <ng-container *ngSwitchDefault>{{ "error.general.default" | translate }}</ng-container>
                    </div>
                </ng-container>
            </mat-card>
        </div>
    </div>
</ng-template>
