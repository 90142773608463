import { Component, OnInit } from '@angular/core';
import { FunxtionApiClientService, User } from '@funxtion/ng-funxtion-api-client';
import { ICON_COLOR, ICON_SIZE, IconConfig } from 'src/app/modules/shared/inputConfig';
import { MainNavItem, sidenavRoutes } from '@app-components/menu/side-menu/side-menu.config';
import { NavigationEnd, Router } from '@angular/router';
import { clone, filter, pipe } from 'ramda';

import { EnvironmentService } from '@app-services/environment/environment.service';
import { PortalEnvironmentVariables } from '@app-environments/EnvironmentVariables';
import { RoleGuard } from 'src/app/guards/role/role.guard';
import { filter as rxFilter } from 'rxjs/operators';

@Component({
    selector: 'app-side-menu',
    templateUrl: './side-menu.component.html',
    styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent implements OnInit {
    isTrainingPlanner = (item: MainNavItem) => {
        if (this.environmentService.environment.trainingPlanner) {
            return item.isTrainingPlanner;
        }
        return true;
    };

    navigationItems: MainNavItem[] = pipe<MainNavItem[], MainNavItem[], MainNavItem[]>(clone, filter(this.isTrainingPlanner))(sidenavRoutes);
    path: string;
    roleGuard: RoleGuard;
    user: User;
    ICON_SIZE = ICON_SIZE;
    ICON_COLOR = ICON_COLOR;
    environment: PortalEnvironmentVariables;

    logout() {
        this.funxtion.user.logout();
        this.environmentService.clearEnvironment();
        window.location.pathname = '/';
    }

    baseIconConfig: IconConfig = {
        iconSize: ICON_SIZE.XLARGE,
        iconColor: ICON_COLOR.LIGHT
    };

    constructor(public environmentService: EnvironmentService, private funxtion: FunxtionApiClientService, private router: Router) {
        this.environment = this.environmentService.environment;
        this.roleGuard = new RoleGuard(funxtion, router);
    }

    async checkVisibilities() {
        this.navigationItems = await Promise.all(
            this.navigationItems.map(async nav => {
                const check = await this.roleGuard.isAuthorised(nav.roles);
                nav.visible = check;
                nav.children = await Promise.all(
                    nav.children.map(async child => {
                        const checkChild = child.roles ? await this.roleGuard.isAuthorised(child.roles) : nav.visible;
                        child.visible = checkChild;
                        return child;
                    })
                );
                return nav;
            })
        );
    }

    ngOnInit() {
        this.path = this.router.url;
        this.user = this.funxtion.user.data;

        this.router.events.pipe(rxFilter(e => e instanceof NavigationEnd)).subscribe((val: NavigationEnd) => {
            this.path = val.url;
        });

        this.checkVisibilities();
    }
}
