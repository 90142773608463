import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Exercise } from "@funxtion/ng-funxtion-api-client";
import { ALIGNMENT, BUTTON_VARIANT } from "src/app/modules/shared/inputConfig";

export interface ViewExerciseDialogData {
    exercise: Exercise;
}

@Component({
    selector: 'app-view-exercise-dialog',
    templateUrl: './view-exercise-dialog.component.html',
    styleUrls: ['./view-exercise-dialog.component.scss'],
})
export class ViewExerciseDialogComponent implements OnInit {
    BUTTON_VARIANT = BUTTON_VARIANT;
    ALIGNMENT = ALIGNMENT;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: ViewExerciseDialogData,
        private dialogRef: MatDialogRef<ViewExerciseDialogComponent>,
    ) {
    }

    ngOnInit() {
    }

    close() {
        this.dialogRef.close();
    }
}
