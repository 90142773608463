import { ITrainingPlan } from '@app-types/vm/vm.training.types';
import { Goal, Level, Location, TrainingPlan, TrainingPlanWeek, Tag } from '@funxtion/ng-funxtion-api-client';
import { AbstractModelMapper } from '@app-services/conversion-services/AbstractModelMapper';
import { AttributeMap, RelationMap } from '@app-types/vm-conversion/vm-conversion.types';
import { resolveOwnerType } from '@app-helpers/vm-conversion.helpers';


export class TrainingPlanMapper extends AbstractModelMapper<ITrainingPlan, TrainingPlan> {

    public readonly attributes: AttributeMap<ITrainingPlan, TrainingPlan> = {
        name: 'name',
        descriptionShort: 'descriptionShort',
        descriptionLong: 'descriptionLong',
        image: 'image',
        position: 'position',
        intensity: 'intensity',
        daysPerWeek: 'daysPerWeek',
    };

    public readonly relationships: RelationMap<ITrainingPlan, TrainingPlan>[] = [
        {
            mutable: false,
            type: 'one',
            vmKey: 'level',
            jamKey: 'level',
            jamClass: Level,
        },
        {
            mutable: false,
            type: 'many',
            vmKey: 'locations',
            jamKey: 'locations',
            jamClass: Location,
        },
        {
            mutable: false,
            type: 'one',
            jamClass: Goal,
            vmKey: 'goal',
            jamKey: 'goal',
        },
        {
            mutable: false,
            type: 'many',
            vmKey: 'tags',
            jamKey: 'tags',
            jamClass: Tag,
        },
        {
            mutable: false,
            type: 'one',
            jamClassResolver: (vm: ITrainingPlan) => resolveOwnerType(vm.owner),
            vmKey: 'owner',
            jamKey: 'owner',
        },
        {
            mutable: true,
            type: 'many',
            vmKey: 'weeks',
            jamKey: 'weeks',
            jamClass: TrainingPlanWeek,
            inverse: {
                key: 'plan',
                type: 'one',
            },
        },
    ];
}
