import { Pipe, PipeTransform } from '@angular/core';
import { TrainingSchemaType } from "@funxtion/ng-funxtion-api-client";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";

@Pipe({
  name: 'typesColumn'
})
export class TypesColumnPipe implements PipeTransform {

    constructor(private sanitizer: DomSanitizer) {
    }

    transform(types: TrainingSchemaType[]): SafeHtml {
        return this.sanitizer.bypassSecurityTrustHtml(`
            <div class="types-column">
                ${types.map((type: TrainingSchemaType) => `<span class="types-name">${type.name}</span>`).join(' | ')}
            </div>
        `);
    }

}
