/**
 * List of available configurations.
 * Used by EnvironmentService, to select based on hostname, or user select
 * This functionality should be moved to the back eventually
 */
export const configurations = [
    {
        name: "Test GoodLife",
        type: "test",
        isTrainingPlanner: false,
        self: {
            hostname: "test.portal.funxtion.com",
            schema: "https"
        },
        auth: {
            clientId: 2,
            clientSecret: "goodlife"
        },
    },
    {
        name: "Test FunXtion",
        type: "test",
        isTrainingPlanner: false,
        self: {
            hostname: "test.portal.funxtion.com",
            schema: "https"
        },
        auth: {
            clientId: 1,
            clientSecret: "dvRLazYVeN19cDovsi6RTjgyuftQIkQUj3FvQY6"
        },
    },
    {
        name: "GoodLife",
        type: "production-cac",
        isTrainingPlanner: true,
        self: {
            hostname: "goodlife.funxtion.com",
            schema: "https"
        },
        auth: {
            clientId: 13,
            clientSecret: "hYHcDbwJFs*Uf3YKQwjbJNaTU!c9.HLe"
        }
    },
    {
        name: "Test Solinca",
        type: "test",
        isTrainingPlanner: true,
        self: {
            hostname: "test.portal.funxtion.com",
            schema: "https"
        },
        auth: {
            clientId: 4,
            clientSecret: "YHmG8KVGAmtEahQgABNij6n8jCzixNbp"
        }
    },
    {
        name: "Solinca",
        type: "production-cac",
        isTrainingPlanner: true,
        self: {
            hostname: "d1m5xlufkpjdea.cloudfront.net",
            schema: "https"
        },
        auth: {
            clientId: 14,
            clientSecret: "YHmG8KVGAmtEahQgABNij6n8jCzixNbp"
        }
    },
    {
        name: "Boss",
        type: "production",
        isTrainingPlanner: true,
        self: {
            hostname: "boss.funxtion.com",
            schema: "https"
        },
        auth: {
            clientId: 8,
            clientSecret: "n5DFGIBOftnNMGCXuWpu7NwIDoFjAHYtyJkYI4J1"
        }
    },
    {
        name: "FLG",
        type: "production",
        isTrainingPlanner: true,
        self: {
            hostname: "flg.funxtion.com",
            schema: "https"
        },
        auth: {
            clientId: 2,
            clientSecret: "o1ZS1SYHdP7mnel6tyBDsxpTEcebgeg9daSQG9JV"
        }
    },
    {
        name: "People-Fitness",
        type: "production",
        isTrainingPlanner: true,
        self: {
            hostname: "people-fitness.funxtion.com",
            schema: "https"},
        auth: {
            clientId: 7,
            clientSecret: "mBo5gvvmyfCM6Dy3wGTLguaxiDke3dzjsif7gnNm"
        }
    },
    {
        name: "Pilot",
        type: "acceptance",
        isTrainingPlanner: false,
        self: {
            hostname: "acceptance.portal.funxtion.com",
            schema: "https"},
        auth: {
            clientId: 2,
            clientSecret: "dvRLazYVeN19cDovsi6RTjgyuftQIkQUj3FvQY6"
        }
    },
    {
        name: "Puregym-test",
        type: "production",
        isTrainingPlanner: true,
        self: {
            hostname: "puregym-test.funxtion.com",
            schema: "https"
        },
        auth: {
            clientId: 12,
            clientSecret: "EkhQk5Ss5FHhRB8VPIkr6mmSaHNQI37h5qkVhCC3"
        }
    },
    {
        name: "Test PureGym",
        type: "test",
        isTrainingPlanner: false,
        self: {
            hostname: "test.portal.funxtion.com",
            schema: "https"
        },
        auth: {
            clientId: 6,
            clientSecret: "wQdeGyurCQxZn3bqmy1OIQRs7f2uHP7O"
        }
    },
    {
        name: "Puregym-acceptance",
        type: "acceptance-v3",
        isTrainingPlanner: true,
        self: {
            hostname: "puregym-acc.funxtion.com",
            schema: "https"
        },
        auth: {
            clientId: 4,
            clientSecret: "EqEeXpqWZ8oYj2yCIa6eSEXccKo0MVH0I2Bv649a"
        }
    },
    {
        name: "Puregym",
        type: "production-cac",
        isTrainingPlanner: true,
        self: {
            hostname: "puregym.funxtion.com",
            schema: "https"
        },
        auth: {
            clientId: 4,
            clientSecret: "EqEeXpqWZ8oYj2yCIa6eSEXccKo0MVH0I2Bv649a"
        }
    },
    {
        name: "Fit For Free",
        type: "production",
        isTrainingPlanner: true,
        self: {
            hostname: "fitforfree.funxtion.com",
            schema: "https"
        },
        auth: {
            clientId: 15,
            clientSecret: "UvmmnQeDkt2LzcYIyP7x9fQ4u4chi7Cp"
        }
    },
    {
        name: "SportCity",
        type: "production",
        isTrainingPlanner: true,
        self: {
            hostname: "sportcity.funxtion.com",
            schema: "https"
        },
        auth: {
            clientId: 16,
            clientSecret: "VzSS8ohUgHafdOA4izKkBDqVcxlhR3Ry"
        }
    },
    {
        name: "Non Stop Gym",
        type: "production-cac",
        isTrainingPlanner: true,
        self: {
            hostname: "nonstopgym.funxtion.com",
            schema: "https"
        },
        auth: {
            clientId: 17,
            clientSecret: "PmLtxfy4ZhMK7aHVCmRQPE6FAk3lH4HK"
        }
    },
    {
        name: "Training-planner",
        type: "acceptance",
        isTrainingPlanner: true,
        self: {
            hostname: "training-planner.funxtion.com",
            schema: "https"
        },
        auth: {
            clientId: 1,
            clientSecret: "uUylok8oejpYU1bmzVNDwd1X2OiJuGccMhFP0Trz"
        }
    }
];
