import { AbstractTargetStrategy } from "../AbstractTargetStrategy";
import { TutValues } from "@app-types/training/editor/time-under-tension.types";
import { TUT } from "@app-helpers/training-schema/time-under-tension.helpers";
import { InputValues, ITargetControlsConfig } from "../TargetStrategy.types";


export class TutTargetStrategy extends AbstractTargetStrategy<TutValues> {

    protected readonly _controlsConfig: ITargetControlsConfig = {
        columns: { absolute: 4, range: 8 },
        inputs: {
            value0: { type: 'number', unit: 's', postDelimiter: '-' },
            value1: { type: 'number', unit: 's', postDelimiter: '-' },
            value2: { type: 'number', unit: 's', postDelimiter: '-' },
            value3: { type: 'number', unit: 's' },
        },
    };

    protected toFormValues(parsed: TutValues): InputValues {
        return {
            value0: String(parsed[0]),
            value1: String(parsed[1]),
            value2: String(parsed[2]),
            value3: String(parsed[3]),
        };
    }

    protected fromFormValues(values: InputValues): TutValues | null {

        const tutValues = [
            parseInt(values.value0, 10),
            parseInt(values.value1, 10),
            parseInt(values.value2, 10),
            parseInt(values.value3, 10),
        ];

        return TUT.validateParsed(tutValues) ? tutValues : null;
    }

    protected fromModelAttribute(serialized: string): TutValues | null {
        return TUT.parse(serialized);
    }

    protected toModelAttribute(parsed: TutValues): string {
        return TUT.serialize(parsed);
    }

    protected defaultParsedValue(): TutValues {
        return [0, 0, 0, 0];
    }
}
