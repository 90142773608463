import { Injectable } from '@angular/core';
import { flatMap, takeUntil, tap, map, take } from "rxjs/operators";
import { FunxtionApiClientService } from "@funxtion/ng-funxtion-api-client";
import { EnvironmentService } from "@app-services/environment/environment.service";
import { AnalyticsService } from "@app-services/analytics-service/analytics.service";
import { from, Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class InitializeService {

  constructor(private funxtion: FunxtionApiClientService,
              private environmentService: EnvironmentService,
              private analytics: AnalyticsService) { }

  initPortal(): Observable<boolean> {

      this.funxtion.setup(this.environmentService.environment).then(() => {
          this.funxtion.oauth.logoutUrl = '/login';
      });

      return from(this.funxtion.ready()).pipe(take(1), flatMap(() => {
          return from(this.funxtion.client.getClient())
              .pipe(
                  tap(() => this.analytics.initialise()),
                  map(() => true)
              );
      }));
  }
}
