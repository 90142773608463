<div class="view-group" (click)="openDetails(exercise)" *ngIf="exercise">
    <div class="group-image">
        <div class="image-container">
            <app-stacked-image [size]="80"
                               [superset]="false"
                               [multi]="false"
                               [selected]="false"
                               [hasNumber]="number"
                               [number]="number"
                               [imageUrl]="exercise.exercise.imageSmall"
            ></app-stacked-image>
        </div>
    </div>

    <div class="group-name-details">
        <div class="group-name">
            {{ exercise.exercise.name }}
        </div>
        <div class="group-details">
            <div class="sets-info">
                {{ exerciseInfo }}
            </div>
        </div>
    </div>

    <div class="group-notes-container">
        <ng-container *ngIf="exercise.description">
            <div class="notes-title">{{ 'common.notes' | translate }}</div>
            <div class="group-notes">
                {{ exercise.description }}
            </div>
        </ng-container>
    </div>

    <div class="group-info">
        <funxtion-icon [config]="defaultIconConfig" [overrides]="{ iconName: 'info' }"></funxtion-icon>
    </div>
</div>


<div class="view-group" *ngIf="group.group.type.type === 'timer'">
    <div class="group-image">
        <div class="image-container">
            <app-stacked-image [size]="80"
                               [superset]="false"
                               [multi]="false"
                               [selected]="false"
                               [hasNumber]="number"
                               [number]="number"
                               imageUrl="/assets/icons/timer.svg"
            ></app-stacked-image>
        </div>
    </div>

    <div class="group-name-details">
        <div class="group-name">
            {{ group.group.type.name }}
        </div>
        <div class="group-details">
            <div class="sets-info">
                {{ group.group.durationInSeconds * 1000 | date:'mm:ss' }}
            </div>
        </div>
    </div>

    <div class="group-notes-container">

    </div>

</div>
