import { BUTTON_VARIANT } from "src/app/modules/shared/inputConfig";
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { Router } from "@angular/router";
import { FunxtionApiClientService, TrainingSchema } from "@funxtion/ng-funxtion-api-client";
import { defaultActionIconConfig } from '@app-helpers/constants';

@Component({
    selector: 'app-training-table',
    templateUrl: './training-table.component.html',
    styleUrls: ['./training-table.component.scss'],
})
export class TrainingTableComponent implements OnInit {
    BUTTON_VARIANT = BUTTON_VARIANT;
    defaultActionIconConfig = defaultActionIconConfig;

    displayedColumns = [
        'image',
        'name',
        'intensity',
        'goal',
        'gender',
        'duration',
    ];

    selected: Set<TrainingSchema> = new Set<TrainingSchema>();

    @Input()
    hasActions: boolean = true;

    @Input()
    hasSelection: boolean = false;

    @Input()
    hasUseTemplate: boolean = false;

    @Input()
    maxSelection: number;

    @Input()
    schemas: TrainingSchema[];

    @Output()
    buttonClicked: EventEmitter<[string, TrainingSchema]> = new EventEmitter<[string, TrainingSchema]>();

    @Output()
    currentSelection: EventEmitter<TrainingSchema[]> = new EventEmitter<TrainingSchema[]>();

    constructor(private router: Router, private funxtion: FunxtionApiClientService) {
    }

    ngOnInit() {
        if (this.hasActions) {
            this.displayedColumns.push('actions');
        } else if (this.hasSelection) {
            this.displayedColumns.push('selection');
        } else if (this.hasUseTemplate) {
            this.displayedColumns.push('template');
        }

        if (this.funxtion.user.data.isAdministrator) {
            this.displayedColumns = this.displayedColumns.reduce((accumulator, currentValue, index) => {
                if (index === 1) {
                    accumulator.push(currentValue);
                    accumulator.push('published-at')
                } else {
                    accumulator.push(currentValue);
                }
                return accumulator
            }, [])
        }
    }

    addToSelection(event: MouseEvent, element: TrainingSchema) {
        event.stopPropagation();
        if (this.maxSelection && this.selected.size >= this.maxSelection) {
            return;
        }
        if (this.selected.has(element)) {
            this.selected.delete(element);
        } else {
            this.selected.add(element);
        }
        this.currentSelection.emit(Array.from(this.selected));
    }

    onButtonClicked(e: MouseEvent, action: string, model) {
        e.preventDefault();
        e.stopPropagation();
        switch (action) {
            case 'edit':
                return this.router.navigate(['training', 'workouts', model.id, 'edit']);
            case 'delete':
            case 'duplicate':
            case 'view':
                return this.buttonClicked.emit([action, model]);
        }
    }
}
