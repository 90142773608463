import { Injectable } from '@angular/core';
import { ITrainingSchema } from "@app-types/vm/vm.schema.types";
import { map, pipe, cond, lensProp, over, T, type, equals, identity, always, has } from 'ramda';
import { IWorkout } from '@app-types/vm/vm.workout.types';
import { ITrainingPlan } from "@app-types/vm/vm.training.types";
import { ProspectService } from "@app-services/prospect-service/prospect.service";
import { FunxtionApiClientService } from "@funxtion/ng-funxtion-api-client";
import { showOwner } from "@app-services/conversion-services/immutable-conversion/generic-api-model-converters";

const isArray = pipe(type, equals("Array"));
const hasOwner = has('owner');

@Injectable({
    providedIn: 'root',
})
export class VmDuplicationService {

    constructor(
        private prospectService: ProspectService,
        private funxtion: FunxtionApiClientService
    ) {
    }



    setPrivateOwner = over(lensProp('owner'), () => showOwner(this.getPrivateOwner()));
    removeIdPipe = over(lensProp('id'), () => this.prospectService.newId);
    mapRelationship = (prop: string, fn: any = identity) => over(
        lensProp(prop), cond([
            [isArray, map(fn)],
            [T, pipe(fn)],
        ]),
    );
    duplicationPipe = (prop: string = '', fn: any = identity) => pipe(
        this.removeIdPipe,
        cond([
            [hasOwner, this.setPrivateOwner],
            [T, identity]
        ]),
        cond([
            [always(! equals(prop, '')), this.mapRelationship(prop, fn)],
            [T, identity],
        ])
    );

    private getPrivateOwner() {
        const userData = this.funxtion.user.data;
        if (userData.isCoach) {
            return userData.coach;
        } else if (userData.isManager) {
            return userData.manager;
        } else if (userData.isAdministrator) {
            return userData.administrator;
        }
    }

    duplicateTraining(training: ITrainingSchema): ITrainingSchema {
        return this.duplicateTrainingPipe(training) as ITrainingSchema;
    }

    duplicateWorkout(workout: IWorkout): IWorkout {
        return this.duplicateWorkoutPipe(workout) as IWorkout;
    }

    duplicateTrainingPlan(plan: ITrainingPlan): ITrainingPlan {
        return this.duplicateTrainingPlanPipe(plan) as ITrainingPlan;
    }

    duplicateWorkoutPipe = this.duplicationPipe(
        'exerciseGroup',
        this.duplicationPipe(
            'trainingExercises',
            this.duplicationPipe(
                'sets',
                this.duplicationPipe(
                    'targets',
                    this.duplicationPipe(),
                ),
            ),
        ),
    );

    duplicateTrainingPipe = this.duplicationPipe(
        'trainingPhases',
        this.duplicationPipe(
            'trainingGroups',
            this.duplicateWorkoutPipe,
        ),
    );

    duplicateTrainingPlanPipe = this.duplicationPipe(
        'weeks',
        this.duplicationPipe(
            'days',
            this.duplicationPipe(),
        ),
    );
}
