import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ErrorResponse } from 'angular2-jsonapi';

@Injectable({
    providedIn: 'root',
})
export class ErrorDisplayService {
    constructor(protected snackBar: MatSnackBar) {
    }

    display(error: ErrorResponse, temp = false) {
        const currentError = error.errors[0];
        const displayError = currentError.detail || currentError.title;
        if (temp) {
            return this.displayAlertTemporarily(displayError);
        } else {
            return this.openSnackbar(displayError);
        }
    }

    displayAlertTemporarily(text: string, duration = 3000) {
        return this.openSnackbar(text, 'Dismiss', {
            duration,
        });
    }

    displayStr(s: string) {
        this.openSnackbar(s);
    }

    openSnackbar(message: string, button = 'Dismiss', options = {}) {
        return this.snackBar.open(message, button, options);
    }
}
