import { IAbsoluteExerciseSetTarget, IExerciseSetTarget, IRangeExerciseSetTarget } from "@app-types/vm/vm.common.types";
import { GroupTargetType } from "@funxtion/ng-funxtion-api-client";

/* tslint:disable:no-any */

export const isAbsoluteTarget = (target: IExerciseSetTarget): target is IAbsoluteExerciseSetTarget => (
    target.type === GroupTargetType.ABSOLUTE
);

export const isRangeTarget = (target: IExerciseSetTarget): target is IRangeExerciseSetTarget => (
    target.type === GroupTargetType.RANGE
);
