<ng-template #loading>
    <mat-card class="schema-details-card schema-details-card--loading">
        <app-loading-spinner [message]="'loading.trainingSchema' | translate"></app-loading-spinner>
    </mat-card>
</ng-template>

<!-------------------------------------------------------------------------------
-------------------------------------------------------------------------------->
<form [formGroup]="editSchemaForm">
<app-header-content [searchId]="searchId">
    <div button-slot>
        <app-visibility-select [requiredRole]="ROLE.Administrator" [currentOwner]="(patchService.revisions$ | async)?.owner || null"
                               (visibilitySelection)="handleVisibilitySelection($event)"
        ></app-visibility-select>

        <funxtion-button [variant]="BUTTON_VARIANT.LIGHT_SMALL" (click)="location.back()">
            {{ 'common.cancel' | translate }}
        </funxtion-button>

        <funxtion-button [variant]="BUTTON_VARIANT.PRIMARY_SMALL" (click)="onSchemaSubmit()">
            {{ 'training.schemas.saveSchema' | translate }}
        </funxtion-button>
    </div>
</app-header-content>


<ng-container class="container" *ngIf="patchService.revisions$ | async as schema; else loading">
    <ng-container *ngIf="!saving; else savingSchema">


        <mat-card class="schema-details-card">
            <h2 class="schema-heading schema-heading--upper">{{ "common.generalInfo" | translate }}</h2>
            <mat-divider></mat-divider>
            <app-training-attributes [parentFormGroup]="editSchemaForm" [training]="schema"></app-training-attributes>
        </mat-card>


        <mat-card class="schema-structure-card">

            <div class="schema-tree-column">
                <h2 class="schema-heading schema-heading--upper">
                    {{ "training.schemas.training" | translate }}
                </h2>

                <mat-divider></mat-divider>

                <div class="phase-container" *ngFor="let phaseMap of (phaseMaps$ | async)">
                    <app-phase [hasTrainingPhase]="phaseMap.trainingPhase != null"
                               [phase]="phaseMap.phase"
                               (add)="addTrainingPhase(phaseMap.phase, $event)"
                               (remove)="deleteTrainingPhase(phaseMap.trainingPhase)"
                               [type]="type"
                    ></app-phase>

                    <app-training-phase *ngIf="phaseMap.trainingPhase as trainingPhase"
                                        [trainingPhase]="trainingPhase"
                    ></app-training-phase>
                </div>
            </div>

            <mat-divider [vertical]="true"></mat-divider>

            <div class="detail-pane-column">

                <ng-container *ngIf="detailSelectionService.selection$ | async as detailSelection"
                              [ngSwitch]="detailSelection.type"
                >

                    <ng-container *ngSwitchCase="'DPSType--none'">
                        <h2 class="schema-heading
                               schema-heading--upper
                               schema-heading--soft"
                        >Detail panel</h2>

                        <div class="detail-pane-content">
                            <p class="detail-pane-message">
                                This is the details panel for your exercises, sets and other details of your training.
                                Select any of your exercises to the left to view and edit its sets and other setup.
                            </p>
                        </div>

                    </ng-container>

                    <ng-container *ngSwitchCase="'DPSType--exercise-group'">
                        <h2 class="schema-heading schema-heading--upper">{{ detailSelection.value.type.name }}</h2>
                        <div class="detail-pane-content">
                            <app-exercise-group-form
                                [exerciseGroup]="detailSelection.value"
                                [parentFormGroup]="editSchemaForm"
                            ></app-exercise-group-form>
                        </div>
                    </ng-container>

                    <ng-container *ngSwitchCase="'DPSType--training-exercise'">
                        <h2 class="schema-heading schema-heading--upper">{{ detailSelection.value?.exercise.name || 'default' }}</h2>
                        <div class="detail-pane-content">
                            <app-training-exercise-form [trainingExercise]="detailSelection.value"
                                                        [multipleSetsAllowed]="detailSelection.context.multipleSetsAllowed"
                                                        [parentFormGroup]="editSchemaForm"
                            ></app-training-exercise-form>
                        </div>
                    </ng-container>

                </ng-container>
            </div>

        </mat-card>
    </ng-container>
</ng-container>
</form>
<div class="footer-padding"></div>

<ng-template #savingSchema>
    <mat-card class="schema-details-card schema-details-card--saving">
        <app-loading-spinner [message]="'saving.training' | translate"></app-loading-spinner>
    </mat-card>
</ng-template>
