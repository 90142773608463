<ng-template #card>
    <mat-card class="exercise-card">
        <ng-container *ngTemplateOutlet="cardInner"></ng-container>
    </mat-card>
</ng-template>

<ng-container *ngIf="!hasCard; else card">
    <ng-container *ngTemplateOutlet="cardInner"></ng-container>
</ng-container>

<ng-template #loading>
    <app-loading-spinner
        message="{{ 'loading.exercise' | translate }}"
    ></app-loading-spinner>
</ng-template>

<ng-template #cardInner>
    <ng-container *ngIf="exercise$ | async as exercise; else loading">
        <div class="details-grid">
            <div class="image">
                <img [src]="exercise.gifLarge"/>
            </div>
            <div class="select-btn-flex">
                <div class="nameDetails">
                    <div class="exercise-name">{{exercise.name}}</div>
                    <div class="rating-container">
                        <span class="rating" [ngClass]="{ 'filled': rating <= exercise.averageRating }" *ngFor="let rating of ratingArray">
                            <mat-icon>star</mat-icon>
                        </span>
                    </div>
                    <div class="steps">

                        <div class="section-title">{{ 'common.instructions' | translate }}</div>
                        <div class="steps-list">
                            <div class="step" *ngFor="let step of exercise.steps">
                                <div class="step-number">
                                    {{ step.position }}
                                </div>
                                <div class="step-description">
                                    {{ step.description }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="hasSelect" class="select-btn">
                    <funxtion-button (click)="onSelect(exercise)" [variant]="BUTTON_VARIANT.SECONDARY_SMALL">
                        {{ (selected ? 'training.exercises.deselectExercise' : 'training.exercises.selectExercise') | translate }}
                    </funxtion-button>
                </div>
            </div>
            <div>
                <mat-divider [inset]="hasCard" class="divider"></mat-divider>
                <div class="muscleGroups">
                    <div class="section-title">{{ 'common.muscleGroups' | translate }}</div>
                    <ul>
                        <li *ngFor="let muscleGroup of exercise.muscleGroups">
                            {{ muscleGroup.name }}
                        </li>
                    </ul>
                </div>
            </div>
            <div>
                <mat-divider [inset]="hasCard" class="divider"></mat-divider>
                <div class="equipments">
                    <div class="section-title">{{ 'common.equipment' | translate }}</div>
                    <div class="equipment" *ngFor="let equipment of exercise.equipment">
                        <img class="equipment-icon" [src]="equipment.icon"/>
                        <div class="equipment-name">
                            {{ equipment.name }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</ng-template>
