import { ErrorHandler, Inject, NgModule } from '@angular/core';
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular';
import { environment } from "@app-environments/environment";
import { LOG_PROVIDER } from "./log-provider.token";

export function errorHandlerFactory() {
    if (environment.production) {
        return new BugsnagErrorHandler()
    } else {
        return new ErrorHandler();
    }
}

@NgModule({
    providers: [
        { provide: ErrorHandler, useFactory: errorHandlerFactory }
    ]
})
export class ErrorHandlingModule {

    constructor(@Inject(LOG_PROVIDER) logger) {

        if (environment.production) {
            logger.start({
                apiKey: environment.apiKeys.bugsnag,
                releaseStage: environment.environment,
                enabledReleaseStages: ['test', 'acceptance', 'production']
            })
        }
    }

}
