import { IPhase, ITrainingGroup, ITrainingPhase, ITrainingSchema } from '@app-types/vm/vm.schema.types';
import { ExerciseGroupLabelsFactory } from '../../../factories/training/editor/exercise-group-labels.factory';
import { Injectable } from '@angular/core';
import { prop, sortBy } from 'ramda';
import {
    Exercise,
    ExerciseSet,
    Group,
    GroupExercise,
    GroupTargetType,
    Phase,
    Target,
    TrainingGroup,
    TrainingPhase,
    TrainingSchema
} from '@funxtion/ng-funxtion-api-client';

import { IExercise, IExerciseGroup, IExerciseSet, IExerciseSetTarget, ITrainingExercise } from '@app-types/vm/vm.common.types';

import {
    showEquipment,
    showExerciseGroupType,
    showGender,
    showGoal,
    showLevel,
    showMeasurement,
    showOwner,
    showTrainingSchemaType,
    showTag
} from '@app-services/conversion-services/immutable-conversion/generic-api-model-converters';

@Injectable({ providedIn: 'root' })
export class SchemaJamConverterService {
    constructor(private exerciseGroupLabelsFactory: ExerciseGroupLabelsFactory) {}

    public showTrainingSchema(model: TrainingSchema): ITrainingSchema {
        const {
            id,
            name,
            descriptionShort,
            descriptionLong,
            durationInSeconds,
            energyExpenditureInKiloCalories,
            intensity,
            image,
            goal,
            level,
            types = [],
            owner,
            phases = [],
            genders = [],
            tags = []
        } = model;

        return {
            id,
            name,
            descriptionShort,
            descriptionLong,
            durationInSeconds,
            energyExpenditureInKiloCalories,
            genders: genders.map(g => showGender(g)),
            goal: goal ? showGoal(goal) : undefined,
            intensity,
            types: types.map(type => showTrainingSchemaType(type)),
            image,
            level: level ? showLevel(level) : undefined,
            owner: owner ? showOwner(owner) : undefined,
            trainingPhases: phases.map(p => this.showTrainingPhase(p)),
            tags: tags ? tags.map(tag => showTag(tag)) : []
        };
    }

    public showTrainingPhase({ id, phase, groups = [] }: TrainingPhase): ITrainingPhase {
        return {
            id,
            phase: this.showPhase(phase),
            trainingGroups: sortBy(
                prop('position'),
                groups.map(g => this.showTrainingGroup(g))
            )
        };
    }

    public showPhase({ id, name, type, position }: Phase): IPhase {
        return {
            id,
            name,
            type,
            position
        };
    }

    public showTrainingGroup({ id, name, position, group }: TrainingGroup): ITrainingGroup {
        return {
            id,
            name,
            position,
            exerciseGroup: group ? this.showExerciseGroup(group) : null
        };
    }

    public showExerciseGroup(group: Group): IExerciseGroup {
        const { id, numberOfRounds, restAfterSetInSeconds, restAfterExerciseInSeconds, durationInSeconds, targetRepetitions, type, exercises = [] } = group;

        return {
            id,
            numberOfRounds,
            restAfterSetInSeconds,
            restAfterExerciseInSeconds,
            durationInSeconds,
            targetRepetitions,
            labels: this.exerciseGroupLabelsFactory.make(showExerciseGroupType(type)),
            type: showExerciseGroupType(type),
            trainingExercises: sortBy(
                prop('position'),
                exercises.map(ex => this.showTrainingExercise(ex))
            )
        };
    }

    public showTrainingExercise({ id, description, position, exercise, sets = [] }: GroupExercise): ITrainingExercise {
        return {
            id,
            description,
            position,
            exercise: this.showExercise(exercise),
            sets: sortBy(
                prop('position'),
                sets.map(s => this.showExerciseSet(s))
            )
        };
    }

    public showExercise(exercise: Exercise): IExercise {
        return {
            id: exercise.id,
            name: exercise.name,
            slug: exercise.slug,
            gifSmall: exercise.gifSmall,
            gifLarge: exercise.gifLarge,
            imageSmall: exercise.imageSmall,
            imageLarge: exercise.imageLarge,
            video: exercise.video,
            averageRating: exercise.averageRating,
            numberOfRatings: exercise.numberOfRatings,
            equipment: exercise.equipment.map(x => showEquipment(x))
        };
    }

    public showExerciseSet({ id, position, restAfterwardsInSeconds, targets }: ExerciseSet): IExerciseSet {
        return {
            id,
            position,
            restAfterwardsInSeconds,
            targets: targets.map(t => this.showExerciseSetTarget(t))
        };
    }

    public showExerciseSetTarget(setTarget: Target): IExerciseSetTarget {
        const { id, type } = setTarget;
        const measurement = showMeasurement(setTarget.measurement);

        switch (type) {
            case GroupTargetType.RANGE:
                return {
                    id,
                    type,
                    measurement,
                    minValue: setTarget.min,
                    maxValue: setTarget.max
                };

            case GroupTargetType.ABSOLUTE:
                return {
                    id,
                    type,
                    measurement,
                    value: setTarget.value
                };
        }
    }
}
