<div class="close-row">
    <funxtion-button (click)="close()"
                     [variant]="BUTTON_VARIANT.CLOSE"
    ></funxtion-button>
</div>
<mat-dialog-content [style.maxHeight]="'unset'">
    <app-exercise-view [exerciseId]="data.exercise.id"
                       [hasCard]="false"
    ></app-exercise-view>
</mat-dialog-content>
