import { Location as ngLocation } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { schemaIncludes } from "@app-config/api-includes.config";
import { Visibility } from '@app-config/model-edit.config';
import { isDMError, isFile, isProspect } from '@app-helpers/narrowing.helpers';
import {
    exerciseGroupSelection,
    trainingExerciseSelection
} from "@app-helpers/training-schema/detail-pane-selection.helpers";
import { AnalyticsEvent, AnalyticsService } from '@app-services/analytics-service/analytics.service';
import { showOwner } from "@app-services/conversion-services/immutable-conversion/generic-api-model-converters";
import { SchemaConverterService } from "@app-services/converter-services/schema-converter/schema-converter.service";
import { DetailPaneSelectionService } from "@app-services/detail-pane-selection/detail-pane-selection.service";
import { ErrorDisplayService } from '@app-services/error-display/error-display.service';
import { ImmutableResourcesService } from "@app-services/immutable-resources/immutable-resources.service";
import { PageHeaderService } from '@app-services/page-header/page-header.service';
import { SchemaPhaseService } from '@app-services/schema-phase/schema-phase.service';
import { TrainingSchemaVMService } from "@app-services/vm/training-schema/training-schema-vm.service";
import { PhaseMap } from "@app-types/training/editor/training-edit.types";
import { IExerciseGroup, IExerciseGroupType } from "@app-types/vm/vm.common.types";
import { IPhase, ITrainingPhase } from "@app-types/vm/vm.schema.types";
import {
    FitnessTrainingType,
    FunxtionApiClientService,
    Phase,
    TrainingSchema
} from '@funxtion/ng-funxtion-api-client';
import { DMError, DMReport, DMRequest } from "@funxtion/ng-funxtion-api-client/lib/services/data/base.service";
import { getSearchId, ModalService, ROLE } from '@funxtion/portal/shared';
import { ConfirmDialogData, ConfirmDialogResult } from '@funxtion/portal/shared/interfaces/confirm-dialog';
import { TranslateService } from '@ngx-translate/core';
import { head, isNil, lensProp, over, pathEq, prop, propEq, reject, set, sortBy } from 'ramda';
import { BehaviorSubject, combineLatest, from, Observable, of, Subscription } from 'rxjs';
import { catchError, first, map as rxMap, skipWhile, take } from 'rxjs/operators';
import { BUTTON_VARIANT } from "src/app/modules/shared/inputConfig";
import { OwnerPickerComponent } from 'src/app/modules/training-schema/components/owner-picker/owner-picker.component';
import { SchemaFactory } from "../../../../factories/training/schema/schema.factory";
import { dialogData, isSingleExerciseGroup } from "./schema-edit.pure";
import { SchemaLensService } from "@app-services/vm-lens-services/schema-lens-service/schema-lens.service";
import { VM_FACTORY_SERVICE, VM_LENS_SERVICE, VM_SERVICE } from "@funxtion/portal/shared";

@Component({
    selector: 'app-schema-edit',
    templateUrl: './schema-edit.component.html',
    providers: [
        DetailPaneSelectionService,
        {
            provide: VM_SERVICE,
            useClass: TrainingSchemaVMService
        },
        {
            provide: VM_LENS_SERVICE,
            useClass: SchemaLensService
        },
        {
            provide: VM_FACTORY_SERVICE,
            useClass: SchemaFactory
        },
        SchemaPhaseService
    ],
    styleUrls: ['./schema-edit.component.scss']
})
export class SchemaEditComponent implements OnInit, OnDestroy {
    type = FitnessTrainingType.TRAINING_SCHEMA;

    public editSchemaForm: FormGroup = new FormGroup({});
    BUTTON_VARIANT = BUTTON_VARIANT;
    ROLE = ROLE;

    private subscriptions: Subscription[] = [];

    // ------------------------------------------------------------------------------
    //      Static data dependencies, initialized on ng-init.
    // ------------------------------------------------------------------------------

    private phases$: Observable<Phase[]>;

    // ------------------------------------------------------------------------------
    //      Computed, altering data flow
    // ------------------------------------------------------------------------------

    public schema$ = new BehaviorSubject<null | TrainingSchema>(null);
    private readonly detailsForm$ = new BehaviorSubject<null | FormGroup>(null);

    public phaseMaps$: Observable<PhaseMap[]>;
    public saving: boolean = false;
    public searchId = getSearchId();

    @ViewChild(OwnerPickerComponent) ownerSelector: OwnerPickerComponent;

    constructor(
        @Inject(VM_SERVICE) public patchService: TrainingSchemaVMService,
        @Inject(VM_LENS_SERVICE) private schemaLenses: SchemaLensService,
        @Inject(VM_FACTORY_SERVICE) private schemaFactory: SchemaFactory,
        private immutableResources: ImmutableResourcesService,
        public detailSelectionService: DetailPaneSelectionService,
        private schemaConverter: SchemaConverterService,
        private funxtion: FunxtionApiClientService,
        private route: ActivatedRoute,
        private router: Router,
        private modal: ModalService,
        private fb: FormBuilder,
        public translateService: TranslateService,
        private errorDisplay: ErrorDisplayService,
        private analytics: AnalyticsService,
        public location: ngLocation,
        public pageHeader: PageHeaderService,
        private schemaPhaseService: SchemaPhaseService
    ) {
    }

    public ngOnInit() {

        this.pageHeader.setup({
            title: this.getPageTitle(),
            filled: false,
            returnLink: null,
        });

        this.phases$ = from(this.immutableResources.getPhases());
        this.subscriptions.push(
            this.funxtion.dataServices.schemaService
                .getById(this.getSchemaId(), schemaIncludes)/*.pipe(tap((schema) => { console.debug('schema', schema); debugger; } ))*/
                .subscribe((schema) => {
                    if (!schema.owner) {
                        schema.owner = this.getPrivateOwner();
                    }

                    this.schema$.next(schema);
                }),
        );

        const schemaNotNull$: Observable<TrainingSchema> = this.schema$.pipe(
            // tap((schema) => { console.debug('Schema: ', schema); debugger; }),
            skipWhile(isNil),
        );

        this.subscriptions.push(
            schemaNotNull$.pipe(
                rxMap((schema) => this.funxtion.dataServices.schemaService.generateForm(this.fb, schema)),
            ).subscribe(this.detailsForm$),
        );

        // Push first schema (after conversion) into the schema patch service.
        this.subscriptions.push(
            schemaNotNull$.pipe(
                // first(),
                take(1),
                rxMap((schema) => this.schemaConverter.toViewModel(schema)),
            ).subscribe((viewSchema) => this.patchService.setRevision(viewSchema)),
        );

        this.phaseMaps$ = combineLatest(
            this.phases$.pipe(rxMap(sortBy(prop('position')))),
            this.patchService.revisions$.pipe(
                skipWhile(isNil),
                rxMap((schema) => schema.trainingPhases ),
            ),
        ).pipe(
            rxMap(([phases, trainingPhases]) => phases.map((phase): PhaseMap => ({
                phase,
                trainingPhase: trainingPhases
                    ? trainingPhases.find((tp) => tp.phase.id === phase.id)
                    : null,
            }))),
        );


        // this.analytics.track(AnalyticsEvent.CREATE_SCHEMA);

        // void this.selectFirstTrainingGroup();
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());

        // unset/clear the current selection to prevent unwanted selections from ending up in the wrong view
        this.detailSelectionService.unsetSelection();
    }

    // ------------------------------------------------------------------------------
    //      Computed
    // ------------------------------------------------------------------------------

    /**
     * Accessor for the most recent TrainingSchema value of this.schema$. Returns null
     * if no schema has been loaded yet. During component lifecycle it should ALWAYS
     * be a TrainingSchema instance.
     */
    public get schema(): TrainingSchema | null {
        return this.schema$.getValue();
    }

    /**
     * Accessor for the current details form instance. A new instance is built each
     * time this.schema$ emits a new revision.
     */
    public get detailsForm(): FormGroup | null {
        return this.detailsForm$.getValue();
    }

    // ------------------------------------------------------------------------------
    //      Add / Delete training-phases
    // ------------------------------------------------------------------------------

    async addTrainingPhase(phase: Phase, groupType: IExerciseGroupType): Promise<ITrainingPhase> {

        const iPhase: IPhase = this.schemaConverter.apiModelConverter.showPhase(phase);

        const trainingPhase = this.schemaFactory.newTrainingPhase(iPhase, []);

        const newSchema = await this.schemaPhaseService.addToTrainingPhase(trainingPhase, groupType);

        // No new schema? Then the user probably closed the dialog without creating anything
        if (!newSchema) {
            return null;
        }

        const newTrainingPhase: ITrainingPhase = newSchema.trainingPhases.find(pathEq(['phase', 'id'], phase.id));
        const newExerciseGroup: IExerciseGroup = newTrainingPhase.trainingGroups[0].exerciseGroup;
        const isSingleTypeGroup: boolean = isSingleExerciseGroup(newExerciseGroup);

        this.detailSelectionService.setSelection(
            isSingleTypeGroup
                ? trainingExerciseSelection(head(newExerciseGroup.trainingExercises), isSingleTypeGroup, newExerciseGroup.type)
                : exerciseGroupSelection(newExerciseGroup),
        );

        return newTrainingPhase;
    }

    private getPageTitle(): string {
        const currentRevision = this.patchService.current();
        return currentRevision && isProspect(currentRevision)
            ? this.translateService.instant('training.schemas.createSchema')
            : this.translateService.instant('training.schemas.editSchema');
    }

    /**
     * Removes given trainingPhase from the schema.
     */
    async deleteTrainingPhase(trainingPhase: ITrainingPhase): Promise<boolean> {

        const { confirmed } = await this.confirmationDialog(
            dialogData.deletePhase(trainingPhase.phase.name),
        );

        if (!confirmed) {
            return false;
        }

        if (this.detailSelectionService.selectionIsWithinTrainingPhase(trainingPhase)) {
            this.detailSelectionService.unsetSelection();
        }

        await this.patchService.transform(
            over(lensProp('trainingPhases'), reject(propEq('id', trainingPhase.id))),
        );

        return true;
    }


    protected getSchemaId(): string {
        const id = this.route.snapshot.paramMap.get('id');
        if (!id || id === 'create') {
            return null;
        }
        return id;
    }

    /**
     * # TO DO!
     */
    async onSchemaSubmit() {

        // First we validate all formgroups
        this.editSchemaForm.updateValueAndValidity();
        this.editSchemaForm.markAsTouched();
        this.editSchemaForm.markAllAsTouched();


        // If the form isn't valid after validation we should not continue
        if (!this.editSchemaForm.valid) {
            return false;
        }

        this.saving = true;
        this.analytics.track(AnalyticsEvent.SAVE_SCHEMA, {
            name: this.detailsForm.value.name,
        });

        const iSchema = this.patchService.current();
        const isNew = !iSchema.id;
        const schema = await this.schemaConverter.fromViewModel(iSchema);

        if (isFile(iSchema.image)) {
            await this.funxtion.datastore.setFileValue(
                schema,
                'image',
                iSchema.image,
            );
        }

        const request: DMRequest<TrainingSchema> = {
            rootModel: schema,
        };

        const response: DMReport<TrainingSchema> | DMError = await (
            this.funxtion.dataServices.schemaService.save(request)
        ).pipe(
            first(),
            catchError((errResponse) => {
                this.saving = false;
                this.errorDisplay.display(errResponse.error);
                return of(null);
            })
        ).toPromise();

        if (!isDMError(response)) {

            this.detailSelectionService.unsetSelection();
            // const savedViewModel = this.schemaConverter.toViewModel(schema);
            return this.router.navigate(['training', 'workouts', response.currentModel.id]);

            //
            // // Todo:
            // // Currently we need to unset the selection because the id of (previously) prospect
            // // models will have changed. Need to keep a record of id's before and after to fix this.
            // this.detailSelectionService.unsetSelection();
            //
            // await this.patchService.setRevision(savedViewModel);
            // this.saving = false;
        }
    }

    // ------------------------------------------------------------------------------
    //      Internal processing
    // ------------------------------------------------------------------------------

    protected confirmationDialog(data: ConfirmDialogData): Promise<ConfirmDialogResult> {
        return this.modal.confirm(data.title, data.message, data.type).toPromise();
    }

    public async handleVisibilitySelection(v: Visibility) {

        let owner;

        switch (v) {
            case Visibility.PRIVATE:
                owner = showOwner(this.getPrivateOwner());
                break;
            case Visibility.PUBLIC:
                owner = showOwner(this.funxtion.client.data.organization);
                break;
        }

        const patch = set(lensProp('owner'), owner);
        await this.patchService.transform(patch);
    }

    private getPrivateOwner() {

        const userData = this.funxtion.user.data;

        return userData.isCoach ? userData.coach :
            userData.isManager ? userData.manager :
                userData.isAdministrator ? userData.administrator :
                    undefined;
    }
}
