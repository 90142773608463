<!-------------------------------------------------------------------------------
The sticky-sentinel element that is observed to determine whether the page is
scrolled by at least the height of this element -- see CSS.
-------------------------------------------------------------------------------->
<div #stickySentinel>

    <div class="page-header--return-link" *ngIf="(pageHeader.config$ | async)?.returnLink as returnLink; else stickySpacer">
        <div class="page-header--content">
            <funxtion-button [variant]="BUTTON_VARIANT.CLEAR_SMALL_ICON" [overrides]="defaultReturnButton" (click)="returnLink.action()">{{ returnLink.label }}</funxtion-button>
        </div>
    </div>

    <ng-template #stickySpacer>
        <div class="sticky-spacer" *ngIf="(pageHeader.config$ | async)?.returnLink"
             [ngClass]="{ scrolled: scrolled, filled: (pageHeader.config$ | async)?.filled }"></div>
    </ng-template>
</div>

<!-------------------------------------------------------------------------------
The page header element that contains the portal outlet for custom headers
-------------------------------------------------------------------------------->
<header class="page-header"
        [hidden]="! hasHeader"
        [ngClass]="{
            hidden: ! (pageHeader.mounted$ | async),
            scrolled: scrolled,
            filled: (pageHeader.config$ | async)?.filled,
            'no-after': true
        }"
>

    <div class="page-header--shadow-mask" [ngClass]="{
        padding: (pageHeader.config$ | async)?.type !== HeaderType.Custom,
        shadow: true
    }">
        <div class="page-header--content">
            <!-- This id must be literal but mind the constant HEADER_PORTAL_HOST_ID -->
            <div id="page-header-portal-host"></div>
        </div>
    </div>
</header>

<!-------------------------------------------------------------------------------
Main page layout: sidebar (left) + main content (right)
-------------------------------------------------------------------------------->
<mat-sidenav-container>

    <mat-sidenav [disableClose]="true"
                 [opened]="true"
                 [fixedInViewport]="true"
                 mode="side"
    >
        <app-side-menu></app-side-menu>
    </mat-sidenav>

    <mat-sidenav-content>
        <div id="main-content" class="container" [ngClass]="{ 'has-header': hasHeader, 'max-width': !fullWidth }">
            <router-outlet></router-outlet>
        </div>
    </mat-sidenav-content>

</mat-sidenav-container>
