import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FitnessTrainingType, Phase } from "@funxtion/ng-funxtion-api-client";
import { ImmutableResourcesService } from "@app-services/immutable-resources/immutable-resources.service";
import { IExerciseGroupType } from "@app-types/vm/vm.common.types";

@Component({
    selector: 'app-phase',
    templateUrl: './phase.component.html',
    styleUrls: ['./phase.component.scss'],
})
export class PhaseComponent implements OnInit {

    @Input()
    public hasTrainingPhase: boolean;

    @Input()
    public phase: Phase;

    @Input() type: FitnessTrainingType;

    @Output()
    public add: EventEmitter<IExerciseGroupType> = new EventEmitter();

    @Output()
    public remove: EventEmitter<void> = new EventEmitter();

    public groupTypes: Promise<IExerciseGroupType[]>;

    constructor(
        private immutableResources: ImmutableResourcesService,
    ) {
    }

    ngOnInit(): void {
        this.groupTypes = this.immutableResources.getGroupTypes(true, this.type);
    }
}
