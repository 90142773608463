<mat-card id="table-container">
    <ng-container *ngIf="!isDuplicating; else duplicating">
        <app-header-content [searchId]="searchId" >
            <div button-slot>
                    <funxtion-button [variant]="BUTTON_VARIANT.PRIMARY_SMALL_ICON"
                                     [overrides]="{ alignIcon: ALIGNMENT.LEFT, iconConfig: { iconName: 'add' } }"
                                     (click)="createTraining()"
                    > {{ 'training.schemas.createSchema' | translate }} </funxtion-button>
            </div>
        </app-header-content>
        <ng-container *ngIf="schemas; else loading">

            <ng-container *ngIf="schemas.length > 0; else empty">

                <app-training-table [schemas]="schemas"
                                    (buttonClicked)="handleTableButtons($event)"
                                    [hasActions]="true"
                ></app-training-table>
                <mat-paginator
                    class="d-flex justify-content-center mt-4 schema-paginator"
                    [length]="paging.total"
                    [pageIndex]="paging.page"
                    (page)="onPageChange($event)"
                    [pageSize]="paging.pageSize"
                    [pageSizeOptions]="paging.pageSizeOptions"
                ></mat-paginator>
            </ng-container>
        </ng-container>
    </ng-container>
</mat-card>

<ng-template #loading>
    <app-loading-spinner
        message="{{ 'loading.trainingSchemas' | translate }}"
    ></app-loading-spinner>
</ng-template>

<ng-template #duplicating>
    <app-loading-spinner *ngIf="currentSchema"
                         message="{{ 'duplicating.training' | translate : { name: currentSchema.name } }}"
    ></app-loading-spinner>
</ng-template>

<ng-template #empty>
    <app-empty-state
        [buttonText]="translate.instant('training.schemas.createSchema')"
        [buttonVariant]="BUTTON_VARIANT.PRIMARY_SMALL_ICON"
        [buttonOverrides]="{
            alignIcon: ALIGNMENT.LEFT,
            iconConfig: {
                iconName: 'add'
            }
        }"
        [title]="translate.instant('empty.schemas.title')"
        [subtitle]="translate.instant('empty.schemas.subtitle')"
        [iconOverrides]="{ iconName: 'training-empty'}"
        (buttonClicked)="gotToCreateSchemaPage()"
    >
    </app-empty-state>
</ng-template>
