import { Injectable } from '@angular/core';
import { SchemaJamConverterService } from "@app-services/conversion-services/schema-conversion/schema-jam-converter.service";
import { SchemaVmConverterService } from "@app-services/conversion-services/schema-conversion/schema-vm-converter.service";
import { TrainingSchema } from "@funxtion/ng-funxtion-api-client";
import { ITrainingSchema } from "@app-types/vm/vm.schema.types";

@Injectable({
    providedIn: 'root',
})
export class SchemaConverterService {

    constructor(
        public readonly apiModelConverter: SchemaJamConverterService,
        public readonly viewModelConverter: SchemaVmConverterService,
    ) {
    }

    public async fromViewModel(viewModel: ITrainingSchema): Promise<TrainingSchema> {
        return await this.viewModelConverter.process(viewModel);
    }

    public toViewModel(apiModel: TrainingSchema): ITrainingSchema {
        return this.apiModelConverter.showTrainingSchema(apiModel);
    }
}
