import { Component, Inject, Input, OnInit } from '@angular/core';
import { IExerciseGroup, IExerciseGroupType } from "@app-types/vm/vm.common.types";
import { ITrainingGroup, ITrainingPhase, ITrainingSchema } from "@app-types/vm/vm.schema.types";
import { any, find, head, last, move, over, pipe, prop, propEq, remove } from 'ramda';
import {
    exerciseGroupSelection,
    isTrainingExerciseSelection,
    trainingExerciseSelection
} from "@app-helpers/training-schema/detail-pane-selection.helpers";
import { isSingleExerciseGroup, writePositions } from "../../../../views/training/schemas/schema-edit/schema-edit.pure";

import { CdkDragDrop } from "@angular/cdk/drag-drop";
import { DetailPaneSelectionService } from "@app-services/detail-pane-selection/detail-pane-selection.service";
import { ExerciseSelectionService } from "@app-services/exercise-selection-service/exercise-selection.service";
import { FitnessTrainingType } from "@funxtion/ng-funxtion-api-client";
import { ImmutableResourcesService } from "@app-services/immutable-resources/immutable-resources.service";
import { SchemaLensService } from "@app-services/vm-lens-services/schema-lens-service/schema-lens.service";
import { SchemaPhaseService } from '@app-services/schema-phase/schema-phase.service';
import { TrainingSchemaVMService } from "@app-services/vm/training-schema/training-schema-vm.service";
import { VMTransformer } from "@app-types/vm.types";
import { vmTrackFunction } from "@app-helpers/vm.helpers";
import { VM_LENS_SERVICE, VM_SERVICE } from "@funxtion/portal/shared";

@Component({
    selector: 'app-training-phase',
    templateUrl: './training-phase.component.html',
    styleUrls: ['./training-phase.component.scss'],
})
export class TrainingPhaseComponent implements OnInit {

    // ------------------------------------------------------------------------------
    //      I/O
    // ------------------------------------------------------------------------------

    @Input()
    public trainingPhase: ITrainingPhase;

    public trainingGroups: ITrainingGroup[];

    public groupTypes: Promise<IExerciseGroupType[]>;

    public scalarTrainingGroup = vmTrackFunction<ITrainingGroup>();

    // ------------------------------------------------------------------------------
    //      Lifecycle
    // ------------------------------------------------------------------------------

    // Exclusive to Trainings
    constructor(
        private exerciseSelectionService: ExerciseSelectionService,
        private immutableResources: ImmutableResourcesService,
        private detailSelectionService: DetailPaneSelectionService,
        @Inject(VM_SERVICE) private patchService: TrainingSchemaVMService,
        @Inject(VM_LENS_SERVICE) private lensService: SchemaLensService,
        private schemaPhaseService: SchemaPhaseService
    ) {
    }

    ngOnInit(): void {
        this.groupTypes = this.immutableResources.getGroupTypes(true, FitnessTrainingType.TRAINING_SCHEMA);
        this.trainingGroups = this.trainingPhase.trainingGroups;
    }

    // ------------------------------------------------------------------------------
    //      View actions
    // ------------------------------------------------------------------------------

    public async moveTrainingGroup({ previousIndex, currentIndex }: CdkDragDrop<string[]>) {

        if (previousIndex === currentIndex) {
            return;
        }

        // Prevent the temporary move back to the original position
        this.trainingGroups = move(
            previousIndex, currentIndex, this.trainingPhase.trainingGroups,
        );

        const patch: VMTransformer<ITrainingSchema> = over(
            this.getTrainingGroupsCollectionLens(),
            pipe(move(previousIndex, currentIndex), writePositions(1)),
        );

        await this.patchService.transform(patch);
    }

    public async addTrainingGroup(groupType: IExerciseGroupType) {

        const newSchema = await this.schemaPhaseService.addToTrainingPhase(this.trainingPhase, groupType);

        // No new schema? Then the user probably closed the dialog without creating anything
        if (!newSchema) {
            return null;
        }

        const verified = pipe<ITrainingPhase[], ITrainingPhase, ITrainingGroup[], ITrainingGroup, IExerciseGroup>(
            find(propEq('id', this.trainingPhase.id)),
            prop('trainingGroups'),
            last,
            prop('exerciseGroup'),
        )(newSchema.trainingPhases);

        const isSingleType = isSingleExerciseGroup(verified);

        this.detailSelectionService.setSelection(
            isSingleType
                ? trainingExerciseSelection(head(verified.trainingExercises), isSingleType, verified.type)
                : exerciseGroupSelection(verified),
        );
    }

    public async removeTrainingGroup(trainingGroup: ITrainingGroup, trainingGroupIndex: number) {

        if (this.detailSelectionService.selectionIsWithinTrainingGroup(trainingGroup)) {
            this.detailSelectionService.unsetSelection();
        }

        const patch = over(
            this.getTrainingGroupsCollectionLens(),
            pipe(remove(trainingGroupIndex, 1), writePositions(1)),
        );

        await this.patchService.transform(patch);
    }

    // ------------------------------------------------------------------------------
    //      View data assertions
    // ------------------------------------------------------------------------------

    groupIsSelected(trainingGroup: ITrainingGroup): boolean {

        if (this.detailSelectionService.groupIsSelected(trainingGroup)) {
            return true;
        }

        // But we also say the group is (visually) selected when it's a single-exercise type group
        // and its (supposedly) first exercise is actually selected, because in that case the exercise
        // is rendered in place of the group name + image stack.

        const currentSelection = this.detailSelectionService.current;

        return isTrainingExerciseSelection(currentSelection)
            && isSingleExerciseGroup(trainingGroup.exerciseGroup)
            && any(propEq('id', currentSelection.value.id), trainingGroup.exerciseGroup.trainingExercises);
    }


    groupIsExpanded(trainingGroup: ITrainingGroup): boolean {
        return this.groupIsSelected(trainingGroup)
            || any(
                (exercise) => this.detailSelectionService.exerciseIsSelected(exercise),
                trainingGroup.exerciseGroup.trainingExercises,
            );
    }

    // ------------------------------------------------------------------------------
    //      Implementation
    // ------------------------------------------------------------------------------

    private getTrainingGroupsCollectionLens() {
        return this.lensService.trainingGroupsCollectionLens(this.trainingPhase);
    }
}
