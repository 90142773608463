import { NgModule } from "@angular/core";
import { LoadingSpinnerComponent } from "./loading-spinner.component";
import { CommonModule } from "@angular/common";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";

@NgModule({
    declarations: [LoadingSpinnerComponent],
    imports: [CommonModule, MatProgressSpinnerModule],
    exports: [LoadingSpinnerComponent],
    providers: [],
})
export class LoadingSpinnerModule {
}
