import { OnDestroy } from "@angular/core";
import { Subject } from "rxjs";

/*
    The purpose of the base view is to make it easy to automatically
    unsubscribe from observables. The view has an unsubscribe property
    that is a subject that emits a value when the view is destroyed

    .pipe(takeUntil(this.unsubscribe))
*/

export class BaseView implements OnDestroy {
    protected unsubscribe: Subject<void> = new Subject<void>();

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
