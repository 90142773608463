<div [@fade]="trainingExercise.id">
    <div class="exercise-details">

        <div class="exercise-image-container" [ngClass]="{ paused: imageDisplay === 'still' }">
            <img class="img-fluid"
                 [attr.alt]="trainingExercise.exercise.name"
                 [attr.src]="imageUrl"
                 (click)="toggleImageDisplay()"
            />
        </div>

        <mat-form-field appearance="outline" class="exercise-notes-field">
            <mat-label>{{ "training.plans.notes" | translate }}</mat-label>
            <textarea matInput placeholder="Note" [formControl]="trainingExerciseForm.get('description')"></textarea>
        </mat-form-field>

    </div>

    <mat-divider inset="true"></mat-divider>

    <h2 class="schema-heading">
        <ng-container *ngIf="useSetLayout">
            Sets
<!--            <div class="schema-sub-heading" *ngIf="isSingleExercise && hasDurationAndRepetitions">-->
<!--                <span>Choose either repetitions or duration: </span>-->
<!--                <span>-->
<!--                    <mat-button-toggle-group [value]="selectedTarget" (change)="handleSelectedTargetChange($event)">-->
<!--                        <mat-button-toggle value="repetitions">repetitions</mat-button-toggle>-->
<!--                        <mat-button-toggle value="duration">duration</mat-button-toggle>-->
<!--                    </mat-button-toggle-group>-->
<!--                </span>-->
<!--            </div>-->
        </ng-container>
    </h2>

    <div class="target-type-toggle-container">

        <ng-container *ngFor="let constraint of targetTypeConstraints">
<!--            *ngIf="selectedTarget !== constraint.measurement.slug"-->
            <div class="target-type-toggle">
                <mat-form-field appearance="outline">
                    <mat-label>{{ constraint.measurement.name }}</mat-label>
                    <mat-select [formControl]="targetTypeConstraintsForm.get(constraint.measurement.slug)">
                        <mat-option [value]="ABS_TARGET_TYPE">Absolute</mat-option>
                        <mat-option [value]="RNG_TARGET_TYPE">Range</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </ng-container>
    </div>

    <div class="sets-list">

        <div class="sets-list-item" *ngFor="let exerciseSet of trainingExercise.sets; index as setIndex; trackBy: scalarSet">

            <app-exercise-set [exerciseSet]="exerciseSet"
                              [exerciseSetLens]="exerciseSetsLenses[setIndex]"
                              [targetTypeConstraints]="targetTypeConstraints"
                              [availableMeasurements]="distinctMeasurements"
                              [referenceNumber]="multipleSetsAllowed ? setIndex + 1 : null"
                              [isCopyable]="multipleSetsAllowed"
                              [isDeletable]="multipleSetsAllowed && trainingExercise.sets.length > 1"
                              (copy)="copySet(setIndex, exerciseSet)"
                              (delete)="deleteSet(setIndex)"
                              [useRestAfterSet]="useRestAfterSet"
                              [useSetLayout]="useSetLayout"
            ></app-exercise-set>
        </div>

        <div class="sets-list-item" *ngIf="hasFreeSetSlots">
            <div class="add-set-button">
                <button mat-mini-fab color="primary" (click)="addSet()">
                    <mat-icon>add</mat-icon>
                </button>

                <span class="label">{{ "training.schemas.addSet" | translate }}</span>
            </div>
        </div>
    </div>

</div>
