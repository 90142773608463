import { BUTTON_VARIANT, ButtonConfig, ICON_COLOR, ICON_SIZE, IconConfig } from "src/app/modules/shared/inputConfig";
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-empty-state',
    templateUrl: './empty-state.component.html',
    styleUrls: ['./empty-state.component.scss'],
})
export class EmptyStateComponent implements OnInit {
    iconConfig: IconConfig = {
        iconSize: ICON_SIZE.XXLARGE,
        iconColor: ICON_COLOR.SUPPORT_5,
    };

    @Input()
    title: string;
    @Input()
    subtitle: string;
    @Input()
    buttonVariant: BUTTON_VARIANT;
    @Input()
    iconOverrides: IconConfig;
    @Input()
    buttonOverrides: ButtonConfig;
    @Input()
    buttonText: string;
    @Output()
    buttonClicked: EventEmitter<void> = new EventEmitter<void>();

    constructor() {
    }

    ngOnInit() {

    }

}
