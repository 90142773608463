<ng-container *ngIf="!isUserOnlyCoach()">
    <ng-container *ngIf="coaches && managers">
        <mat-radio-group>
            <ng-container *ngIf="funxtion.user.data.isAdministrator; else selfManager">
                <mat-radio-button [value]="funxtion.user.data.administrator" (change)="onChange($event)" class="not-ff"
                                  [checked]="amIOwnerAdmin" name="amIAdmin" ngDefaultControl><span
                    class="text-label">{{"common.meAsAdmin" | translate}}</span></mat-radio-button>
            </ng-container>
            <ng-template #selfManager>
                <mat-radio-button [value]="funxtion.user.data.manager" (change)="onChange($event)" class="not-ff"
                                  [checked]="amIOwnerManager" name="amIManager" ngDefaultControl><span
                    class="text-label">{{"common.meAsManager" | translate}}</span></mat-radio-button>
            </ng-template>

            <ng-container *ngIf="coaches && coaches.length > 0">
                <mat-radio-button value="coaches" class="radio-select" (change)="onChange($event)"
                                  [checked]="ownerIsCoach" name="ownerIsCoach" ngDefaultControl>
                    <mat-form-field appearance="outline">
                        <mat-select placeholder="Select coach" #coachesList [compareWith]="compareModels"
                                    [value]="coachSelectValue">
                            <mat-option *ngFor="let coach of coaches" [value]="coach"
                                        (onSelectionChange)="onChange($event)">{{ coach.name }} (as Coach)
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </mat-radio-button>
            </ng-container>

            <ng-container *ngIf="funxtion.user.data.isAdministrator">
                <ng-container *ngIf="managers && managers.length > 0">
                    <mat-radio-button value="managers" class="radio-select" (change)="onChange($event)"
                                      [checked]="ownerIsManager" name="ownerIsManager" ngDefaultControl>
                        <mat-form-field appearance="outline">
                            <mat-select placeholder="Select manager" #managersList [compareWith]="compareModels"
                                        [value]="managerSelectValue">
                                <mat-option *ngFor="let manager of managers" [value]="manager"
                                            (onSelectionChange)="onChange($event)">{{ manager.name }} (as Manager)
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </mat-radio-button>
                </ng-container>
            </ng-container>
            <div>
                <mat-radio-button value="organization" (change)="onChange($event)" [checked]="amIOwnerOrg"
                                  name="amIOwnerOrg" ngDefaultControl><span class="text-label">{{
                    funxtion.client.data.organization.name
                    }}</span></mat-radio-button>
            </div>
        </mat-radio-group>
    </ng-container>
</ng-container>
