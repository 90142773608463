/**
 * @description Function to give components a unique search id.
 *              This allows them to subscribe to only their own search/filter updates
 *              Currently uses a timestamp, but implementation might change in the future
 *
 * @link SearchService._filtersCollection
 */
export function getSearchId(): string {
    return Date.now().toString();
}
