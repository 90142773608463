import { AttributeWriterConfig, IMapper, IMapperChain, Jam, MapperClass, Unit, Vm } from '@app-types/vm-conversion/vm-conversion.types';
import { chainChainTrace, chainMapTrace, traced } from '@app-helpers/decorators/traced/traced.decorator';


export class MapperChain<T extends Vm<U> = Vm, U extends Jam = Jam> implements IMapperChain<T, U> {

    /**
     * All chains that were chained onto this one before.
     */
    private chains: MapperChain[] = [];

    constructor(
        private mappers: IMapper<T, U>[],
    ) {
    }

    /**
     * Makes instances of this class an iterable over the mapper instances.
     * Used in the own chain method for flattening chains over child models.
     */
    [Symbol.iterator] = ((mappers) => function *(): Iterator<IMapper<T, U>> {
        for (const mapper of mappers()) {
            yield mapper;
        }
    })(() => this.mappers);

    /**
     * Returns an array of mapped models obtained by calling map iteratively on all contained mappers
     */
    @traced(chainMapTrace)
    async mapChildren(): Promise<U[]> {

        const result = [];

        for (const mapper of this.mappers) {
            result.push(await mapper.map());
        }

        return result;
    }

    // ------------------------------------------------------------------------------
    //      Interface
    // ------------------------------------------------------------------------------

    @traced(chainChainTrace)
    chain<
        K extends keyof T,
        J extends keyof U,
        R extends Unit<T[K]> & Vm<S>,
        S extends Unit<U[J]> & Jam
        >(vmKey: K, jamKey: J, mapperClass: MapperClass<R, S>): IMapperChain<R, S> {

        const instance = new MapperChain<R, S>(
            this.mappers.reduce((acc, mapper) => [
                ...acc,
                ...mapper.chain(vmKey, jamKey, mapperClass),
            ], []),
        );

        this.chains.push(instance);

        return instance;
    }


    tap(f: (chain: this) => void): this {
        f(this);
        return this;
    }


    setupAttributes(config?: AttributeWriterConfig<U>): this {
        this.mappers.forEach((m) => m.setupAttributes(config));
        return this;
    }
}




