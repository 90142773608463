import { ALIGNMENT, BUTTON_VARIANT } from "src/app/modules/shared/inputConfig";
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
    FunxtionApiClientService,
    TrainingGroup,
    TrainingPhase,
    TrainingSchema
} from '@funxtion/ng-funxtion-api-client';
import { defaultTo, path, pipe, prop, sortBy } from 'ramda';

import { BackendService } from "@app-services/backend-service/backend.service";
import { DurationColumnPipe } from "@app-pipes/column-pipes/duration-column/duration-column.pipe";
import { FormatTimePipe } from '@app-pipes/format-time/format-time.pipe';
import { HeaderType } from "@app-types/page-header.types";
import { PageHeaderService } from '@app-services/page-header/page-header.service';
import { asPromise } from '@app-helpers/api-client.helpers';
import { schemaIncludes } from '@app-config/api-includes.config';
import { DeleteModelService, getSearchId, ModalService } from '@funxtion/portal/shared';
import { isDMError } from '@app-helpers/narrowing.helpers'
import { TrainingSchemaDuplicationService } from '../../../../modules/training-schema'
import { ErrorDisplayService } from '@app-services/error-display/error-display.service'
import { TrainingSchemaService } from '../../../../modules/training-schema/services/training-schema/training-schema.service';
import { filter, flatMap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-schema-view',
    templateUrl: './schema-view.component.html',
    styleUrls: ['./schema-view.component.scss'],
    providers: [
        DurationColumnPipe
    ]
})
export class SchemaViewComponent implements OnInit {

    @Input()
    trainingId?: string;

    @Input()
    usesPortal?: boolean = true;

    @Input()
    useTemplate?: boolean = false;

    @Input()
    hideButtons?: boolean = false;

    @Input()
    portalId?: string = this.backendService.newPortalId.next().value;

    @Output()
    buttonClicked: EventEmitter<string> = new EventEmitter<string>();


    // ------------------------------------------------------------------------------
    //      Template constants
    // ------------------------------------------------------------------------------

    BUTTON_VARIANT = BUTTON_VARIANT;
    ALIGNMENT = ALIGNMENT;


    schema$: Observable<TrainingSchema>;
    schema: TrainingSchema;
    accordionObservable: BehaviorSubject<string>;
    accordionObservables: BehaviorSubject<boolean>[] = [];
    isDuplicating: boolean = false;
    searchId = getSearchId();
    isAllowedToPublish: boolean;
    isPublished: boolean;

    constructor(
        private funxtion: FunxtionApiClientService,
        private route: ActivatedRoute,
        private router: Router,
        private formatTime: FormatTimePipe,
        public pageHeader: PageHeaderService,
        private backendService: BackendService,
        private deleteModelService: DeleteModelService<TrainingSchema>,
        private trainingSchemaDuplicationService: TrainingSchemaDuplicationService,
        private errorDisplayService: ErrorDisplayService,
        private trainingSchemaService: TrainingSchemaService,
        private translate: TranslateService,
        private modalService: ModalService
    ) {}


    /**
     * Get the ID value for the plan that has been navigated to.
     */
    protected getId(): string {
        if (this.trainingId) {
            return this.trainingId;
        }
        return this.route.snapshot.paramMap.get('id');
    }

    editTraining() {
        return this.router.navigate(['training', 'workouts', this.getId(), 'edit']);
    }

    duplicateTraining() {
        return this.buttonClicked.emit('duplicate');
    }

    async ngOnInit(): Promise<void> {
        this.isAllowedToPublish = this.funxtion.user.data.isAdministrator;

        await this.initialize();
    }

    async initialize() {
        const schema = await asPromise(
            this.funxtion.datastore.findRecord(TrainingSchema, this.getId(), { include: schemaIncludes.join(',') })
        );

        this.schema = schema;

        if (this.isAllowedToPublish) {
            this.isPublished = !!this.schema.publishedAt;
        }

        this.pageHeader.setup({
            title: prop('name', schema),
            image: pipe<TrainingSchema, string, string>(prop('image'), defaultTo('/assets/icons/training_default.svg'))(schema),
            type: HeaderType.Detail,
            filled: true,
            returnLink: {
                label: 'Back to overview',
                action: () => this.router.navigate(['training', 'workouts']),
            },
        });

        const firstPhaseId = path<string>(['phases', 0, 'id'], schema) || null;

        this.accordionObservable = new BehaviorSubject<string>(firstPhaseId);
        this.accordionObservables = schema.phases.map((_, i) => new BehaviorSubject<boolean>(i === 0));

        const counts = {};

        schema.phases = (schema.phases ? schema.phases : []).map((phase: TrainingPhase) => {
            phase.groups = (phase.groups ? phase.groups : []).map((group: TrainingGroup) => {
                if (!group.group.isSingle) {
                    counts[group.group.type.name] = counts[group.group.type.name] ? counts[group.group.type.name] + 1 : 1;
                    group.name = `${group.group.type.name} ${counts[group.group.type.name]}`;
                }
                return group;
            });
            phase.groups = sortBy<TrainingGroup>(path(['position']), phase.groups);
            return phase;
        });
        schema.phases = sortBy<TrainingPhase>(path(['phase', 'id']), schema.phases);
    }

    getOffset(index: number, schema: TrainingSchema) {
        let result = 0;
        for (let i = 0; i < index; i++) {
            result += schema.phases[i].groups.length;
        }
        return result;
    }

    phaseExpanded(index: number, [id, expanded]: [string, boolean]) {
        if (expanded) {
            this.accordionObservable.next(id);
            this.accordionObservables.forEach((observable: BehaviorSubject<boolean>, i) => {
                if (i !== index) {
                    observable.next(false);
                } else {
                    observable.next(true);
                }
            });
        }
    }

    handleDeleteAction() {
        this.deleteModelService
            .deleteModelWithDialog(this.schema, TrainingSchema, 'training schema')
            .subscribe(async () => await this.router.navigate(['training', 'workouts']));
    }

    handleDuplicateAction() {
        this.trainingSchemaDuplicationService.duplicate(this.schema, true)
            .subscribe(async (duplicationStatus) => {
                    this.isDuplicating = duplicationStatus.isDuplicating;

                    if (duplicationStatus.response) {
                        const response = duplicationStatus.response;

                        if (isDMError(response)) {
                            this.errorDisplayService.display(response.error);
                        } else {
                            // update the trainingId, see comment below
                            if (this.trainingId) {
                                this.trainingId = response.currentModel.id;
                            }
                            await this.router.navigate([`training/workouts/${response.currentModel.id}`]);
                            // the router doesn't destroy this component on a navigation change,
                            // so just re-initialize all the data to update the view
                            await this.initialize();
                        }
                    }
                }
            );
    }

    publishTrainingSchema() {
        const publishTitle = this.translate.instant('training.schemas.publish.title');
        const publishMessage = this.translate.instant('training.schemas.publish.message');

        this.modalService.confirm(publishTitle, publishMessage, 'warn',{ maxWidth: '600px'})
            .pipe(filter(result => result.confirmed))
            .pipe(flatMap(() => this.trainingSchemaService.publishTrainingSchema(this.schema)))
            .subscribe((trainingSchema: TrainingSchema) => this.processPublishedAtStatus(trainingSchema));
    }

    unpublishTrainingSchema() {
        const unpublishTitle = this.translate.instant('training.schemas.unpublish.title');
        const unpublishMessage = this.translate.instant('training.schemas.unpublish.message');

        this.modalService.confirm(unpublishTitle, unpublishMessage, 'warn', { maxWidth: '600px' })
            .pipe(filter(result => result.confirmed))
            .pipe(flatMap(() => this.trainingSchemaService.unpublishTrainingSchema(this.schema)))
            .subscribe((trainingSchema: TrainingSchema) => this.processPublishedAtStatus(trainingSchema));
    }

    processPublishedAtStatus(trainingSchema: TrainingSchema) {
        this.schema = trainingSchema;
        this.isPublished = !!trainingSchema.publishedAt;
    }
}
