import { emailValidator } from "./email-validator";
import { passwordValidator } from "./password-validator";

export class ValidatorService {
    static getValidatorErrorMessage(
        validatorName: string,
        validatorValue?: any,
    ) {
        let config = {
            required: "Required",
            invalidCreditCard: "Is invalid credit card number",
            invalidEmailAddress: "Invalid email address",
            invalidPassword:
                "Invalid password. Password must be at least 6 characters long, and contain a number.",
            minlength: `Minimum length ${validatorValue.requiredLength}`,
        };

        return config[validatorName];
    }

    static emailValidator = emailValidator;
    static passwordValidator = passwordValidator;
}
