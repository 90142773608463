export const symbolIdGenerator = function* (
    content: (id: number) => string,
    startFrom: number = 1): Iterator<symbol> {

    let i = startFrom;

    while (true) {
        yield Symbol(content(i++));
    }
};

export const prospectIdPool = () => symbolIdGenerator((x) => `VM:Prospect:${x}`);
export const revisionIdPool = () => symbolIdGenerator((x) => `VM:Revision:${x}`);
