import { Pipe, PipeTransform } from '@angular/core';
import { Tag } from "@funxtion/ng-funxtion-api-client";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";

class Tags {
}

@Pipe({
    name: 'tagsColumn'
})
export class TagsColumnPipe implements PipeTransform {

    constructor(private sanitizer: DomSanitizer) {
    }

    transform(tags: Tags[]): SafeHtml {
        return this.sanitizer.bypassSecurityTrustHtml(`
            <div class="tags-column">
                ${tags.map((tag: Tag) => `<span class="tag-name">${tag.name}</span>`).join(' | ')}
            </div>
        `);
    }

}
