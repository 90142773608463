import {
    AfterViewInit,
    ApplicationRef,
    Component,
    ComponentFactoryResolver,
    Injector, Input,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { CdkPortal, DomPortalOutlet } from '@angular/cdk/portal';
import { HEADER_PORTAL_HOST_ID } from '@app-config/dom-layout.config';
import { PageHeaderService } from '@app-services/page-header/page-header.service';
import { HeaderType } from "@app-types/page-header.types";
import { FilterDialogComponent } from "@app-components/dialogs/filter-dialog/filter-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { SearchService } from "@app-services/search-service/search.service";
import { debounceTime } from "rxjs/operators";
import { FormBuilder, FormGroup } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { Filter } from '@app-components/filter/interfaces/filter';

/**
 * This component binds the own template to the main header outlet.
 * This component itself contains content-projection slots for the top-right section, selected with [button-slot]
 * and another one for additional detailed information, selected with [detail-slot].
 */
@Component({
    selector: 'app-header-content',
    templateUrl: './header-content.component.html',
    styleUrls: ['./header-content.component.scss'],
})
export class HeaderContentComponent implements OnInit, AfterViewInit, OnDestroy {

    // ------------------------------------------------------------------------------
    //      DOM Portal connection points
    // ------------------------------------------------------------------------------
    //
    //      This connection makes sure that the entire component gets rendered
    //      inside the header element, which is outside the scope of most root
    //      components.

    /**
     * The portal template reference to inject into the header outlet
     */
    @ViewChild(CdkPortal, /* TODO: add static flag */ {})
    private portal: CdkPortal;

    searchPlaceholderKey: string = 'common.search';

    /**
     * The portal outlet for the projected content, located in the default template.
     */
    private domPortalOutlet: DomPortalOutlet;

    public HeaderType = HeaderType;
    public searchForm: FormGroup;
    public showFilterButton = false;

    @Input()
    isDialog?: boolean = false;

    @Input() searchId = 'default';

    _portalId: string;

    get portalId(): string {
        if (!this._portalId) {
            this.portalId = HEADER_PORTAL_HOST_ID
        }
        return this._portalId;
    }

    @Input()
    set portalId(id: string) {
        this._portalId = id;
        setTimeout(() => {
            const parent = document.getElementById(this.portalId);
            const child = document.getElementById(`${this.portalId}-content`);
            let outlet;
            if (child) {
                outlet = child;
            } else if (parent) {
                outlet = document.createElement('div');
                outlet.id = `${this.portalId}-content`;
                parent.appendChild(outlet);
            }
            if (outlet && outlet.childElementCount === 0) {
                this.domPortalOutlet = new DomPortalOutlet(
                    outlet,
                    this.componentFactoryResolver,
                    this.applicationRef,
                    this.injector,
                );

                this.domPortalOutlet.attach(this.portal);
            }
        }, 0);
     }

    @Input()
    usePortal?: boolean = true;


    // ------------------------------------------------------------------------------
    //      Lifecycle
    // ------------------------------------------------------------------------------

    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private applicationRef: ApplicationRef,
        private injector: Injector,
        public pageHeader: PageHeaderService,
        private dialog: MatDialog,
        public search: SearchService,
        private fb: FormBuilder,
        public translate: TranslateService,
    ) {
    }

    ngOnInit(): void {
        // if (this.dialog.openDialogs.length >= 2) {
        //     this.usePortal = false;
        // }
        this.searchForm = this.fb.group({
            query: [''],
        });
        this.searchForm.valueChanges.pipe(debounceTime(250)).subscribe((values) => {
            this.search.setQuery(this.searchId, values.query);
        });

        // check if filter button should be shown
        this.showFilterButton = this.areFiltersAvailable();
    }

    /**
     * After the view has been initialized we can initialize the portal.
     */
    ngAfterViewInit(): void {
        if (!this._portalId) {
            this.portalId = HEADER_PORTAL_HOST_ID
        }
    }

    ngOnDestroy(): void {
        try {
            this.domPortalOutlet.dispose();
        } catch {

        }
    }

    areFiltersAvailable(): boolean {
        const filters: Filter[] = this.search.getFilter(this.searchId);
        return filters && filters.length > 0;
    }

    openFilterDialog($event) {
        const bounding: DOMRect = $event.currentTarget.getBoundingClientRect();
        const dialogRef = this.dialog.open(FilterDialogComponent, {
            backdropClass: "clear-backdrop",
            restoreFocus: false,
            width: "300px",
            position: {
                top: `${bounding.top + bounding.height}px`,
                left: `${bounding.left + bounding.width - 300}px`,
            },
            data: {
                filters: this.search.getFilter(this.searchId)
            },
        });
        dialogRef.afterClosed().subscribe(filters => {
            if (filters) {
                this.search.setFilter(this.searchId, filters);
            }
        });
    }
}
