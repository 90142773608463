import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs'
import { filter, map } from 'rxjs/operators'
import { Filter } from '@app-components/filter/interfaces/filter';

export interface SearchFilterQuery {
    id: string;
    query: string;
    filters: Filter[];
}

export interface SearchQuery {
    id: string;
    query: string;
}

export interface FilterQuery {
    id: string;
    filters: Filter[];
}

@Injectable({
    providedIn: 'root',
})
export class SearchService {

    private _queryUpdated: BehaviorSubject<SearchQuery> = new BehaviorSubject<SearchQuery>({ id: 'default', query: '' });

    /**
     * Observable for updated queries
     * NOTE: values aren't filtered on id, this is responsibility of the subscriber
     */
    public queryUpdated: Observable<SearchQuery> = this._queryUpdated.asObservable();

    /**
     * Collection of queries with unique identifiers, this allows each component to only subscribe to
     * it's own updated queries
     */
    private _searchCollection: Map<string, string> = new Map<string, string>();

    private _filtersUpdated: BehaviorSubject<FilterQuery> = new BehaviorSubject<FilterQuery>({ id: 'default', filters: [] });

    /**
     * Observable for updated filters
     * NOTE: values aren't filtered on id, this is responsibility of the subscriber
     */
    public filtersUpdated: Observable<FilterQuery> = this._filtersUpdated.asObservable();

    /**
     * Collection of filters with unique identifiers, this allows each component to only subscribe to
     * it's own updated filters
     */
    private _filtersCollection: Map<string, Filter[]> = new Map<string, Filter[]>();

    constructor() {
    }

    getQuery(id: string): string {
        return this._searchCollection.get(id);
    }

    /**
     * Adds a query to the search collection
     *
     * @param id - identifier of the search query
     * @param query - value to search for
     * @param emitUpdatedQuery - whether the query should be published to subscribers
     */
    setQuery(id: string, query: string, emitUpdatedQuery = true): void {
        // only set changed queries
        if (query === this._searchCollection.get(id)) {
            return;
        }

        this._searchCollection.set(id, query);

        if (emitUpdatedQuery) {
            this._queryUpdated.next({ id, query });
        }
    }

    removeQuery(id: string): boolean {
        return this._searchCollection.delete(id);
    }

    getFilter(id: string): Filter[] {
        return this._filtersCollection.get(id);
    }

    /**
     * Adds a filter to the filters collection
     *
     * @param id - identifier of the filter
     * @param filters - that should be applied
     * @param emitUpdatedFilter - whether the filters should be published to subscribers
     */
    setFilter(id: string, filters: Filter[], emitUpdatedFilter = true): void {
        this._filtersCollection.set(id, filters);

        if (emitUpdatedFilter) {
            this._filtersUpdated.next({ id, filters });
        }
    }

    /**
     * Observable to update subscribers about updated queries & filters for the specified id
     */
    public searchUpdated(id: string): Observable<SearchFilterQuery> {
        return combineLatest([
            this.queryUpdated.pipe(filter(query => query.id === id)),
            this.filtersUpdated.pipe(filter(filters => filters.id === id))
        ]).pipe(
            map(([queryResult, filtersResult]): SearchFilterQuery => {
                return {
                    id,
                    query: queryResult.query,
                    filters: filtersResult.filters
                };
            }),
        );
    }

    /**
     * @returns true if the filter is removed
     */
    removeFilter(id: string): boolean {
        return this._filtersCollection.delete(id);
    }
}
