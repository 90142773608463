import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { AnalyticsEvent, AnalyticsService } from './services/analytics-service/analytics.service';
import { filter, map, takeUntil, tap } from 'rxjs/operators';

import { BaseView } from './views/base-view';
import { Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { EnvironmentService } from '@app-services/environment/environment.service';
import { FunxtionApiClientService } from '@funxtion/ng-funxtion-api-client';
import { InitializeService } from "@funxtion/portal/shared/services/initialize-service/initialize.service";
import { MatDialog } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { LogService } from "./feature-modules/error-handling/services/log/log.service";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent extends BaseView {
    error;
    errorCode;
    header = 'logo';
    loaded = false;
    subs: Array<Subscription> = [];
    title = 'dashboard';

    constructor(
        environmentService: EnvironmentService,
        funxtion: FunxtionApiClientService,
        route: ActivatedRoute,
        router: Router,
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer,
        translate: TranslateService,
        analytics: AnalyticsService,
        dialog: MatDialog,
        initializeService: InitializeService,
        log: LogService
    ) {
        super();
        translate.setDefaultLang('en');

        funxtion.datastore.error$
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(error => {
                log.notify(error);
                this.error = error;
                this.errorCode = -1;
                if (error?.errors.length) {
                    this.errorCode = error.errors[0].status;
                }
            });

        initializeService.initPortal().subscribe(() => this.loaded = true, (error) => {
            log.notify(error);
            this.error = true;
            this.errorCode = -1;
            if (error.name === "HttpErrorResponse") {
                this.errorCode = 500;
            }
        })

        this.subs[0] = router.events
            .pipe(
                filter(event => event instanceof NavigationEnd),
                tap(() => dialog.closeAll()),
                map(() => route.snapshot),
                map((r) => {
                    while (r.firstChild) {
                        r = r.firstChild;
                    }
                    return r;
                }),
                takeUntil(this.unsubscribe),
            )
            .subscribe((snapshot: ActivatedRouteSnapshot) => this.header = snapshot.data.header);

        router.events
            .pipe(
                filter(event => event instanceof NavigationEnd),
                takeUntil(this.unsubscribe),
            )
            .subscribe((event: NavigationEnd) => {
                analytics.track(AnalyticsEvent.PAGEVIEW, {
                    url: event.url,
                });
            });
        this.registerIcons();
    }

    registerIcon(name, path) {
        this.matIconRegistry.addSvgIcon(
            name,
            this.domSanitizer.bypassSecurityTrustResourceUrl(path),
        );
    }

    registerIcons() {
        this.registerIcon('valid', '../assets/icons/valid.svg');
        this.registerIcon('invalid', '../assets/icons/invalid.svg');
        this.registerIcon('arrow_down', '../assets/icons/arrow_down.svg');
        this.registerIcon('notification_bell', '../assets/icons/bell.svg');
        this.registerIcon('faq', '../assets/icons/FAQ.svg');
        this.registerIcon('search', '../assets/icons/search.svg');
        this.registerIcon('dashboard', '../assets/icons/dashboard.svg');
        this.registerIcon('members', '../assets/icons/members.svg');
        this.registerIcon('members_filled', '../assets/icons/members_filled.svg');
        this.registerIcon('management', '../assets/icons/management.svg');
        this.registerIcon('management_filled', '../assets/icons/management_filled.svg');
        this.registerIcon('training', '../assets/icons/training.svg');
        this.registerIcon('training_filled', '../assets/icons/training_filled.svg');
        this.registerIcon('chat', '../assets/icons/chat.svg');
        this.registerIcon('chat_filled', '../assets/icons/chat_filled.svg');
        this.registerIcon('nutrition', '../assets/icons/nutrition.svg');
        this.registerIcon('collapse', '../assets/icons/collapse.svg');
        this.registerIcon('expand', '../assets/icons/expand.svg');
        this.registerIcon('funxtion', '../assets/icons/funxtion.svg');
        this.registerIcon('filter', '../assets/icons/filter.svg');
        this.registerIcon('delete', '../assets/icons/delete.svg');
        this.registerIcon('move_handle', '../assets/icons/move.svg');
        this.registerIcon('email', '../assets/icons/email.svg');
        this.registerIcon('padlock', '../assets/icons/padlock.svg');
        this.registerIcon('menu_horizontal', '../assets/icons/menu_horizontal.svg');
        this.registerIcon('edit', '../assets/icons/edit.svg');
        this.registerIcon('copy', '../assets/icons/copy.svg');
        this.registerIcon('document', '../assets/icons/document.svg');
        this.registerIcon('download', '../assets/icons/download.svg');
        this.registerIcon('settings', '../assets/icons/settings.svg');
        this.registerIcon('settings_filled', '../assets/icons/settings_filled.svg');
    }
}
