import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ALIGNMENT, BUTTON_VARIANT } from "src/app/modules/shared/inputConfig";

export interface IDeleteModelDialogConfig {
    title: string;
    message: string;
}

@Component({
    selector: 'app-delete-model-dialog',
    templateUrl: './duplicate-model-dialog.component.html',
    styleUrls: ['./duplicate-model-dialog.component.scss'],
})
export class DuplicateModelDialogComponent implements OnInit {
    BUTTON_VARIANT = BUTTON_VARIANT;
    ALIGNMENT = ALIGNMENT;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: IDeleteModelDialogConfig,
        private matDialog: MatDialogRef<DuplicateModelDialogComponent>,
    ) {
    }

    ngOnInit() {
    }

    done(value: boolean) {
        this.matDialog.close(value);
    }
}
