import { ITrainingPlanDay } from '@app-types/vm/vm.training.types';
import { TrainingPlanDay, TrainingSchema } from '@funxtion/ng-funxtion-api-client';
import { AbstractModelMapper } from '@app-services/conversion-services/AbstractModelMapper';
import { AttributeMap, RelationMap } from '@app-types/vm-conversion/vm-conversion.types';


export class TrainingPlanDayMapper extends AbstractModelMapper<ITrainingPlanDay, TrainingPlanDay> {

    public readonly attributes: AttributeMap<ITrainingPlanDay, TrainingPlanDay> = {
        position: 'position',
    };

    public readonly relationships: RelationMap<ITrainingPlanDay, TrainingPlanDay>[] = [
        {
            mutable: false,
            type: 'one',
            vmKey: 'schema',
            jamKey: 'schema',
            jamClass: TrainingSchema,
            inverse: false,
        },
    ];
}
