import { IOwner } from '@app-types/vm/vm.immutable.types';
import { ModelType } from 'angular2-jsonapi';
import { Jam } from '@app-types/vm-conversion/vm-conversion.types';
import { Administrator, Coach, Manager, Member, Organization } from '@funxtion/ng-funxtion-api-client';

type OwnerType = 'member' | 'organization' | 'administrator' | 'coach' | 'manager';

type OwnerTypeMap = {
    [K in OwnerType]: ModelType<Jam>;
};

const ownerTypes: OwnerTypeMap = {
    member: Member,
    organization: Organization,
    administrator: Administrator,
    coach: Coach,
    manager: Manager,
};

export const resolveOwnerType = (owner: IOwner): ModelType<Jam> => ownerTypes[owner.type];
