import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { FunxtionApiClientService } from "@funxtion/ng-funxtion-api-client";

@Injectable({
    providedIn: "root",
})
export class RoleGuard implements CanActivate {
    constructor(
        private funxtion: FunxtionApiClientService,
        private router: Router,
    ) {
    }

    async isAuthorised(roles): Promise<boolean> {
        if (!this.funxtion.user.data.id) {
            await this.funxtion.user.getUser();
        }

        return roles.some((role: string) => {
            return !!this.funxtion.user.data.roles.find(
                val => val.modelConfig.type === role,
            );
        });
    }

    async canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Promise<boolean> {
        const hasRoles = await this.isAuthorised(next.data.roles);
        if (!hasRoles) {
            this.router.navigate(["/"]);
        }
        return hasRoles;
    }
}
