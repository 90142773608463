import { Component, Inject, Input, OnChanges, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { animate, style, transition, trigger } from '@angular/animations';
import { lensProp, mergeDeepLeft, over, set } from 'ramda';

import { IExerciseGroup } from '@app-types/vm/vm.common.types';
import { SchemaLensService } from '@app-services/vm-lens-services/schema-lens-service/schema-lens.service';
import { TrainingSchemaVMService } from '@app-services/vm/training-schema/training-schema-vm.service';
import { debounceTime, tap } from 'rxjs/operators';
import { WorkoutVMService } from 'src/app/modules/workouts/services/workout/workout-vm.service';
import { VM_LENS_SERVICE, VM_SERVICE } from '@funxtion/portal/shared/injection-tokens/vm-tokens';

// This union is intended as type decoration for the FormGroup of this component.
// Together with the getFormControl method it provides full type completion for
// form-control bindings in the template.
type ExerciseGroupFormKey = 'numberOfRounds' | 'restAfterSetInSeconds' | 'restAfterExerciseInSeconds' | 'durationInSeconds' | 'targetRepetitions';

// The intersection type guard here is to make sure that all items are both
// a key in IExerciseGroup and part of the ExerciseGroupFormKey union.
const formProps: Array<keyof IExerciseGroup> & Array<ExerciseGroupFormKey> = [
    'numberOfRounds',
    'restAfterSetInSeconds',
    'restAfterExerciseInSeconds',
    'durationInSeconds',
    'targetRepetitions'
];

@Component({
    selector: 'app-exercise-group-form',
    templateUrl: './exercise-group-form.component.html',
    styleUrls: ['./exercise-group-form.component.scss'],
    animations: [trigger('fade', [transition('* => *', [style({ opacity: 0 }), animate('200ms ease-in')])])]
})
export class ExerciseGroupFormComponent implements OnChanges, OnDestroy {
    @Input()
    exerciseGroup: IExerciseGroup;

    @Input()
    public parentFormGroup: FormGroup;

    exerciseGroupForm: FormGroup;

    constructor(
        private formBuilder: FormBuilder,
        @Inject(VM_SERVICE) private patchService: TrainingSchemaVMService,
        @Inject(VM_LENS_SERVICE) private vmLenses: SchemaLensService
    ) {}

    ngOnChanges() {
        if (this.exerciseGroup.type.requiresNumberOfRounds && !this.exerciseGroup.numberOfRounds) {
            this.exerciseGroup.numberOfRounds = 1;
        }

        this.createExerciseGroupForm();
        let exerciseGroup: IExerciseGroup;
        this.exerciseGroupForm.valueChanges.pipe(
            tap(() => exerciseGroup = this.exerciseGroup),
            debounceTime(1000),
            ).subscribe(values => {
            this.patchExerciseGroup(values, exerciseGroup);
        });
    }

    ngOnDestroy() {
        this.parentFormGroup.removeControl(`exerciseGroup-${this.exerciseGroup.id.toString()}`);
    }

    public get exercisesLength(): number {
        return this.exerciseGroup.trainingExercises.length;
    }

    private createExerciseGroupForm() {
        const exerciseGroup = formProps.reduce((formModel: object, prop: keyof IExerciseGroup & ExerciseGroupFormKey) => {
            return { ...formModel, [prop]: new FormControl(this.exerciseGroup[prop]) };
        }, {});

        this.exerciseGroupForm = this.formBuilder.group(exerciseGroup);
        this.parentFormGroup.addControl(`exerciseGroup-${this.exerciseGroup.id.toString()}`, this.exerciseGroupForm);
    }

    getFormControl(key: ExerciseGroupFormKey): FormControl {
        return this.exerciseGroupForm.get(key) as FormControl;
    }

    private async patchExerciseGroup(values: Partial<IExerciseGroup>, exerciseGroup: IExerciseGroup): Promise<void> {
        await this.patchService.transform(over(this.vmLenses.exerciseGroupLens(exerciseGroup), mergeDeepLeft(values)));
        // patch the durationInSeconds in the GI section for challenges (workouts)
        if (this.patchService instanceof WorkoutVMService && values.durationInSeconds) {
            await this.patchService.transform(set(lensProp('durationInSeconds'), values.durationInSeconds));
        }
    }
}
