import { IGender, ITrainingSchemaType } from '@app-types/vm/vm.immutable.types';
import { ITrainingPlan } from '@app-types/vm/vm.training.types';
import { ITrainingSchema } from '@app-types/vm/vm.schema.types';
import { Validators } from '@angular/forms';

export const getTrainingAttributesForm = (schema: ITrainingSchema = null, genders: IGender[] = [], types: ITrainingSchemaType[] = []) => ({
    name: [schema ? schema.name : '', Validators.required],
    image: [schema ? schema.image : null],
    goal: [schema ? schema.goal : null, Validators.required],
    intensity: [schema ? schema.intensity : 6, Validators.required],
    types: [schema && schema.types ? schema.types : []],
    level: [schema?.level ? schema.level : []],
    descriptionShort: [schema ? schema.descriptionShort : '', Validators.required],
    descriptionLong: [schema ? schema.descriptionLong : '', Validators.required],
    durationInSeconds: [schema ? schema.durationInSeconds : 216000, Validators.required],
    genders: [schema && schema.genders ? schema.genders : [], Validators.required],
    energyExpenditureInKiloCalories: [schema ? schema.energyExpenditureInKiloCalories : 0, Validators.required],
    tags: [schema?.tags ? schema.tags : []]
});

export const getTrainingPlanAttributesForm = (trainingPlan: ITrainingPlan = null) => {
    return {
        name: [trainingPlan ? trainingPlan.name : '', Validators.required],
        image: [trainingPlan ? trainingPlan.image : null],
        goal: [trainingPlan ? trainingPlan.goal : null, Validators.required],
        intensity: [trainingPlan ? trainingPlan.intensity : null, Validators.required],
        descriptionShort: [trainingPlan ? trainingPlan.descriptionShort : '', Validators.required],
        descriptionLong: [trainingPlan ? trainingPlan.descriptionLong : '', Validators.required],
        level: [trainingPlan ? trainingPlan.level : null, Validators.required],
        locations: [trainingPlan && trainingPlan.locations ? trainingPlan.locations : [], Validators.required],
        tags: [trainingPlan?.tags ? trainingPlan.tags : []]
    };
};
