export enum HeaderType {
    None     = 'ht-none',
    Overview = 'ht-overview',
    Detail   = 'ht-detail',
    Edit     = 'ht-edit',
    Custom   = 'ht-custom',
}

/**
 * All title sizes used for the page header.
 */
export enum HeaderTitleSize {
    Large  = 38,
    Medium = 28,
    Small  = 22,
}

export interface HeaderReturnLink {
    label: string;
    icon?: string;
    action: () => void;
}

export interface HeaderConfig {
    type: HeaderType;
    title: string;
    image: string;
    returnLink: HeaderReturnLink | null;
    filled: boolean;
}
