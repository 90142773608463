import { Pipe, PipeTransform } from '@angular/core';
import { Level } from '@funxtion/ng-funxtion-api-client';
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";

@Pipe({
    name: 'levelColumn',
})
export class LevelColumnPipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) {
    }

    getLevelColor(level: Level, allLevels: Level[]): { levelPosition: number, levelColor: string } {
        const levelPosition = Math.ceil((level.position / allLevels.length) * 3);
        switch (levelPosition) {
            case 1:
                return { levelPosition, levelColor: "--color-level-low" };
            case 2:
                return { levelPosition, levelColor: "--color-level-medium" };
            case 3:
                return { levelPosition, levelColor: "--color-level-high" };
        }
    }

    transform(level: Level, allLevels: Level[] = []): SafeHtml {
        if (allLevels.length === 0 || !level) {
            return '';
        }
        const { levelColor, levelPosition } = this.getLevelColor(level, allLevels);
        // Index things to turn off color of later bars
        const maxWidth = (allLevels.length * 22) + ((allLevels.length - 1) * 2);
        return this.sanitizer.bypassSecurityTrustHtml(`
            <div class="level-column">
                <div class="level-name">${level.name}</div>
                <div class="level-column-indicator" style="max-width: ${maxWidth}px">
                    ${allLevels.map((currentLevel) => {
                        return `<div class="indicator indicator-${currentLevel.name.toLowerCase().replace(/\s/, '-')}">
                            ${currentLevel.id === level.id
                                ?   `<span style="color: var(${levelColor});" class="icon icon-traingle-indicator">
                                        <span class="path1"></span><span class="path2"></span>
                                    </span>`
                                : ''
                            }
                        </div>`;
        }).join('')}
                </div>
                <div class="level-column-levels" style="max-width: ${maxWidth}px">
                    ${allLevels.map((currentLevel, index) => {
            return `<div style="background-color: var(${levelPosition >= (index + 1) ? levelColor : '--color-support-tint-2'});" class="level level-${currentLevel.name.toLowerCase().replace(/\s/, '-')}"></div>`;
        }).join('')}
                </div>
            </div>
        `);
    }

}
