import { Inject, Injectable } from '@angular/core';
import { setDetailSelectionTrace, traced, unsetDetailSelectionTrace } from '@app-helpers/decorators/traced/traced.decorator';
import { exerciseGroupSelection, isExerciseGroupSelection, isNoneSelection, isTrainingExerciseSelection, NONE_DETAIL_SELECTION, trainingExerciseSelection } from "@app-helpers/training-schema/detail-pane-selection.helpers";
import { SchemaLensService } from "@app-services/vm-lens-services/schema-lens-service/schema-lens.service";
import { DetailPaneSelection, DetailPaneSelectionType } from "@app-types/training/editor/detail-pane-selection.types";
import { IViewModel } from "@app-types/vm.types";
import { IExerciseGroup, ITrainingExercise } from "@app-types/vm/vm.common.types";
import { ITrainingGroup, ITrainingPhase, ITrainingSchema } from "@app-types/vm/vm.schema.types";
import { IWorkout } from "@app-types/vm/vm.workout.types";
import { any, eqBy, equals, path, view } from 'ramda';
import { BehaviorSubject, Observable } from "rxjs";
import { distinctUntilChanged } from "rxjs/operators";
import { VM_LENS_SERVICE, VM_SERVICE } from "@funxtion/portal/shared/injection-tokens/vm-tokens";
import { VMService } from "@app-services/vm/vm.service";


/**
 * This services keeps track of the current model selection for detailed viewing and editing.
 * It also provides the methods to set and unset the current selection.
 *
 * Note: Be aware of the 2 distinct comparison strategies that are used here:
 *
 * - R.equals                     = full shape equality check
 * - this.selectionsAreEquivalent = Check for selection-type & value ID matches
 */
@Injectable()
export class DetailPaneSelectionService {

    public readonly selection$: Observable<DetailPaneSelection>;
    private readonly _selection$: BehaviorSubject<DetailPaneSelection>;

    public get current(): DetailPaneSelection {
        return this._selection$.getValue();
    }

    constructor(
        @Inject(VM_SERVICE) private vm: VMService,
        @Inject(VM_LENS_SERVICE) private lensService: SchemaLensService,
    ) {
        this._selection$ = new BehaviorSubject<DetailPaneSelection>({ type: DetailPaneSelectionType.None });
        this.selection$ = this._selection$.asObservable().pipe(
            distinctUntilChanged<DetailPaneSelection>(equals),
        );
        vm.revisions$.subscribe((schema) => this.updateSelectionContentFromSchema(schema));
    }

    /**
     * Sets the current detail selection to the given selection object.
     */
    @traced(setDetailSelectionTrace)
    public setSelection(selection: DetailPaneSelection, unsetIfEqual: boolean = false): void {
        if (equals(selection, this.current)) {
            if (unsetIfEqual) {
                this.unsetSelection(); // Assume toggle off
            }
        } else {
            this._selection$.next(selection);
        }
    }

    /**
     * Un-sets the current detail-selection, if any.
     */
    @traced(unsetDetailSelectionTrace)
    public unsetSelection(): void {
        this._selection$.next(NONE_DETAIL_SELECTION);
    }

    // ------------------------------------------------------------------------------
    //      State assertions
    // ------------------------------------------------------------------------------

    /**
     * Tells whether given exercise-group is currently selected for display / editing
     * in the editor detail pane.
     */
    public exerciseGroupIsSelected(exerciseGroup: IExerciseGroup): boolean {
        return this.selectionsAreEquivalent(
            this.current, exerciseGroupSelection(exerciseGroup),
        );
    }

    /**
     * Tells whether given training-group is currently selected for display / editing
     * in the editor detail pane.
     */
    public groupIsSelected(trainingGroup: ITrainingGroup): boolean {
        return this.exerciseGroupIsSelected(trainingGroup.exerciseGroup);
    }

    /**
     * Tells whether given exercise is currently selected for display / editing
     * in the editor detail pane.
     */
    public exerciseIsSelected(exercise: ITrainingExercise): boolean {

        return this.selectionsAreEquivalent(
            // Note: the boolean value for context is irrelevant here. Gotta make that prettier in time...
            this.current, trainingExerciseSelection(exercise, true),
        );
    }

    public selectionIsWithinTrainingPhase(trainingPhase: ITrainingPhase): boolean {

        if (isNoneSelection(this.current)) {
            return false;
        }

        return any((group) => this.selectionIsWithinTrainingGroup(group), trainingPhase.trainingGroups);
    }

    public selectionIsWithinTrainingGroup(trainingGroup: ITrainingGroup): boolean {

        if (isNoneSelection(this.current)) {
            return false;
        }

        return this.groupIsSelected(trainingGroup)
            || any((exercise) => this.exerciseIsSelected(exercise), trainingGroup.exerciseGroup.trainingExercises);
    }

    // ------------------------------------------------------------------------------
    //      Private implementation
    // ------------------------------------------------------------------------------

    /**
     * Tells whether the two given selection objects represent the same model.
     *
     * Note that this does NOT check whether the model attributes / nested relations
     * are also identical!
     */
    private selectionsAreEquivalent(selectionA: DetailPaneSelection, selectionB: DetailPaneSelection): boolean {
        if (selectionA.type !== selectionB.type) {
            return false;
        }

        return isNoneSelection(selectionA) // either really, as they must be the same type now
            || eqBy(path(['value', 'id']), selectionA, selectionB);
    }

    /**
     *
     */
    public updateSelectionContentFromSchema(model: ITrainingSchema | IWorkout | IViewModel): void {

        const current = this.current;

        if (isNoneSelection(current)) {
            return;
        }

        if (isTrainingExerciseSelection(current)) {

            const lens = this.lensService.trainingExerciseLens(current.value);
            const trainingExercise: ITrainingExercise = view(lens, model);

            return this.setSelection(
                trainingExerciseSelection(trainingExercise, current.context.multipleSetsAllowed, current.context.groupType),
            );
        }

        if (isExerciseGroupSelection(current)) {

            const lens = this.lensService.exerciseGroupLens(current.value);
            const exerciseGroup: IExerciseGroup = view(lens, model);

            return this.setSelection(
                exerciseGroupSelection(exerciseGroup),
            );
        }
    }



}
