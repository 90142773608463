import { Component, HostBinding, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { IAbsoluteExerciseSetTarget, IExerciseSetTarget, IRangeExerciseSetTarget, MeasurementSlug } from "@app-types/vm/vm.common.types";
import { GroupTargetType } from "@funxtion/ng-funxtion-api-client";
import { complement, equals, isNil, Lens, path, set } from 'ramda';
import { filter as rxFilter, skipWhile } from "rxjs/operators";
import { fallbackStrategy, strategies } from "./TargetStrategies/TargetStrategy.config";
import { ITargetStrategy, TargetControl, TargetValueDefaults, TargetValueReaders } from "./TargetStrategies/TargetStrategy.types";
import { VMService } from "@app-services/vm/vm.service";
import { VM_SERVICE } from "@funxtion/portal/shared";


@Component({
    selector: 'app-exercise-set-target',
    templateUrl: './exercise-set-target.component.html',
    styleUrls: ['./exercise-set-target.component.scss'],
})
export class ExerciseSetTargetComponent implements OnInit, OnDestroy {

    // ------------------------------------------------------------------------------
    //      I/O
    // ------------------------------------------------------------------------------
    @Input()
    public target: IExerciseSetTarget;

    @Input()
    public targetLens: Lens;

    // ------------------------------------------------------------------------------
    //      Template constants
    // ------------------------------------------------------------------------------

    public readonly ABS_TARGET_TYPE: GroupTargetType = GroupTargetType.ABSOLUTE;
    public readonly RNG_TARGET_TYPE: GroupTargetType = GroupTargetType.RANGE;

    public strategy: ITargetStrategy;

    minValueControls: TargetControl[];
    maxValueControls: TargetControl[];
    absValueControls: TargetControl[];

    @HostBinding('class')
    private columnClass;

    constructor(
        @Inject(VM_SERVICE) private readonly patchService: VMService
    ) {
    }

    ngOnInit() {
        this.strategy = this.makeStrategy();

        this.minValueControls = this.strategy.getControls('minValue');
        this.maxValueControls = this.strategy.getControls('maxValue');
        this.absValueControls = this.strategy.getControls('value');
        this.columnClass = `column-${this.strategy.getColumnSize()}`;

        // This will auto-unsubscribe if the strategy is properly destructed.
        this.strategy.revisions$
            .pipe(
                skipWhile(equals(this.target)),
                rxFilter(complement(isNil))
            )
            .subscribe(revision => this.patchService.transform(set(this.targetLens, revision)));
    }

    ngOnDestroy(): void {
        this.strategy.destruct();
    }

    // ------------------------------------------------------------------------------
    //      Initialization helpers
    // ------------------------------------------------------------------------------

    /**
     * Creates the appropriate target-strategy instance for the current target.
     */
    private makeStrategy(): ITargetStrategy {

        const slug = path<MeasurementSlug>(['measurement', 'slug'], this.target);

        const { clazz, defaults } = strategies[slug] || fallbackStrategy;

        return new clazz(
            this.target,
            this.getValueReaders(defaults),
        );
    }

    /**
     * Get a set of target-value readers that default to given default values.
     */
    private getValueReaders({ minValue, maxValue, value }: TargetValueDefaults): TargetValueReaders {
        return {
            minValue: (target: IRangeExerciseSetTarget) => target.minValue || minValue,
            maxValue: (target: IRangeExerciseSetTarget) => target.maxValue || maxValue,
            value: (target: IAbsoluteExerciseSetTarget) => target.value || value,
        };
    }
}
