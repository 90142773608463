<div id="set-password">
    <form
        [formGroup]="form"
        #formDirective="ngForm"
        (ngSubmit)="resetPassword()"
        class="form"
    >
        <input type="hidden" formControlName="token" required/>
        <div>
            <mat-form-field appearance="outline" floatLabel="never">
                <mat-icon
                    inline="true"
                    matPrefix
                    svgIcon="padlock"
                ></mat-icon>
                <input
                    matInput
                    [attr.placeholder]="'login.password' | translate"
                    type="password"
                    formControlName="password"
                    required
                />
            </mat-form-field>
        </div>
        <div>
            <mat-form-field appearance="outline" floatLabel="never">
                <mat-icon
                    inline="true"
                    matPrefix
                    svgIcon="padlock"
                ></mat-icon>
                <input
                    matInput
                    [attr.placeholder]="'login.repeatPassword' | translate"
                    type="password"
                    formControlName="passwordConfirmation"
                    required
                />
                <mat-error
                    *ngIf="form.controls['passwordConfirmation'].hasError('noMatch')"
                >{{ "login.noMatch" | translate }}</mat-error
                >
            </mat-form-field>
        </div>
        <mat-error *ngIf="error">
            <p>{{ error }}</p>
        </mat-error>
        <ng-container>
            <button
                mat-flat-button
                color="accent-rounded"
                [disabled]="form.invalid"
                type="submit"
            >
                {{ "login.setPassword" | translate }}
            </button>
        </ng-container>
    </form>
</div>
