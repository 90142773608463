import {
    DateRangeValue,
    Filter,
    FilterType,
    RangeFilterConfig,
    RelationshipFilterConfig
} from "@app-components/filter/interfaces/filter";
import { JsonApiModel, ModelType } from "angular2-jsonapi";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import {
    ICON_COLOR,
    ICON_SIZE,
    IconConfig
} from "src/app/modules/shared/inputConfig";
import { OnDestroy, OnInit } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { PageEvent } from "@angular/material/paginator";
import { Observable, Subscription } from "rxjs";
import {
    SearchFilterQuery,
    SearchService
} from "@app-services/search-service/search.service";

import { BaseView } from "./base-view";
import { FunxtionApiClientService } from "@funxtion/ng-funxtion-api-client";
import { Paging } from "../models/paging";
import { take, takeUntil } from "rxjs/operators";
import { getSearchId } from "@funxtion/portal/shared";

// tslint:disable: no-any Todo: fix the any's

export enum Direction {
    ASC = "",
    DESC = "-"
}

export abstract class IndexView<T extends JsonApiModel> extends BaseView
    implements OnInit, OnDestroy {
    protected models: T[];
    protected dataSource: MatTableDataSource<T>;
    defaultPaging: Paging;
    queryPaging: Paging;
    _filter: any = {};
    form: FormGroup;
    displayedColumns: string[] = [];
    mainData: Observable<any>;
    sort: string = "updated-at";
    direction: Direction = Direction.DESC;
    protected typeTimer;
    protected query: string;
    previousQuery: string;
    filterConfig: Filter[] = [];
    fetching: boolean = false;

    defaultActionIconConfig: IconConfig = {
        iconSize: ICON_SIZE.DEFAULT,
        iconColor: ICON_COLOR.SUPPORT
    };
    subscriptions: Subscription[] = [];
    searchId: string;

    protected constructor(
        public funxtion: FunxtionApiClientService,
        public fb: FormBuilder,
        public search: SearchService,
        protected modelType: ModelType<T>,
        protected modelName: string,
        protected includes?: string[],
        protected defaultFilter?: any,
        protected searchField: string = "name"
    ) {
        super();
        this.funxtion = funxtion;
        this.defaultPaging = new Paging();
        this.queryPaging = new Paging();
        this.searchId = getSearchId();

        this.form = this.fb.group({
            query: new FormControl("")
        });
        this._filter = defaultFilter;
    }

    ngOnInit() {
        this.subscriptions.push(
            this.search
                .searchUpdated(this.searchId)
                .subscribe((searchFilter: SearchFilterQuery) => {
                    this.query = searchFilter.query;
                    this.updateFilters(searchFilter.filters);
                })
        );
        this.initFilters();
    }

    ngOnDestroy() {
        super.ngOnDestroy();
        this.subscriptions.map(subscription => subscription.unsubscribe());

        // clean up filter & query entries
        this.search.removeFilter(this.searchId);
        this.search.removeQuery(this.searchId);
    }

    get paging(): Paging {
        if (this.query) {
            return this.queryPaging;
        } else {
            return this.defaultPaging;
        }
    }

    get filter(): any {
        return {
            ...this._filter,
            ...this.defaultFilter
        };
    }

    set filter(filter) {
        this._filter = filter;
    }

    protected getModels() {
        this.models = null;
        const sorting = [];
        this.fetching = true;

        if (this.sort) {
            sorting.push(this.direction + this.sort);
        }

        if (
            !(
                sorting.length > 0 &&
                (sorting[0] === "name" || sorting[0] === "-name")
            )
        ) {
            sorting.push("name");
        }

        const searchFilter = {};
        searchFilter[this.searchField] = {
            co: this.query || null
        };

        this.mainData = this.funxtion.datastore
            .findAll(this.modelType, {
                include: this.includes ? this.includes.join(",") : null,
                sort: sorting.join(","),
                page: {
                    number: this.paging.page + 1,
                    size: this.paging.pageSize
                },
                filter: {
                    ...searchFilter,
                    ...this.filter
                }
            })
            .pipe(takeUntil(this.unsubscribe));

        this.mainData.pipe(take(1)).subscribe(data => {
            this.fetching = false;
            this.models = data.getModels();
            this.dataSource = new MatTableDataSource(this.models);
            this.paging.total = data.getMeta().meta
                ? data.getMeta().meta.resources
                : this.models.length;
            this.paging.page = data.getMeta().meta
                ? data.getMeta().meta.pages.current - 1
                : 0;
        });
    }

    protected createFilter() {
        return {
            name: this.query || null
        };
    }

    initFilters() {
        this.search.setFilter(this.searchId, []);
        this.search.setQuery(this.searchId, "");
    }

    onPageChange(page: PageEvent) {
        this.paging.page = page.pageIndex;
        this.paging.pageSize = page.pageSize;
        this.getModels();
    }

    onSearch() {
        window.clearTimeout(this.typeTimer);
        if (this.form.value.query === this.query) {
            return;
        }

        this.typeTimer = window.setTimeout(() => {
            this.query = this.form.value.query;
            this.queryPaging.page = 0;
            this.getModels();
        }, 250);
    }

    setSort(field: string) {
        if (this.sort === field) {
            this.direction =
                this.direction === Direction.ASC
                    ? Direction.DESC
                    : Direction.ASC;
        } else {
            this.direction = Direction.ASC;
        }
        this.sort = field;
        this.getModels();
    }

    updateFilters(filters: Filter[]) {
        this.filterConfig = filters;
        this.filter = filters.reduce((acc, filter: Filter) => {
            if (filter.filterSet) {
                const relConfig = filter.config as RelationshipFilterConfig<
                    JsonApiModel
                >;
                const rangeConfig = filter.config as RangeFilterConfig;
                if (relConfig.type) {
                    acc[filter.field] = {
                        in: relConfig.value
                            .map(val => relConfig.renderValue(val))
                            .join(",")
                    };
                } else if (rangeConfig.min || rangeConfig.max) {
                    const filterRange = {};
                    if (filter.type === FilterType.NUMBERRANGE) {
                        if (typeof rangeConfig.value[0] !== "undefined") {
                            filterRange["gte"] = rangeConfig.value[0];
                        }
                        if (typeof rangeConfig.value[1] !== "undefined") {
                            filterRange["lte"] = rangeConfig.value[1];
                        }
                        acc[filter.field] = filterRange;
                    } else if (filter.type === FilterType.DATERANGE) {
                        const dateRangeValue = rangeConfig.value as DateRangeValue;
                        if (typeof dateRangeValue.begin !== "undefined") {
                            filterRange["gte"] = dateRangeValue.begin.toDate();
                        }
                        if (typeof dateRangeValue.end !== "undefined") {
                            filterRange["lte"] = dateRangeValue.end.toDate();
                        }
                        acc[filter.field] = filterRange;
                    }
                }
            }
            return acc;
        }, {});

        this.getModels();
    }
}
