import { Component, EventEmitter, Input, Output } from '@angular/core';

import { BehaviorSubject } from "rxjs";
import { TrainingPhase } from "@funxtion/ng-funxtion-api-client";

@Component({
    selector: 'app-view-training-phase',
    templateUrl: './view-training-phase.component.html',
    styleUrls: ['./view-training-phase.component.scss'],
})
export class ViewTrainingPhaseComponent {
    @Input()
    numberOffset: number = 0;

    @Input()
    phase: TrainingPhase;

    @Output()
    expanded: EventEmitter<[string, boolean]> = new EventEmitter<[string, boolean]>();

    @Input()
    accordionObservable: BehaviorSubject<string>;


    constructor() {
    }

    expandedChange($event: boolean) {
        this.expanded.emit([this.phase.id, $event]);
    }
}
