<mat-dialog-content>

<ng-template #actionsList>
    <div class="actions-bar actions-bar--list underlined">
        <div>
            <funxtion-button
                [variant]="BUTTON_VARIANT.CLOSE"
                (click)="close()"
            ></funxtion-button>
        </div>
    </div>
</ng-template>

<ng-template #actionsDetail>
    <div class="actions-bar actions-bar--detail underlined">
        <div>
            <funxtion-button
                [variant]="BUTTON_VARIANT.CLEAR_SMALL_ICON"
                [overrides]="{
                        alignIcon: ALIGNMENT.LEFT,
                        iconConfig: {
                            iconName: 'back-arrow'
                        }
                    }"
                (click)="backToOverview()"
            >{{ 'common.backToAllTemplates' | translate }}</funxtion-button>
        </div>
        <div>
            <funxtion-button [variant]="BUTTON_VARIANT.CLOSE" (click)="close()"></funxtion-button>
        </div>
    </div>
</ng-template>

<div class="sticky">
    <ng-container *ngIf="selectedTraining; else actionsList">
        <ng-container *ngTemplateOutlet="actionsDetail"></ng-container>
    </ng-container>
    <div class="header">
        <div [attr.id]="portalId"></div>
    </div>
</div>

    <ng-container *ngIf="!selectedTraining; else trainingDetails">

        <app-header-content [portalId]="portalId" [searchId]="searchId">
            <div button-slot>
                <funxtion-button [variant]="BUTTON_VARIANT.PRIMARY_SMALL_ICON"
                                 [overrides]="{ alignIcon: ALIGNMENT.LEFT, iconConfig: { iconName: 'add' } }"
                                 (click)="handleHeroButtons('newTraining')"
                > {{ 'training.schemas.newSchema' | translate }} </funxtion-button>
            </div>
        </app-header-content>

        <div>
            <app-training-table [schemas]="schemas"
                                [hasActions]="false"
                                [hasUseTemplate]="true"
                                [maxSelection]="1"
                                (currentSelection)="templateSelected($event)"
                                (buttonClicked)="handleTableButtons($event)"
            ></app-training-table>
            <mat-paginator
                *ngIf="schemas && schemas.length > 0"
                class="d-flex justify-content-center mt-4 plans-paginator"
                [length]="paging.total"
                [pageIndex]="paging.page"
                (page)="onPageChange($event)"
                [pageSize]="defaultPaging.pageSize"
                [pageSizeOptions]="defaultPaging.pageSizeOptions"
            ></mat-paginator>
        </div>
    </ng-container>
    <ng-template #trainingDetails>
        <div class="view-training">
            <app-schema-view
                [portalId]="portalId"
                [trainingId]="selectedTraining.id"
                [usesPortal]="false"
                [useTemplate]="true"
                (buttonClicked)="handleViewButtons($event)"
                *ngIf="selectedTraining"
            ></app-schema-view>
        </div>
    </ng-template>
</mat-dialog-content>
