<div *ngIf="exerciseSetTargets?.length > 0" class="exercise-set" [class.exercise-set--no-set-layout]="!useSetLayout">

    <div *ngIf="useSetLayout" class="exercise-set-header">
        <h2 class="schema-sub-heading">
            {{ referenceNumber ? 'Set ' + referenceNumber : 'Single set' }}
        </h2>

        <div class="exercise-set-actions">

            <button *ngIf="isCopyable" (click)="copy.emit()"
                    class="action"
                    matTooltip="Click to copy this set"
                    matTooltipPosition="above"
                    matTooltipShowDelay="1000"
            >
                <funxtion-icon [config]="defaultIconConfig" [overrides]="{iconName: 'duplicate'}"></funxtion-icon>
            </button>

            <button *ngIf="isDeletable" (click)="delete.emit()"
                    class="action"
                    matTooltip="Click to remove this set"
                    matTooltipPosition="above"
                    matTooltipShowDelay="1000"
            >
                <mat-icon inline="true" svgIcon="delete"></mat-icon>
            </button>
        </div>
    </div>

    <div class="exercise-set-targets">
        <app-exercise-set-target *ngFor="let target of exerciseSetTargets; index as targetIndex; trackBy: scalarTarget"
                                 [target]="target"
                                 [targetLens]="targetIndex | exerciseSetLens: exerciseSetLens"
        ></app-exercise-set-target>
    </div>

    <!-- Todo: make this less sketchy -->
    <div class="exercise-set-fields" *ngIf="setFormGroup">
        <fieldset class="exercise-set-field">
            <legend>Rest after set</legend>

            <div class="input-wrapper">
                <input type="number" [formControl]="setFormGroup.get('restAfterwardsInSeconds')">
                <div class="suffix"><span class="spacer">{{ setFormGroup.get('restAfterwardsInSeconds').value }}</span>&nbsp;s</div>
            </div>
        </fieldset>
    </div>
</div>
