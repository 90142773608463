<h1 class="h2" mat-dialog-title>{{ data.title }}</h1>
<mat-dialog-content>
    <p class="message wrap-newline">{{ data.message }}</p>
</mat-dialog-content>
<mat-dialog-actions>

    <ng-container *ngIf="data.type === 'alert'">
        <funxtion-button mat-dialog-close [variant]="BUTTON_VARIANT.SECONDARY_SMALL"
                         (click)="done(true)"
        >
            {{ 'common.ok' | translate }}
        </funxtion-button>
    </ng-container>

    <ng-container *ngIf="data.type === 'info' || data.type === 'warn'">
        <funxtion-button mat-dialog-close
                         [variant]="BUTTON_VARIANT.CLEAR_SMALL"
                         (click)="done(false)"
        >
            {{ 'common.no' | translate }}
        </funxtion-button>

        <funxtion-button mat-dialog-close
                         [variant]="data.type === 'warn' ? BUTTON_VARIANT.DANGER_SMALL : BUTTON_VARIANT.SECONDARY_SMALL"
                         (click)="done(true)"
        >
            {{ 'common.yes' | translate }}
        </funxtion-button>
    </ng-container>
</mat-dialog-actions>
