import { ActivatedRoute, ParamMap } from "@angular/router";
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Exercise, FunxtionApiClientService } from "@funxtion/ng-funxtion-api-client";

import { BUTTON_VARIANT } from "src/app/modules/shared/inputConfig";
import { Observable } from "rxjs";
import { exerciseIncludes } from "@app-config/api-includes.config";

@Component({
    selector: 'app-exercise-view',
    templateUrl: './exercise-view.component.html',
    styleUrls: ['./exercise-view.component.scss'],
})
export class ExerciseViewComponent implements OnInit {
    exercise$: Observable<Exercise>;
    BUTTON_VARIANT = BUTTON_VARIANT;
    ratingArray = Array(5)
        .fill(0)
        .map((x, i) => i + 1);

    @Input() exerciseId?: string;
    @Input() hasCard?: boolean = true;
    @Input() hasSelect?: boolean = false;
    @Input() selected?: boolean = false;

    @Output() exerciseSelected: EventEmitter<Exercise> = new EventEmitter<Exercise>();

    constructor(
        private funxtion: FunxtionApiClientService,
        private route: ActivatedRoute,
    ) {
    }

    onSelect(exercise: Exercise) {
        this.exerciseSelected.emit(exercise);
    }

    async ngOnInit() {
        if (!this.exerciseId) {
            this.route.paramMap.subscribe((paramMap: ParamMap) => {
                this.exercise$ = this.funxtion.datastore.findRecord(Exercise, paramMap.get('id'), {
                    include: exerciseIncludes.join(','),
                });
            });
        } else {
            this.exercise$ = this.funxtion.datastore.findRecord(Exercise, this.exerciseId, {
                include: exerciseIncludes.join(','),
            });
        }
    }

}
