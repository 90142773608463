import { ITrainingPlanWeek } from '@app-types/vm/vm.training.types';
import { TrainingPlanDay, TrainingPlanWeek } from '@funxtion/ng-funxtion-api-client';
import { AbstractModelMapper } from '@app-services/conversion-services/AbstractModelMapper';
import { AttributeMap, RelationMap } from '@app-types/vm-conversion/vm-conversion.types';


export class TrainingPlanWeekMapper extends AbstractModelMapper<ITrainingPlanWeek, TrainingPlanWeek> {

    public readonly attributes: AttributeMap<ITrainingPlanWeek, TrainingPlanWeek> = {
        position: 'position',
    };

    public readonly relationships: RelationMap<ITrainingPlanWeek, TrainingPlanWeek>[] = [
        {
            mutable: true,
            type: 'many',
            vmKey: 'days',
            jamKey: 'days',
            jamClass: TrainingPlanDay,
            inverse: {
                key: 'week',
                type: 'one',
            },
        },
    ];
}
