import { FunxtionApiClientService } from "@funxtion/ng-funxtion-api-client";
import { isString } from '@app-helpers/narrowing.helpers';
import { IMapper, Jam, MapperClass, Vm } from '@app-types/vm-conversion/vm-conversion.types';
import { Observable } from 'rxjs';
import { vmConversionTrace, traced } from '@app-helpers/decorators/traced/traced.decorator';
import { asPromise } from '@app-helpers/api-client.helpers';

export abstract class AbstractVmConverter<T extends Vm<U> = Vm, U extends Jam = Jam> {

    /**
     * Specifies the root mapper for the types T and U to map.
     */
    protected abstract readonly rootMapper: MapperClass<T, U>;

    protected constructor(
        protected funxtion: FunxtionApiClientService,
    ) {
    }

    @traced(vmConversionTrace)
    async process(vm: T): Promise<U> {

        const id = isString(vm.id) ? vm.id : null;
        const jam = await asPromise(this.getRecord(id));

        const mapper = new this.rootMapper(vm, jam, this.funxtion.datastore);

        this.setupChains(mapper);

        return await mapper.map();
    }

    /**
     * Specifies how to get from the datastore a record of type U by given id.
     */
    protected abstract getRecord(id: string | null): Observable<U>;

    /**
     * Sets up the chained relationships through the root model mapper.
     */
    protected abstract setupChains(mapper: IMapper<T, U>): void;
}
