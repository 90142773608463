import { Injectable } from '@angular/core'
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { ConfirmDialogComponent } from '@app-components/dialogs/confirm-dialog/confirm-dialog.component'
import { ComponentType } from '@angular/cdk/overlay'
import {
    GetTextInputDialogComponent,
    TextInputDialogResult,
} from '@app-components/dialogs/get-text-input-dialog/get-text-input-dialog.component'
import { DeleteModelDialogComponent } from '@app-components/dialogs/delete-model-dialog/delete-model-dialog.component'
import { ConfirmDialogData } from '../../interfaces/confirm-dialog';
import { confirmModalType } from '../../types/confirm-modal-type';

@Injectable({
    providedIn: 'root'
})
export class ModalService {

    constructor(private dialog: MatDialog) { }

    /**
     * Triggers a dialog to ask a user for confirmation
     *
     * @param title - text in the title/header of the modal
     * @param message - text content of the modal
     * @param type - 'info', 'alert' or 'warn', used to set the color of the action buttons
     * @param customConfig - {@link MatDialogConfig} options as addition or overrides
     * @param customComponent - optional component to use instead of the default {@link ConfirmDialogComponent}
     */
    confirm<T>(title: string, message: string, type: confirmModalType = 'info',
               customConfig: MatDialogConfig = {}, customComponent?: ComponentType<T>
               // tslint:disable-next-line:no-any
    ): Observable<ConfirmDialogData | any> {

        const defaultDialogConfig: MatDialogConfig = {
            data: {
                title,
                message,
                type
            } as ConfirmDialogData
        }

        const dialogComponent: ComponentType<T | ConfirmDialogComponent> = customComponent || ConfirmDialogComponent;
        const dialogConfig: MatDialogConfig = Object.assign(defaultDialogConfig, customConfig);

        const dialog = this.dialog.open(dialogComponent, dialogConfig);

        return dialog.afterClosed();
    }

    textInput(title: string, message: string): Observable<TextInputDialogResult> {

        const dialogConfig: MatDialogConfig = {
            data: { title, message }
        }

        const dialog = this.dialog.open(GetTextInputDialogComponent, dialogConfig);

        return dialog.afterClosed();
    }

    confirmDelete(title: string, message: string, customConfig: MatDialogConfig = {}): Observable<boolean> {

        const defaultDialogConfig: MatDialogConfig = {
            data: { title, message }
        }

        const dialogConfig: MatDialogConfig = Object.assign(defaultDialogConfig, customConfig);
        const dialog = this.dialog.open(DeleteModelDialogComponent, dialogConfig);

        return dialog.afterClosed();
    }
}
