import { StorageService } from './../../modules/shared/services/storage/storage.service';
import { Inject, Injectable } from '@angular/core';
import { PortalEnvironmentVariables } from 'src/environments/EnvironmentVariables';
import { environment } from '../../../environments/environment';
import { WINDOW } from '@app-helpers/injection-tokens';

import { apis } from "src/configuration/apis";
import { configurations } from "src/configuration/configurations";

@Injectable({
  providedIn: 'root'
})
export class EnvironmentService {

    // Used to display environment selector for enduser. Will be set to true only if one environment could be autodetected
    public detected = false;
    public options: any;

    // Access to environment variables is done through here
    public environment: PortalEnvironmentVariables;

    private detectedEnvironment: any;
    private detectedClient: any;

    constructor(
        @Inject(WINDOW) private window: Window,
        private storageService: StorageService) {

        this.detectedEnvironment = this.determineEnvironmentType();

        this.detectedClient = this.determineClient();

        this.setEnvironment();
    }

    /**
     * Combines default environment configuration with selected client and environment combination
     * Takes an optional user selected environment
     */
    setEnvironment(userSelectedEnvironment?: PortalEnvironmentVariables) {

        // Do we get a user selection override? Then force an environment
        if (userSelectedEnvironment) {
            this.detectedClient = userSelectedEnvironment;
        }

        // Store the selection in the session storage
        if (this.detectedClient && this.detectedClient.auth) {
            this.storageService.set("environment", this.detectedEnvironment.type);
            this.storageService.set("clientId", this.detectedClient.auth.clientId);
        }

        const apiConfiguration = {
            api: {...environment.api, hostname: this.detectedEnvironment.hostname},
            websockets: {...environment.websockets, hostname: this.detectedEnvironment.hostname}
        }
        // Now merge and create the environment object
        this.environment = {...environment, ...this.detectedClient, ...apiConfiguration};

        this.environment.environment = this.detectedEnvironment.type;
    }

    /**
     * Tries to detect what the environment type is based on the current host domain
     */
    determineEnvironmentType() {

        let currentEnvironment = [];

        // See if there was already an environment id saved the session
        const sessionEnvironment = this.storageService.get("clientId");
        if (sessionEnvironment) {
            currentEnvironment = apis.filter(env => env.type === sessionEnvironment);
        }

        // Then try the default environment selector
        if (currentEnvironment.length !== 1) {
            currentEnvironment = apis.filter(env => env.domains.includes(this.window.location.hostname));
        }

        // If we did not get an environment we default to production
        if (currentEnvironment.length !== 1) {
            currentEnvironment = apis.filter(env => env.type === 'production');
        }

        // Make sure we really have an environment. If not we should show atleast a warning
        if (currentEnvironment.length !== 1) {
            alert('EnvironmentService:: Unable to determine an environment');
            return {};
        }
        return currentEnvironment[0];
    }

    /**
     * Tries to find out the client based on the detect environment.
     * If there are more then 1 clients available it will provide an environment selector on the login page
     */
    determineClient() {
        this.detected = false;
        this.options = [];

        let detectedClient = [];

        // See if there was already a client id saved the session
        const sessionClientId = this.storageService.get("clientId");
        if (sessionClientId) {
            detectedClient = configurations.filter(conf => conf.auth.clientId === sessionClientId
                && conf.type === this.detectedEnvironment.type);
        }

        // If not then see how many ClientId's are available for our hostname
        if (!detectedClient.length) {
            detectedClient = configurations.filter(conf => conf.self.hostname === this.window.location.hostname);
        }

        // If we have not found a client, try finding one (ore more)
        // based on the detected environment
        if (!detectedClient.length) {
            detectedClient = configurations.filter(conf => conf.type === this.detectedEnvironment.type);
        }

        // If we don't have exactly one selected environment we will need to ask the user about the environment
        // If we have 0 environments there must be something else wrong
        if (detectedClient.length === 1) {
            this.detected = true;
            return detectedClient[0];
        }
        else if (detectedClient.length > 1)  {
            this.options = detectedClient;
        }
        else {
            // Could not detected, should return everything
            this.options = configurations;
        }

        return {};
    }

    /**
     * Clears the selected environment from session
     * Should be called on a logout
     */
    clearEnvironment() {
        this.storageService.delete("environment");
        this.storageService.delete("clientId");
    }

    setEnvironmentOptions() {
        this.options =  configurations.filter(conf => conf.type === this.detectedEnvironment.type);
    }
}
