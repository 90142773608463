import { Directive, HostBinding } from '@angular/core';

import { MatFormField } from '@angular/material/form-field';

@Directive({
    // tslint:disable-next-line:directive-selector
    selector: 'mat-form-field[funxtion-form-field]'
})
export class FunxtionFormFieldDirective {
    @HostBinding('class.funxtion-form-field') class = true;


    constructor(private matFormField: MatFormField) {
        this.matFormField.appearance = 'outline';
    }
}
