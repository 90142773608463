export class Paging {
    page = 0;
    _pageSize: number = 10;
    total: number;
    pageSizeOptions: number[] = [5, 10, 25, 100];

    get pageSize() {
        return this._pageSize;
    }

    set pageSize(size) {
        if (this.pageSizeOptions.indexOf(size) >= 0) {
            this._pageSize = size;
        }
    }
}
