import { OnDestroy } from '@angular/core';
import { IViewModel, VMTransformer } from "@app-types/vm.types";
import { BehaviorSubject, identity, Observable, Subject } from "rxjs";
import { bufferTime, concatMap, distinctUntilChanged, filter as rxFilter, scan, skipWhile } from "rxjs/operators";
import { clone, complement, equals, isEmpty, transpose, always, isNil } from "ramda";
import { isFunction } from "@app-helpers/narrowing.helpers";
import { traced, vmApplyBufferTrace, vmSetTrace, vmTransformTrace } from '@app-helpers/decorators/traced/traced.decorator';


/**
 * Takes a model T that is the result of an applied transform buffer.
 */
type VMTransformCallback<T extends IViewModel> = (model: T) => void;

/**
 * A tuple of a VM transformer and a corresponding result callback that's
 * called with the result after the buffer that the transformer is part
 * of has been applied to the view model, AND after the new revision has
 * been emitted.
 */
type TransformRegistration<T extends IViewModel = IViewModel> = [
    VMTransformer<T>,
    VMTransformCallback<T>
];

/**
 * A list of transform registrations to run successively against a model in one go.
 */
type TransformBuffer = TransformRegistration[];


/**
 *
 */
export abstract class VMService<T extends IViewModel = IViewModel> implements OnDestroy {

    // ------------------------------------------------------------------------------
    //      Schema revisions: the private subject + public observable
    // ------------------------------------------------------------------------------

    public revisions$: Observable<T>;
    public revisionHistory$: Observable<T[]>;

    private _revisions$: BehaviorSubject<T>;
    private patchQueue$: Subject<TransformRegistration<T>>;

    // ------------------------------------------------------------------------------
    //      Lifecycle
    // ------------------------------------------------------------------------------


    constructor() {
        this.patchQueue$ = new Subject();
        this._revisions$ = new BehaviorSubject(null);
        this.revisions$ = this._revisions$
            .asObservable()
            .pipe(
                skipWhile(isNil),
                distinctUntilChanged<T>(equals),
            );

        this.revisionHistory$ = this.revisions$.pipe(
            scan((xs: T[], x: T) => xs.concat(x), []),
        );

        this.patchQueue$.pipe(
            bufferTime(40),
            rxFilter(complement(isEmpty)),
            concatMap((buffer) => this.applyTransformBuffer(buffer)),
        ).subscribe();
    }

    ngOnDestroy(): void {
        this.patchQueue$.complete();
        this._revisions$.complete();
    }

    /**
     * Get the latest / current revision of the VM.
     */
    public current(): T {
        return this._revisions$.getValue();
    }

    /**
     * Set the viewmodel to given revision, overwriting all current data. A promise is returned
     * that provides the actual new revision.
     */
    @traced(vmSetTrace)
    public setRevision(vm: T): Promise<T> {
        return this.transform(always(vm));
    }

    /**
     * Register a view-model transformer to run against the current model. The transformer
     * is added to the current buffer and will be executed asynchronously. The returned
     * promise contains the result of applying the entire buffer that the given transformer
     * becomes part of (in order of registration)
     */
    @traced(vmTransformTrace)
    public transform(transformer: VMTransformer<T>): Promise<T> {
        return new Promise((resolve: VMTransformCallback<T>) => this.patchQueue$.next([
            isFunction(transformer) ? transformer : identity,
            resolve,
        ]));
    }

    /**
     * Normalizes a transformed model revision. Can be used to dispose invalid data, empty collections
     * and the like. Default implementation returns the given model as-is without any transformations.
     */
    protected normalizeRevision(vm: T): T {
        return vm;
    }

    /**
     * Takes a buffer and
     *  1. Applies its transforms iteratively to the current revision.
     *  2. Emits the new revision.
     *  3. Calls all transform callbacks iteratively with the new revision.
     * Returns a boolean telling whether the model changed by the given buffer.
     */
    @traced(vmApplyBufferTrace)
    private async applyTransformBuffer(buffer: TransformBuffer): Promise<boolean> {

        const latestRevision = this.current();
        const [transformers, transformCallbacks] = transpose(buffer);

        // If we don't clone the model and the transformers would mutate in-place and return,
        // the equals check would always pass, and no update would ever be emitted.
        let revision: T = clone(latestRevision);

        for (const transformer of transformers) {
            revision = await (transformer as VMTransformer<T>)(revision);
        }

        revision = this.normalizeRevision(revision);

        const modelChanged = ! equals(revision, latestRevision);

        if (modelChanged) {
            this._revisions$.next(revision);
        }

        for (const f of transformCallbacks) {
            f(revision);
        }

        return modelChanged;
    }
}
