import { ITrainingPhase } from '@app-types/vm/vm.schema.types';
import { Phase, TrainingGroup, TrainingPhase } from '@funxtion/ng-funxtion-api-client';
import { AbstractModelMapper } from '@app-services/conversion-services/AbstractModelMapper';
import { AttributeMap, RelationMap } from '@app-types/vm-conversion/vm-conversion.types';


export class TrainingPhaseMapper extends AbstractModelMapper<ITrainingPhase, TrainingPhase> {

    public readonly attributes: AttributeMap<ITrainingPhase, TrainingPhase> = {};

    public readonly relationships: RelationMap<ITrainingPhase, TrainingPhase>[] = [
        {
            mutable: false,
            type: 'one',
            vmKey: 'phase',
            jamKey: 'phase',
            jamClass: Phase,
        },
        {
            mutable: true,
            type: 'many',
            vmKey: 'trainingGroups',
            jamKey: 'groups',
            jamClass: TrainingGroup,
            inverse: {
                key: 'phase',
                type: 'one',
            },
        },
    ];
}
