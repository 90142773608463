import { Component, ElementRef, HostBinding, Input, OnDestroy, OnInit, Optional, Self } from "@angular/core";
import { MatFormFieldControl } from "@angular/material/form-field";
import { ControlValueAccessor, FormBuilder, FormControl, FormGroup, NgControl } from "@angular/forms";
import { Subject } from "rxjs";
import { FocusMonitor } from "@angular/cdk/a11y";
import { coerceBooleanProperty } from "@angular/cdk/coercion";
import { SecondsToTimePipe } from "@app-pipes/seconds-to-time/seconds-to-time.pipe";
import { isHourInterval, TimeInterval } from "@app-helpers/time-interval.helpers";

@Component({
    selector: "app-input-time",
    templateUrl: "./input-time.component.html",
    styleUrls: ["./input-time.component.scss"],
    providers: [
        { provide: MatFormFieldControl, useExisting: InputTimeComponent },
    ],
})
export class InputTimeComponent
    implements MatFormFieldControl<string>,
        OnInit,
        OnDestroy,
        ControlValueAccessor {
    static nextId = 0;
    @HostBinding() id = `app-input-time-${InputTimeComponent.nextId++}`;

    readonly autofilled: boolean;
    controlType = "input-time";
    errorState = false;
    focused = false;
    touched = false;
    stateChanges: Subject<void>;

    group: FormGroup;

    @Input()
    useHours = false;

    @Input()
    get value(): string | null {
        // if (this.empty) {
        //     return null;
        // }
        let time: number = +(isNaN(this.group.value.seconds)
            ? 0
            : this.group.value.seconds);
        time +=
            (isNaN(this.group.value.minutes) ? 0 : this.group.value.minutes) *
            60;
        if (this.useHours) {
            time +=
                (isNaN(this.group.value.hours) ? 0 : this.group.value.hours) *
                60 *
                60;
        }
        return time ? "" + time : null;
    }

    set value(value: string | null) {
        this.writeValue(value);
        this.onChange(value);
        this.stateChanges.next();
    }

    @Input()
    get placeholder() {
        return this._placeholder;
    }

    set placeholder(plh) {
        this._placeholder = plh;
        this.stateChanges.next();
    }

    private _placeholder;

    @Input()
    get required() {
        return this._required;
    }

    set required(req) {
        this._required = coerceBooleanProperty(req);
        this.stateChanges.next();
    }

    private _required = false;

    @Input()
    get disabled() {
        return this._disabled;
    }

    set disabled(dis) {
        this._disabled = coerceBooleanProperty(dis);
        this.stateChanges.next();
    }

    private _disabled = false;

    @HostBinding("class.floating")
    get shouldLabelFloat() {
        return this.focused || !this.empty;
    }

    get empty(): boolean {
        const values = this.group.value;
        return (
            (!this.useHours || this.isValueEmpty(values.hours)) &&
            this.isValueEmpty(values.minutes) &&
            this.isValueEmpty(values.seconds)
        );
    }

    @HostBinding("attr.aria-describedby") describedBy = "";

    onChange = (delta) => {
    };
    onTouched = () => {
        this.touched = true;
    };

    constructor(
        @Optional() @Self() public ngControl: NgControl,
        private fb: FormBuilder,
        private fm: FocusMonitor,
        private elRef: ElementRef<HTMLElement>,
        private secondsToTime: SecondsToTimePipe,
    ) {
        this.setupNgControl();
        this.setupStateChanges();
        this.setupForm();
        this.setupFocusMonitoring();
    }

    protected isValueEmpty(value): boolean {
        return value === "" || value === null;
    }

    ngOnInit() {
        this.writeValue(this.value);
    }

    ngOnDestroy() {
        this.fm.stopMonitoring(this.elRef.nativeElement);
        this.stateChanges.complete();
    }

    setupNgControl() {
        if (this.ngControl != null) {
            this.ngControl.valueAccessor = this;
        }
    }

    setupStateChanges() {
        this.stateChanges = new Subject<void>();
    }

    setupForm() {
        this.group = this.fb.group({
            hours: new FormControl(""),
            minutes: new FormControl(""),
            seconds: new FormControl(""),
        });
    }

    setupFocusMonitoring() {
        this.fm.monitor(this.elRef.nativeElement, true).subscribe(origin => {
            this.focused = !!origin;
            this.stateChanges.next();
        });
    }

    update($event) {
        this.overflowFields();
        this.value = this.value;
        this.setErrorState();
    }

    overflowFields() {
        this.overflowSeconds();
        if (this.useHours) {
            this.overflowMinutes();
        }
    }

    overflowSeconds() {
        const seconds = this.group.value.seconds;
        if (seconds) {
            this.group.controls.seconds.patchValue(seconds % 60);
            const minutes = Math.floor(seconds / 60);
            if (minutes) {
                this.group.controls.minutes.patchValue(
                    +this.group.value.minutes + minutes,
                );
            }
        }
    }

    overflowMinutes() {
        const minutes = this.group.value.minutes;
        if (minutes) {
            this.group.controls.minutes.patchValue(minutes % 60);
            const hours = Math.floor(minutes / 60);
            if (hours) {
                this.group.controls.hours.patchValue(
                    +this.group.value.hours + hours,
                );
            }
        }
    }

    onContainerClick(event: MouseEvent): void {
        if ((event.target as Element).tagName.toLowerCase() !== "input") {
            this.elRef.nativeElement.querySelector("input").focus();
        }
    }

    setDescribedByIds(ids: string[]): void {
        this.describedBy = ids.join(" ");
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
    }

    setErrorState(): void {
        if (this.ngControl) {
            this.errorState = this.ngControl.invalid;
        } else {
            this.errorState = this.group.invalid;
        }
    }

    writeValue(value: string | null): void {
        const interval: TimeInterval = this.secondsToTime.transform(
            value,
            this.useHours,
        );
        if (!interval) {
            return;
        }

        if (this.useHours && isHourInterval(interval)) {
            this.group.controls.hours.setValue(interval.hours);
        } else if (this.useHours) {
            this.group.controls.hours.setValue(0);
        }
        if (interval.minutes) {
            this.group.controls.minutes.setValue(interval.minutes);
        } else if (!interval.minutes) {
            this.group.controls.minutes.setValue(0);
        }
        this.group.controls.seconds.setValue(interval.seconds);
    }
}
