export function passwordValidator(control) {
    return null;
    // TODO: Determine password reqs
    if (
        control.value.match(
            /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/,
        )
    ) {
        return null;
    } else {
        return {
            invalidPassword: true,
        };
    }
}
