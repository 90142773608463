<mat-card id="not-found">
    <p>
        {{ "error.general.404" | translate }}
    </p>
    <p>
        <button (click)="goBack()" mat-flat-button color="primary">
            <mat-icon inline="true">arrow_back</mat-icon>
            {{ "common.goBack" | translate }}
        </button>
    </p>
</mat-card>
