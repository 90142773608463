import { Component } from '@angular/core';
import { TokenView } from '../token-view';
import { FunxtionApiClientService, PasswordReset } from '@funxtion/ng-funxtion-api-client';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ModalService } from '../../../modules/shared/services/modal-service/modal.service';
import { EnvironmentService } from '@app-services/environment/environment.service';
import { configurations } from 'src/configuration/configurations';
import { PortalEnvironmentVariables } from '@app-environments/EnvironmentVariables';
import { InitializeService } from '@funxtion/portal/shared/services/initialize-service/initialize.service';

@Component({
    selector: 'app-reset-password',
    templateUrl: '../set-password/set-password.component.html',
    styleUrls: ['../set-password/set-password.component.scss']
})
export class ResetPasswordComponent extends TokenView<PasswordReset> {
    constructor(
        public funxtion: FunxtionApiClientService,
        public environmentService: EnvironmentService,
        protected modal: ModalService,
        protected fb: FormBuilder,
        protected route: ActivatedRoute,
        protected router: Router,
        protected translateService: TranslateService,
        private initializeService: InitializeService
    ) {
        super(funxtion, fb, route, modal, router, PasswordReset, translateService);
        const clientId = this.route.snapshot.params.clientId;
         if (clientId) {
            const environment = configurations.find(configuration => String(configuration.auth.clientId) === clientId)
            this.environmentService.clearEnvironment();
            this.environmentService.detected = false;
            this.environmentService.setEnvironment(environment as unknown as PortalEnvironmentVariables);
            this.initializeService.initPortal();
        }
    }
}
