import { CanDeactivate } from "@angular/router";
import { Observable } from "rxjs";
import { ComponentCanDeactivate } from "../../types/ComponentCanDeactivate.d";
import { Injectable } from "@angular/core";

@Injectable()
export class PendingChangesGuard
    implements CanDeactivate<ComponentCanDeactivate> {
    canDeactivate(
        component: ComponentCanDeactivate,
    ): boolean | Observable<boolean> {
        if (component.canDeactivate({ returnValue: "" })) {
            return true;
        } else {
            confirm(
                "You have unsaved changes. Press Cancel to go back and save these changes, or OK to lose these changes.",
            );
        }
    }
}
