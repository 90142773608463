import {
    ALIGNMENT,
    BUTTON_SIZE,
    BUTTON_VARIANT,
    ButtonConfig,
    getButtonConfig
} from '../../inputConfig';
import { Component, HostBinding, Input } from '@angular/core';

import { DomSanitizer } from '@angular/platform-browser';
import { merge } from 'lodash';

@Component({
    // tslint:disable-next-line:component-selector
  selector: 'funxtion-button',
  templateUrl: './funxtion-button.component.html',
  styleUrls: ['./funxtion-button.component.scss']
})
export class FunxtionButtonComponent {
    BUTTON_SIZE = BUTTON_SIZE;
    ALIGNMENT = ALIGNMENT;

    @HostBinding('class') class = 'fx-btn';
    @HostBinding('style') get style() {
        return this.sanitizer.bypassSecurityTrustStyle(`
            ${this.config.hasBoxShadow ? `--box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1); --mouse-over-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1);` : ''}
            ${this.config.textColor ? `--text-color: ${this.config.textColor};` : ''}
            ${this.config.buttonColor ? `--background-color: ${this.config.buttonColor};` : ''}
            ${this.config.borderColor ? `--border-color: ${this.config.borderColor};` : ''}
            ${this.config.buttonSize ? `${this.config.buttonSize}` : ''}
            ${this.config.buttonShape ? `--border-radius: ${this.config.buttonShape};` : ''}
            ${this.config.buttonFill ? `--border-width: ${this.config.buttonFill};` : ''}
            ${this.config.iconConfig && this.config.iconConfig.iconSize ? `--icon-size: ${this.config.iconConfig.iconSize};` : '' }
            ${this.config.iconConfig && this.config.iconConfig.iconColor ? `--icon-color: ${this.config.iconConfig.iconColor};` : ''}
            ${this.config.notificationConfig && this.config.notificationConfig.notificationBackgroundColor ?
                `--notification-background: ${this.config.notificationConfig.notificationBackgroundColor}` : ''}
            ${this.config.notificationConfig && this.config.notificationConfig.notificationTextColor ?
                `--notification-text: ${this.config.notificationConfig.notificationTextColor};` : ''}
            ${this.config.mouseOverTextColor ? `--mouse-over-text-color: ${this.config.mouseOverTextColor};` : ''}
            ${this.config.mouseOverButtonColor ? `--mouse-over-button-color: ${this.config.mouseOverButtonColor};` : ''}
            ${this.config.mouseOverBorderColor ? `--mouse-over-border-color: ${this.config.mouseOverBorderColor};` : ''}
            ${this.config.mouseOverIconColor ? `--mouse-over-icon-color: ${this.config.mouseOverIconColor};` : ''}
        `);
    }

    _variant: BUTTON_VARIANT;

    @Input()
    get variant(): BUTTON_VARIANT {
        return this._variant;
    }
    set variant(variant: BUTTON_VARIANT) {
        this._variant = variant;
        this.config = merge(getButtonConfig(this._variant), this.overrides);
    }

    _overrides: ButtonConfig;

    @Input()
    get overrides(): ButtonConfig {
        return this._overrides;
    }
    set overrides(overrides: ButtonConfig) {
        this._overrides = overrides;
        const config: ButtonConfig = merge(getButtonConfig(this._variant), this.overrides);
        if (
            config.iconConfig &&
            config.iconConfig.hasBackground &&
            (config.buttonSize === BUTTON_SIZE.ROUND || config.buttonSize === BUTTON_SIZE.ROUND_BIG)
        ) {
            config.iconConfig.hasBackground = false;
        }
        this.config = config;
    }

    config: ButtonConfig = {};

    constructor(private sanitizer: DomSanitizer) {}
}
