import { Component, HostBinding, Input } from '@angular/core';
import { DomSanitizer } from "@angular/platform-browser";

@Component({
    selector: 'app-image-list-item',
    templateUrl: './image-list-item.component.html',
    styleUrls: ['./image-list-item.component.scss'],
})
export class ImageListItemComponent {
    @Input() size?: number = 40;

    constructor(private sanitizer: DomSanitizer) {
    }

    @HostBinding('style') get style() {
        return this.sanitizer.bypassSecurityTrustStyle(`
            --image-size: ${this.size}px;
        `);
    }

    /** Toggles indented style that is used for exercises under a multi-exercise group */
    @Input() public indent: boolean = false;

    /** Toggles multi-layer style for the image, used for multi-exercise group-types. */
    @Input() public multi: boolean = false;

    /** Toggles display of the superset icon over the image  */
    @Input() public superset: boolean = false;
    @Input() public textContent: string;
    @Input() public imageUrl: string;

    /** Selected state used for applying style tweaks to the image tile */
    @Input() public selected: boolean = false;
}
