import { AbstractTargetStrategy } from "../AbstractTargetStrategy";
import { InputValues, ITargetControlsConfig } from "../TargetStrategy.types";
import { emptyTimeInterval, MinuteTimeInterval, timeInterval, toSeconds } from "@app-helpers/time-interval.helpers";
import { isNumber } from "@app-helpers/narrowing.helpers";
import { allPass, complement } from 'ramda';

const isValidNumber = allPass([
    isNumber,
    complement(Number.isNaN),
]);

export class MinuteIntervalTargetStrategy extends AbstractTargetStrategy<MinuteTimeInterval> {

    protected readonly _controlsConfig: ITargetControlsConfig = {
        columns: { absolute: 2, range: 4 },
        inputs: {
            minutes: { type: 'number', unit: 'm', postDelimiter: ':' },
            seconds: { type: 'number', unit: 's' },
        },
    };

    protected toFormValues(parsed: MinuteTimeInterval): InputValues {
        return {
            minutes: String(parsed.minutes),
            seconds: String(parsed.seconds),
        };
    }

    protected fromFormValues(values: InputValues): MinuteTimeInterval | null {

        const minutes = parseInt(values.minutes, 10);
        const seconds = parseInt(values.seconds, 10);

        if (isValidNumber(minutes) && isValidNumber(seconds) && toSeconds({ minutes, seconds }) > 0) {
            return { minutes, seconds };
        }

        return null;
    }

    protected fromModelAttribute(serialized: string): MinuteTimeInterval | null {
        const n = parseInt(serialized, 10);
        return isValidNumber(n) ? timeInterval(n, false) : null;
    }

    protected toModelAttribute(parsed: MinuteTimeInterval): string {
        return String(toSeconds(parsed));
    }

    protected defaultParsedValue(): MinuteTimeInterval {
        return emptyTimeInterval(false);
    }
}
