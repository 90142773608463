import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Exercise } from '@funxtion/ng-funxtion-api-client';


@Component({
    selector: 'app-exercise-selector-button',
    templateUrl: './exercise-selector-button.component.html',
    styleUrls: ['./exercise-selector-button.component.scss'],
})
export class ExerciseSelectorButtonComponent {

    @Input() exercise: Exercise;
    @Input() selected: boolean;

    @Output() selection = new EventEmitter<void>();
    @Output() deselection = new EventEmitter<void>();
    @Output() detail = new EventEmitter<Exercise>();

    @ViewChild('exerciseImg', /* TODO: add static flag */ {})
    private exerciseImg: ElementRef;
    public isLoaded = false;

    select() {
        this.selection.emit();
    }

    deselect() {
        this.deselection.emit();
    }

    toggleSelection() {
        if (this.selected) {
            this.deselect();
        } else {
            this.select();
        }
    }

    showDetail(ev) {
        ev.stopPropagation();
        this.detail.emit(this.exercise);
    }
}
