import { divMod } from "@app-helpers/arithmetic.helpers";
import { clone } from 'ramda';

export interface HourTimeInterval {
    hours: number;
    minutes: number;
    seconds: number;
}

export interface MinuteTimeInterval {
    minutes: number;
    seconds: number;
}

export type TimeInterval = HourTimeInterval | MinuteTimeInterval;

// noinspection JSUnusedGlobalSymbols
/**
 * Create an empty time-interval object.
 */
export const emptyTimeInterval = (useHours: boolean = true): TimeInterval => {
    return useHours
        ? { seconds: 0, minutes: 0, hours: 0 }
        : { seconds: 0, minutes: 0 };
};

/**
 *
 */
export const isHourInterval = (interval: TimeInterval): interval is HourTimeInterval => {
    return interval.hasOwnProperty('hours');
};

/**
 *
 */
export const isMinuteInterval = (interval: TimeInterval): interval is MinuteTimeInterval => {
    return !isHourInterval(interval);
};

/**
 * Takes any `TimeInterval` and returns a `MinuteTimeInterval`. If the given object has an `hours` property itself,
 * the minutes value is increased by 60 times this number.
 */
export const withoutHours = (interval: TimeInterval): MinuteTimeInterval => {

    if (isMinuteInterval(interval)) {
        return clone(interval);
    }

    const { hours, minutes, seconds } = interval as HourTimeInterval;
    return { minutes: 60 * hours + minutes, seconds };
};

/**
 * Takes any `TimeInterval` and returns an `HourTimeInterval`. If the given object does not have an `hours` property
 * itself, the minutes value is reduced by the maximum number by chunks of 60 (an hour) that fit in.
 */
export const withHours = (interval: TimeInterval): HourTimeInterval => {

    if (isHourInterval(interval)) {
        return clone(interval);
    }

    const { minutes: minutesIn, seconds } = interval;

    const [hours, minutes] = divMod(minutesIn, 60);
    return { hours, minutes, seconds };
};

// noinspection JSUnusedGlobalSymbols
/**
 *
 */
export const toSeconds = (interval: TimeInterval): number => {

    const { hours, minutes, seconds } = withHours(interval);
    return 3600 * hours + 60 * minutes + seconds;
};

/**
 * Splits a given time in seconds into an object counting hours, minutes and seconds in that time.
 *
 * @param timeInSeconds - The total time in seconds to split out.
 * @param useHours      - Whether to create an object with an hours segment
 */
export const timeInterval = (timeInSeconds: number, useHours: boolean = true): TimeInterval => {

    const [hours, minAndSec] = divMod(timeInSeconds, 3600);
    const [minutes, seconds] = divMod(minAndSec, 60);

    const interval = { hours, minutes, seconds };
    return useHours ? interval : withoutHours(interval);
};
