import { Inject, Injectable } from '@angular/core';
import { LOG_PROVIDER } from "../../log-provider.token";
import { BugsnagStatic } from '@bugsnag/js';

@Injectable({
    providedIn: 'root'
})
export class LogService {

    constructor(@Inject(LOG_PROVIDER) private logger: BugsnagStatic) {
    }

    notify(error: Error | string) {
        if (error instanceof Error) {
            this.logger.notify(error);
        } else {
            this.logger.notify(new Error(error))
        }
    }

    setBreadcrumb(name: string) {
        this.logger.leaveBreadcrumb(name);
    }

}
