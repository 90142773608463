import { Pipe, PipeTransform } from '@angular/core';
import { SecondsToTimePipe } from '@app-pipes/seconds-to-time/seconds-to-time.pipe';
import { isHourInterval, TimeInterval } from "@app-helpers/time-interval.helpers";

@Pipe({ name: 'formatTime' })
export class FormatTimePipe implements PipeTransform {

    constructor(
        private secondsToTime: SecondsToTimePipe,
    ) {
    }

    transform(time: string | number | null, useHours: boolean = false): string {
        const obj: TimeInterval = this.secondsToTime.transform(time, useHours);
        if (!obj) {
            return '';
        }

        let str = '';

        if (useHours && isHourInterval(obj)) {
            str += obj.hours + 'h ';
        }

        if (str !== '' || obj.minutes) {
            str += obj.minutes + 'm ';
        }
        str += obj.seconds + 's';
        return str;
    }

}
