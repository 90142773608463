import { merge } from 'lodash';

export enum BUTTON_VARIANT {
    PRIMARY = 'primary',
    PRIMARY_OUTLINE = 'primary-outline',
    PRIMARY_ICON = 'primary-icon',
    PRIMARY_SMALL = 'primary-small',
    PRIMARY_SMALL_ICON = 'primary-small-icon',
    PRIMARY_SMALL_NOTIFICATION = 'primary-small-notification',
    SECONDARY = 'secondary',
    SECONDARY_ICON = 'secondary-icon',
    SECONDARY_SMALL = 'secondary-small',
    SECONDARY_SMALL_ICON = 'secondary-small-icon',
    SECONDARY_SMALL_NOTIFICATION = 'secondary-small-notification',
    CLEAR = 'clear',
    CLEAR_ICON = 'clear-icon',
    CLEAR_SMALL = 'clear-small',
    CLEAR_SMALL_ICON = 'clear-small-icon',
    CLEAR_OUTLINE = 'clear-outline',
    CLEAR_SMALL_OUTLINE = 'clear-small-outline',
    CLEAR_SMALL_OUTLINE_ICON = 'clear-small-outline-icon',
    CLEAR_SMALL_OUTLINE_ICON_ONLY = 'clear-small-outline-icon-only',
    LIGHT = 'light',
    LIGHT_SMALL = 'light-small',
    ROUND_ICON = 'round-icon',
    ROUND_BIG_ICON = 'round-big-icon',
    DANGER = 'danger',
    DANGER_SMALL = 'danger-small',
    CLOSE = 'close',
}

export enum TEXT_COLOR {
    PRIMARY = 'var(--color-primary)',
    PRIMARY_TINT = 'var(--color-primary-tint)',
    SECONDARY = 'var(--color-secondary)',
    SECONDARY_TINT = 'var(--color-secondary-tint)',
    LIGHT = 'var(--color-light)'
}

export enum BORDER_COLOR {
    PRIMARY = 'var(--color-primary)',
    PRIMARY_TINT = 'var(--color-primary-tint)',
    SECONDARY = 'var(--color-secondary)',
    SECONDARY_TINT = 'var(--color-secondary-tint)',
    SUPPORT_TINT_1 = 'var(--color-support-tint-1)'
}

export enum BUTTON_COLOR {
    PRIMARY = 'var(--color-primary)',
    PRIMARY_TINT = 'var(--color-primary-tint)',
    SECONDARY = 'var(--color-secondary)',
    SECONDARY_TINT = 'var(--color-secondary-tint)',
    LIGHT = 'var(--color-light)',
    SUPPORT_MEDIUM = 'var(--color-support-tint-1)',
    SUPPORT_LIGHT = 'var(--color-support-tint-3)',
    CLOSE = 'var(--color-support-tint-6)',
    CLEAR = 'transparent',
    DANGER = 'var(--color-danger)'
}

export enum BUTTON_SIZE {
    SMALL = '--padding-horizontal: 16px; --min-height: 32px; --line-height: 1.43;',
    SMALL_ICON = '--padding-horizontal: 16px; --min-height: 32px; --line-height: 1.43;',
    SMALL_ICON_ONLY = '--width: auto; --padding-horizontal: 9px; --min-height: 32px; --line-height: 1.43;',
    DEFAULT = '--padding-horizontal: 32px; --min-height: 48px;',
    ICON_ONLY = '--padding-horizontal: 24px; --min-height: 24px; --width: 24px',
    TEXT_ONLY = '--min-height: 32px; --padding-horizontal: 16px;',
    ROUND = '--width: 28px; --min-height: 28px; --border-radius: 50%;',
    ROUND_BIG = '--width: 40px; --min-height: 40px; --border-radius: 50%;'
}

export enum BUTTON_FILL {
    OUTLINE = '2px',
    OUTLINE_THIN = '1px',
    FULL = '0',
    CLEAR = '0'
}

export enum BUTTON_WIDTH {
    FULL = 'full',
    BLOCK = 'block'
}

export enum BUTTON_SHAPE {
    RADIUS = '4px'
}

export enum ICON_COLOR {
    PRIMARY = 'var(--color-primary)',
    PRIMARY_TINT = 'var(--color-primary-tint)',
    SECONDARY = 'var(--color-secondary)',
    SECONDARY_TINT = 'var(--color-secondary-tint)',
    LIGHT = 'var(--color-light)',
    SUCCESS = 'var(--color-success)',
    DANGER = 'var(--color-danger)',
    SUPPORT = 'var(--color-support)',
    SUPPORT_5 = 'var(--color-support-tint-5)',
    CLOSE = 'var(--color-support-tint-6)',
    CURRENT = 'currentColor'
}

export enum ICON_SIZE {
    XSMALL = 'var(--icon-size-xsmall)',
    SMALL = 'var(--icon-size-small)',
    DEFAULT = 'var(--icon-size-default)',
    LARGE = 'var(--icon-size-large)',
    XLARGE = 'var(--icon-size-xlarge)',
    XXLARGE = 'var(--icon-size-xxlarge)'
}

export enum ALIGNMENT {
    LEFT = 'left',
    CENTER = 'center',
    RIGHT = 'right'
}

export type StandardSize =
    'small'
    | 'default'
    | 'large';


export interface ButtonConfig {
    textColor?: TEXT_COLOR;
    buttonColor?: BUTTON_COLOR;
    borderColor?: BORDER_COLOR;
    buttonFill?: BUTTON_FILL;
    buttonSize?: BUTTON_SIZE;
    buttonWidth?: BUTTON_WIDTH;
    buttonShape?: BUTTON_SHAPE;
    mouseOverButtonColor?: BUTTON_COLOR;
    mouseOverTextColor?: TEXT_COLOR;
    mouseOverBorderColor?: BORDER_COLOR;
    mouseOverIconColor?: ICON_COLOR;
    hasBoxShadow?: boolean;
    hasText?: boolean;
    withIcon?: boolean;
    iconConfig?: IconConfig;
    alignIcon?: ALIGNMENT.LEFT | ALIGNMENT.RIGHT;
    withNotification?: boolean;
    notificationConfig?: ButtonNotificationConfig;
}

export interface ButtonNotificationConfig {
    notificationLabel?: number;
    notificationBackgroundColor?: ICON_COLOR;
    notificationTextColor?: TEXT_COLOR;
}

export interface IconConfig {
    iconName?: string;
    hasBackground?: boolean;
    iconBackground?: ICON_COLOR;
    iconColor?: ICON_COLOR;
    iconSize?: ICON_SIZE;
}

export class LayoutConfig {
    align: ALIGNMENT;
    standardSize: StandardSize;
    bool: boolean;
}

export function getButtonConfig(variant: BUTTON_VARIANT): ButtonConfig {
    switch (variant) {
        case BUTTON_VARIANT.PRIMARY:
            return {
                textColor: TEXT_COLOR.PRIMARY_TINT,
                buttonColor: BUTTON_COLOR.SECONDARY,
                buttonSize: BUTTON_SIZE.DEFAULT,
                buttonFill: BUTTON_FILL.FULL,
                buttonShape: BUTTON_SHAPE.RADIUS,
                mouseOverButtonColor: BUTTON_COLOR.SECONDARY_TINT,
                withIcon: false,
                hasBoxShadow: true,
                hasText: true,
                withNotification: false
            };
        case BUTTON_VARIANT.SECONDARY:
            return {
                textColor: TEXT_COLOR.LIGHT,
                buttonColor: BUTTON_COLOR.PRIMARY_TINT,
                buttonSize: BUTTON_SIZE.DEFAULT,
                buttonFill: BUTTON_FILL.FULL,
                buttonShape: BUTTON_SHAPE.RADIUS,
                mouseOverButtonColor: BUTTON_COLOR.PRIMARY,
                withIcon: false,
                hasBoxShadow: true,
                hasText: true,
                withNotification: false
            };
        case BUTTON_VARIANT.PRIMARY_OUTLINE:
            return {
                textColor: TEXT_COLOR.SECONDARY,
                buttonSize: BUTTON_SIZE.DEFAULT,
                buttonFill: BUTTON_FILL.OUTLINE,
                borderColor: BORDER_COLOR.SECONDARY,
                buttonShape: BUTTON_SHAPE.RADIUS,
                mouseOverTextColor: TEXT_COLOR.SECONDARY_TINT,
                mouseOverBorderColor: BORDER_COLOR.SECONDARY_TINT,
                withIcon: false,
                hasText: true,
                withNotification: false
            };
        case BUTTON_VARIANT.LIGHT:
            return {
                textColor: TEXT_COLOR.PRIMARY,
                buttonColor: BUTTON_COLOR.SUPPORT_MEDIUM,
                buttonSize: BUTTON_SIZE.DEFAULT,
                buttonFill: BUTTON_FILL.FULL,
                buttonShape: BUTTON_SHAPE.RADIUS,
                mouseOverButtonColor: BUTTON_COLOR.SUPPORT_LIGHT,
                withIcon: false,
                hasText: true,
                withNotification: false
            };
        case BUTTON_VARIANT.LIGHT_SMALL:
            return merge(getButtonConfig(BUTTON_VARIANT.LIGHT), {
                buttonSize: BUTTON_SIZE.SMALL
            });
        case BUTTON_VARIANT.PRIMARY_ICON:
            return merge(getButtonConfig(BUTTON_VARIANT.PRIMARY), {
                withIcon: true,
                iconConfig: {
                    iconColor: ICON_COLOR.PRIMARY_TINT,
                    iconSize: ICON_SIZE.LARGE,
                },
            });
        case BUTTON_VARIANT.SECONDARY_ICON:
            return merge(getButtonConfig(BUTTON_VARIANT.SECONDARY), {
                withIcon: true,
                iconConfig: {
                    iconColor: ICON_COLOR.LIGHT,
                    iconSize: ICON_SIZE.LARGE,
                },
            });
        case BUTTON_VARIANT.SECONDARY_SMALL:
            return merge(getButtonConfig(BUTTON_VARIANT.SECONDARY), {
                buttonSize: BUTTON_SIZE.SMALL
            });
        case BUTTON_VARIANT.PRIMARY_SMALL:
            return merge(getButtonConfig(BUTTON_VARIANT.PRIMARY), {
                buttonSize: BUTTON_SIZE.SMALL
            });
        case BUTTON_VARIANT.PRIMARY_SMALL_ICON:
            return merge(getButtonConfig(BUTTON_VARIANT.PRIMARY_SMALL), {
                withIcon: true,
                iconConfig: {
                    iconColor: ICON_COLOR.PRIMARY_TINT,
                    iconSize: ICON_SIZE.SMALL,
                },
            });
        case BUTTON_VARIANT.SECONDARY_SMALL_ICON:
            return merge(getButtonConfig(BUTTON_VARIANT.SECONDARY_SMALL), {
                withIcon: true,
                iconConfig: {
                    iconColor: ICON_COLOR.SECONDARY_TINT,
                    iconSize: ICON_SIZE.SMALL,
                },
            });
        case BUTTON_VARIANT.PRIMARY_SMALL_NOTIFICATION:
            return merge(getButtonConfig(BUTTON_VARIANT.PRIMARY_SMALL), {
                withNotification: true,
                notificationConfig: {
                    notificationBackgroundColor: ICON_COLOR.PRIMARY,
                    notificationLabel: '5',
                    notificationTextColor: TEXT_COLOR.SECONDARY
                }
            });
        case BUTTON_VARIANT.SECONDARY_SMALL_NOTIFICATION:
            return merge(getButtonConfig(BUTTON_VARIANT.SECONDARY_SMALL), {
                withNotification: true,
                notificationConfig: {
                    notificationBackgroundColor: ICON_COLOR.SECONDARY,
                    notificationLabel: '5',
                    notificationTextColor: TEXT_COLOR.PRIMARY
                }
            });
        case BUTTON_VARIANT.CLEAR:
            return {
                buttonColor: BUTTON_COLOR.CLEAR,
                textColor: TEXT_COLOR.PRIMARY,
                buttonSize: BUTTON_SIZE.DEFAULT,
                buttonFill: BUTTON_FILL.CLEAR,
                buttonShape: BUTTON_SHAPE.RADIUS,
                mouseOverTextColor: TEXT_COLOR.SECONDARY,
                hasText: true,
                withIcon: false,
                withNotification: false
            };
        case BUTTON_VARIANT.CLEAR_OUTLINE:
            return merge(getButtonConfig(BUTTON_VARIANT.CLEAR), {
                buttonFill: BUTTON_FILL.OUTLINE_THIN,
                borderColor: BORDER_COLOR.SUPPORT_TINT_1,
                mouseOverBorderColor: BORDER_COLOR.SECONDARY
            });
        case BUTTON_VARIANT.CLEAR_SMALL:
            return merge(getButtonConfig(BUTTON_VARIANT.CLEAR), {
                buttonSize: BUTTON_SIZE.SMALL
            });
        case BUTTON_VARIANT.CLEAR_SMALL_OUTLINE:
            return merge(getButtonConfig(BUTTON_VARIANT.CLEAR_SMALL), {
                buttonFill: BUTTON_FILL.OUTLINE_THIN,
                borderColor: BORDER_COLOR.SUPPORT_TINT_1,
                mouseOverBorderColor: BORDER_COLOR.SECONDARY
            });
        case BUTTON_VARIANT.CLEAR_SMALL_ICON:
            return merge(getButtonConfig(BUTTON_VARIANT.CLEAR_SMALL), {
                withIcon: true,
                mouseOverIconColor: ICON_COLOR.SECONDARY,
                iconConfig: {
                    iconColor: ICON_COLOR.PRIMARY_TINT,
                    iconSize: ICON_SIZE.SMALL
                }
            });
        case BUTTON_VARIANT.CLEAR_SMALL_OUTLINE_ICON:
            return merge(getButtonConfig(BUTTON_VARIANT.CLEAR_SMALL_ICON), {
                buttonFill: BUTTON_FILL.OUTLINE_THIN,
                borderColor: BORDER_COLOR.SUPPORT_TINT_1,
                mouseOverBorderColor: BORDER_COLOR.SECONDARY
            });
        case BUTTON_VARIANT.CLEAR_ICON:
            return merge(getButtonConfig(BUTTON_VARIANT.CLEAR), {
                withIcon: true,
                mouseOverIconColor: ICON_COLOR.SECONDARY,
                iconConfig: {
                    iconColor: ICON_COLOR.PRIMARY_TINT,
                    iconSize: ICON_SIZE.SMALL,
                }
            });
        case BUTTON_VARIANT.CLEAR_SMALL_OUTLINE_ICON_ONLY:
            return merge(getButtonConfig(BUTTON_VARIANT.CLEAR_SMALL_OUTLINE_ICON), {
                hasText: false,
                buttonSize: BUTTON_SIZE.SMALL_ICON_ONLY
            });
        case BUTTON_VARIANT.ROUND_ICON:
            return {
                buttonColor: BUTTON_COLOR.PRIMARY,
                buttonSize: BUTTON_SIZE.ROUND,
                mouseOverButtonColor: BUTTON_COLOR.SECONDARY_TINT,
                hasText: false,
                withIcon: true,
                iconConfig: {
                    iconColor: ICON_COLOR.LIGHT,
                    iconSize: ICON_SIZE.SMALL,
                }
            };
        case BUTTON_VARIANT.ROUND_BIG_ICON:
            return merge(getButtonConfig(BUTTON_VARIANT.ROUND_ICON), {
                buttonSize: BUTTON_SIZE.ROUND_BIG,
                iconConfig: {
                    iconSize: ICON_SIZE.LARGE
                }
            });
        case BUTTON_VARIANT.DANGER_SMALL:
            return merge(getButtonConfig(BUTTON_VARIANT.PRIMARY_SMALL), {
                textColor: TEXT_COLOR.LIGHT,
                buttonColor: BUTTON_COLOR.DANGER,
                borderColor: BORDER_COLOR.PRIMARY
            });
        case BUTTON_VARIANT.CLOSE:
            return merge(getButtonConfig(BUTTON_VARIANT.CLEAR_ICON), {
                iconConfig: {
                    iconName: 'close',
                    iconSize: ICON_SIZE.DEFAULT,
                    iconColor: ICON_COLOR.CLOSE
                },
                hasText: false,
                alignIcon: ALIGNMENT.RIGHT,
                buttonSize: BUTTON_SIZE.ICON_ONLY
            });
    }
}
