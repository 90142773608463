import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, flatMap } from 'rxjs/operators';
import { JsonApiModel, ModelType } from 'angular2-jsonapi';
import { FunxtionApiClientService } from '@funxtion/ng-funxtion-api-client';
import { ModalService } from '../modal-service/modal.service'

@Injectable({
    providedIn: 'root',
})
export class DeleteModelService<T extends JsonApiModel> {

    constructor(private funxtion: FunxtionApiClientService, private modal: ModalService) {
    }

    /**
     * @Deletes a model, but first asks the user for a confirmation through a dialog
     */
    deleteModelWithDialog(model: T, modelType: ModelType<T>, modelName: string, event: MouseEvent = null): Observable<Response> {
        if (event) {
            event.stopPropagation();
        }

        const title = `Delete ${modelName}`;
        const message = `Are you sure you want to delete the ${modelName} ${model.name}?`;

        return this.modal.confirmDelete(title, message, { width: '370px'})
            .pipe(
                filter(confirmed => confirmed),
                flatMap(_ => this.funxtion.datastore.deleteRecord(modelType, model.id))
            );
    }
}
