import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Pipe({
    name: "dateDiff",
})
export class DateDiffPipe implements PipeTransform {
    constructor(private translate: TranslateService) {
    }

    transform(value: Date, args?: any): any {
        const now = new Date();
        const difference = now.getTime() - value.getTime();
        const hoursDiff = difference / 1000 / 60 / 60;
        const daysDiff = hoursDiff / 24;
        const weeksDiff = daysDiff / 7;
        const monthsDiff = daysDiff / 30;
        const yearsDiff = daysDiff / 365;

        switch (true) {
            case 3 < yearsDiff: {
                return this.translate.instant(
                    "common.dateDiff.moreThanThreeYearsAgo",
                );
            }
            case 3 > yearsDiff && yearsDiff > 1: {
                return this.translate.instant("common.dateDiff.yearsAgo", {
                    count: Math.round(yearsDiff + 0.5),
                });
            }
            case monthsDiff > 1: {
                return this.translate.instant("common.dateDiff.monthsAgo", {
                    count: Math.round(monthsDiff + 0.5),
                });
            }
            case weeksDiff > 1: {
                return this.translate.instant("common.dateDiff.weeksAgo", {
                    count: Math.round(weeksDiff + 0.5),
                });
            }
            case daysDiff > 1: {
                return this.translate.instant("common.dateDiff.daysAgo", {
                    count: Math.round(daysDiff + 0.5),
                });
            }
            case hoursDiff > 1: {
                return this.translate.instant("common.dateDiff.hoursAgo", {
                    count: Math.round(hoursDiff + 0.5),
                });
            }
            default: {
                return this.translate.instant("common.dateDiff.justNow");
            }
        }
    }
}
