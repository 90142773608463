import { JsonApiQueryData } from "angular2-jsonapi";
import { ExerciseFilter, ExerciseFilterOptions, ScalarExerciseFilters } from "@app-types/training/editor/exercise-selector-dialog.types";

export const emptyFilters = (): ExerciseFilter => ({
    query: null,
    category: null,
    equipment: null,
    muscleGroup: null,
});

/**
 * Returns a fresh FilterOptions object; all contained options-arrays are empty.
 */
export const emptyFilterOptions = (): ExerciseFilterOptions => ({
    exerciseCategories: [],
    equipmentModels: [],
    muscleGroups: [],
});

export const getModels = <T>(data: JsonApiQueryData<T>): T[] => data.getModels();

export const toApiFilter = (filters: ExerciseFilter): ScalarExerciseFilters => {

    const apiFilters: ScalarExerciseFilters = {};

    if (filters.query && filters.query.trim()) {
        apiFilters.name = filters.query.trim();
    }

    if (filters.category) {
        apiFilters.categories = filters.category.id;
    }

    if (filters.equipment) {
        apiFilters.equipment = filters.equipment.id;
    }

    if (filters.muscleGroup) {
        apiFilters["muscle-groups"] = filters.muscleGroup.id;
    }

    return apiFilters;
};
