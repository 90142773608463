/**
 * @see TrainingPlan
 */
export const trainingPlanIncludes: string[] = [
    'level',
    'goal',
    'locations',
    'weeks',
    'weeks.days',
    'weeks.days.schema',
    'weeks.days.schema.genders',
    'weeks.days.schema.goal',
    'weeks.days.schema.level',
    'weeks.days.schema.owner',
    'owner',
];

/**
 * @see TrainingSchema
 */
export const schemaIncludes: string[] = [
    'genders',
    'goal',
    'owner',
    'level',
    'types',
    'tags',
    'phases.phase',
    'phases.groups.group.type',
    'phases.groups.group.exercises.sets.targets.measurement',
    'phases.groups.group.exercises.exercise.equipment.measurements',
];

/**
 * @see Workout
 */
export const workoutIncludes: string[] = [
    'goal',
    'level',
    'categories',
    'body-section',
    'locations',
    'owner',
    'group.type',
    'group.exercises.sets',
    'group.exercises.sets.targets',
    'group.exercises.sets.targets.measurement',
    'group.exercises.exercise.equipment.measurements',
];

export const exerciseIncludes: string[] = [
    'equipment',
    'steps',
    'muscle-groups',
];
