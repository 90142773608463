<ng-template #loading>
    <mat-card class="container">
        <app-loading-spinner
            message="{{ 'loading.trainingSchema' | translate }}"
        ></app-loading-spinner>
    </mat-card>
</ng-template>

<ng-container *ngIf="schema && !isDuplicating; else loading">

    <app-header-content [portalId]="! usesPortal ? portalId : undefined" [searchId]="searchId">
        <div button-slot>

            <ng-container *ngIf="isAllowedToPublish && !useTemplate">
                <funxtion-button *ngIf="isPublished" [variant]="BUTTON_VARIANT.CLEAR_SMALL_OUTLINE_ICON"
                                 [overrides]="{ alignIcon: ALIGNMENT.LEFT, iconConfig: { iconName: 'close' } }"
                                 (click)="unpublishTrainingSchema()"
                >
                    {{ 'common.unpublish' | translate }}
                </funxtion-button>

                <funxtion-button *ngIf="!isPublished" [variant]="BUTTON_VARIANT.CLEAR_SMALL_OUTLINE_ICON"
                                 [overrides]="{ alignIcon: ALIGNMENT.LEFT, iconConfig: { iconName: 'calendar' } }"
                                 (click)="publishTrainingSchema()"
                >
                    {{ 'common.publish' | translate }}
                </funxtion-button>

            </ng-container>

            <ng-container *ngIf="!useTemplate; else templateButton">

                <funxtion-button [variant]="BUTTON_VARIANT.CLEAR_SMALL_OUTLINE_ICON"
                                 [overrides]="{ alignIcon: ALIGNMENT.LEFT, iconConfig: { iconName: 'edit' } }"
                                 (click)="editTraining()"

                > {{ "common.edit" | translate }} </funxtion-button>

            </ng-container>

            <ng-template #templateButton>
                <funxtion-button [variant]="BUTTON_VARIANT.SECONDARY_SMALL"
                                 (click)="duplicateTraining()"
                > {{ 'training.plans.useTemplate' | translate }} </funxtion-button>
            </ng-template>

            <funxtion-button [matMenuTriggerFor]="appMenu" [variant]="BUTTON_VARIANT.CLEAR_SMALL_OUTLINE_ICON_ONLY"
            [overrides]="{ alignIcon: ALIGNMENT.CENTER, iconConfig: { iconName: 'more-filled' } }">
              </funxtion-button>

            <mat-menu #appMenu="matMenu" xPosition="before">
                <button mat-menu-item (click)="handleDuplicateAction()">
                    <funxtion-icon [overrides]="{iconName: 'duplicate'}"></funxtion-icon>
                    <span>{{ "common.duplicate" | translate }}</span>
                </button>
                <button mat-menu-item (click)="handleDeleteAction()">
                    <funxtion-icon [overrides]="{iconName: 'delete'}"></funxtion-icon>
                    <span>{{ "common.delete" | translate }}</span>
                </button>
            </mat-menu>
        </div>
    </app-header-content>

    <div class="phase-card grid">
        <div class="description">
            <label>{{ 'common.description' | translate }}</label>
            <div class="value">{{ schema?.descriptionLong }}</div>
        </div>
        <div class="intensity">
            <label>{{ "common.intensity" | translate }}</label>
            <div class="value" [innerHTML]="schema?.intensity | intensityColumn"></div>
        </div>


        <div class="goal">
            <label>{{ "common.goal" | translate }}</label>
            <div class="value" [innerHTML]="schema?.goal | goalColumn"></div>
        </div>


        <div class="genders">
            <label>{{ "common.gender" | translate }}</label>
            <div class="value">
                <app-column-icon
                    *ngFor="let gender of schema?.genders"
                    [tooltip]="gender.name"
                    [iconName]="gender.type === 'male' ? 'mars' : 'venus'"
                ></app-column-icon>
            </div>
        </div>


        <div class="duration">
            <label>{{ "common.duration" | translate }}</label>
            <div class="value" [innerHTML]="schema?.durationInSeconds | durationColumn"></div>
        </div>

        <div *ngIf="schema.types && schema.types.length > 0" class="types">
            <label>{{ "common.types" | translate }}</label>
            <div class="value" [innerHTML]="schema?.types | typesColumn"></div>
        </div>

        <div *ngIf="schema?.levels?.length > 0" class="levels">
            <label>{{ "common.level" | translate }}</label>
            <div class="value" [innerHTML]="schema?.level | levelColumn"></div>
        </div>

        <div *ngIf="schema?.tags?.length > 0" class="tags">
            <label>{{ "common.bodySection" | translate }}</label>
            <div class="value" [innerHTML]="schema?.tags | tagsColumn"></div>
        </div>
    </div>

    <mat-card class="phase-card">
        <ng-container *ngFor="let phase of schema.phases; let index = index">
            <app-view-training-phase *ngIf="phase.groups.length > 0"
                                     [numberOffset]="getOffset(index, schema)"
                                     [phase]="phase"
                                     (expanded)="phaseExpanded(index, $event)"
                                     [accordionObservable]="accordionObservable"
            ></app-view-training-phase>
        </ng-container>
    </mat-card>
</ng-container>
