import { Injectable } from '@angular/core';
import { FunxtionApiClientService } from '@funxtion/ng-funxtion-api-client';
import * as mixpanel from 'mixpanel-browser';

@Injectable({
    providedIn: 'root',
})
export class AnalyticsService {
    constructor(private funxtion: FunxtionApiClientService) {
    }

    initialise() {
        mixpanel.init("4b1420e24ba429db23d479391e0175f5");
        this.identify();
    }

    identify() {
        mixpanel.identify(this.funxtion.user.data.id);
        mixpanel.people.set({
            $name: this.funxtion.user.data.fullName,
            $email: this.funxtion.user.data.email,
            $created: this.funxtion.user.data.createdAt,
        });
    }

    // tslint:disable-next-line: no-any
    track(id: AnalyticsEvent, action: any = {}): void {
        if (window.location.href.indexOf('localhost') === -1) {
            mixpanel.track(id, action);
        }
    }
}

export enum AnalyticsEvent {
    PAGEVIEW = "pageview",
    SIGN_IN_SUCCESSFUL = "sign_in_successful",
    SIGN_IN_UNSUCCESSFUL = "sign_in_unsuccessful",
    FORGOT_PASSWORD = "forgot_password",
    CHAT_SENT = "chat_sent",
    CHAT_FILE_UPLOADED = "chat_file_uploaded",
    ADD_MEMBER = "add_member",
    ADD_MEMBER_SEND_INVITE = "add_member_send_invite",
    ADD_USER = "add_user",
    ADD_USER_SEND_INVITE = "add_user_send_invite",
    PLAN_EVENT = "plan_event",
    SELECT_PLAN = "select_plan",
    SAVE_SELECTED_PLAN = "save_selected_plan",
    SELECT_SCHEDULED_DAY = "select_scheduled_day",
    SCHEDULED_DAY_EDIT = "scheduled_day_edit",
    SCHEDULED_DAY_SAVE = "scheduled_day_save",
    SELECT_MEMBER = "select_member",
    MEMBER_INFO = "member_info",
    MEMBER_INFO_EDIT = "member_info_edit",
    MEMBER_INFO_SAVE = "member_info_save",
    MEMBER_CALENDAR = "member_calendar",
    MEMBER_ACTIVITIES = "member_activities",
    MEMBER_TRAINING_PLANS = "member_training_plans",
    SELECT_MONTH = "select_month",
    SELECT_WEEK = "select_week",
    SELECT_DAY = "select_day",
    NEXT_MONTH = "next_month",
    PREVIOUS_MONTH = "previous_month",
    SELECT_TODAY = "select_today",
    CREATE_SCHEMA = "create_schema",
    SAVE_SCHEMA = "save_schema",
    CREATE_WORKOUT = "create_workout",
    SAVE_WORKOUT = "save_workout",
    CREATE_PLAN_START = "create_plan_start",
    CREATE_PLAN_TEMPLATE = "create_plan_template",
    CREATE_PLAN_TEMPLATE_SELECT = "create_plan_template_select",
    CREATE_PLAN_SCRATCH = "create_plan_scratch",
    SAVE_PLAN = "save_plan"
}
