<ng-container *ngIf="confirmed || error || timeout; else loading">
    <div *ngIf="confirmed" id="set-password">
        {{ "login.emailConfirmed" | translate }}
    </div>
    <div *ngIf="error" id="set-password">
        {{ "login.emailConfirmError" | translate }}
    </div>
    <div *ngIf="timeout" id="set-password">
        {{ "login.emailConfirmTimeout" | translate}}
    </div>
</ng-container>

<ng-template #loading>
    <div id="set-password">
        <app-loading-spinner message="{{ 'loading.confirmEmail' | translate }}"></app-loading-spinner>
    </div>
</ng-template>