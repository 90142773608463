import { Injectable, Inject } from '@angular/core';
import { PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  private readonly isClient: boolean;

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    if (isPlatformBrowser(this.platformId)) {
      // Client only code.
      this.isClient = true;
    }

    if (isPlatformServer(this.platformId)) {
      // Server only code.
      this.isClient = false;
    }
  }

  /**
   * Retrieves a key
   * @param key
   */
  get(key) {
    if (this.isClient) {
      const obj = sessionStorage.getItem(key);
      if (obj === "undefined") {
        return undefined;
      }
      else {
        return JSON.parse(obj);
      }
    }
    return null;
  }

  /**
   * Sets a key
   * @param key
   * @param value
   */
  set(key, value) {
    if (this.isClient) {
        sessionStorage.setItem(key, JSON.stringify(value));
    }
  }

  /**
   * Removes key
   * @param key
   */
  delete(key) {
    sessionStorage.removeItem(key);
  }

}
