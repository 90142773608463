import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "formatDate",
})
export class FormatDatePipe implements PipeTransform {
    transform(value: Date, args?: any): any {
        return value.toLocaleDateString();
    }
}
