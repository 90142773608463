import { Injectable } from '@angular/core';
import { ProspectService } from "@app-services/prospect-service/prospect.service";
import { IPhase, ITrainingGroup, ITrainingPhase } from "@app-types/vm/vm.schema.types";
import {
    IEquipmentMeasurement,
    IExercise,
    IExerciseGroup,
    IExerciseGroupType,
    IExerciseSet,
    IExerciseSetTarget,
    ITrainingExercise,
} from "@app-types/vm/vm.common.types";
import { GroupTargetType } from "@funxtion/ng-funxtion-api-client";
import { ExerciseGroupLabelsFactory } from "../editor/exercise-group-labels.factory";

@Injectable({
    providedIn: 'root',
})
export class SchemaFactory {

    constructor(
        private prospectService: ProspectService,
        private exerciseGroupLabelsFactory: ExerciseGroupLabelsFactory,
    ) {

    }

    // ------------------------------------------------------------------------------
    //      New ViewModel object factories
    // ------------------------------------------------------------------------------

    public newTrainingPhase(phase: IPhase, trainingGroups: ITrainingGroup[] = []): ITrainingPhase {
        return { id: this.prospectService.newId, phase, trainingGroups };
    }

    public newExerciseGroup(type: IExerciseGroupType, trainingExercises: ITrainingExercise[] = []): IExerciseGroup {
        return {
            id: this.prospectService.newId,
            labels: this.exerciseGroupLabelsFactory.make(type),
            numberOfRounds: 1,
            durationInSeconds: 1,
            restAfterSetInSeconds: 0,
            restAfterExerciseInSeconds: 0,
            targetRepetitions: 1,
            type,
            trainingExercises,
        };
    }

    public newTrainingGroup(exerciseGroup: IExerciseGroup, position: number, name: string = 'NO NAME'): ITrainingGroup {
        return {
            id: this.prospectService.newId,
            name,
            position,
            exerciseGroup,
        };
    }

    public newTrainingExercise(exercise: IExercise, position: number): ITrainingExercise {
        return {
            id: this.prospectService.newId,
            position,
            description: '',
            sets: [],
            exercise,
        };
    }

    public newExerciseSet(targets: IExerciseSetTarget[], position: number): IExerciseSet {
        return { id: this.prospectService.newId, position, targets, restAfterwardsInSeconds: undefined };
    }

    public newExerciseSetTarget(
        measurement: IEquipmentMeasurement,
        type: GroupTargetType = GroupTargetType.ABSOLUTE,
    ): IExerciseSetTarget {

        switch (type) {

            case GroupTargetType.RANGE:
                return { id: this.prospectService.newId, type, measurement, minValue: null, maxValue: null };

            case GroupTargetType.ABSOLUTE:
                return { id: this.prospectService.newId, type, measurement, value: null };
        }
    }
}
