import { Component, OnDestroy } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { FunxtionApiClientService, UserInviteAccept } from "@funxtion/ng-funxtion-api-client";
import { ActivatedRoute, Router } from "@angular/router";
import { ErrorDisplayService } from "src/app/services/error-display/error-display.service";
import { TokenView } from "../token-view";
import { TranslateService } from "@ngx-translate/core";
import { ModalService } from '@funxtion/portal/shared'
import { OAuthStorage } from "angular-oauth2-oidc";
import { HttpResponse } from "@angular/common/http";
import { Subscription } from "rxjs";

@Component({
    selector: "app-set-password",
    templateUrl: "./set-password.component.html",
    styleUrls: ["./set-password.component.scss"],
})
export class SetPasswordComponent extends TokenView<UserInviteAccept> implements OnDestroy {
    passwordGrant = {};
    private responseSubscription: Subscription;

    constructor(
        public funxtion: FunxtionApiClientService,
        protected modal: ModalService,
        protected fb: FormBuilder,
        protected route: ActivatedRoute,
        protected errorDisplay: ErrorDisplayService,
        protected router: Router,
        protected translateService: TranslateService,
        private oauthStorage: OAuthStorage
    ) {
        super(
            funxtion,
            fb,
            route,
            modal,
            router,
            UserInviteAccept,
            translateService
        );
    }

    resetPassword() {
        const reset = this.funxtion.datastore.createRecord(UserInviteAccept, {
            password: this.form.controls.password.value,
            passwordConfirmation: this.form.controls.passwordConfirmation.value,
        });

        reset.id = this.form.controls.token.value;

        // set up a subscription to catch the response with the access token in the metadata
        this.responseSubscription = this.funxtion.datastore.getRawHttpResponsesForEndpoint(reset.modelConfig.modelEndpointUrl)
            .subscribe((response: HttpResponse<any>) => {
                if (response?.body?.meta) {
                    this.passwordGrant = response.body.meta
                }
            });

        reset
            .save()
            .subscribe(
                (success) => this.onSaveSuccess(success),
                err => this.onSaveError(err)
            );
    }

    protected onSaveSuccess(success: UserInviteAccept) {
        this.loading = false;
        this.formDirective.resetForm();

        this.dialogTitle = this.translateService.instant('login.setPassword');
        this.dialogMessage = this.translateService.instant('login.setPasswordSuccess');

        if (this.passwordGrant['access_token'] && this.passwordGrant['expires_in'] && this.passwordGrant['refresh_token']) {
            this.oauthStorage.setItem('access_token', this.passwordGrant['access_token']);
            this.oauthStorage.setItem('expires_in', this.passwordGrant['expires_in']);
            this.oauthStorage.setItem('refresh_token', this.passwordGrant['refresh_token']);

            this.passwordGrant = {};
        }

        this.modal.confirm(this.dialogTitle, this.dialogMessage, 'info')
            .subscribe(_ => this.router.navigateByUrl("/login"));
    }

    ngOnDestroy(): void {
        if (this.responseSubscription) {
            this.responseSubscription.unsubscribe();
        }
    }
}
