import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule, Provider } from '@angular/core';
import { TranslateCompiler, TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AuthLayoutComponent } from './components/layouts/auth-layout/auth-layout.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { ConfirmEmailComponent } from './views/auth/confirm-email/confirm-email.component';
import { CreateChatDialogComponent } from './components/dialogs/create-chat-dialog/create-chat-dialog.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormatFileSizePipe } from './pipes/format-file-size/format-file-size.pipe';
import { FormatTimePipe } from './pipes/format-time/format-time.pipe';
import { LevelColumnPipe } from './pipes/column-pipes/level-column/level-column.pipe';
import { LoadingSpinnerModule } from './components/loading-spinner/loading-spinner.module';
import { LocationColumnPipe } from './pipes/column-pipes/location-column/location-column.pipe';
import { LoginComponent } from './views/login/login.component';
import { NgxHmCarouselModule } from 'ngx-hm-carousel';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { PendingChangesGuard } from './guards/pending-changes/pending-changes.guard';
import { PortalModule } from "@angular/cdk/portal";
import { ResetPasswordComponent } from './views/auth/reset-password/reset-password.component';
import { SecondsToTimePipe } from './pipes/seconds-to-time/seconds-to-time.pipe';
import { SetPasswordComponent } from './views/auth/set-password/set-password.component';
import { SharedModule } from './modules/shared/shared.module';
import { TrainingSchemaModule } from "./modules/training-schema/training-schema.module";
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';
import { ErrorHandlingModule } from "./feature-modules/error-handling/error-handling.module";

// tslint:disable-next-line: variable-name
export const pipe_providers: Provider[] = [
    { provide: SecondsToTimePipe, useClass: SecondsToTimePipe },
    { provide: FormatTimePipe, useClass: FormatTimePipe },
    { provide: FormatFileSizePipe, useClass: FormatFileSizePipe },
];

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        NotFoundComponent,
        CreateChatDialogComponent,
        SetPasswordComponent,
        AuthLayoutComponent,
        ResetPasswordComponent,
        ConfirmEmailComponent
    ],
    imports: [
        SharedModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
            compiler: {
                provide: TranslateCompiler,
                useClass: TranslateMessageFormatCompiler,
            },
        }),
        AppRoutingModule,
        BrowserAnimationsModule,
        BrowserModule,
        DragDropModule,
        FormsModule,
        HttpClientModule,
        PortalModule,
        NgxHmCarouselModule,
        NgxMatSelectSearchModule,
        ReactiveFormsModule,
        LoadingSpinnerModule,
        TrainingSchemaModule,
        ErrorHandlingModule
    ],
    providers: [
        pipe_providers,
        PendingChangesGuard
    ],
    exports: [
        LevelColumnPipe,
        LocationColumnPipe,
        TranslateModule
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
}

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}
