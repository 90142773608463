import { ROLE_ADMINISTRATOR, ROLE_COACH, ROLE_MANAGER } from '../../../models/roles';

export interface MainNavItem {
    title: string;
    url: string;
    icon: string;
    roles: string[];
    visible: boolean;
    alerts: number;
    isTrainingPlanner: boolean;
    children: SubNavItem[];
}

export interface SubNavItem {
    title: string;
    roles?: string[];
    visible?: boolean;
    url: string;
}

export const sidenavRoutes: MainNavItem[] = [
    {
        title: 'Members',
        url: '/members',
        icon: 'members',
        roles: [ROLE_COACH],
        visible: false,
        isTrainingPlanner: false,
        alerts: 0,
        children: []
    },
    // {
    //     title: 'Chats',
    //     url: '/chats',
    //     icon: 'chat',
    //     roles: [ROLE_COACH],
    //     visible: false,
    //     isTrainingPlanner: false,
    //     alerts: 0,
    //     children: []
    // },
    {
        title: 'Training',
        url: '/training',
        icon: 'training',
        roles: [ROLE_ADMINISTRATOR, ROLE_MANAGER, ROLE_COACH],
        visible: false,
        children: [
            { title: 'Training plans', url: '/training/plans' },
            { title: 'Workouts', url: '/training/workouts' },
            {
                title: 'Challenges',
                url: '/training/challenges',
                roles: [ROLE_ADMINISTRATOR, ROLE_COACH, ROLE_MANAGER]
            },
            {
                title: 'Exercises',
                url: '/training/exercises',
                roles: [ROLE_ADMINISTRATOR, ROLE_MANAGER]
            }
        ],
        isTrainingPlanner: true,
        alerts: 0
    },
    // {
    //     title: 'Nutrition',
    //     url: '/nutrition',
    //     icon: 'nutrition',
    //     roles: [ROLE_ADMINISTRATOR, ROLE_MANAGER, ROLE_COACH],
    //     visible: false,
    //     isTrainingPlanner: true,
    //     alerts: 0,
    //     children: [
    //         // { title: 'Meal plans', url: '/nutrition/meal-plans' },
    //         { title: 'Meals', url: '/nutrition/meals' }
    //     ]
    // },
    {
        title: 'Management',
        url: '/management',
        icon: 'management',
        isTrainingPlanner: false,
        alerts: 0,
        roles: [ROLE_ADMINISTRATOR, ROLE_MANAGER],
        visible: false,
        children: [
            { title: 'Chains', url: '/management/chains' },
            { title: 'Gyms', url: '/management/gyms' },
            { title: 'Users', url: '/management/users' }
        ]
    }
];
