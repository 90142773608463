import { Administrator, Coach, Manager, Member } from "@funxtion/ng-funxtion-api-client";
import { ModelType } from "angular2-jsonapi";

export type RolePartial = Partial<Member | Administrator | Coach | Manager>;
export type Role = Member | Administrator | Coach | Manager;
export type RoleType = ModelType<Member | Administrator | Coach | Manager>;


export enum ROLE {
    Member = 'member',
    Administrator = 'administrator',
    Coach = 'coach',
    Manager = 'manager'
}

export function getModelTypeForRole(roleName: string): RoleType {
    switch (roleName) {
        case ROLE.Member:
            return Member;
        case ROLE.Administrator:
            return Administrator;
        case ROLE.Coach:
            return Coach;
        case ROLE.Manager:
            return Manager;
    }
}

export function getRoleName(role: RolePartial): string {
    switch (role.modelConfig.type) {
        case 'members':
            return 'member';
        case 'coaches':
            return 'coach';
        case 'managers':
            return 'manager';
        case 'administrators':
            return 'administrator';
    }
}
