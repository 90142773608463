import { JsonApiModel, ModelType } from 'angular2-jsonapi'
import { Moment } from 'moment'

export enum FilterType {
    RELATIONSHIP = 0,
    DATERANGE = 1,
    NUMBERRANGE = 2
}

export interface Filter {
    field: string;
    label: string;
    filterSet?: boolean;
    type: FilterType;
    config: RelationshipFilterConfig<JsonApiModel> | RangeFilterConfig;
}

export interface RelationshipFilterConfig<T extends JsonApiModel> {
    type: ModelType<T>;
    filter?: Filter[];
    sort?: string[];
    include?: string[];
    value?: T[];
    renderValue: (model: T) => any;
    renderLabel: (model: T) => string;
}

export interface RangeFilterConfig {
    min?: Date | number | Moment;
    max?: Date | number | Moment;
    value?: [Date, Date] | [number, number] | DateRangeValue;
}

export interface DateRangeValue {
    begin: Moment;
    end: Moment;
}
