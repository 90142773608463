import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Exercise, FitnessExeriseGroupType } from "@funxtion/ng-funxtion-api-client";
import { BaseView } from "src/app/views/base-view";
import { concatMap, scan, startWith, switchMap, takeWhile, tap, throttleTime } from "rxjs/operators";
import { BehaviorSubject, Subject } from "rxjs";
import { append, flip, inc, isEmpty, pluck, prop, propEq, reject, uniqBy } from 'ramda';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { emptyFilterOptions, emptyFilters } from "./exercise-selector.pure";

import {
    ApiPageMetadata,
    ExercisePage,
    ExerciseSelectionResult,
    ExerciseSelectorConfig,
} from "@app-types/training/editor/exercise-selector-dialog.types";
import { SchemaConverterService } from "@app-services/converter-services/schema-converter/schema-converter.service";
import { IExercise } from "@app-types/vm/vm.common.types";
import { ImmutableResourcesService } from "@app-services/immutable-resources/immutable-resources.service";
// @ts-ignore
import { NgScrollbar } from "ngx-scrollbar";
import { TranslateService } from "@ngx-translate/core";
import { ALIGNMENT, BUTTON_VARIANT } from "src/app/modules/shared/inputConfig";


@Component({
    selector: "app-exercise-selector",
    templateUrl: "./exercise-selector.component.html",
    styleUrls: ["./exercise-selector.component.scss"],
})
export class ExerciseSelectorComponent extends BaseView implements OnInit, AfterViewInit, OnDestroy {
    BUTTON_VARIANT = BUTTON_VARIANT;
    ALIGNMENT = ALIGNMENT;

    @ViewChild(NgScrollbar, { static: true }) scrollable: NgScrollbar;


    // ------------------------------------------------------------------------------
    //      Selected exercises list
    // ------------------------------------------------------------------------------
    //
    //      This list has a height of one row of flex items with overflow hidden. The
    //      hidden elements are identified with an intersection observer. This way we
    //      can reliably count the number of hidden elements regardless of their sizes.

    /**
     * The selected-exercise-buttons intersection observer.
     */
    private selectionListIO: IntersectionObserver;

    /**
     * The selected exercises list element.
     */
    @ViewChild('exerciseSelectionList', /* TODO: add static flag */ {})
    exerciseSelectionList: ElementRef;

    /**
     * The set of selected exercise list item elements (live collection)
     */
    @ViewChildren('exerciseSelectionButton')
    exerciseSelectionButtons: QueryList<HTMLElement>;

    // ------------------------------------------------------------------------------
    //      Exercise search results list
    // ------------------------------------------------------------------------------
    //
    //      This list implements lazy loading through an intersection observer. At the
    //      very end of the results container a trigger element is inserted that is
    //      observed by the IO.

    /**
     * The loading-trigger intersection observer.
     */
    private loadingIO: IntersectionObserver;

    /**
     * The exercises results container that is the root for the `loadingIO` observer.
     */
    @ViewChild('exerciseResultsList', /* TODO: add static flag */ {})
    private exerciseResultsList: ElementRef;

    /**
     * The loading trigger element that is observed by `loadingIO` to figure out when to load exercises.
     */
    @ViewChild('loadingTrigger')
    private loadingTrigger: ElementRef;

    // ------------------------------------------------------------------------------
    // Async data sources
    // ------------------------------------------------------------------------------

    readonly filterOptions$ = new BehaviorSubject(emptyFilterOptions());
    private readonly filters$ = new BehaviorSubject(emptyFilters());

    // ------------------------------------------------------------------------------
    //      Internal streams / data
    // ------------------------------------------------------------------------------

    public filterForm: FormGroup;
    private readonly lazyLoadCommands$: Subject<void> = new Subject();
    readonly loadingGridIterable: void[] = Array(12);

    // ------------------------------------------------------------------------------
    //      State
    // ------------------------------------------------------------------------------

    public exercisePages: ExercisePage[] = [];
    public isLoading = true;
    public isExhausted = false;
    public selectionListExpanded = false;
    public selectedExercises: IExercise[] = [];
    public hiddenSelectionCount: number = 0;
    exerciseInfo: Exercise = null;

    // ------------------------------------------------------------------------------
    //      Lifecycle
    // ------------------------------------------------------------------------------

    constructor(
        @Inject(MAT_DIALOG_DATA) public readonly config: Readonly<ExerciseSelectorConfig>,
        private translate: TranslateService,
        private immutableResources: ImmutableResourcesService,
        private dialogRef: MatDialogRef<ExerciseSelectorComponent, ExerciseSelectionResult>,
        private formBuilder: FormBuilder,
        private schemaConverter: SchemaConverterService,
    ) {
        super();
    }

    ngOnInit() {

        this.filterForm = this.formBuilder.group(emptyFilters());

        this.filterForm.valueChanges.pipe(
            throttleTime(400),
        ).subscribe(this.filters$);

        this.immutableResources.getExerciseFilterOptions().then(
            (xs) => this.filterOptions$.next(xs),
        );

        this.filters$.pipe(
            tap(() => this.restartLoadingExercises()),

            switchMap((filter) => this.lazyLoadCommands$.pipe(
                startWith(1),
                scan(inc),
                tap(() => this.startLoadingExercises()),

                // This is the async part of the pipeline: concatMap is used to defer
                // loading requests that are successive to one that's still running.
                // If in the meantime the filters change, switchMap above will
                // automatically unsubscribe from the exercises stream for the previous
                // filters, and remaining requests for that filter will not be performed.
                concatMap((page) => this.immutableResources.getExercisePage(filter, page)),

                tap((result) => this.finishLoadingExercises(result.pageMetadata)),
                takeWhile((result) => !isEmpty(result.exercises)),
                scan(flip(append), []),
            )),
        ).subscribe((exercisePages: ExercisePage[]) => this.exercisePages = exercisePages);
    }

    /**
     * After view initialization we can use the ViewChild(ren) template references and
     * attach them to intersection observers.
     */
    ngAfterViewInit(): void {

        this.initLoadingIO();

        this.loadingIO.observe(this.loadingTrigger.nativeElement);

        this.initSelectionListIO();

        this.exerciseSelectionButtons.changes.subscribe((buttons: QueryList<ElementRef>) => {
            this.selectionListIO.disconnect();
            buttons.forEach(button => this.selectionListIO.observe(button.nativeElement));
        });
    }

    /**
     * On component destruction, disconnect all intersection observers and complete all hot streams.
     */
    ngOnDestroy() {
        super.ngOnDestroy();
        this.loadingIO.disconnect();
        this.selectionListIO.disconnect();
        this.filterOptions$.complete();
        this.filters$.complete();
        this.lazyLoadCommands$.complete();
    }

    // ------------------------------------------------------------------------------
    //      Presentation
    // ------------------------------------------------------------------------------

    public get hasSelection(): boolean {
        return !isEmpty(this.selectedExercises);
    }

    public get selectionCount(): number {
        return this.selectedExercises.length;
    }

    public get confirmButtonContent(): string {
        return this.hasSelection
            ? this.translate.instant('exercises.search.add-n-exercises', { n: this.selectionCount })
            : this.translate.instant('exercises.search.add-exercise');
    }

    public get confirmButtonTheme(): BUTTON_VARIANT {
        return this.hasSelection
            ? BUTTON_VARIANT.PRIMARY_SMALL
            : BUTTON_VARIANT.LIGHT_SMALL;
    }

    public get confirmButtonDisabled(): boolean {
        return !this.hasSelection;
    }

    public get selectedExerciseIds(): string[] {
        return pluck('id', this.selectedExercises) as string[];
    }

    // ------------------------------------------------------------------------------
    //      Template actions
    // ------------------------------------------------------------------------------

    selectExercise(exercise: Exercise): void {
        if (this.exerciseInfo) {
            this.exerciseInfo = null;
        }

        /**
         * Single exercise types means multiple exercises can be selected,
         * but they will all be added to the root level of the phase
         */
        if (this.config?.type === FitnessExeriseGroupType.EXERCISE || (this.config.maxExerciseCount > this.selectedExercises.length)) {
            this.selectedExercises = uniqBy(prop('id'), [
                ...this.selectedExercises,
                this.schemaConverter.apiModelConverter.showExercise(exercise),
            ]);
        } else {
            alert(`You can only add 1 exercise to the ${this.config.groupTypeName} group type`);
        }
    }

    deselectExercise(exerciseId: string): void {
        this.selectedExercises = reject(propEq('id', exerciseId), this.selectedExercises);
    }

    /**
     * Closes the dialog without exposing any selected exercises.
     */
    public cancel() {
        this.dialogRef.close({ confirmed: false });
    }

    /**
     * Closes the dialog and exposes the set of selected exercises.
     */
    public confirm() {
        if (isEmpty(this.selectedExercises)) {
            return this.cancel();
        }

        this.dialogRef.close({ confirmed: true, exercises: this.selectedExercises });
    }

    public showExerciseDetail(exercise: Exercise) {
        this.exerciseInfo = exercise;
    }

    // ------------------------------------------------------------------------------
    //      Loading- and exhaustion-state management
    // ------------------------------------------------------------------------------

    private restartLoadingExercises(): void {
        this.exercisePages = [];
        this.isExhausted = false;
    }

    private startLoadingExercises(): void {

        // If results were just (by our expectation based on the page metadata) exhausted for the current filters, one
        // more request WILL go to the API because of how the observable stream works, and additionally to verify that
        // there are indeed no results for a page exceeding the locally known maximum. However, we do not want to show a
        // loading spinner in this case as the response is in fact expected to be empty of exercises.
        this.isLoading = !this.isExhausted;
    }

    private finishLoadingExercises(meta: ApiPageMetadata): void {
        this.isLoading = false;
        this.isExhausted = meta.pages.current >= meta.pages.total;
    }

    // ------------------------------------------------------------------------------
    //      Field initialization
    // ------------------------------------------------------------------------------

    /**
     * Initializes the intersection observer that determines when to lazy-load exercise pages.
     */
    private initLoadingIO(): void {
        this.loadingIO = new IntersectionObserver(([entry]) => {
            if (entry.intersectionRatio > 0) {
                this.lazyLoadCommands$.next();
            }
        }, { root: this.exerciseResultsList.nativeElement });
    }

    /**
     * Initializes the intersection observer that determines how many exercises are hidden when in compact mode.
     */
    private initSelectionListIO(): void {
        this.selectionListIO = new IntersectionObserver((entries) => {
            this.hiddenSelectionCount = entries.filter((entry) => entry.intersectionRatio === 0).length;
        }, { root: this.exerciseSelectionList.nativeElement });
    }
}
