import { Component, OnInit } from "@angular/core";
import { Location } from "@angular/common";

@Component({
    selector: "app-not-found",
    templateUrl: "./not-found.component.html",
    styleUrls: ["./not-found.component.scss"],
})
export class NotFoundComponent implements OnInit {
    constructor(private location: Location) {
    }

    goBack() {
        this.location.back();
    }

    ngOnInit() {
    }
}
