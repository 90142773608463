<div class="phase-name-container">
    <div class="schema-sub-heading phase-name">{{ phase.name }}</div>

    <button *ngIf="hasTrainingPhase" (click)="remove.emit()" class="clear-phase-button">
        {{ "training.schemas.clearPhase" | translate }}
    </button>
</div>

<button class="tree-add-button"
        *ngIf="! hasTrainingPhase"
        [matMenuTriggerFor]="ExerciseGroupTypeSelector"
        mat-button
>
    <span class="tree-add-button-content">
        <mat-icon [inline]="false">add</mat-icon>
        <span>Add</span>
    </span>
</button>

<mat-menu #ExerciseGroupTypeSelector="matMenu">

    <button *ngFor="let groupType of groupTypes | async"
            mat-menu-item
            [matTooltip]="groupType.description"
            matTooltipPosition="after"
            (click)="add.emit(groupType)"
    >
        {{ groupType.name }}
    </button>
</mat-menu>
