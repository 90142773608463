import { AbstractVmConverter } from "@app-services/conversion-services/AbstractVmConverter";
import { FunxtionApiClientService, TrainingSchema } from "@funxtion/ng-funxtion-api-client";
import { Injectable } from '@angular/core';
import { ITrainingSchema } from "@app-types/vm/vm.schema.types";
import { schemaIncludes } from "@app-config/api-includes.config";
import {
    ExerciseGroupMapper,
    ExerciseSetMapper,
    ExerciseSetTargetMapper,
    TrainingExerciseMapper,
    TrainingGroupMapper,
    TrainingPhaseMapper,
    TrainingSchemaMapper,
} from '@app-services/conversion-services/ModelMappers';
import { Observable } from 'rxjs';
import { IMapper } from '@app-types/vm-conversion/vm-conversion.types';


@Injectable({ providedIn: 'root' })
export class SchemaVmConverterService extends AbstractVmConverter<ITrainingSchema, TrainingSchema> {

    /**
     * Specifies the root mapper for the types T and U to map.
     */
    protected readonly rootMapper = TrainingSchemaMapper;

    constructor(funxtion: FunxtionApiClientService) {
        super(funxtion);
    }

    /**
     * Specifies how to get from the datastore a record of type U by given id.
     */
    protected getRecord(id: string | null): Observable<TrainingSchema> {
        return this.funxtion.dataServices.schemaService.getById(id, schemaIncludes);
    }

    /**
     * Sets up the chained relationships through the root model mapper.
     */
    protected setupChains(mapper: IMapper<ITrainingSchema, TrainingSchema>): void {
        mapper
            .chain('trainingPhases', 'phases', TrainingPhaseMapper)
            .chain('trainingGroups', 'groups', TrainingGroupMapper).setupAttributes({ skip: ['name'] })
            .chain('exerciseGroup', 'group', ExerciseGroupMapper)
            .chain('trainingExercises', 'exercises', TrainingExerciseMapper)
            .chain('sets', 'sets', ExerciseSetMapper)
            .chain('targets', 'targets', ExerciseSetTargetMapper);
    }
}
