<div id="login-form">
    <form [formGroup]="loginForm" (ngSubmit)="login()" *ngIf="!resetPassword">
        <h2 class="mat-h2" capitalize data-cy="login.greeting">
            {{ "login.greeting" | translate }}
        </h2>
        <ng-container *ngIf="!loading; else loggingIn">
            <div *ngIf="!environmentService.detected">
                <mat-form-field appearance="outline">
                    <mat-label>Environment</mat-label>
                    <mat-select formControlName="environment" (selectionChange)="changeEnvironment($event)">
                        <mat-option *ngFor="let option of environmentService.options" [value]="option">
                          {{option.name}}
                        </mat-option>
                      </mat-select>
                </mat-form-field>
            </div>
            <div>
                <mat-form-field appearance="outline">
                    <mat-label>{{ 'login.email' | translate }}</mat-label>
                    <input
                        type="email"
                        required
                        matInput
                        placeholder="{{ 'login.emailPlaceholder' | translate }}"
                        formControlName="username"
                    />
                </mat-form-field>
            </div>
            <div>
                <mat-form-field appearance="outline">
                    <mat-label>{{ 'login.password' | translate }}</mat-label>
                    <input
                        type="password"
                        required
                        matInput
                        placeholder="{{
                            'login.passwordPlaceholder' | translate
                        }}"
                        formControlName="password"
                    />
                </mat-form-field>
            </div>
            <mat-error *ngIf="error">
                <p>{{ friendlyError || error }}</p>
            </mat-error>
            <div style="--width: 100%;">
                <funxtion-button (click)="login()" [variant]="BUTTON_VARIANT.PRIMARY_SMALL" >
                    {{ 'common.signIn' | translate }}
                </funxtion-button>
            </div>
        </ng-container>
        <ng-template #loggingIn>
            <app-loading-spinner
                message="{{ 'login.loggingIn' | translate }}"
            ></app-loading-spinner>
        </ng-template>
        <div id="password-info">
            <funxtion-button (click)="resetPassword = true" [variant]="BUTTON_VARIANT.CLEAR_SMALL">
                {{ "login.forgotPassword" | translate }}
            </funxtion-button>
        </div>
    </form>
    <form
        *ngIf="resetPassword"
        [formGroup]="resetForm"
    >
        <h2 class="mat-h2" capitalize data-cy="login.resetGreeting">
            {{ "login.resetPasswordIntro" | translate }}
        </h2>
        <p>
            {{ "login.resetPasswordText" | translate }}
        </p>
        <div>
            <mat-form-field appearance="outline" floatLabel="never">
                <mat-label>{{ 'login.email' | translate }}</mat-label>
                <input
                    type="email"
                    required
                    matInput
                    placeholder="{{ 'login.emailPlaceholder' | translate }}"
                    formControlName="username"
                />
            </mat-form-field>
        </div>
        <div class="button-container">
            <funxtion-button
                [variant]="BUTTON_VARIANT.PRIMARY_SMALL"
                (click)="submitReset()"
            >{{ 'login.resetPassword' | translate }}</funxtion-button>

            <funxtion-button
                [variant]="BUTTON_VARIANT.SECONDARY_SMALL"
                (click)="resetPassword = false"
            >{{ "common.goBack" | translate }}</funxtion-button>
        </div>
    </form>
</div>
