import { Injectable } from '@angular/core';
import { HeaderConfig } from '@app-types/page-header.types';
import { BehaviorSubject, Observable } from 'rxjs';
import { mergeDeepRight } from 'ramda';
import { defaultHeaderConfig } from '@app-config/page-header.config';
import { delay } from "rxjs/operators";

@Injectable({ providedIn: 'root' })
export class PageHeaderService {

    private _config$ = new BehaviorSubject<HeaderConfig>(defaultHeaderConfig);
    public config$: Observable<HeaderConfig> = this._config$.pipe(delay(0));
    private previousConfig: HeaderConfig = null;
    private _mounted$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public mounted$: Observable<boolean> = this._mounted$.pipe(delay(0));

    public setup(config: Partial<HeaderConfig>, mounted: boolean = true): void {
        this._config$.next(
            mergeDeepRight(defaultHeaderConfig, config)
        );
        if (mounted) {
            this._mounted$.next(true);
        }
    }

    public savePreviousConfig(): void {
        this.previousConfig = this._config$.getValue();
    }

    public reloadPrevious() {
        this.setup(this.previousConfig);
    }
}

