<table mat-table [dataSource]="schemas">
    <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef>
            {{ "common.id" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">{{ element.id }}</td>
    </ng-container>
    <ng-container matColumnDef="image">
        <th mat-header-cell *matHeaderCellDef>
            {{ "common.image" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
            <img
                default="/assets/icons/default-plan.svg"
                class="training-image"
                [attr.src]="element.image || '/assets/icons/training_default.svg'"
            />
        </td>
    </ng-container>

    <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>{{ "common.name" | translate }}</th>
        <td class="title" mat-cell *matCellDef="let element">
            {{ element.name }}
        </td>
    </ng-container>

    <ng-container matColumnDef="published-at">
        <th mat-header-cell *matHeaderCellDef>
            {{ "common.published" | translate }}
        </th>
        <td class="title" mat-cell *matCellDef="let element" [title]="element.publishedAt || ('common.unpublished' | translate)">
            <div clamped-title>
                <funxtion-icon
                    [config]="defaultActionIconConfig"
                    [overrides]="{ iconName: !!element.publishedAt ? 'check' : 'close' }"
                ></funxtion-icon>
            </div>
        </td>
    </ng-container>

    <ng-container matColumnDef="intensity">
        <th mat-header-cell *matHeaderCellDef>
            {{ "common.intensity" | translate }}
        </th>
        <td mat-cell *matCellDef="let element" [innerHTML]="element.intensity | intensityColumn"></td>
    </ng-container>
    <ng-container matColumnDef="owner">
        <th mat-header-cell *matHeaderCellDef>
            {{ "common.owner" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
            {{ element.owner?.name }}
        </td>
    </ng-container>
    <ng-container matColumnDef="duration">
        <th mat-header-cell *matHeaderCellDef>
            {{ "common.approxDuration" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
            {{ element.durationInSeconds | durationColumn }}
        </td>
    </ng-container>
    <ng-container matColumnDef="goal">
        <th mat-header-cell *matHeaderCellDef>
            {{ "common.goal" | translate }}
        </th>
        <td mat-cell *matCellDef="let element" [innerHTML]="element.goal | goalColumn">

        </td>
    </ng-container>
    <ng-container matColumnDef="gender">
        <th mat-header-cell *matHeaderCellDef>
            {{ "common.gender" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
            <app-column-icon *ngFor="let gender of element.genders" [iconName]="gender.type === 'male' ? 'mars' : 'venus'" [tooltip]="gender.name"></app-column-icon>
        </td>
    </ng-container>
    <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element" class="actions">
            <div class="actions-container">
                <funxtion-icon
                    [config]="defaultActionIconConfig"
                    [overrides]="{iconName: 'edit'}"
                    (click)="onButtonClicked($event, 'edit', element)"
                ></funxtion-icon>
                <funxtion-icon
                    [config]="defaultActionIconConfig"
                    [overrides]="{iconName: 'duplicate'}"
                    (click)="onButtonClicked($event, 'duplicate', element)"
                ></funxtion-icon>
                <funxtion-icon
                    [config]="defaultActionIconConfig"
                    [overrides]="{iconName: 'delete'}"
                    (click)="onButtonClicked($event, 'delete', element)"
                ></funxtion-icon>

            </div>
        </td>
    </ng-container>
    <ng-container matColumnDef="selection">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element" class="selection">
            <funxtion-button [variant]="selected.has(element) ? BUTTON_VARIANT.PRIMARY_SMALL : BUTTON_VARIANT.SECONDARY_SMALL"
                             (click)="addToSelection($event, element)"
            >{{ (selected.has(element) ? 'training.plans.removeTraining' : 'training.plans.addTraining') | translate }}</funxtion-button>
        </td>
    </ng-container>
    <ng-container matColumnDef="template">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element" class="selection">
            <funxtion-button [variant]="selected.has(element) ? BUTTON_VARIANT.PRIMARY_SMALL : BUTTON_VARIANT.SECONDARY_SMALL"
                             (click)="addToSelection($event, element)"
            >{{ (selected.has(element) ? '' : 'training.plans.useTemplate') | translate }}</funxtion-button>
        </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
        class="schema-row"
        mat-row
        (click)="onButtonClicked($event, 'view', row)"
        *matRowDef="let row; columns: displayedColumns"
    ></tr>
</table>
