import { ALIGNMENT, BUTTON_VARIANT } from "src/app/modules/shared/inputConfig";
import { Component, OnInit } from "@angular/core";

@Component({
    selector: "app-auth-layout",
    templateUrl: "./auth-layout.component.html",
    styleUrls: ["./auth-layout.component.scss"],
})
export class AuthLayoutComponent implements OnInit {
    currentIndex = 0;

    BUTTON_VARIANT = BUTTON_VARIANT;
    ALIGNMENT = ALIGNMENT;

    constructor() {

    }

    ngOnInit() {
    }
}
