import { ErrorResponse, JsonApiError, JsonApiModel, ModelType } from "angular2-jsonapi";
import { OnInit, ViewChild, Directive } from "@angular/core";
import { FunxtionApiClientService } from "@funxtion/ng-funxtion-api-client";
import { AbstractControl, FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, ValidatorFn, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ErrorStateMatcher } from "@angular/material/core";
import { HttpErrorResponse } from "@angular/common/http";
import { TranslateService } from "@ngx-translate/core";
import { ModalService } from '../../modules/shared/services/modal-service/modal.service'

export class MyErrorStateMatcher implements ErrorStateMatcher {
    isErrorState(
        control: FormControl | null,
        form: FormGroupDirective | NgForm | null,
    ): boolean {
        return control.invalid && control.dirty;
    }
}

@Directive()
export class TokenView<T extends JsonApiModel> implements OnInit {
    form: FormGroup;
    loading = false;
    error: string;

    dialogTitle: string;
    dialogMessage: string;
    generalError: string;

    @ViewChild("formDirective", /* TODO: add static flag */ {})
    protected formDirective: NgForm;

    constructor(
        public funxtion: FunxtionApiClientService,
        protected fb: FormBuilder,
        protected route: ActivatedRoute,
        protected modal: ModalService,
        protected router: Router,
        protected modelType: ModelType<T>,
        protected translateService: TranslateService,
    ) {
    }

    ngOnInit() {
        this.form = this.fb.group({
            token: ["", Validators.required],
            password: ["", Validators.required],
            passwordConfirmation: ["", Validators.required],
        });

        this.route.params.subscribe(params => {
            this.form.controls.token.setValue(params["token"]);
        });

        this.translateService
            .get(["login.resetPassword", "login.resetPasswordSuccess", "login.resetPasswordError"])
            .subscribe((data) => {
                this.dialogTitle = data['login.resetPassword'];
                this.dialogMessage = data['login.resetPasswordSuccess'];
                this.generalError = data['login.resetPasswordError'];
            });
    }

    matchField(fieldName: string): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            return !control.parent ||
            control.value === control.parent.controls[fieldName].value
                ? null
                : { noMatch: true };
        };
    }

    submit() {
        if (this.form.invalid) {
            return;
        }

        this.loading = true;
        this.error = null;
        this.resetPassword();
    }

    resetPassword() {
        const reset = this.funxtion.datastore.createRecord(this.modelType, {
            token: this.form.controls.token.value,
            password: this.form.controls.password.value,
            passwordConfirmation: this.form.controls.passwordConfirmation.value,
        });
        reset
            .save()
            .subscribe(
                () => this.onSaveSuccess(),
                error => this.onSaveError(error),
            );
    }

    protected onSaveSuccess(success?: any) {
        this.loading = false;
        this.formDirective.resetForm();

        this.modal.confirm(this.dialogTitle, this.dialogMessage, 'alert')
            .subscribe(_ => this.router.navigateByUrl("/login"));
    }

    protected onSaveError(response: ErrorResponse | HttpErrorResponse) {
        this.loading = false;
        if (response instanceof ErrorResponse) {
            this.error = response.errors
                .map((error: JsonApiError) => {
                    if (error.detail) {
                        return error.detail.split('data.attributes.').join('');
                    }
                    return this.generalError;
                }).join(",");
        } else {
            this.error = response.message;
        }
    }
}
