import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { Visibility } from '@app-config/model-edit.config';
import { FormControl } from '@angular/forms';
import { any, equals, isNil } from 'ramda';
import { IOwner } from '@app-types/vm/vm.immutable.types';
import { FunxtionApiClientService } from "@funxtion/ng-funxtion-api-client";
import { getRoleName, ROLE } from "@funxtion/portal/shared/interfaces/role";

const privateOwnerTypes = [
    'administrator',
    'manager',
    'coach',
];

@Component({
    selector: 'app-visibility-select',
    templateUrl: './visibility-select.component.html',
    styleUrls: ['./visibility-select.component.scss'],
})
export class VisibilitySelectComponent implements OnChanges {

    @Input()
    currentOwner: IOwner | null = null;

    @Input()
    requiredRole: ROLE;

    @Output()
    visibilitySelection = new EventEmitter<Visibility>();

    /** Visibility Enum reference for use in the template. */
    Visibility = Visibility;

    /** Visibility selection form control */
    public visibilityControl: FormControl = new FormControl();

    // whether a user is allowed to set the visibility
    public isAllowedToControlVisibility = false;

    constructor(private funxtion: FunxtionApiClientService) {
    }

    get ownerVisibility(): Visibility {
        if (isNil(this.currentOwner)) {
            return Visibility.PRIVATE;
        }

        return this.hasPrivateOwner() ? Visibility.PRIVATE : Visibility.PUBLIC;
    }

    ngOnChanges() {
        this.isAllowedToControlVisibility = this.hasUserRequiredRole();
        this.visibilityControl.setValue(this.ownerVisibility);
        this.visibilityControl.valueChanges.subscribe((visibility) => {
            this.visibilitySelection.emit(visibility);
        });
    }

    private hasPrivateOwner(): boolean {
        const owner = this.currentOwner;
        const type = owner ? owner.type : null;
        return any(equals(type), privateOwnerTypes);
    }

    /**
     * Checks if a user has the role required to make changes.
     * Based on {@link this.requiredRole}
     */
    private hasUserRequiredRole(): boolean {
        // if no required role is given, assume user is not allowed
        if (this.requiredRole) {
            return this.funxtion.user.data.roles.map(role => getRoleName(role)).includes(this.requiredRole);
        } else {
            return false;
        }
    }
}
