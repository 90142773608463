import { GroupTargetType, Target } from '@funxtion/ng-funxtion-api-client';
import { JsonApiModel } from "angular2-jsonapi";
import { DMReport } from "@funxtion/ng-funxtion-api-client/lib/services/data/base.service";
import { ApiMutationResult, DMResult } from "@app-types/api-service/api-service.types";
import { ITrainingPhase } from "@app-types/vm/vm.schema.types";
import { IExerciseGroup, IExerciseGroupType } from "@app-types/vm/vm.common.types";
import { SortableResource } from "@app-types/vm.types";
import { ConfirmDialogData } from '@funxtion/portal/shared';
import { GetTextInputDialogData } from '@app-components/dialogs/get-text-input-dialog/get-text-input-dialog.component';

// Note: still thinking of the best way to properly organize this.

// ------------------------------------------------------------------------------
//      Object factories
// ------------------------------------------------------------------------------

export const dialogData = {

    newTrainingGroupName: (groupType: IExerciseGroupType, trainingPhase: ITrainingPhase): GetTextInputDialogData => ({
        message: `Enter a name for your new ${groupType.name.toLowerCase()} within the ${trainingPhase.phase.name.toLowerCase()} phase.`,
        title: `New ${groupType.name}`,
        inputType: 'text'
    }),

    deleteGroup: (typeName: string, displayName: string): ConfirmDialogData => ({
        title: `Delete ${typeName}`,
        message: `Are you sure you want to delete the ${typeName.toLowerCase()} ${displayName}?`,
        type: 'info'
    }),

    deletePhase: (name: string): ConfirmDialogData => ({
        title: 'Delete phase',
        message: `Are you sure you want to delete the phase ${name}?`,
        type: 'info'
    }),

    deleteTrainingSchema: (name: string): ConfirmDialogData => ({
        title: 'Delete training schema',
        message: `Are you sure you want to delete the training schema ${name}?`,
        type: 'info'
    }),

    duplicateTrainingSchema: (name: string): ConfirmDialogData => ({
        title: `Duplicate ${name}`,
        message: 'Are you sure you want to duplicate this training schema?',
        type: 'info'
    }),

    clearWorkoutGroup: (): ConfirmDialogData => ({
        title: `Clear Workout Exercises?`,
        message: 'Changing the type of this workout will clear it of all exercises. Are you sure you wish to continue?',
        type: 'info'
    }),
};

// ------------------------------------------------------------------------------
//      Data validation
// ------------------------------------------------------------------------------

export const isValidTarget = (target: Target): boolean => {

    switch (target.type) {

        case GroupTargetType.ABSOLUTE:
            return Boolean(target.value);

        case GroupTargetType.RANGE:
            return Boolean(target.min && target.max);

        default:
            return false;
    }
};

const isSuccessResponse = <T extends JsonApiModel>(response: DMResult<T>): response is DMReport<T> => {
    // A sketchy check to see if this thing is a success response...
    return response.hasOwnProperty('currentModel');
};

export const toApiMutationResult = <T extends JsonApiModel>(response: DMResult<T>): ApiMutationResult<T> => {
    return isSuccessResponse(response)
        ? { success: true, model: response.currentModel }
        : { success: false, error: response.error };
};

/**
 * Writes sorting to the `T.position` properties of the objects in the given array.
 * New values for `T.position` are the current indices increased by given `startFrom` number.
 *
 * ---
 *
 * **Note:**
 * This is a curried function! It must first be called with the `startFrom` number, and
 * it will return a new function that takes the list to write sorting to. Signature:
 *
 * ```
 * (IN 1)          (IN 2)                               (OUT)
 * (number)   =>   (Array<{ position: number }>)   =>   (Array<{ position: number }>)
 * ```
 *
 * ---
 *
 * **Note:**
 * This will **not** work with JSON API models as it returns new plain objects.
 */
export const writePositions = (startFrom: number) => <T extends SortableResource>(list: T[]): T[] => list.reduce(
    (acc, t, i) => [...acc, { ...t, position: startFrom + i }],
    [],
);

export const isSingleExerciseGroup = (group: IExerciseGroup): boolean => group.type.maxExercises === 1;
