<div class="container">
    <div class="content">
        <div class="logo-container">
            <mat-icon svgIcon="funxtion" class="logo"></mat-icon>
        </div>
        <mat-card class="auth-content">
            <router-outlet></router-outlet>
        </mat-card>
        <div class="go-back">
            <funxtion-button
                [variant]="BUTTON_VARIANT.CLEAR_ICON"
                [overrides]="{
                    iconConfig: {
                        iconName: 'next-arrow'
                    },
                    alignIcon: ALIGNMENT.RIGHT
                }"
            >
                {{ 'login.goBackToTheWebsite' | translate }}
            </funxtion-button>
        </div>
    </div>
    <div class="background">
        <div class="image"></div>
        <div class="solid"></div>
    </div>
</div>
