import { ALIGNMENT, BUTTON_VARIANT } from "src/app/modules/shared/inputConfig";
import { Component, OnInit } from "@angular/core";
import { Filter, RelationshipFilterConfig } from '@app-components/filter/interfaces/filter';
import { FunxtionApiClientService, Gender, Goal, TrainingSchema } from "@funxtion/ng-funxtion-api-client";

import { CreateTrainingDialogComponent } from "@app-components/training/create-training-dialog/create-training-dialog.component";
import { DefaultDialogDimensionsLarge } from "@app-helpers/constants";
import { ErrorDisplayService } from "@app-services/error-display/error-display.service";
import { FilterType } from "@app-components/dialogs/filter-dialog/filter-dialog.component";
import { FormBuilder } from "@angular/forms";
import { HeaderType } from "@app-types/page-header.types";
import { IndexView } from "../../../index-view";
import { MatDialog } from "@angular/material/dialog";
import { PageHeaderService } from "@app-services/page-header/page-header.service";
import { Router } from "@angular/router";
import { SearchService } from "@app-services/search-service/search.service";
import { TranslateService } from "@ngx-translate/core";
import { takeUntil } from "rxjs/operators";
import { isDMError } from "@app-helpers/narrowing.helpers";
import { DeleteModelService } from '@funxtion/portal/shared';
import { TrainingSchemaDuplicationService } from '../../../../modules/training-schema';

@Component({
    selector: "app-schema-index",
    templateUrl: "./schema-index.component.html",
    styleUrls: ["./schema-index.component.scss"],
})
export class SchemaIndexComponent extends IndexView<TrainingSchema> implements OnInit {
    title = "Training schemas";
    description = null;
    displayedColumns = ["image", "name", "intensity", "goal", "gender", "duration", "owner", "actions"];
    currentSchema = null;
    isDuplicating = false;
    BUTTON_VARIANT = BUTTON_VARIANT;
    ALIGNMENT = ALIGNMENT;

    constructor(
        funxtion: FunxtionApiClientService,
        private dialog: MatDialog,
        fb: FormBuilder,
        search: SearchService,
        protected router: Router,
        public translate: TranslateService,
        private errorDisplay: ErrorDisplayService,
        private pageHeader: PageHeaderService,
        private deleteModelService: DeleteModelService<TrainingSchema>,
        private trainingSchemaDuplicationService: TrainingSchemaDuplicationService
    ) {
        super(funxtion, fb, search, TrainingSchema, "training schema", [
            "genders",
            "goal",
            "owner",
        ], {
            owner_type: {
                in: funxtion.user.data.isAdministrator ? 'Organization,Administrator,Coach' : undefined,
                eq: !funxtion.user.data.isAdministrator ? 'Coach' : undefined
            }
        });
    }

    ngOnInit() {
        super.ngOnInit();

        this.pageHeader.setup({
            type: HeaderType.Overview,
            title: this.translate.instant('common.trainingSchemas'),
            filled: true,
        })
    }

    get schemas(): TrainingSchema[] {
        return this.models;
    }

    createTraining() {
        this.pageHeader.savePreviousConfig();
        // this.analytics.track(AnalyticsEvent.CREATE_PLAN_START);
        const dialog = this.dialog.open(CreateTrainingDialogComponent, {
            ...DefaultDialogDimensionsLarge,
            panelClass: 'create-dialog'
        });

        dialog.beforeClosed().subscribe(() => this.pageHeader.reloadPrevious());

        dialog.afterClosed().subscribe((schema: TrainingSchema) => {
            if (schema) {
                this.duplicateSchema(schema, false);
            }
        });
    }

    initFilters() {
        super.initFilters();
        const goalFilterConfig: RelationshipFilterConfig<Goal> = {
            type: Goal,
            renderLabel: goal => goal.name,
            renderValue: goal => goal.id,
        };
        const goalFilter: Filter = {
            label: "Goal",
            field: "goal",
            type: FilterType.RELATIONSHIP,
            config: goalFilterConfig,
        };
        const genderFilterConfig: RelationshipFilterConfig<Gender> = {
            type: Gender,
            renderLabel: gender => gender.name,
            renderValue: gender => gender.id,
        };
        const genderFilter: Filter = {
            label: "Gender",
            field: "gender",
            type: FilterType.RELATIONSHIP,
            config: genderFilterConfig,
        };
        this.filterConfig.push(goalFilter);
        this.filterConfig.push(genderFilter);
    }

    async gotToCreateSchemaPage() {
        await this.router.navigate(["training/workouts/create"]);
    }

    duplicateSchema(schema: TrainingSchema, confirm: boolean = true) {
        this.trainingSchemaDuplicationService.duplicate(schema, confirm)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(
                async (duplicationStatus) => {
                    this.isDuplicating = duplicationStatus.isDuplicating;

                    if (this.isDuplicating) {
                        this.currentSchema = schema;
                    }

                    if (duplicationStatus.response) {
                        const response = duplicationStatus.response;

                        if (isDMError(response)) {
                            this.errorDisplay.display(response.error);
                        } else {
                            await this.router.navigate(['training', 'workouts', response.currentModel.id]);
                        }
                    }
                },
                () => this.isDuplicating = false
        );
    }

    async handleTableButtons([action, schema]: [string, TrainingSchema]) {
        switch (action) {
            case 'delete':
                return this.deleteSchema(schema);
            case 'duplicate':
                return this.duplicateSchema(schema);
            case 'view':
                return this.router.navigate(['training', 'workouts', schema.id]);
        }
    }

    deleteSchema(schema: TrainingSchema) {
        this.deleteModelService
            .deleteModelWithDialog(schema, this.modelType, this.modelName)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(
                () => this.getModels(),
                (errorResponse) => {
                    if (isDMError(errorResponse)) {
                        this.errorDisplay.display(errorResponse.error);
                    }
                }
            );
    }
}
