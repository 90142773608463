<mat-dialog-content>
    <div class="dialog-header">
        <div mat-dialog-title>{{ data.title }}</div>
        <funxtion-button class="close-button" (click)="done(false)"
                         [variant]="BUTTON_VARIANT.CLOSE"
        ></funxtion-button>
    </div>
    <p class="message">{{ data.message }}</p>
    <mat-dialog-actions>
        <funxtion-button mat-dialog-close
                         [variant]="BUTTON_VARIANT.CLEAR_SMALL"
                         (click)="done(false)"
        >
            {{ 'common.cancel' | translate }}
        </funxtion-button>

        <funxtion-button mat-dialog-close
                         [variant]="BUTTON_VARIANT.SECONDARY_SMALL"
                         (click)="done(true)"
        >
            {{ 'common.duplicate' | translate }}
        </funxtion-button>
    </mat-dialog-actions>
</mat-dialog-content>
