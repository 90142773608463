import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { GroupExercise, GroupTargetType, Target, TrainingGroup, Workout } from "@funxtion/ng-funxtion-api-client";

import { DurationColumnPipe } from "@app-pipes/column-pipes/duration-column/duration-column.pipe";
import { MatDialog } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { ViewExerciseDialogComponent } from "@app-components/dialogs/training/exercises/view-exercise-dialog/view-exercise-dialog.component";
import { defaultActionIconConfig } from "@app-helpers/constants";

interface InfoLabel {
    label: string;
    props: { [k: string]: string };
}

@Component({
    selector: 'app-view-training-group-single',
    templateUrl: './view-training-group-single.component.html',
    styleUrls: ['./view-training-group-single.component.scss'],
})
export class ViewTrainingGroupSingleComponent implements OnChanges {
    defaultIconConfig = defaultActionIconConfig;

    @Input()
    number: number;

    @Input()
    group: TrainingGroup | Workout;

    @Input()
    exercise: GroupExercise;

    public exerciseInfo = "";

    constructor(
        private dialog: MatDialog,
        private translate: TranslateService,
        private durationPipe: DurationColumnPipe,
    ) {
    }

    ngOnChanges(changes: SimpleChanges) {

        if (!this.exercise) {
            return;
        }
        const labels: InfoLabel[] = [];
        const set = this.exercise.sets[0];
        if (! set || set.targets.length === 0) {
            return '';
        }
        const repsTarget: Target = set.targets.find((target: Target) => target.measurement.slug === 'repetitions');
        const durationTarget = set.targets.find((target: Target) => target.measurement.slug === 'duration');
        const weightTarget = set.targets.find((target: Target) => target.measurement.slug === 'weight');

        if (repsTarget) {
            if (repsTarget.type === GroupTargetType.ABSOLUTE) {
                labels.push({
                    label: 'training.schemas.xReps.absolute',
                    props: {
                        reps: repsTarget.value
                    }
                });
            } else {
                labels.push({
                    label: 'training.schemas.xReps.range',
                    props: {
                        min: repsTarget.min,
                        max: repsTarget.max
                    }
                });
            }
        }

        if (durationTarget) {
            if (durationTarget.type === GroupTargetType.ABSOLUTE) {
                labels.push({
                    label: 'training.schemas.xExDuration.absolute',
                    props: {
                        duration: this.durationPipe.transform(parseInt(durationTarget.value, 10), false),
                    }
                });
            } else {
                labels.push({
                    label: 'training.schemas.xExDuration.range',
                    props: {
                        min: this.durationPipe.transform(parseInt(durationTarget.min, 10), false),
                        max: this.durationPipe.transform(parseInt(durationTarget.max, 10), false),
                    }
                });
            }
        }

        if (weightTarget) {
            if (weightTarget.type === GroupTargetType.ABSOLUTE) {
                labels.push({
                    label: 'training.schemas.xWeight.absolute',
                    props: {
                        weight: weightTarget.value
                    }
                });
            } else {
                labels.push({
                    label: 'training.schemas.xWeight.range',
                    props: {
                        min: weightTarget.min,
                        max: weightTarget.max
                    }
                });
            }
        }

        const text = labels.map((label: InfoLabel) => {
            return this.translate.instant(label.label, label.props);
        });

        this.exerciseInfo = text.join(', ');
    }

    openDetails(exercise: GroupExercise) {
        this.dialog.open<ViewExerciseDialogComponent>(ViewExerciseDialogComponent, {
            data: {
                exercise: exercise.exercise,
            }
        });
    }
}
