import { ITrainingGroup } from '@app-types/vm/vm.schema.types';
import { Group, TrainingGroup } from '@funxtion/ng-funxtion-api-client';
import { AbstractModelMapper } from '@app-services/conversion-services/AbstractModelMapper';
import { AttributeMap, RelationMap } from '@app-types/vm-conversion/vm-conversion.types';


export class TrainingGroupMapper extends AbstractModelMapper<ITrainingGroup, TrainingGroup> {

    public readonly attributes: AttributeMap<ITrainingGroup, TrainingGroup> = {
        position: 'position',
        name: 'name',
    };

    public readonly relationships: RelationMap<ITrainingGroup, TrainingGroup>[] = [
        {
            mutable: true,
            type: 'one',
            vmKey: 'exerciseGroup',
            jamKey: 'group',
            jamClass: Group,
            inverse: false,
        },
    ];
}
