import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "src/app/guards/auth/auth.guard";
import { DefaultComponent } from "src/app/components/layouts/default/default.component";
import { SchemaIndexComponent } from "src/app/views/training/schemas/schema-index/schema-index.component";
import { SchemaViewComponent } from "src/app/views/training/schemas/schema-view/schema-view.component";
import { SchemaEditComponent } from "src/app/views/training/schemas/schema-edit/schema-edit.component";

const routes: Routes = [
    {
        path: "",
        canActivate: [AuthGuard],
        component: DefaultComponent,
        pathMatch: "full",
        children: [
            {
                pathMatch: "full",
                path: "",
                component: SchemaIndexComponent,
                data: {
                    title: "common.trainingSchemas",
                },
            },
        ],
    },
    {
        path: "create",
        canActivate: [AuthGuard],
        component: DefaultComponent,
        pathMatch: "full",
        children: [
            {
                pathMatch: "full",
                path: "",
                component: SchemaEditComponent,
            },
        ],
    },
    {
        path: ":id",
        canActivate: [AuthGuard],
        component: DefaultComponent,
        pathMatch: "full",
        children: [
            {
                path: "",
                component: SchemaViewComponent,
            },
        ],
    },
    {
        path: ":id/edit",
        canActivate: [AuthGuard],
        component: DefaultComponent,
        pathMatch: "full",
        children: [
            {
                path: "",
                component: SchemaEditComponent,
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class TrainingSchemaRoutingModule {
}
