import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class BackendService {
    newPortalId = this.portalIdGenerator();

    constructor() {}

    *portalIdGenerator(): IterableIterator<string> {
        let currentCopy = 0;

        while (true) {
            currentCopy += 1;
            yield `page-header-dialog-host-${currentCopy}`;
        }
    }
}
