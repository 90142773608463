import { T as TRUE, cond, fromPairs, map, merge, prop, sortBy, toPairs } from 'ramda';
import { identity, pipe } from "rxjs";

import { IViewModel } from "@app-types/vm.types";
import { TrackByFunction } from "@angular/core";
import { isPlainObject } from "@app-helpers/narrowing.helpers";

/**
 * Returns a string representation for given view-model. This can be used to match against other strings
 * to find out whether the contents of 2 model objects are identical.
 */
export const scalar = <T extends IViewModel>({ id, ...attributesAndRelations }: T): string => pipe(
    toPairs,
    // @ts-ignore
    sortBy(prop(1)),
    map(cond([
        [([, v]) => Array.isArray(v), ([k, v]) => [k, v.map(scalar)]],
        [([, v]) => isPlainObject(v), ([k, v]) => [k, scalar(v)]],
        [TRUE, identity],
    ])),
    fromPairs,
    merge({ id: String(id) }),
    JSON.stringify
    // @ts-ignore
)(attributesAndRelations); // @TODO See https://funxtion.atlassian.net/browse/FP-77


/**
 * Creates a generic TrackByFunction for view-model shape comparison.
 */
export const vmTrackFunction = <T extends IViewModel>(): TrackByFunction<T> => (i: number, vm: T) => scalar(vm);
