import { Group, GroupExercise, GroupType } from '@funxtion/ng-funxtion-api-client';
import { AbstractModelMapper } from '@app-services/conversion-services/AbstractModelMapper';
import { AttributeMap, RelationMap } from '@app-types/vm-conversion/vm-conversion.types';
import { IExerciseGroup } from '@app-types/vm/vm.common.types';


export class ExerciseGroupMapper extends AbstractModelMapper<IExerciseGroup, Group> {

    public readonly attributes: AttributeMap<IExerciseGroup, Group> = {
        numberOfRounds: 'numberOfRounds',
        durationInSeconds: 'durationInSeconds',
        targetRepetitions: 'targetRepetitions',
        restAfterSetInSeconds: 'restAfterSetInSeconds',
        restAfterExerciseInSeconds: 'restAfterExerciseInSeconds',
    };

    public readonly relationships: RelationMap<IExerciseGroup, Group>[] = [
        {
            mutable: false,
            type: 'one',
            vmKey: 'type',
            jamKey: 'type',
            jamClass: GroupType,
        },
        {
            mutable: true,
            type: 'many',
            vmKey: 'trainingExercises',
            jamKey: 'exercises',
            jamClass: GroupExercise,
            inverse: {
                key: 'group',
                type: 'one',
            },
        },
    ];
}
