import { ChangeDetectorRef, Component, ElementRef, HostBinding, Input, OnDestroy, OnInit, Optional, Self, ViewChild } from "@angular/core";
import { ControlValueAccessor, NgControl } from "@angular/forms";
import { Administrator, Coach, FunxtionApiClientService, Manager, Member, Organization } from "@funxtion/ng-funxtion-api-client";
import { MatFormFieldControl } from "@angular/material/form-field";
import { MatRadioChange } from "@angular/material/radio";
import { MatSelect, MatSelectChange } from "@angular/material/select";
import { JsonApiModel, JsonApiQueryData } from "angular2-jsonapi";
import { Observable, Subject } from "rxjs";
import { FocusMonitor } from "@angular/cdk/a11y";
import { coerceBooleanProperty } from "@angular/cdk/coercion";

@Component({
    selector: "app-owner-picker",
    templateUrl: "./owner-picker.component.html",
    styleUrls: ["./owner-picker.component.scss"],
    providers: [
        { provide: MatFormFieldControl, useExisting: OwnerPickerComponent },
    ],
})
export class OwnerPickerComponent implements ControlValueAccessor,
    MatFormFieldControl<Coach | Manager | Administrator | Organization>,
    OnInit,
    OnDestroy {

    private _owner: Coach | Manager | Administrator | Organization;
    private readonly roles: (Member | Coach | Manager | Administrator)[];

    stateChanges = new Subject<void>();
    static nextId = 0;
    errorState = false;

    ngOnInit() {
        this.cdr.markForCheck();
    }

    focused = false;
    @HostBinding("attr.aria-describedby") describedBy = "";

    setDescribedByIds(ids: string[]) {
        this.describedBy = ids.join(" ");
    }

    onContainerClick(event: MouseEvent) {
    }

    @Input()
    get disabled(): boolean {
        return this._disabled;
    }

    set disabled(value: boolean) {
        this._disabled = coerceBooleanProperty(value);
        this.stateChanges.next();
    }

    private _disabled = false;

    @HostBinding() id = `example-tel-input-${OwnerPickerComponent.nextId++}`;

    @HostBinding("class.floating")
    get shouldLabelFloat() {
        return this.focused || !this.empty;
    }

    $coaches: Observable<JsonApiQueryData<Coach>>;
    $managers: Observable<JsonApiQueryData<Manager>>;
    coaches: Coach[];
    managers: Manager[];

    @ViewChild("coachesList", /* TODO: add static flag */ {})
    protected coachesList: MatSelect;

    @ViewChild("managersList", /* TODO: add static flag */ {})
    protected managersList: MatSelect;

    @Input()
    get placeholder() {
        return this._placeholder;
    }

    set placeholder(plh) {
        this._placeholder = plh;
        this.stateChanges.next();
    }

    private _placeholder: string;

    private propagateChange = (_) => {
    };

    @Input()
    get required() {
        return this._required;
    }

    set required(req) {
        this._required = coerceBooleanProperty(req);
        this.stateChanges.next();
    }

    private _required = false;

    constructor(
        @Optional() @Self() public ngControl: NgControl,
        public funxtion: FunxtionApiClientService,
        private fm: FocusMonitor,
        private elRef: ElementRef<HTMLElement>,
        private cdr: ChangeDetectorRef,
    ) {
        this.fm.monitor(this.elRef.nativeElement, true).subscribe(origin => {
            this.focused = !!origin;
            this.stateChanges.next();
        });
        if (this.ngControl !== null) {
            this.ngControl.valueAccessor = this;
        }
        this.roles = this.pruneUserRoles();
        if (!this.isUserOnlyCoach()) {
            this.getSelectData();
        }
    }

    ownerIsManager = false;
    ownerIsCoach = false;
    amIOwnerManager = false;
    amIOwnerAdmin = false;
    amIOwnerOrg = false;
    coachSelectValue: Coach = null;
    managerSelectValue: Manager = null;

    get value() {
        return this._owner;
    }

    set value(val: Coach | Manager | Administrator | Organization) {
        this._owner = val;

        this.stateChanges.next();
    }

    get empty() {
        return false;
    }

    @Input()
    set owner(owner: Coach | Manager | Administrator | Organization) {
        this._owner = owner;
        this.ownerIsCoach = owner instanceof Coach;
        this.ownerIsManager = owner instanceof Manager;
        this.amIOwnerAdmin =
            owner instanceof Administrator &&
            owner.id === this.funxtion.user.data.administrator.id;
        this.amIOwnerManager =
            owner instanceof Manager &&
            owner.id === this.funxtion.user.data.manager.id;
        this.amIOwnerOrg =
            owner instanceof Organization &&
            owner.id === this.funxtion.client.data.organization.id;
        if (this.coaches) {
            this.coachSelectValue =
                owner instanceof Coach
                    ? this.coaches.find((coach) => coach.id === owner.id)
                    : null;
        } else {
            this.getCoaches().subscribe(data => {
                this.coachSelectValue = owner instanceof Coach
                    ? data.getModels().find((coach) => coach.id === owner.id)
                    : null;
            });
        }
        if (this.managers) {
            this.managerSelectValue = owner instanceof Manager
                ? this.managers.find((manager) => manager.id === owner.id)
                : null;
        } else {

            this.getManagers().subscribe(data => {
                const managers = data.getModels();
                this.managerSelectValue = owner instanceof Manager
                    ? managers.find((manager) => manager.id === owner.id)
                    : null;
            });
        }

        if (!this._owner) {
            this.setDefaultOwner();
        }
    }

    get owner(): Coach | Manager | Administrator | Organization {
        return this._owner;
    }

    setDefaultOwner() {
        if (this.isUserOnlyCoach()) {
            this._owner = this.funxtion.user.data.coach;
        } else if (this.funxtion.user.data.isAdministrator) {
            this._owner = this.funxtion.user.data.administrator;
        } else if (this.funxtion.user.data.isManager) {
            this._owner = this.funxtion.user.data.manager;
        }
        this.propagateChange(this._owner);
    }

    pruneUserRoles() {
        return this.funxtion.user.data.roles.filter(
            (role: Member | Coach | Manager | Administrator) =>
                !(role instanceof Member),
        );
    }

    isUserOnlyCoach(): boolean {
        return this.roles.length === 1 && this.roles[0] instanceof Coach;
    }

    getSelectData() {

        this.getCoaches().subscribe(data => {
            this.coaches = data.getModels();
        });

        if (this.funxtion.user.data.isAdministrator) {
            this.getManagers().subscribe(data => {
                this.managers = data.getModels();
            });
        }
    }

    getCoaches() {
        if (!this.$coaches) {
            this.$coaches = this.funxtion.datastore.findAll(Coach);
        }
        return this.$coaches;
    }

    getManagers() {
        if (!this.$managers) {
            this.$managers = this.funxtion.datastore.findAll(Manager);
        }
        return this.$managers;
    }

    onChange(changeEvent: MatSelectChange | MatRadioChange) {
        if (!changeEvent.source["value"]) {
            return;
        }

        switch (changeEvent.source.value) {
            case "organization":
                this._owner = this.funxtion.client.data.organization;
                break;

            case "coaches":
            case "managers":
                this.valueFromList(changeEvent.source["value"]);
                break;

            default:
                this._owner = changeEvent.source["value"];
                break;
        }
        this.propagateChange(this._owner);
        this.cdr.markForCheck();
        this.stateChanges.next();
    }

    private valueFromList(name: string) {
        switch (name) {
            case "coaches":
                if (!this.coachesList.value) {
                    this.coachesList.value = this.coachesList.options.first.value;
                }
                this._owner = this.coachesList.value;
                break;

            case "managers":
                if (!this.managersList.value) {
                    this.managersList.value = this.managersList.options.first.value;
                }
                this._owner = this.managersList.value;
                break;
        }
        this.stateChanges.next();
    }

    public compareModels(a: JsonApiModel, b: JsonApiModel): boolean {
        if (!a || !b) {
            return false;
        }
        return a.id === b.id;
    }

    registerOnChange(fn): void {
        this.propagateChange = fn;
    }

    registerOnTouched(): void {
    }

    setDisabledState(isDisabled: boolean): void {
    }

    writeValue(obj): void {
        this.owner = obj;
    }

    ngOnDestroy() {
        this.stateChanges.complete();
    }
}
