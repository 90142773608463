<div class="training-attributes-container" *ngIf="training">

    <div class="image-container">
        <div class="img" (click)="imgFileInput.click()">
            <img [src]="imageString ? imageString : training.image || '/assets/icons/default-plan.svg'"
                 default="/assets/icons/default-plan.svg"
            />
            <div class="edit-icon">
                <funxtion-icon [config]="editImageIcon"></funxtion-icon>
            </div>
            <input type="file" hidden #imgFileInput (change)="setImage($event)"/>
        </div>
    </div>
    <div class="form-container">
        <form *ngIf="detailsForm" [formGroup]="detailsForm">
            <div class="row">

                <div class="col-4">

                    <mat-form-field class="w-100" funxtion-form-field>
                        <mat-label>{{ "training.schemas.title" | translate }}</mat-label>
                        <input matInput formControlName="name" required/>
                        <mat-icon matSuffix svgIcon="valid" class="funxtion-form-field-icon-valid"></mat-icon>
                        <mat-icon matSuffix svgIcon="invalid" class="funxtion-form-field-icon-invalid"></mat-icon>
                        <mat-error *ngIf="detailsForm.controls.name.hasError('required')">
                            {{ "training.schemas.title" | translate }} is <strong>required</strong>
                          </mat-error>
                    </mat-form-field>

                    <mat-form-field *ngIf="goals" funxtion-form-field>
                        <mat-label>{{ "common.goal" | translate }}</mat-label>
                        <mat-select formControlName="goal"
                                    [placeholder]="'common.goal' | translate"
                                    [compareWith]="compareById"
                                    required
                        >
                            <mat-option *ngFor="let goal of goals" [value]="goal">
                                {{ goal.name }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="detailsForm.controls.goal.hasError('required')">
                            {{ "common.goal" | translate }} is <strong>required</strong>
                          </mat-error>
                    </mat-form-field>

                    <div class="slider-container" funxtion-form-field>
                        <label firstLabel>{{ 'training.plans.intensity' | translate }} *</label>
                        <mat-slider matInput [value]="detailsForm.value.intensity" [min]="1" [max]="10" (change)="setIntensity($event)"></mat-slider>
                        <label secondLabel>{{detailsForm.value.intensity}}</label>
                    </div>

                    <mat-form-field *ngIf="types" funxtion-form-field>
                        <mat-label>{{ "common.type" | translate }}</mat-label>
                        <mat-select formControlName="types"
                                    multiple
                                    [placeholder]="'common.type' | translate"
                                    [compareWith]="compareById"
                        >
                            <mat-option *ngFor="let type of types" [value]="type">
                                {{ type.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                </div>

                <div class="col-4">
                    <mat-form-field class="w-100" funxtion-form-field>
                        <mat-label>{{ "common.shortDescription" | translate }}</mat-label>
                        <textarea matInput
                                  required
                                  matTextareaAutosize
                                  matAutosizeMinRows="2"
                                  formControlName="descriptionShort"
                                  spellcheck="false"
                        ></textarea>
                        <label secondLabel *ngIf="detailsForm.get('descriptionShort').value as value">
                            {{ value.length }} / {{ '255' }}
                        </label>
                        <mat-icon matSuffix svgIcon="valid" class="funxtion-form-field-icon-valid"></mat-icon>
                        <mat-icon matSuffix svgIcon="invalid" class="funxtion-form-field-icon-invalid"></mat-icon>
                        <mat-error *ngIf="detailsForm.controls.descriptionShort.hasError('required')">
                            {{ "common.shortDescription" | translate }} is <strong>required</strong>
                          </mat-error>
                    </mat-form-field>

                    <mat-form-field class="w-100" funxtion-form-field>
                        <mat-label>{{ "common.longDescription" | translate }}</mat-label>
                        <textarea matInput
                                  matTextareaAutosize
                                  matAutosizeMinRows="4"
                                  formControlName="descriptionLong"
                                  required
                        ></textarea>
                        <label secondLabel *ngIf="detailsForm.get('descriptionLong').value as value">
                            {{ value.length }} / {{ '512' }}
                        </label>
                        <mat-icon matSuffix svgIcon="valid" class="funxtion-form-field-icon-valid"></mat-icon>
                        <mat-icon matSuffix svgIcon="invalid" class="funxtion-form-field-icon-invalid"></mat-icon>
                        <mat-error *ngIf="detailsForm.controls.descriptionLong.hasError('required')">
                            {{ "common.longDescription" | translate }} is <strong>required</strong>
                          </mat-error>
                    </mat-form-field>

                    <div *ngIf="levels">
                        <mat-label>{{ 'common.level' | translate }}</mat-label>
                        <mat-slider matInput [value]="detailsForm.value.level?.position" [min]="1" [max]="levels.length" (change)="setLevel($event)"></mat-slider>
                        <label>{{ detailsForm.value.level?.name }}</label>
                    </div>

                </div>

                <div class="col-4">

                    <mat-form-field funxtion-form-field>
                        <mat-label>{{ "training.schemas.approx-duration" | translate }}</mat-label>
                        <app-input-time formControlName="durationInSeconds"
                                        placeholder="{{ 'training.schemas.approx-duration' | translate }}"
                                        required
                                        [useHours]="false"></app-input-time>
                        <mat-error *ngIf="detailsForm.controls.durationInSeconds.hasError('required')">
                            {{ "training.schemas.approx-duration" | translate }} is <strong>required</strong>
                          </mat-error>
                    </mat-form-field>

                    <mat-form-field funxtion-form-field *ngIf="genders">
                        <mat-label>{{ "common.gender" | translate }}</mat-label>
                        <mat-select multiple formControlName="genders"
                                    [compareWith]="compareById"
                                    required
                        >
                            <mat-option *ngFor="let gender of genders" [value]="gender">
                                {{ gender.name }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="detailsForm.controls.genders.hasError('required')">
                            {{ "common.gender" | translate }} is <strong>required</strong>
                          </mat-error>
                    </mat-form-field>

                    <mat-form-field class="w-100" funxtion-form-field>
                        <mat-label>{{ "training.schemas.caloriesBurned" | translate }}</mat-label>
                        <input matInput
                               formControlName="energyExpenditureInKiloCalories"
                               type="number"
                               required/>
                        <span matSuffix>{{ "common.units.kilocalories_abbr" | translate }}</span>
                        <mat-error *ngIf="detailsForm.controls.energyExpenditureInKiloCalories.hasError('required')">
                            {{ "training.schemas.caloriesBurned" | translate }} is <strong>required</strong>
                          </mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline" *ngIf="tags?.length > 0" funxtion-form-field>
                        <mat-label>{{ "common.bodySection" | translate }}</mat-label>
                        <mat-select matInput multiple formControlName="tags" [compareWith]="compareById">
                            <mat-option *ngFor="let tag of tags" [value]="tag">
                                {{ tag.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </form>
    </div>
</div>
