import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Pipe({
    name: "formatFileSize",
})
export class FormatFileSizePipe implements PipeTransform {
    constructor(private translateService: TranslateService) {
    }

    units = [
        "common.units.bytes_abbr",
        "common.units.kilobytes_abbr",
        "common.units.megabytes_abbr",
        "common.units.gigabytes_abbr",
    ];

    transform(size: number) {
        let unitIndex = 0;
        while (size / 1000 > 1) {
            size = size / 1000;
            unitIndex++;
        }
        return `${Math.round(size * 10) / 10} ${this.translateService.instant(
            this.units[unitIndex],
        )}`;
    }
}
