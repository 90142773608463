import {
    Administrator,
    BodySection,
    Coach,
    Equipment,
    EquipmentMeasurement,
    Funxtion,
    Gender,
    Goal,
    GroupType,
    Level,
    Location,
    Manager,
    Member,
    Organization,
    TrainingSchemaType,
    WorkoutCategory,
    Tag,
    Country
} from '@funxtion/ng-funxtion-api-client';
import { IBodySection, IGender, IGoal, ILevel, ILocation, IOwner, ITrainingSchemaType, ITag, ICountry } from '@app-types/vm/vm.immutable.types';
import { IEquipment, IEquipmentMeasurement, IExerciseGroupType, MeasurementSlug } from '@app-types/vm/vm.common.types';
import { IWorkoutCategory } from '@app-types/vm/vm.workout.types';

export function showLevel({ id, name, type, position }: Level): ILevel {
    return { id, name, type, position };
}

export function showGoal({ id, name, type, position }: Goal): IGoal {
    return { id, name, type, position };
}

export function showCountry({ id, name, type, position }: Country): ICountry {
    return { id, name, type, position };
}

export function showTrainingSchemaType({ id, name }: TrainingSchemaType): ITrainingSchemaType {
    return { id, name };
}

export function showGender({ id, name, type }: Gender): IGender {
    return { id, name, type };
}

export function showEquipment({ id, name, slug, icon, measurements }: Equipment): IEquipment {
    return {
        id,
        name,
        slug,
        icon,
        measurements: measurements.map(x => showMeasurement(x))
    };
}

export function showMeasurement(measurement: EquipmentMeasurement): IEquipmentMeasurement {
    return {
        id: measurement.id,
        name: measurement.name,
        slug: measurement.slug as MeasurementSlug,
        unit: measurement.unit,
        position: measurement.position,
        min: measurement.min,
        max: measurement.max,
        step: measurement.step
    };
}

export function showExerciseGroupType(groupType: GroupType): IExerciseGroupType {
    const {
        id,
        name,
        description,
        type,
        maxExercises,
        requiresNumberOfRounds,
        requiresRestAfterSetInSeconds,
        requiresRestAfterExerciseInSeconds,
        requiresDurationInSeconds,
        requiresTargetRepetitions
    } = groupType;

    return ({
        id,
        name,
        description,
        type,
        maxExercises,
        requiresNumberOfRounds,
        requiresRestAfterSetInSeconds,
        requiresRestAfterExerciseInSeconds,
        requiresDurationInSeconds,
        requiresTargetRepetitions
    } as unknown) as IExerciseGroupType; // Because of misalignment of requires<X> properties. These are
    // currently wrongly typed as numbers in the API model.
}

export function showOwner(owner: Funxtion | Organization | Administrator | Manager | Coach | Member): IOwner {
    const { id, name } = owner;
    let { type } = owner;
    if (owner instanceof Funxtion) {
        type = 'funxtion';
    } else if (owner instanceof Organization) {
        type = 'organization';
    } else if (owner instanceof Administrator) {
        type = 'administrator';
    } else if (owner instanceof Manager) {
        type = 'manager';
    } else if (owner instanceof Coach) {
        type = 'coach';
    } else if (owner instanceof Member) {
        type = 'member';
    }
    return { id, name, type };
}

export function showLocation({ id, name, type }: Location): ILocation {
    return {
        id,
        name,
        type
    };
}

export function showTag({ id, name, slug, groupName }: Tag): ITag {
    return {
        id,
        name,
        slug,
        groupName
    };
}

export function showBodySection({ id, name, slug, type }: BodySection): IBodySection {
    return {
        id,
        name,
        slug,
        type
    };
}

export function showWorkoutCategory({ id, name, description, slug, type }: WorkoutCategory): IWorkoutCategory {
    return {
        id,
        name,
        description,
        slug,
        type
    };
}
