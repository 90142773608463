import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";

import { FunxtionApiClientService } from "@funxtion/ng-funxtion-api-client";
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root",
})
export class GuestGuard implements CanActivate {
    constructor(
        private funxtion: FunxtionApiClientService,
        private router: Router,
    ) {
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): boolean {

        if (!this.funxtion.oauth.hasValidAccessToken()) {
            return true;
        }

        return false;
    }
}
