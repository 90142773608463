import { Component, Inject } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

export interface GetTextInputDialogData {
    title: string;
    message: string;
    inputType?: string;
}

export interface TextInputDialogResult {
    confirmed: boolean,
    value?: string // only set if confirmed = true
}

export type TextInputDialogRef = MatDialogRef<GetTextInputDialogComponent, TextInputDialogResult>;

@Component({
    selector: "app-get-text-input-dialog",
    templateUrl: "./get-text-input-dialog.component.html",
    styleUrls: ["./get-text-input-dialog.component.scss"],
})
export class GetTextInputDialogComponent {

    text: FormControl = new FormControl("");
    form: FormGroup;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: GetTextInputDialogData,
        private fb: FormBuilder,
        private dialogRef: MatDialogRef<GetTextInputDialogComponent, TextInputDialogResult>,
    ) {
        this.form = this.fb.group({
            text: ["", Validators.required],
        });
    }

    submit() {

        if (this.form.valid) {
            this.dialogRef.close({
                confirmed: true,
                value: this.form.value.text,
            });
        } else {
            // ?
        }
    }

    cancel() {
        this.dialogRef.close({
            confirmed: false,
        });
    }
}
