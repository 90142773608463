import { Injectable } from '@angular/core';
import { prospectIdPool } from "@app-helpers/prospectIdPool";

@Injectable({
    providedIn: 'root',
})
export class ProspectService {
    public get newId() {
        return this.idGenerator.next().value;
    }

    private idGenerator: Iterator<symbol>;

    constructor() {
        this.idGenerator = prospectIdPool();
    }
}
