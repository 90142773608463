import { Component, Input, OnInit } from '@angular/core';
import { ICON_COLOR, ICON_SIZE, IconConfig } from "src/app/modules/shared/inputConfig";

@Component({
    selector: 'app-column-icon',
    templateUrl: './column-icon.component.html',
    styleUrls: ['./column-icon.component.scss'],
})
export class ColumnIconComponent implements OnInit {
    config: IconConfig = {
        iconSize: ICON_SIZE.DEFAULT,
        iconColor: ICON_COLOR.PRIMARY,
    };

    @Input()
    tooltip: string = '';

    @Input()
    iconName: string = '';

    constructor() {
    }

    ngOnInit() {
    }

}
