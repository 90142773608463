import { ITrainingSchema } from '@app-types/vm/vm.schema.types';
import {
    Gender,
    Goal,
    Level,
    TrainingPhase,
    TrainingSchema,
    TrainingSchemaType,
    Tag
} from '@funxtion/ng-funxtion-api-client';
import { AbstractModelMapper } from '@app-services/conversion-services/AbstractModelMapper';
import { AttributeMap, RelationMap } from '@app-types/vm-conversion/vm-conversion.types';
import { resolveOwnerType } from '@app-helpers/vm-conversion.helpers';


export class TrainingSchemaMapper extends AbstractModelMapper<ITrainingSchema, TrainingSchema> {

    public readonly attributes: AttributeMap<ITrainingSchema, TrainingSchema> = {
        name: 'name',
        descriptionShort: 'descriptionShort',
        descriptionLong: 'descriptionLong',
        durationInSeconds: 'durationInSeconds',
        energyExpenditureInKiloCalories: 'energyExpenditureInKiloCalories',
        intensity: 'intensity',
        image: 'image',
    };

    public readonly relationships: RelationMap<ITrainingSchema, TrainingSchema>[] = [
        {
            mutable: false,
            type: 'one',
            vmKey: 'goal',
            jamKey: 'goal',
            jamClass: Goal,
        },
        {
            mutable: false,
            type: 'many',
            vmKey: 'genders',
            jamKey: 'genders',
            jamClass: Gender,
        },
        {
            mutable: false,
            type: 'many',
            vmKey: 'types',
            jamKey: 'types',
            jamClass: TrainingSchemaType,
        },
        {
            mutable: false,
            type: 'many',
            vmKey: 'tags',
            jamKey: 'tags',
            jamClass: Tag,
        },
        {
            mutable: false,
            type: 'one',
            vmKey: 'level',
            jamKey: 'level',
            jamClass: Level,
        },
        {
            mutable: false,
            type: 'one',
            vmKey: 'owner',
            jamKey: 'owner',
            jamClassResolver: (vm: ITrainingSchema) => resolveOwnerType(vm.owner),
        },
        {
            mutable: true,
            type: 'many',
            vmKey: 'trainingPhases',
            jamKey: 'phases',
            jamClass: TrainingPhase,
            inverse: {
                key: 'schema',
                type: 'one',
            },
        },
    ];
}
