<div id="side-navigation-container">

    <div class="logo-container">
        <div class="environment-container" *ngIf="environmentService.environment.environment!=='production'">
            {{environmentService.environment.environment}}</div>
        <mat-icon id="funxtion-logo" svgIcon="funxtion"></mat-icon>
    </div>

    <nav class="navigation-list">

        <ul class="main-navigation-list">

            <ng-container *ngFor="let mainNavItem of navigationItems">

                <li class="main-navigation-list-item" *ngIf="mainNavItem.visible"
                    [ngClass]="{ active: path.startsWith(mainNavItem.url)}">
                    <a class="navigation-link main-navigation-link" routerLink="{{ mainNavItem.url }}">
                        <span class="icon-container">
                            <funxtion-icon
                                [svgIcon]="mainNavItem.icon + (path.startsWith(mainNavItem.url) ? '_filled' : '')"
                                [config]="baseIconConfig"></funxtion-icon>
                        </span>
                        <span class="link-label">{{ mainNavItem.title }}</span>
                    </a>

                    <ul class="sub-navigation-list" *ngIf="mainNavItem.children.length">
                        <ng-container *ngFor="let subNavItem of mainNavItem.children">

                            <li class="sub-navigation-list-item" *ngIf="subNavItem.visible"
                                [ngClass]="{ active: path.startsWith(subNavItem.url)}">
                                <a class="navigation-link sub-navigation-link" routerLink="{{ subNavItem.url }}">
                                    {{ subNavItem.title }}
                                </a>
                            </li>
                        </ng-container>
                    </ul>
                </li>
            </ng-container>
        </ul>
    </nav>


    <mat-menu #userMenu="matMenu" class="dark-menu">
        <button *ngIf="!environment.trainingPlanner" mat-menu-item routerLink="/members/me">
            {{ "common.profile" | translate }}
        </button>
        <button mat-menu-item (click)="logout()">{{ "common.logOut" | translate }}</button>
    </mat-menu>

    <button class="user" [matMenuTriggerFor]="userMenu">

        <div class="user--left">
            <img class="user--avatar" default="/assets/icons/default-member.svg"
                [src]="user.avatar ? user.avatar : '/assets/icons/default-member.svg'" />
            <div class="user--name">{{ user.fullName }}</div>
        </div>

        <div class="user--arrow">
            <funxtion-icon [overrides]="{ iconSize: ICON_SIZE.DEFAULT
                                        , iconName: 'dropdown-arrow'
                                        , iconColor: ICON_COLOR.SUPPORT }" [config]="baseIconConfig"></funxtion-icon>
        </div>
    </button>
</div>
