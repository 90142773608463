<mat-accordion class="training-group-accordion">
    <mat-expansion-panel>
        <mat-expansion-panel-header [collapsedHeight]="'auto'" [expandedHeight]="'auto'">
            <div class="view-group">
                <div class="group-image">
                    <app-stacked-image [size]="80"
                                       [superset]="group.group.type.id === '2'"
                                       [multi]="true"
                                       [selected]="false"
                                       [hasNumber]="hasNumber"
                                       [number]="number"
                                       [imageUrl]="exercise?.exercise?.imageSmall"
                    ></app-stacked-image>
                </div>

                <div class="group-name-details">
                    <div class="group-name">
                        {{ displayName }}
                    </div>
                    <div class="group-details">
                        <div class="sets-info">
                            {{ groupText }}
                        </div>
<!--                        <div class="sets-rest">-->
<!--                            {{ "training.plans.secondsRestAfterSets" | translate : { number: group.group.restAfterSetInSeconds } }}-->
<!--                        </div>-->
                    </div>
                </div>

                <div class="group-notes-container">

                </div>
            </div>
        </mat-expansion-panel-header>
        <div class="exercise-children">
            <div class="flow-section-top">
                <img src="/assets/svg/training-loop/circuit-stroke-top.svg"/>
            </div>
            <ng-container *ngFor="let exerciseChild of exercise?.group?.exercises">
                <div class="flow-section">
                    <img src="/assets/svg/training-loop/circuit-stroke-dot.svg"/>
                </div>
                <app-view-training-group-single [group]="group" [exercise]="exerciseChild"></app-view-training-group-single>
            </ng-container>
            <div class="flow-section-bottom">
                <img src="/assets/svg/training-loop/circuit-stroke-bottom.svg"/>
            </div>
        </div>
    </mat-expansion-panel>
</mat-accordion>
