import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from "@angular/forms";
import { MatSliderChange } from "@angular/material/slider";
import { getTrainingAttributesForm } from "@app-helpers/forms/training-attributes.form";
import { ImmutableResourcesService } from "@app-services/immutable-resources/immutable-resources.service";
import { TrainingSchemaVMService } from "@app-services/vm/training-schema/training-schema-vm.service";
import { HTMLInputEvent } from "@app-types/HTMLInputEvent";
import { IGender, IGoal, ILevel, ITrainingSchemaType, ITag } from '@app-types/vm/vm.immutable.types';
import { ITrainingSchema } from "@app-types/vm/vm.schema.types";
import { FunxtionApiClientService } from "@funxtion/ng-funxtion-api-client";
import { flip, merge } from 'ramda';
import { debounceTime } from "rxjs/operators";
import { IconConfig, ICON_SIZE, ICON_COLOR } from '@funxtion/portal/shared/inputConfig';
import { VM_SERVICE } from "@funxtion/portal/shared/injection-tokens/vm-tokens";

interface Identified {
    id: string;
}

@Component({
    selector: 'app-training-attributes',
    templateUrl: './training-attributes.component.html',
    styleUrls: ['./training-attributes.component.scss'],
})
export class TrainingAttributesComponent implements OnInit {

    // ------------------------------------------------------------------------------
    //      Static data dependencies, initialized on ng-init.
    // ------------------------------------------------------------------------------

    public genders: IGender[];
    public goals: IGoal[];
    public types: ITrainingSchemaType[];
    public levels: ILevel[];
    public tags: ITag[];

    public detailsForm: FormGroup;
    public imageString: string;

    private _training: ITrainingSchema;
    editImageIcon: IconConfig = {
        iconSize: ICON_SIZE.DEFAULT,
        iconColor: ICON_COLOR.PRIMARY,
        iconName: 'edit',
    };

    @Input('training')
    set training(value: ITrainingSchema) {
        if (value) {
            this._training = value;
        }
    }
    get training() {
        return this._training;
    }


    // If provided will add this FormGroup as a child to it's parent component
    @Input()
    public parentFormGroup: FormGroup;

    constructor(
        @Inject(VM_SERVICE) private vm: TrainingSchemaVMService,
        private funxtion: FunxtionApiClientService,
        private fb: FormBuilder,
        private immutableResources: ImmutableResourcesService,
    ) {
    }

    async ngOnInit() {
        this.genders = await this.immutableResources.getGenders(true);
        this.goals = await this.immutableResources.getGoals(true);
        this.types = await this.immutableResources.getTrainingSchemaType(true);
        this.levels = await this.immutableResources.getLevels(true);
        this.tags = await this.immutableResources.getTags(true);

        this.buildForm();
    }

    buildForm() {
        this.detailsForm = this.fb.group(getTrainingAttributesForm(this.training, this.genders));

        this.detailsForm.valueChanges.pipe(
            debounceTime(1000),
        ).subscribe((values) => this.vm.transform(
            flip(merge)(values),
        ));

        this.parentFormGroup.addControl('trainingAttributes', this.detailsForm);
    }

    compareById<T extends Identified, U extends Identified>(a: T, b: U): boolean {
        return a?.id === b?.id;
    }

    setImage($event: HTMLInputEvent) {
        const file: File = $event.srcElement.files[0] || null;
        const reader = new FileReader();
        reader.onload = () => {
            this.imageString = reader.result.toString();
        };
        reader.readAsDataURL(file);
        this.detailsForm.get('image').setValue(file);
    }

    setIntensity($event: MatSliderChange) {
        this.detailsForm.get('intensity').setValue($event.value);
    }

    setLevel($event: MatSliderChange) {
        this.detailsForm.get('level').setValue(this.levels.find((level) => level.position === $event.value));
    }

}
