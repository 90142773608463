<!-- Option 1 / 3 : it's a single-exercise type group, and there is an exercise to show -->

<div *ngIf="switches.type === 'timer'" class="tree-list-item-content" (click)="showTrainingGroupDetail()">
    <app-image-list-item [multi]="false"
                            [indent]="false"
                            [superset]="false"
                            [textContent]="exGroup.type.name"
                            imageUrl="/assets/icons/timer.svg"
                            [selected]="detailSelectionService.exerciseGroupIsSelected(exGroup)"
        ></app-image-list-item>
</div>

<ng-container *ngIf="switches.type !== 'timer'">
    <div *ngIf="switches.single && switches.withExercises"
        class="tree-list-item-content"
        (click)="showTrainingExerciseDetail(firstTrainingExercise)">

        <app-image-list-item [multi]="switches.multi"
                            [indent]="false"
                            [superset]="switches.superset"
                            [textContent]="firstTrainingExercise.exercise.name"
                            [imageUrl]="firstTrainingExercise.exercise.imageSmall"
                            [selected]="detailSelectionService.exerciseIsSelected(firstTrainingExercise)"
        ></app-image-list-item>
    </div>


    <div *ngIf="switches.single && switches.withoutExercises"
        class="tree-list-item-content spaced padded"
    >
        {{ exGroup.type.name }} (No exercise selected)
    </div>

    <ng-container *ngIf="switches.multi">
        <div class="tree-list-item-content"
            (click)="showTrainingGroupDetail()"
        >
            <app-image-list-item [multi]="true"
                                [indent]="false"
                                [superset]="switches.superset"
                                [textContent]="exGroup.type.name"
                                [imageUrl]="firstTrainingExercise?.exercise?.imageSmall"
                                [selected]="detailSelectionService.exerciseGroupIsSelected(exGroup)"
            ></app-image-list-item>
        </div>

        <app-exercise-group [hidden]="!isExpanded"
                            [isWorkout]="!!exerciseGroup"
                            [exerciseGroup]="exGroup"
        ></app-exercise-group>
    </ng-container>
</ng-container>
