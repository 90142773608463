<!--suppress HtmlFormInputWithoutLabel -->

<div class="row" [@fade]="exerciseGroup.id">



    <div class="col" *ngIf="exerciseGroup.type.requiresNumberOfRounds">

        <mat-form-field appearance="outline">
            <mat-label>{{ exerciseGroup.labels.numberOfRounds }}</mat-label>
            <input [formControl]="getFormControl('numberOfRounds')"
                   type="number"
                   matInput
                   min="1"
                   required
            />
            <span matInfix>&times;</span>
        </mat-form-field>
    </div>


    <div class="col" *ngIf="exerciseGroup.type.requiresDurationInSeconds">
        <mat-form-field appearance="outline">

            <mat-label>{{ exerciseGroup.labels.durationInSeconds }}</mat-label>

            <app-input-time [formControl]="getFormControl('durationInSeconds')"
                [placeholder]="'training.schemas.approx-duration' | translate"
                [required]="true"
                [useHours]="false"
            ></app-input-time>
        </mat-form-field>
        <mat-error *ngIf="getFormControl('durationInSeconds').hasError('required')">
            {{ 'training.workouts.validations.targetTimeRequired' | translate }}
        </mat-error>
    </div>


    <div class="col" *ngIf="exerciseGroup.type.requiresTargetRepetitions">
        <mat-form-field appearance="outline">

            <mat-label>{{ exerciseGroup.labels.targetRepetitions }}</mat-label>

            <input [formControl]="getFormControl('targetRepetitions')"
                   required
                   type="number"
                   matInput
                   min="0"
            />
            <span matInfix>x</span>
        </mat-form-field>
    </div>


    <div class="col" *ngIf="exerciseGroup.type.requiresRestAfterSetInSeconds">
        <mat-form-field appearance="outline">

            <mat-label>{{ exerciseGroup.labels.restAfterSetInSeconds }}</mat-label>

            <input [formControl]="getFormControl('restAfterSetInSeconds')"
                   required
                   type="number"
                   matInput
                   min="0"
                   step="5"
            />
            <span matInfix>{{ "common.units.seconds" | translate : { value: getFormControl('restAfterSetInSeconds').value } }}</span>
        </mat-form-field>
    </div>


    <div class="col" *ngIf="exerciseGroup.type.requiresRestAfterExerciseInSeconds">
        <mat-form-field appearance="outline">

            <mat-label>{{ exerciseGroup.labels.restAfterExerciseInSeconds }}</mat-label>

            <input [formControl]="getFormControl('restAfterExerciseInSeconds')"
                   required
                   type="number"
                   matInput
                   min="0"
                   step="5"
            />
            <span matInfix>{{ "common.units.seconds" | translate : { value: getFormControl('restAfterExerciseInSeconds').value } }}</span>
        </mat-form-field>
    </div>

    <p class="notice" *ngIf="exerciseGroup.type.type !== 'timer'">
        {{
        'exercises.n-exercises' | translate:{ n: exercisesLength }
        }}&nbsp;&nbsp;|&nbsp;&nbsp;{{
        (exercisesLength
                ? 'exercises.group-detail-pane.add-or-adjust-exercises'
                : 'exercises.group-detail-pane.add-exercises'
        ) | translate:{ groupType: exerciseGroup.type?.name?.toLowerCase() }
        }}
    </p>
</div>
