import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Filter } from '@app-components/filter/interfaces/filter';
import { FilterComponent } from "src/app/components/filter/filter.component";

/*
    Possible filter types: Relationship, NumberRange, DateRange

    If Relationship use multiple mat-select
    If NumberRange use p-slider (PrimeNG)
    If DateRange use ??? ngx-mat-daterange-picker, ngx-daterangepicker-material
*/

export enum FilterType {
    RELATIONSHIP = 0,
    DATERANGE = 1,
    NUMBERRANGE = 2
}

export interface FilterDialogData {
    filters: Filter[];
}

@Component({
    selector: "app-filter-dialog",
    templateUrl: "./filter-dialog.component.html",
    styleUrls: ["./filter-dialog.component.scss"],
})
export class FilterDialogComponent implements OnInit {
    filters: Filter[];

    @ViewChild("filter", /* TODO: add static flag */ {})
    filterComponent: FilterComponent;

    constructor(
        @Inject(MAT_DIALOG_DATA) data: FilterDialogData,
        private dialogRef: MatDialogRef<FilterDialogComponent>,
    ) {
        this.filters = data.filters;
    }

    ngOnInit() {
    }

    submit() {
        this.filterComponent.submit();
    }

    emitResult(result) {
        this.dialogRef.close(result);
    }
}
