/**
 * List of available api's and the type of environment they provide
 * Used by EnvironmentService to select the correct api based on hostname
 * If the domain is not found we will select production
 */

export const apis = [
    {
        type: "test",
        domains: ["localhost", "test.portal.funxtion.com"],
        hostname: "test.api.funxtion.com"
    },
    {
        type: "acceptance",
        domains: ["acceptance.portal.funxtion.com"],
        hostname: "acc.api.funxtion.com"
    },
    {
        type: "acceptance-v3",
        domains: ["puregym-acc.funxtion.com"],
        hostname: "apiv3.funxtion.com"
    },
    {
        type: "production",
        domains: ["portal.funxtion.com"],
        hostname: "euw.funxtion.com"
    },
    {
        type: "production-cac",
        domains: ["goodlife.funxtion.com", "d1m5xlufkpjdea.cloudfront.net", "nonstopgym.funxtion.com", "puregym.funxtion.com"],
        hostname: "cac.funxtion.com"
    }
]
