import { AbstractTargetStrategy } from "../AbstractTargetStrategy";
import { InputValues, ITargetControlsConfig } from "../TargetStrategy.types";
import { isNumber } from "@app-helpers/narrowing.helpers";


export class NumberTargetStrategy extends AbstractTargetStrategy<number> {

    protected readonly _controlsConfig: ITargetControlsConfig = {
        columns: { absolute: 2, range: 2 },
        inputs: {
            value: { type: 'number' },
        },
    };

    protected toFormValues(parsed: number): InputValues {
        return { value: String(parsed) };
    }

    protected fromModelAttribute(serialized: string): number | null {

        const value = parseInt(serialized, 10);

        return isNumber(value) && !isNaN(value) && value >= 1
            ? value
            : null;
    }

    protected toModelAttribute(parsed: number): string {
        return String(parsed);
    }

    protected fromFormValues(values: InputValues): number | null {
        return this.fromModelAttribute(values.value);
    }

    protected defaultParsedValue(): number {
        return 0;
    }
}
