<fieldset class="target" [ngSwitch]="strategy.getCurrentType()">

    <legend>{{ target.measurement.name }}</legend>

    <div class="absolute-target-container" *ngSwitchCase="ABS_TARGET_TYPE">
        <div class="input-group input-group--abs">

            <div class="input-wrapper" *ngFor="let item of absValueControls">

                <div class="suffix"><span class="spacer">{{ item.formControl.value }}</span>&nbsp;{{ item.unit }}</div>

                <input [type]="item.inputType"
                       [formControl]="item.formControl"
                       [min]="target.measurement.min"
                       [max]="target.measurement.max"
                       [step]="target.measurement.step"
                >

                <div class="post-delimiter" *ngIf="item.postDelimiter">{{ item.postDelimiter }}</div>
            </div>

        </div>
    </div>

    <div class="range-target-container" *ngSwitchCase="RNG_TARGET_TYPE">

        <div class="input-group input-group--min">

            <div class="range-value-indicator">Low</div>

            <div class="input-wrapper" *ngFor="let item of minValueControls">

                <div class="suffix"><span class="spacer">{{ item.formControl.value }}</span>&nbsp;{{ item.unit }}</div>

                <input [type]="item.inputType"
                       [formControl]="item.formControl"
                       [min]="target.measurement.min"
                       [max]="target.measurement.max"
                       [step]="target.measurement.step"
                >

                <div class="post-delimiter" *ngIf="item.postDelimiter">{{ item.postDelimiter }}</div>
            </div>
        </div>

        <div class="range-spacer" *ngIf="strategy.getColumnSize() > 4"></div>

        <div class="input-group input-group--max">

            <div class="range-value-indicator">High</div>

            <div class="input-wrapper" *ngFor="let item of maxValueControls">

                <div class="suffix"><span class="spacer">{{ item.formControl.value }}</span>&nbsp;{{ item.unit }}</div>

                <input [type]="item.inputType"
                       [formControl]="item.formControl"
                       [min]="target.measurement.min"
                       [max]="target.measurement.max"
                       [step]="target.measurement.step"
                >

                <div class="post-delimiter" *ngIf="item.postDelimiter">{{ item.postDelimiter }}</div>
            </div>
        </div>

    </div>

</fieldset>
