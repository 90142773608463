import { Pipe, PipeTransform } from '@angular/core';
import { timeInterval, TimeInterval } from "@app-helpers/time-interval.helpers";

@Pipe({
    name: 'secondsToTime',
})
export class SecondsToTimePipe implements PipeTransform {

    transform(seconds: string | number | null, useHours: boolean = false): TimeInterval | null {

        if (!String(seconds).match(/^\d+$/)) {
            return null;
        }

        return this.secondsToObject(Number(seconds), useHours);
    }

    private secondsToObject(timeInSeconds: number, useHours: boolean): TimeInterval {
        return timeInterval(timeInSeconds, useHours);
    }
}
