import { Injectable } from '@angular/core';
import { FunxtionApiClientService, TrainingSchema } from '@funxtion/ng-funxtion-api-client';
import { schemaIncludes } from '@app-config/api-includes.config';
import { first } from 'rxjs/operators';
import { DMError, DMReport, DMRequest } from '@funxtion/ng-funxtion-api-client/lib/services/data/base.service';
import { TranslateService } from '@ngx-translate/core';
import { VmDuplicationService } from '@app-services/vm-duplication-service/vm-duplication.service';
import { SchemaConverterService } from '@app-services/converter-services/schema-converter/schema-converter.service';
import { from, Observable } from 'rxjs';
import { ITrainingSchema } from '@app-types/vm/vm.schema.types';
import { AbstractDuplicationService } from '../../../shared/services/duplication-service/abstract-duplication.service';
import { ModalService } from '../../../shared/services/modal-service/modal.service';

@Injectable({
  providedIn: 'root'
})
export class TrainingSchemaDuplicationService extends AbstractDuplicationService<TrainingSchema> {

    constructor(funxtion: FunxtionApiClientService,
                modalService: ModalService,
                translate: TranslateService,
                private duplicationService: VmDuplicationService,
                private schemaConverter: SchemaConverterService
    ) {
        super(funxtion, modalService, translate);
        this.setDialogTextByTranslateKey('training.schemas.duplicate.title', 'training.schemas.duplicate.message');
    }

    getNewModel(model: TrainingSchema): ITrainingSchema {
        return this.duplicationService.duplicateTraining(
            this.schemaConverter.toViewModel(model)
        );
    }

    getFullModel(trainingSchema: TrainingSchema): Observable<TrainingSchema> {
        return this.funxtion.dataServices.schemaService.getById(trainingSchema.id, schemaIncludes).pipe(first());
    }

    convertModel(trainingSchema: ITrainingSchema): Observable<TrainingSchema> {
       return from(this.schemaConverter.fromViewModel(trainingSchema));
    }

    saveModel(dmRequest: DMRequest<TrainingSchema>): Observable<DMReport<TrainingSchema> | DMError> {
        return this.funxtion.dataServices.schemaService.save(dmRequest)
    }
}
