<ng-template #loading>
    <mat-spinner class="mps-inline mps-sm"></mat-spinner>
</ng-template>

<mat-form-field *ngIf="filtered; else loading"
                appearance="outline"
                class="w-100"
>
    <mat-select [formControl]="control"
                [placeholder]="placeholder"
                [multiple]="multiple"
                [required]="required"
                [(ngModel)]="modelData"
                [compareWith]="compareModels"
    >
        <mat-option>
            <ngx-mat-select-search [formControl]="filterCtrl"
                                   placeholderLabel="Search..."
                                   noEntriesFoundLabel=""
                                   clearSearchInput
            >
                <span ngxMatSelectSearchClear>{{ "common.clear" | translate }}</span>
            </ngx-mat-select-search>
        </mat-option>

        <mat-option *ngIf="useNone">{{ "common.none" | translate }}</mat-option>
        <mat-option *ngFor="let model of filtered" [value]="model">{{
            model.name
            }}</mat-option>
    </mat-select>
</mat-form-field>
