<mat-accordion class="phase-accordion" *ngIf="phase">
    <mat-expansion-panel [expanded]="(accordionObservable | async) === phase.id" (expandedChange)="expandedChange($event)">
        <mat-expansion-panel-header>
            <h2 class="phase-title">
                {{ phase.phase.name }}
            </h2>
        </mat-expansion-panel-header>

        <div class="view-phase-groups">
            <ng-container *ngFor="let group of phase.groups; let index = index">
                <ng-container [ngSwitch]="group.group.isSingle">
                    <app-view-training-group-single *ngSwitchCase="true"
                                                    [group]="group"
                                                    [exercise]="group.group.exercises[0]"
                                                    [number]="index + 1 + numberOffset"
                    ></app-view-training-group-single>
                    <app-view-training-group-multi *ngSwitchCase="false"
                                                   [group]="group"
                                                   [number]="index + 1 + numberOffset"
                    ></app-view-training-group-multi>
                </ng-container>
                <mat-divider [inset]="true"></mat-divider>
            </ng-container>
        </div>
    </mat-expansion-panel>
</mat-accordion>
