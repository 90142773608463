import { IExerciseGroup, IExerciseGroupType } from "@app-types/vm/vm.common.types";
import { FitnessExeriseGroupType } from "@funxtion/ng-funxtion-api-client";
import { TranslateService } from "@ngx-translate/core";
import { Injectable } from "@angular/core";

@Injectable({ providedIn: 'root' })
export class ExerciseGroupLabelsFactory {

    public constructor(private translate: TranslateService) {
    }

    public make(gt: IExerciseGroupType): IExerciseGroup['labels'] {
        return {
            durationInSeconds: this.durationLabel(gt),
            restAfterSetInSeconds: this.setRestLabel(gt),
            restAfterExerciseInSeconds: this.translate.instant('training.schemas.restBetweenExercises'),
            numberOfRounds: this.roundsLabel(gt),
            targetRepetitions: this.translate.instant('training.schemas.targetRepetitions'),

        };
    }

    private durationLabel(groupType: IExerciseGroupType): string {

        switch (groupType.type) {
            case FitnessExeriseGroupType.CIRCUIT:
                return this.translate.instant('training.schemas.durationPerExercise');

            case FitnessExeriseGroupType.ROUNDS_FOR_TIME:
                return this.translate.instant('training.schemas.targetTime');

            case FitnessExeriseGroupType.AS_MANY_REPETITIONS_AS_POSSIBLE:
                return this.translate.instant('common.duration');

            default:
                return this.translate.instant('common.duration');
        }
    }

    private setRestLabel(groupType: IExerciseGroupType): string {
        return groupType.type === FitnessExeriseGroupType.CIRCUIT
            ? this.translate.instant('training.schemas.restBetweenRounds')
            : this.translate.instant('training.schemas.restBetweenSets');
    }

    private roundsLabel(groupType: IExerciseGroupType): string {
        return groupType.type === FitnessExeriseGroupType.SUPERSET
            ? this.translate.instant('training.schemas.sets')
            : this.translate.instant('training.schemas.rounds');
    }
}
