import { NumberTargetStrategy } from "./Strategies/NumberTargetStrategy";
import { StrategyBinding, TargetStrategyMap } from "./TargetStrategy.types";
import { TutTargetStrategy } from "./Strategies/TutTargetStrategy";
import { StringTargetStrategy } from "./Strategies/StringTargetStrategy";
import { MinuteIntervalTargetStrategy } from "./Strategies/MinuteIntervalTargetStrategy";

// tslint:disable: no-any
// tslint:disable: object-literal-key-quotes

const genericNumberStrategy: StrategyBinding = {
    clazz: NumberTargetStrategy,
    defaults: {
        minValue: '0',
        maxValue: '0',
        value: '0',
    },
};

export const fallbackStrategy: StrategyBinding = {
    clazz: StringTargetStrategy,
    defaults: {
        minValue: '',
        maxValue: '',
        value: '',
    },
};

// ------------------------------------------------------------------------------
//      Target Strategy map
// ------------------------------------------------------------------------------
//
//      This map specifies all links between measurements and a strategy class
//      that knows how to interpret, display and validate values of the specific
//      measurement. In addition, the strategies provide for patching the last
//      known revision of the target that refers to the measurement, and will
//      broadcast new revisions to the host, who is expected to further process
//      the changes.
//
export const strategies: { [ley: string]: StrategyBinding } & TargetStrategyMap = {

    '1rm': genericNumberStrategy,
    'angle': genericNumberStrategy,
    'cycles': genericNumberStrategy,
    'distance': genericNumberStrategy,

    'duration': {
        clazz: MinuteIntervalTargetStrategy,
        defaults: {
            minValue: '0',
            maxValue: '0',
            value: '0',
        },
    },

    'energy': genericNumberStrategy,
    'height': genericNumberStrategy,
    'incline': genericNumberStrategy,
    'level': genericNumberStrategy,

    // Not sure if needed?
    'number-of-rounds': {
        clazz: NumberTargetStrategy,
        defaults: {
            minValue: '1',
            maxValue: '1',
            value: '1',
        },
    },

    'power': genericNumberStrategy,
    'repetitions': genericNumberStrategy,
    'speed': genericNumberStrategy,

    'tut': {
        clazz: TutTargetStrategy,
        defaults: {
            // These defaults are invalid on purpose
            minValue: null,
            maxValue: null,
            value: null,
        },
    },

    'weight': genericNumberStrategy,
};
