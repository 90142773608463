<div>
    <form [formGroup]="form" *ngIf="form; else loading">
        <div *ngFor="let filter of filters" class="filters-container">
            <ng-container [ngSwitch]="filter.type">
                <ng-container *ngSwitchCase="filterTypes.RELATIONSHIP">
                    <mat-form-field appearance="outline">
                        <mat-label>{{ filter.label }}</mat-label>
                        <mat-select matInput multiple [compareWith]="compareObjects" [formControlName]="filter.field"
                                    [attr.data-cy]="filter.field">
                            <mat-option disabled="disabled" class="filter-option">
                                <button mat-flat-button color="primary" data-cy="selectAll"
                                        (click)="selectAll(filter.field)">
                                    {{"common.selectAll" | translate}}
                                </button>
                                <button mat-flat-button color="primary" data-cy="deselectAll"
                                        (click)="deselectAll(filter.field)">
                                    {{"common.deselectAll" | translate}}
                                </button>
                            </mat-option>
                            <mat-option *ngFor="let option of data[filter.field]" [value]="option" [attr.data-cy]="
                                    getRelationshipFilterConfig(
                                        filter
                                    ).renderLabel(option)
                                ">
                                {{
                                getRelationshipFilterConfig(
                                    filter
                                ).renderLabel(option)
                                }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </ng-container>
                <ng-container *ngSwitchCase="filterTypes.NUMBERRANGE">
                    <div class="fake-mat-ff">
                        <label matLabel>{{ filter.label }}
                            {{
                            form.value[filter.field][0] +
                            " - " +
                            form.value[filter.field][1]
                            }}<br/></label>
                        <div *ngIf="getRangeFilterConfig(filter) as config">
                            <p-slider *ngIf="!cypress" [attr.data-cy]="filter.field" [animate]="true"
                                      [formControlName]="filter.field" [min]="config.min" [max]="config.max" [range]="true">
                            </p-slider>
                            <div *ngIf="cypress" [attr.data-cy]="filter.field">
                                <input data-cy="begin" matInput [ngModel]="form.value[filter.field][0]"
                                       [ngModelOptions]="{ standalone: true }"
                                       (change)="changeBegin(filter.field, $event)"/>
                                <input data-cy="end" matInput [ngModel]="form.value[filter.field][1]"
                                       [ngModelOptions]="{ standalone: true }"
                                       (change)="changeEnd(filter.field, $event)"/>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngSwitchCase="filterTypes.DATERANGE">
                    <mat-form-field appearance="outline">
                        <mat-label>{{ filter.label }}</mat-label>
                        <input matInput [satDatepicker]="picker" [formControlName]="filter.field"
                               [attr.data-cy]="filter.field"/>
                        <sat-datepicker #picker [rangeMode]="true"></sat-datepicker>
                        <sat-datepicker-toggle matSuffix [for]="picker"></sat-datepicker-toggle>
                    </mat-form-field>
                </ng-container>
            </ng-container>
        </div>
    </form>
</div>

<ng-template #loading>
    <app-loading-spinner message="{{ 'loading.filters' | translate }}"></app-loading-spinner>
</ng-template>
