import { ICON_COLOR, ICON_SIZE, IconConfig } from 'src/app/modules/shared/inputConfig';

export const TITLE_BASE = "Dashboard - ";
export const defaultActionIconConfig: IconConfig = {
    iconSize: ICON_SIZE.DEFAULT,
    iconColor: ICON_COLOR.SUPPORT,
};
export const ratingArray = Array(5)
    .fill(0)
    .map((x, i) => i + 1);

export const PREFERRED_TIMES = {
    MORNING: {
        START: "08:00",
        END: "12:00"
    },
    AFTERNOON: {
        START: "12:00",
        END: "18:00"
    },
    EVENING: {
        START: "18:00",
        END: "22:00"
    }
};

export const DefaultDialogDimensionsLarge = {
    width: '80vw',
    height: '90vh'
};
