import { AbstractModelMapper } from '@app-services/conversion-services/AbstractModelMapper';
import { AttributeMap, RelationMap } from '@app-types/vm-conversion/vm-conversion.types';
import { IExerciseSetTarget } from '@app-types/vm/vm.common.types';
import { EquipmentMeasurement, Target } from '@funxtion/ng-funxtion-api-client';


export class ExerciseSetTargetMapper extends AbstractModelMapper<IExerciseSetTarget, Target> {

    public readonly attributes: AttributeMap<IExerciseSetTarget, Target> = {
        type: 'type',
        value: 'value',
        minValue: 'min',
        maxValue: 'max',
    };

    public readonly relationships: RelationMap<IExerciseSetTarget, Target>[] = [
        {
            mutable: false,
            type: 'one',
            vmKey: 'measurement',
            jamKey: 'measurement',
            jamClass: EquipmentMeasurement,
        },
    ];
}
