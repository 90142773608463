<h1 class="h2" mat-dialog-title>{{ data.title }}</h1>
<mat-dialog-content [formGroup]="form">
    <p>{{ data.message }}</p>
    <p>
        <mat-form-field>
            <input
                matInput
                formControlName="text"
                [type]="data.inputType || 'text'"
            />
        </mat-form-field>
    </p>
</mat-dialog-content>
<mat-dialog-actions>
    <button
        mat-flat-button
        color="primary"
        (click)="submit()"
    >
        {{ "common.done" | translate }}
    </button>
    &nbsp;
    <button mat-flat-button
            (click)="cancel()">
        {{ "common.cancel" | translate }}
    </button>
</mat-dialog-actions>
