import { Workout, Level, Location, Goal, BodySection, WorkoutCategory, Group, Tag } from '@funxtion/ng-funxtion-api-client';
import { AbstractModelMapper } from '@app-services/conversion-services/AbstractModelMapper';
import { AttributeMap, RelationMap } from '@app-types/vm-conversion/vm-conversion.types';
import { IWorkout } from '@app-types/vm/vm.workout.types';
import { resolveOwnerType } from '@app-helpers/vm-conversion.helpers';


export class WorkoutMapper extends AbstractModelMapper<IWorkout, Workout> {

    public readonly attributes: AttributeMap<IWorkout, Workout> = {
        name: 'name',
        description: 'description',
        durationInSeconds: 'durationInSeconds',
        energyExpenditureInKiloCalories: 'energyExpenditureInKiloCalories',
    };

    public readonly relationships: RelationMap<IWorkout, Workout>[] = [
        {
            mutable: false,
            type: 'one',
            vmKey: 'level',
            jamKey: 'level',
            jamClass: Level,
        },
        {
            mutable: false,
            type: 'many',
            vmKey: 'locations',
            jamKey: 'locations',
            jamClass: Location,
        },
        {
            mutable: false,
            type: 'many',
            vmKey: 'tags',
            jamKey: 'tags',
            jamClass: Tag,
        },
        {
            mutable: false,
            type: 'one',
            vmKey: 'goal',
            jamKey: 'goal',
            jamClass: Goal,
        },
        {
            mutable: false,
            type: 'one',
            vmKey: 'bodySection',
            jamKey: 'bodySection',
            jamClass: BodySection,
        },
        {
            mutable: false,
            type: 'many',
            vmKey: 'categories',
            jamKey: 'categories',
            jamClass: WorkoutCategory,
        },
        {
            mutable: false,
            type: 'one',
            vmKey: 'owner',
            jamKey: 'owner',
            jamClassResolver: (vm: IWorkout) => resolveOwnerType(vm.owner),
        },
        {
            mutable: true,
            type: 'one',
            vmKey: 'exerciseGroup',
            jamKey: 'group',
            jamClass: Group,
            inverse: false,
        },
    ];
}
