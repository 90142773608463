import { animate, style, transition, trigger } from "@angular/animations";
import { Component, Inject, Input, OnChanges, SimpleChanges } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { vmTrackFunction } from "@app-helpers/vm.helpers";
import { DetailPaneSelectionService } from '@app-services/detail-pane-selection/detail-pane-selection.service';
import { ProspectService } from "@app-services/prospect-service/prospect.service";
import { SchemaLensService } from "@app-services/vm-lens-services/schema-lens-service/schema-lens.service";
import { ConstraintInputPair, TargetTypeConstraint } from "@app-types/training/editor/training-exercise-form.types";
import { IViewModel } from "@app-types/vm.types";
import {
    IAbsoluteExerciseSetTarget,
    IEquipmentMeasurement,
    IExerciseSet,
    IExerciseSetTarget,
    IRangeExerciseSetTarget,
    ITrainingExercise,
    MeasurementSlug
} from "@app-types/vm/vm.common.types";
import {
    ChallengeExerciseGroupType,
    FitnessExeriseGroupType,
    GroupTargetType,
    GroupTargetType as GTType
} from "@funxtion/ng-funxtion-api-client";
import {
    append,
    compose,
    differenceWith,
    equals,
    head,
    inc,
    insert,
    Lens,
    lensIndex,
    merge,
    mergeDeepLeft,
    not,
    omit,
    over,
    pipe,
    remove,
    toPairs
} from 'ramda';
import { Observable } from "rxjs";
import { debounceTime, map as rxMap, pairwise, startWith } from "rxjs/operators";
import { SchemaFactory } from "../../../../factories/training/schema/schema.factory";
import { writePositions } from "../../../../views/training/schemas/schema-edit/schema-edit.pure";
import {
    currentSetLength,
    distinctMeasurements,
    filterMeasurementsBySlug,
    targetTypeConstraints
} from "./training-exercise-form.pure";
import { DetailPaneTrainingExercise } from "@app-types/training/editor/detail-pane-selection.types";
import { MatButtonToggleChange } from "@angular/material/button-toggle";
import { VM_FACTORY_SERVICE, VM_LENS_SERVICE, VM_SERVICE } from "@funxtion/portal/shared";
import { VMService } from "@app-services/vm/vm.service";

@Component({
    selector: 'app-training-exercise-form',
    templateUrl: './training-exercise-form.component.html',
    styleUrls: ['./training-exercise-form.component.scss'],
    animations: [
        trigger('fade', [
            transition('* => *', [
                style({ opacity: 0 }),
                animate('200ms ease-in'),
            ]),
        ]),
    ],
})
export class TrainingExerciseFormComponent implements OnChanges {

    // ------------------------------------------------------------------------------
    //      I/O
    // ------------------------------------------------------------------------------

    @Input()
    public trainingExercise: ITrainingExercise;

    @Input()
    public multipleSetsAllowed;

    @Input()
    public parentFormGroup: FormGroup;

    public scalarSet = vmTrackFunction<IExerciseSet>();

    // ------------------------------------------------------------------------------
    //      Template constants
    // ------------------------------------------------------------------------------

    readonly ABS_TARGET_TYPE: GTType = GTType.ABSOLUTE;
    readonly RNG_TARGET_TYPE: GTType = GTType.RANGE;

    // ------------------------------------------------------------------------------
    //      Internal & State
    // ------------------------------------------------------------------------------

    public imageDisplay: 'still' | 'motion' = 'still';
    public imageUrl: string;
    public trainingExerciseForm: FormGroup;
    public hasFreeSetSlots: boolean;
    public targetTypeConstraints: TargetTypeConstraint[];
    public useRestAfterSet = true;
    public useSetLayout = true;
    public isSingleExercise = false;
    public selectedTarget: 'duration' | 'repetitions' = 'repetitions';
    public hasDurationAndRepetitions: boolean;

    // Q&D fix to prevent infinite callbacks
    public exerciseSetsLenses: Lens[];
    public trainingExerciseSets: IExerciseSet[] = [];

    private currentSetLength: number;
    distinctMeasurements: IEquipmentMeasurement[];
    targetTypeConstraintsForm: FormGroup;
    private hasAnySets: boolean;

    // ------------------------------------------------------------------------------
    //      Lifecycle
    // ------------------------------------------------------------------------------

    constructor(
        @Inject(VM_SERVICE) private patchService: VMService,
        @Inject(VM_FACTORY_SERVICE) private factoryService: SchemaFactory,
        @Inject(VM_LENS_SERVICE) private lensService: SchemaLensService,
        private prospectService: ProspectService,
        private formBuilder: FormBuilder,
        private detailPaneSelectionService: DetailPaneSelectionService
    ) {
    }

    ngOnChanges(changes: SimpleChanges): void {

        // Determine which lensService we should used based on whether we are working with a workout or a schema
        this.imageUrl = this.computeImageUrl();
        this.currentSetLength = currentSetLength(this.trainingExercise);
        this.hasAnySets = this.currentSetLength > 0;
        this.hasFreeSetSlots = this.multipleSetsAllowed || not(this.hasAnySets);
        this.distinctMeasurements = this.getMeasurements();
        this.targetTypeConstraints = this.getTargetTypeConstraints();
        this.trainingExerciseForm = this.bootTrainingExerciseForm();
        if (this.parentFormGroup) {
            this.parentFormGroup.addControl('trainingExercise', this.trainingExerciseForm);
        }
        this.targetTypeConstraintsForm = this.bootTargetConstraintsForm();
        this.setLayoutForSet();

        this.bootConstraintChangesObservable()
            .subscribe(async (change) => {
                await this.applyConstraint(change);
            });

        this.trainingExerciseForm.valueChanges.pipe(debounceTime(1000))
            .subscribe((attributes) => void this.patchExerciseAttributes(attributes));

        if (!this.hasAnySets) {
            void this.addSet();
        }

        // Keep an array of the lenses so they won't be re-created one every digest cycle in the view
        this.exerciseSetsLenses = []
        for (let i = 0; this.trainingExercise.sets.length > i; i++) {
            this.exerciseSetsLenses.push(this.exerciseSetLens(i))
        }

        // if it's a single exercise, the user should choose between using duration or repetitions
        this.isSingleExercise = this.getType() === FitnessExeriseGroupType.EXERCISE;
        if (changes?.trainingExercise?.currentValue && this.isSingleExercise) {
            const hasDuration = !!this.distinctMeasurements.find(measurement => measurement.slug === 'duration');
            const hasRepetitions = !!this.distinctMeasurements.find(measurement => measurement.slug === 'repetitions');
            if (hasDuration && hasRepetitions) {
                this.hasDurationAndRepetitions = true;
                const defaultTarget = hasRepetitions ? 'repetitions' : 'duration';
                this.selectedTarget = changes.trainingExercise.currentValue?.targetType || defaultTarget;
            } else {
                this.hasDurationAndRepetitions = false;
            }
        }
    }

    // ------------------------------------------------------------------------------
    //      Model patching
    // ------------------------------------------------------------------------------

    /**
     * Generic patching template for editing the exercise-sets collection of this component's training-exercise.
     */
    private async patchSetsCollection(setsTransformer: (sets: IExerciseSet[]) => IExerciseSet[]): Promise<[IViewModel, Lens]> {
        const setsLens = this.lensService.exerciseSetsCollectionLens(this.trainingExercise);

        const newSchema = await this.patchService.transform(
            over(setsLens, setsTransformer),
        );

        this.detailPaneSelectionService.updateSelectionContentFromSchema(newSchema);

        return [newSchema, setsLens];
    }

    /**
     * Adds a new set to the end of the current collection
     */
    async addSet(): Promise<void> {
        const newSet = this.getFreshExerciseSet();
        await this.patchSetsCollection(
            append(newSet),
        );
    }



    /**
     * Deletes the set at given index.
     */
    async deleteSet(index: number): Promise<void> {

        type T = IExerciseSet[];

        await this.patchSetsCollection(
            pipe<T, T, T>(
                remove(index, 1), writePositions(1),
            ),
        );
    }

    /**
     * Copies the set at given index and inserts it at the following index.
     */
    async copySet(index: number, set: IExerciseSet): Promise<void> {

        await this.patchSetsCollection(
            pipe(
                insert(inc(index), this.stripSetAndTargetIds(set)), writePositions(1),
            ),
        );
    }

    private async patchExerciseAttributes(attributes: Partial<ITrainingExercise>): Promise<void> {
        const lens: Lens = this.lensService.trainingExerciseLens(this.trainingExercise);

        await this.patchService.transform(
            over(lens, mergeDeepLeft(attributes)),
        );
    }

    // ------------------------------------------------------------------------------
    //      Contextual factory methods
    // ------------------------------------------------------------------------------

    /**
     * Returns a copy of the given IExerciseSet, including data for its targets.
     * The set's id and those of the contained targets are set to fresh prospect
     * id values.
     */
    private stripSetAndTargetIds(set: IExerciseSet): IExerciseSet {

        const overrides: Partial<IExerciseSet> = {
            id: this.prospectService.newId,
            targets: set.targets.map((target) => merge(target, { id: this.prospectService.newId })),
        };

        return merge(set, overrides);
    }

    /**
     * Returns a new `IExerciseSet` pre-configured for the current trainingExercise.
     */
    private getFreshExerciseSet(): IExerciseSet {

        const targets = this.distinctMeasurements.map((measurement) => (
            this.factoryService.newExerciseSetTarget(measurement)
        ));

        return this.factoryService.newExerciseSet(targets, inc(this.currentSetLength));
    }

    // ------------------------------------------------------------------------------
    //      Management of target-type constraints
    // ------------------------------------------------------------------------------

    /**
     * Applies the type of given input-pair to all targets that belong to the measurement with the contained slug.
     *
     * This goes to the schema patch-service to update the targets, and will therefore (asynchronously) trigger
     * re-evaluation of the active constraints.
     */
    private async applyConstraint([measurementSlug, targetType]: ConstraintInputPair): Promise<void> {

        const exerciseSetsLens = this.lensService.exerciseSetsCollectionLens(this.trainingExercise);

        const transformer = over(exerciseSetsLens, (sets: IExerciseSet[]): IExerciseSet[] => sets.map((set) => merge(set, {

            targets: set.targets.map((target: IExerciseSetTarget) => target.measurement.slug === measurementSlug
                ? this.switchTargetType(target, targetType)
                : target,
            ),
        })));

        const updatedSchema = await this.patchService.transform(transformer);
        await this.detailPaneSelectionService.updateSelectionContentFromSchema(updatedSchema);
    }

    /**
     *
     */
    private switchTargetType(target: IExerciseSetTarget, newType: GroupTargetType): IExerciseSetTarget {

        const overloaded = target as IRangeExerciseSetTarget & IAbsoluteExerciseSetTarget;

        switch (newType) {

            case GroupTargetType.ABSOLUTE:
                return omit(['minValue', 'maxValue'], merge(overloaded, {
                    type: newType,
                    value: overloaded.minValue || overloaded.maxValue,
                }));

            case GroupTargetType.RANGE:
                return omit(['value'], merge(overloaded, {
                    type: newType,
                    minValue: overloaded.value || overloaded.minValue,
                    maxValue: overloaded.value || overloaded.maxValue,
                }));
        }
    }

    private getTargetTypeConstraints(): TargetTypeConstraint[] {
        return targetTypeConstraints(this.trainingExercise, this.getMeasurementSlugsToExclude());
    }

    private getMeasurementSlugsToExclude(): MeasurementSlug[] {
        let measurementSlugsToExclude: MeasurementSlug[] = [];

        const type = this.getType();
        switch (type) {
            case FitnessExeriseGroupType.CIRCUIT:
                // Duration or repetitions on exercise level are not allowed within a circuit, these are set on group level.
                // so exclude these slugs from the target types
                measurementSlugsToExclude = ['duration', 'repetitions', 'tut'];
                this.useRestAfterSet = false;
                break;
            case ChallengeExerciseGroupType.AS_MANY_REPETITIONS_AS_POSSIBLE:
                measurementSlugsToExclude = ['duration', 'tut']
                this.useRestAfterSet = false;
                break;
            case ChallengeExerciseGroupType.ROUNDS_FOR_TIME:
                measurementSlugsToExclude = ['tut'];
                this.useRestAfterSet = false;
                break;
            case FitnessExeriseGroupType.SUPERSET:
                this.useRestAfterSet = false;
                break;
        }

        // selectedTarget can either be duration or repetitions. So if one is selected, hide the other one
        // if (this.isSingleExercise && this.selectedTarget) {
        //     const selectedTargetSlugToExclude = this.selectedTarget === 'duration' ? 'repetitions' : 'duration';
        //     measurementSlugsToExclude.push(selectedTargetSlugToExclude);
        // }

        return measurementSlugsToExclude;
    }

    private getMeasurements(): IEquipmentMeasurement[] {
        return filterMeasurementsBySlug(distinctMeasurements(this.trainingExercise), this.getMeasurementSlugsToExclude());
    }

    async handleSelectedTargetChange(newTarget: MatButtonToggleChange) {
        this.selectedTarget = newTarget.value;
        this.distinctMeasurements = this.getMeasurements();
        this.targetTypeConstraints = this.getTargetTypeConstraints();

        await this.patchExerciseAttributes({
            targetType: this.selectedTarget
        })
    }

    // ------------------------------------------------------------------------------
    //      Other actions
    // ------------------------------------------------------------------------------

    public toggleImageDisplay() {
        this.imageDisplay = this.imageDisplay === 'motion' ? 'still' : 'motion';
        this.imageUrl = this.computeImageUrl();
    }

    exerciseSetLens(index: number): Lens {
        return compose(
            this.lensService.exerciseSetsCollectionLens(this.trainingExercise),
            lensIndex(index),
        ) as Lens;
    }

    // ------------------------------------------------------------------------------
    //      Computed data & boot methods (ng-on-change time)
    // ------------------------------------------------------------------------------

    /**
     * Computes & returns the URL of the image to use for the exercise UI.
     */
    private computeImageUrl(): string {
        switch (this.imageDisplay) {
            case "motion":
                return this.trainingExercise.exercise.gifSmall;

            case "still":
            default:
                return this.trainingExercise.exercise.imageSmall;
        }
    }

    /**
     * Build the training-exercise form-group based on the current input.
     */
    private bootTrainingExerciseForm(): FormGroup {
        return this.formBuilder.group({
            description: new FormControl(this.trainingExercise.description)
        });
    }

    /**
     * Build the target-constraints form-group based on the current input.
     */
    private bootTargetConstraintsForm(): FormGroup {
        const controls = this.targetTypeConstraints.reduce(
            (acc, { measurement, targetType }) => ({ ...acc, [measurement.slug]: new FormControl(targetType) }),
            {},
        );

        return new FormGroup(controls);
    }

    /**
     * Builds the observable that emits changes in the target-type constraints form.
     */
    private bootConstraintChangesObservable(): Observable<ConstraintInputPair> {

        const currentValues = this.targetTypeConstraintsForm.value;

        type Pair = ConstraintInputPair;

        return this.targetTypeConstraintsForm.valueChanges.pipe(
            // We have to start the observable with this initial value,
            // otherwise we won't get the first change -- because of
            // pairwise() below.
            startWith(currentValues),

            // Take the previous values along with the current.
            pairwise(),

            // Run differenceWith over both objects' pair representations to get
            // the actual changed values as a list of pairs.
            rxMap(([prevValues, currValues]) => differenceWith<Pair, Pair>(
                equals,
                toPairs(currValues),
                toPairs(prevValues),
            )),

            // Only one change is expected at once, hence the head() call.
            // (The valueChanges observable should emit on each single selection)
            rxMap<Pair[], Pair>(head),
        );
    }

    private setLayoutForSet() {
        // If only 1 set is allowed, use a different layout to prevent confusion
        const type = (this.detailPaneSelectionService.current as DetailPaneTrainingExercise)?.context?.groupType?.type;
        if (
            type === FitnessExeriseGroupType.CIRCUIT ||
            type === FitnessExeriseGroupType.ROUNDS_FOR_TIME ||
            type === FitnessExeriseGroupType.AS_MANY_REPETITIONS_AS_POSSIBLE
        ) {
            this.useSetLayout = false;
        }
    }

    private getType(): FitnessExeriseGroupType | ChallengeExerciseGroupType {
        return (this.detailPaneSelectionService.current as DetailPaneTrainingExercise)?.context?.groupType?.type;
    }
}
