<mat-dialog-content class="filter-content">
    <app-filter
        [filters]="filters"
        #filter
        (results)="emitResult($event)"
    ></app-filter>
</mat-dialog-content>
<mat-dialog-actions class="filter-buttons">
    <ng-container>
        <button
            mat-flat-button
            color="primary-rounded"
            [mat-dialog-close]="true"
            data-cy="apply"
            (click)="submit()"
        >
            {{ "common.apply" | translate }}
        </button>
    </ng-container>
</mat-dialog-actions>
