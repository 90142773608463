import { complement, isEmpty, merge, pathSatisfies, propSatisfies } from 'ramda';

import { ITrainingSchema } from "@app-types/vm/vm.schema.types";
import { Injectable } from "@angular/core";
import { VMService } from "@app-services/vm/vm.service";
import { IWorkout } from '@app-types/vm/vm.workout.types';

@Injectable({
    providedIn: 'root'
})
export class TrainingSchemaVMService extends VMService<ITrainingSchema> {

    protected normalizeRevision(vm: ITrainingSchema): ITrainingSchema {

        // Removes some empty collections, bottom-to-top, as a way of model normalisation

        const isNotEmpty = complement(isEmpty);

        const trainingPhases = vm.trainingPhases
            .map((tp) => merge(tp, {

                // We should allow empty groups, for the timer
                trainingGroups: tp.trainingGroups.filter(pathSatisfies(isNotEmpty, ['exerciseGroup'])),
            }))

            // Then remove empty training phases
            .filter(propSatisfies(isNotEmpty, 'trainingGroups'));

        return merge(vm, { trainingPhases });
    }
}

