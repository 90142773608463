import { ALIGNMENT, BUTTON_VARIANT } from 'src/app/modules/shared/inputConfig';
import { Component, OnInit } from '@angular/core';
import { Filter, RelationshipFilterConfig } from '@app-components/filter/interfaces/filter';
import { FunxtionApiClientService, Gender, Goal, TrainingSchema } from "@funxtion/ng-funxtion-api-client";
import { MatDialogRef } from "@angular/material/dialog";

import { BackendService } from "@app-services/backend-service/backend.service";
import { FilterType } from "@app-components/dialogs/filter-dialog/filter-dialog.component";
import { FormBuilder } from "@angular/forms";
import { HeaderType } from "@app-types/page-header.types";
import { IndexView } from "../../../views/index-view";
import { PageHeaderService } from "@app-services/page-header/page-header.service";
import { Router } from "@angular/router";
import { SearchService } from "@app-services/search-service/search.service";
import { TranslateService } from "@ngx-translate/core";
import { getSearchId } from '@funxtion/portal/shared';

@Component({
    selector: 'app-create-training-dialog',
    templateUrl: './create-training-dialog.component.html',
    styleUrls: ['./create-training-dialog.component.scss']
})
export class CreateTrainingDialogComponent extends IndexView<TrainingSchema> implements OnInit {
    BUTTON_VARIANT = BUTTON_VARIANT;
    ALIGNMENT = ALIGNMENT;
    selectedTraining: TrainingSchema;
    portalId: string = this.backendService.newPortalId.next().value;
    searchId = getSearchId();

    constructor(
        funxtion: FunxtionApiClientService,
        fb: FormBuilder,
        search: SearchService,
        private router: Router,
        public translate: TranslateService,
        private matDialog: MatDialogRef<CreateTrainingDialogComponent>,
        private pageHeader: PageHeaderService,
        private backendService: BackendService,
    ) {
        super(funxtion, fb, search, TrainingSchema, 'training', [
            "genders",
            "goal",
            "owner",
        ], {
            owner_type: {
                eq: 'Organization'
            }
        });
    }

    setupTitle() {
        this.pageHeader.setup({
            title: this.translate.instant('training.schemas.create.title'),
            type: HeaderType.Overview,
            filled: true,
        })
    }

    handleHeroButtons(eventChannel) {
        switch (eventChannel) {
            case "newTraining":
                this.matDialog.afterClosed().subscribe( async () => {
                    await this.router.navigate(['training', 'workouts', 'create'])
                });
                this.matDialog.close();
        }
    }

    handleTableButtons([eventChannel, training]) {
        switch (eventChannel) {
            case "view":
                this.selectedTraining = training;
                break;
        }
    }

    initFilters() {
        super.initFilters();
        const goalFilterConfig: RelationshipFilterConfig<Goal> = {
            type: Goal,
            renderLabel: goal => goal.name,
            renderValue: goal => goal.id,
        };
        const goalFilter: Filter = {
            label: "Goal",
            field: "goal",
            type: FilterType.RELATIONSHIP,
            config: goalFilterConfig,
        };
        const genderFilterConfig: RelationshipFilterConfig<Gender> = {
            type: Gender,
            renderLabel: gender => gender.name,
            renderValue: gender => gender.id,
        };
        const genderFilter: Filter = {
            label: "Gender",
            field: "gender",
            type: FilterType.RELATIONSHIP,
            config: genderFilterConfig,
        };
        this.filterConfig.push(goalFilter);
        this.filterConfig.push(genderFilter);
    }

    get schemas() {
        return this.models;
    }

    ngOnInit() {
        super.ngOnInit();
        this.setupTitle();
    }

    close() {
        this.matDialog.close();
    }

    templateSelected([selectedTraining]: TrainingSchema[]) {
        this.matDialog.close(selectedTraining);
    }

    handleViewButtons($event: string) {
        switch ($event) {
            case 'duplicate':
                return this.templateSelected([this.selectedTraining]);
        }
    }

    backToOverview() {
        this.setupTitle();
        this.selectedTraining = null;
    }
}
