<!-------------------------------------------------------------------------------
The main div is the portal for the header outlet. The entire contents will be
rendered in the designated content slot in the default template.
-------------------------------------------------------------------------------->
<div *ngIf="!usePortal; else portalContainer">
    <ng-container *ngTemplateOutlet="headerContent"></ng-container>
</div>

<ng-template #portalContainer>
    <div *cdkPortal>
        <ng-container *ngTemplateOutlet="headerContent"></ng-container>
    </div>
</ng-template>

<ng-template #headerContent>
    <ng-container *ngIf="pageHeader.config$ | async as config">
        <ng-container [ngSwitch]="config.type">

            <div class="title-button-container" *ngSwitchDefault>

                <!-------------------------------------------------------------------------------
                The title container yields two input variables 'title' and (optionally)
                'subtitle' that are given to the component.
                -------------------------------------------------------------------------------->
                <div class="title-container">
                    <div class="image-container" *ngIf="
                        config?.type === HeaderType.Detail &&
                        config?.image
                    ">
                        <img [src]="config?.image"/>
                    </div>
                    <h1 class="title">{{ config?.title || '&nbsp;' }}</h1>
                </div>

                <!-------------------------------------------------------------------------------
                The button-container only contains the content slot for button projection from
                the including template.
                -------------------------------------------------------------------------------->
                <div class="button-container">
                    <ng-container *ngTemplateOutlet="buttonSlot"></ng-container>
                </div>

            </div>

            <div *ngSwitchCase="HeaderType.Custom">
                <ng-content></ng-content>
            </div>

            <div class="overview-container" *ngSwitchCase="HeaderType.Overview">
                <div class="title-container">
                    <h1 class="title">{{ config?.title || '&nbsp;' }}</h1>
                </div>
                <div class="button-container">
                    <ng-container *ngTemplateOutlet="buttonSlot"></ng-container>
                </div>
                <div class="search-container">
                    <form [formGroup]="searchForm">
                        <mat-form-field apperance="none" floatLabel="never">
                            <mat-icon svgIcon="search" inline="true" matPrefix></mat-icon>
                            <input
                                matInput
                                [placeholder]="translate.instant(searchPlaceholderKey || 'search.query-placeholder')"
                                type="text"
                                formControlName="query"
                            />
                        </mat-form-field>
                    </form>
                </div>
                <div class="filter-container">
                    <button
                        *ngIf="showFilterButton"
                        data-cy="filterButton"
                        class="filter-button"
                        mat-stroked-button
                        (click)="openFilterDialog($event)"
                    >
                        <mat-icon svgIcon="filter"></mat-icon>&nbsp;{{"common.filters" | translate}}
                    </button>
                </div>
            </div>
        </ng-container>
    </ng-container>
</ng-template>

<ng-template #buttonSlot>
    <ng-content select="[button-slot]"></ng-content>
</ng-template>
