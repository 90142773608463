import { ROLE_ADMINISTRATOR, ROLE_COACH, ROLE_MANAGER } from "./models/roles";
import { RouterModule, Routes } from "@angular/router";

import { AuthGuard } from "./guards/auth/auth.guard";
import { AuthLayoutComponent } from "./components/layouts/auth-layout/auth-layout.component";
import { ConfirmEmailComponent } from './views/auth/confirm-email/confirm-email.component';
import { DefaultComponent } from "./components/layouts/default/default.component";
import { GuestGuard } from "./guards/guest/guest.guard";
import { LoginComponent } from "./views/login/login.component";
import { MemberGuard } from './guards/member/member.guard';
import { NgModule } from "@angular/core";
import { NotFoundComponent } from "./components/not-found/not-found.component";
import { ResetPasswordComponent } from "./views/auth/reset-password/reset-password.component";
import { RoleGuard } from "./guards/role/role.guard";
import { SetPasswordComponent } from "./views/auth/set-password/set-password.component";

export const HEADERS = {
    LOGO: "logo",
    MENU: "menu",
};

const routes: Routes = [
    {
        path: "",
        redirectTo: "/training/plans",
        pathMatch: "full",
    },
    {
        path: "login",
        component: AuthLayoutComponent,
        canActivate: [GuestGuard],
        children: [
            {
                path: "",
                component: LoginComponent,
            },
        ],
    },
    {
        path: "set-password/:token",
        component: AuthLayoutComponent,
        canActivate: [GuestGuard],
        children: [
            {
                path: "",
                component: SetPasswordComponent,
            },
        ],
    },
    {
        path: "reset-password/environment/:clientId/:token",
        component: AuthLayoutComponent,
        canActivate: [GuestGuard],
        children: [
            {
                path: "",
                component: ResetPasswordComponent,
            },
        ],
    },
    {
        path: "confirm-email",
        component: AuthLayoutComponent,
        canActivate: [MemberGuard],
        children: [
            {
                path: "",
                component: ConfirmEmailComponent,
            },
        ],
    },
    // {
    //     path: "chats",
    //     canActivate: [AuthGuard, RoleGuard],
    //     data: {
    //         roles: [ROLE_COACH],
    //     },
    //     loadChildren: () => import('./modules/chat-module/chat.module').then(m => m.ChatModule),
    // },
    {
        path: "members",
        canActivate: [AuthGuard, RoleGuard],
        data: {
            roles: [ROLE_COACH],
        },
        loadChildren: () => import('./modules/members/members.module').then(m => m.MembersModule),
    },
    {
        path: "training",
        redirectTo: "training/plans",
        pathMatch: "full",
    },
    {
        path: "training/plans",
        canActivate: [AuthGuard],
        loadChildren:
            () => import('./modules/training-plan-module/training-plan.module').then(m => m.TrainingPlanModule),
    },
    {
        path: "training/workouts",
        loadChildren:
            () => import('./modules/training-schema/training-schema.module').then(m => m.TrainingSchemaModule),
    },
    {
        path: "training/challenges",
        loadChildren: () => import('./modules/workouts/workouts.module').then(m => m.WorkoutsModule),
    },
    {
        path: "training/exercises",
        loadChildren: () => import('./modules/exercises/exercises.module').then(m => m.ExercisesModule),
    },
    {
        path: "nutrition",
        redirectTo: "nutrition/meals",
        pathMatch: "full"
    },
    {
        path: "nutrition/meals",
        loadChildren: () => import('./modules/nutrition/meals/meals.module').then(m => m.MealsModule),
    },
    {
        path: "management",
        canActivate: [AuthGuard, RoleGuard],
        data: {
            roles: [ROLE_ADMINISTRATOR, ROLE_MANAGER],
        },
        loadChildren: () => import('./modules/management/management.module').then(m => m.ManagementModule),
    },
    {
        path: "**",
        component: DefaultComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: "",
                component: NotFoundComponent,
                data: { title: "common.pageNotFound" },
            },
        ],
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            enableTracing: false,
            paramsInheritanceStrategy: "always",
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {
}
