import { ActivatedRoute, Router } from "@angular/router";
import { AnalyticsEvent, AnalyticsService } from '@app-services/analytics-service/analytics.service';
import { Component, OnDestroy, OnInit } from "@angular/core";
import { ErrorResponse, JsonApiError } from "angular2-jsonapi";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { FunxtionApiClientService, PasswordRequest } from "@funxtion/ng-funxtion-api-client";

import { BUTTON_VARIANT } from "src/app/modules/shared/inputConfig";
import { EnvironmentService } from '@app-services/environment/environment.service';
import { HttpErrorResponse } from "@angular/common/http";
import { ValidatorService } from "../../validators/validator-service";
import { ModalService } from '@funxtion/portal/shared'
import { TranslateService } from "@ngx-translate/core";
import { MatSelectChange } from "@angular/material/select";
import { InitializeService } from "@funxtion/portal/shared/services/initialize-service/initialize.service";
import { PortalEnvironmentVariables } from '@app-environments/EnvironmentVariables';

@Component({
    selector: "app-login",
    templateUrl: "./login.component.html",
    styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit, OnDestroy {
    BUTTON_VARIANT = BUTTON_VARIANT;
    loginForm: FormGroup;
    resetForm: FormGroup;
    resetPassword: boolean = false;
    loading = false;
    submitted = false;
    redirectUrl: string;
    error: string;
    friendlyError: string;
    currentIndex = 0;

    title = "Login";
    description = "Login with your account to start planning your workouts.";

    constructor(
        public environmentService: EnvironmentService,
        private funxtion: FunxtionApiClientService,
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private modal: ModalService,
        private analytics: AnalyticsService,
        public translate: TranslateService,
        private initializeService: InitializeService
    ) {
        if (this.funxtion.oauth.hasValidAccessToken()) {
            this.router.navigate(["training/workouts"]);
        }
    }

    ngOnInit() {
        this.addKeyDownListener();
        this.loginForm = this.formBuilder.group({
            environment: [],
            username: [
                "",
                [Validators.required, ValidatorService.emailValidator],
            ],
            password: [
                "",
                [Validators.required, ValidatorService.passwordValidator],
            ],
        });
        this.resetForm = this.formBuilder.group({
            username: ["", [Validators.required, Validators.email]],
        });

        this.redirectUrl = this.route.snapshot.queryParams["next"] || "";

        if (this.route.snapshot.queryParams.hasOwnProperty("not-allowed")) {
            this.modal.confirm(
                'Not Allowed',
                'You do not have sufficient rights to use this application. You were automatically logged out.',
                'alert'
            );
        }
    }

    ngOnDestroy() {
        this.removeKeyDownListener();
    }

    submitReset() {
        const request = this.funxtion.datastore.createRecord(PasswordRequest, {
            email: this.resetForm.controls.username.value,
            returnUrl: this.getReturnUrl(),
        });
        request
            .save()
            .subscribe(
                () => this.onSaveSuccess(),
                error => this.onSaveError(error),
            );
    }

    protected onSaveSuccess() {
        this.loading = false;
        this.analytics.track(AnalyticsEvent.FORGOT_PASSWORD);
        this.modal.confirm(
            'Request sent',
            'Your request for a password reset link has been sent. Please check your the inbox of your mail client.',
            'alert'
        );
    }

    protected onSaveError(response: ErrorResponse | HttpErrorResponse) {
        this.loading = false;
        if (response instanceof ErrorResponse) {
            this.error = response.errors
                .map((error: JsonApiError) => error.detail)
                .join(",");
        } else {
            this.error = response.message;
        }
    }

    protected getReturnUrl(): string {
        const environment: PortalEnvironmentVariables = this.environmentService.environment;
        return `${this.environmentService.environment.self.schema}://${this.environmentService.environment.self.hostname}/reset-password/environment/${environment.auth.clientId}/`;
    }

    async login() {
        this.submitted = true;
        if (this.loginForm.invalid) {
            // TODO: Show error
            return;
        }

        this.loading = true;
        this.error = null;

        try {
            this.funxtion.user.data.email = this.loginForm.value.username;
            this.funxtion.user.data.password = this.loginForm.value.password;
            await this.funxtion.user.login();

            if (
                this.funxtion.user.data.isAdministrator ||
                this.funxtion.user.data.isManager ||
                this.funxtion.user.data.isCoach
            ) {
                const {
                    email,
                    isAdministrator,
                    isManager,
                    isCoach,
                } = this.funxtion.user.data;
                this.analytics.track(AnalyticsEvent.SIGN_IN_SUCCESSFUL);
            }
            await this.router.navigateByUrl(this.redirectUrl || "");
        } catch (error) {
            this.loading = false;
            if (error instanceof HttpErrorResponse) {
                this.analytics.track(AnalyticsEvent.SIGN_IN_UNSUCCESSFUL, {
                    reason: error.error.message,
                });
                this.error = error.error.message;

                if (error.error.error === 'invalid_grant') {
                    this.friendlyError = this.translate.instant("login.invalid");
                }
            } else {
                throw error;
            }
        }
    }

    /**
     * Changes environment based on selection above login form
     * Only used when EnvironmentService was unable to determine the correct environment
     */
    changeEnvironment(selectChange?: MatSelectChange) {
        this.environmentService.clearEnvironment();
        this.environmentService.detected = false;
        this.environmentService.setEnvironment(selectChange?.value);
        this.initializeService.initPortal();
    }

    openEnvironmentSwitcher() {
        this.environmentService.setEnvironmentOptions();
        this.environmentService.detected = false;
    }

    private addKeyDownListener() {
        document.addEventListener('keydown', this.keyDownHandler)
    }

    private removeKeyDownListener() {
        document.removeEventListener('keydown', this.keyDownHandler);
    }

    keyDownHandler = (e: KeyboardEvent) => {
        if (e.shiftKey && e.key === 'S') {
            this.openEnvironmentSwitcher();
            this.removeKeyDownListener();
        }
    }
}
