import { IExerciseSetTarget } from "@app-types/vm/vm.common.types";
import { FormControl, FormGroup } from "@angular/forms";
import {
    Controls,
    InputValues,
    ITargetControlsConfig,
    ITargetStrategy,
    SourceSet,
    TargetControl,
    TargetValueReaders,
    TargetValueSource,
} from "./TargetStrategy.types";
import { isAbsoluteTarget } from "@app-helpers/training-schema/exercise-targets.helpers";
import { combineLatest, Observable, Subject } from "rxjs";
import { GroupTargetType } from "@funxtion/ng-funxtion-api-client";
import { any, equals, fromPairs, map, omit, path, pipe, propEq, toPairs } from 'ramda';
import { debounceTime, distinctUntilChanged, map as rxMap, skipWhile, startWith } from "rxjs/operators";


interface SourceValue<T> {
    source: TargetValueSource;
    value: T;
}

export abstract class AbstractTargetStrategy<ParsedType> implements ITargetStrategy {

    protected abstract readonly _controlsConfig: ITargetControlsConfig;

    // ------------------------------------------------------------------------------
    //      Revision streams
    // ------------------------------------------------------------------------------
    public readonly revisions$: Observable<IExerciseSetTarget | null>;
    private readonly _revisions$: Subject<IExerciseSetTarget | null>;

    // ------------------------------------------------------------------------------
    //      Form groups (dynamically assigned).
    //      Can be null depending on the applicable fields for the target type
    // ------------------------------------------------------------------------------
    // noinspection JSUnusedLocalSymbols
    private minValue: FormGroup | null = null; // noinspection JSUnusedLocalSymbols
    private maxValue: FormGroup | null = null; // noinspection JSUnusedLocalSymbols
    private value: FormGroup | null = null;

    // ------------------------------------------------------------------------------
    //      Lifecycle
    // ------------------------------------------------------------------------------
    constructor(
        protected readonly target: IExerciseSetTarget,
        protected readonly readers: TargetValueReaders,
    ) {

        this._revisions$ = new Subject();
        this.revisions$ = this._revisions$.asObservable().pipe(
            skipWhile(equals(this.target)),
            distinctUntilChanged<IExerciseSetTarget | null>(equals),
        );

        this.createForms();
        this.feedRevisions();
    }

    destruct() {
        this._revisions$.complete();
    }

    // ------------------------------------------------------------------------------
    //      Interface
    // ------------------------------------------------------------------------------

    getControls(source: TargetValueSource): TargetControl[] {

        if (!this.isApplicableSource(source)) {
            return [];
        }

        const form: FormGroup = this[source];

        return toPairs(form.controls).map(([key, formControl]) => ({
            formControl,
            inputType: "number",
            unit: this.getInputUnit(key),
            postDelimiter: this.getPostDelimiter(key),
        } as TargetControl));
    }

    public getColumnSize(): number {
        return isAbsoluteTarget(this.target)
            ? this._controlsConfig.columns.absolute
            : this._controlsConfig.columns.range;
    }

    public getCurrentType(): GroupTargetType {
        return this.target.type;
    }

    // ------------------------------------------------------------------------------
    //      Abstract implementation (protected)
    // ------------------------------------------------------------------------------
    //
    //      These methods should be written on a per-extension basis. They form the
    //      blanks in the 'code templates' of this class, and determine for a specific
    //      strategy whether
    //          - something is a valid ParsedType
    //          - something is a valid string representation (model attribute)
    //          - how to serialize from ParsedType
    //          - how to parse from a string into ParsedType
    //          - how to interpret and transform user input.
    //      Also some abstract methods are specified to determine display logic such
    //      as units as an input suffix and the input types.

    /**
     * Parse a serialized (as model attribute) target value into the `ParsedType`.
     */
    protected abstract fromModelAttribute(attribute: string): ParsedType | null;

    /**
     * Serialize a `ParsedType` target value to its string representation (model attribute).
     */
    protected abstract toModelAttribute(parsed: ParsedType): string;

    /**
     * Create the object of form controls for a single value of the ParsedType.
     */
    protected abstract toFormValues(parsed: ParsedType): InputValues;

    /**
     * Form-values transformer that generates either the ParsedType if valid,
     * or null if the input is invalid.
     */
    protected abstract fromFormValues(values: InputValues): ParsedType | null;

    /**
     * Provide a default value to use when parsing a serialized value fails.
     */
    protected abstract defaultParsedValue(): ParsedType;

    // ------------------------------------------------------------------------------
    //      Initialization
    // ------------------------------------------------------------------------------

    /**
     * Create the forms for the applicable properties based on the type of the target.
     */
    private createForms(): void {

        for (const source of this.applicableSources()) {

            const valueOrNull = this.fromModelAttribute(
                this.readers[source](this.target),
            );

            const values = this.toFormValues(
                valueOrNull || this.defaultParsedValue(),
            );

            const controls = pipe(
                toPairs,
                map(([key, val]) => [key, new FormControl(val)]),
                fromPairs,
            )(values) as Controls;

            this[source] = new FormGroup(controls);
        }
    }

    /**
     *
     */
    private feedRevisions(): void {

        const sources: TargetValueSource[] = this.applicableSources();

        const streams = sources.map((source) => this[source].valueChanges.pipe(
            startWith(this[source].value),
            rxMap((formValues) => ({ source, value: this.fromFormValues(formValues) })),
            distinctUntilChanged(equals),
        ));

        combineLatest(streams).pipe(
            rxMap<SourceValue<ParsedType>[], IExerciseSetTarget>((sourceInputs) => this.patchModel(sourceInputs)),
            debounceTime(1000),
        ).subscribe(this._revisions$);
    }

    /**
     *
     */
    private patchModel(sourceInputs: SourceValue<ParsedType>[]): IExerciseSetTarget | null {

        if (any(propEq('value', null), sourceInputs)) {
            return null;
        }

        const targetBase = omit([
            'value',
            'minValue',
            'maxValue',
        ], this.target);

        for (const { source, value } of sourceInputs) {
            targetBase[source] = this.toModelAttribute(value);
        }

        return targetBase as IExerciseSetTarget;
    }

    // ------------------------------------------------------------------------------
    //      Morphing helpers
    // ------------------------------------------------------------------------------

    private isApplicableSource(source: TargetValueSource): boolean {
        return this.applicableSources().includes(source);
    }

    private applicableSources(): SourceSet {
        return isAbsoluteTarget(this.target)
            ? ['value']
            : ['minValue', 'maxValue'];
    }

    // ------------------------------------------------------------------------------
    //      Config readers
    // ------------------------------------------------------------------------------

    /**
     * Returns the suffix (unit string) for a particular field, or null if no suffix applies.
     */
    protected getInputUnit(field: string): string | null {
        return path(['inputs', field, 'unit'], this._controlsConfig)
            || this.target.measurement.unit;
    }

    /**
     * Returns the post-delimiter for a particular field, or null if no delimiter is configured.
     */
    protected getPostDelimiter(field: string): string | null {
        return path(['inputs', field, 'postDelimiter'], this._controlsConfig)
            || null;
    }
}
