import { FunxtionApiClientService } from '@funxtion/ng-funxtion-api-client';
import { DuplicateModelDialogComponent } from '@app-components/dialogs/duplicate-model-dialog/duplicate-model-dialog.component';
import { filter, flatMap, map, tap } from 'rxjs/operators';
import { DMError, DMReport, DMRequest } from '@funxtion/ng-funxtion-api-client/lib/services/data/base.service';
import { TranslateService } from '@ngx-translate/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { JsonApiModel } from 'angular2-jsonapi';
import { IViewModel } from '@app-types/vm.types';
import { ModalService } from '../modal-service/modal.service';

export interface DuplicationStatus {
    isDuplicating: boolean;
    response: DMReport<JsonApiModel> | DMError;
}

export abstract class AbstractDuplicationService<T extends JsonApiModel> {
    dialogMaxWidth: string = '370px';
    dialogTitle: string;
    dialogMessage: string;

    protected constructor(protected funxtion: FunxtionApiClientService,
                          protected dialogService: ModalService,
                          protected translate: TranslateService,
    ) {}

    /**
     * @description Emits two values. The first one indicates the duplication process is starting and allows a component to respond,
     * for example by showing a loader. The second value indicates the process is done and includes the response.
     *
     * @param model - the model that needs to be duplicated, it's not modified in the process
     * @param confirm - true if the confirm dialog should be used to ask for confirmation
     */
    public duplicate(model: T, confirm: boolean = true): Observable<DuplicationStatus> {

        if (confirm) {
            /** use a subject to push updates about the duplication process to the subscribers {@see DuplicationStatus} */
            const duplicateSubject: Subject<DuplicationStatus> = new Subject<DuplicationStatus>();

            const duplicateSubscription = this.showDuplicateDialog(model)
                .pipe(
                    filter(isConfirmed => isConfirmed), // only process the true (confirmed by user) values
                    // emit the updated status
                    tap(isConfirmed => duplicateSubject.next({ isDuplicating: isConfirmed, response: null })),
                    flatMap(_ => this.handleDuplication(model))
                )
                .subscribe((response) => {
                    // emit final status with the response
                    duplicateSubject.next({ isDuplicating: false, response });
                    duplicateSubject.complete();
                    duplicateSubscription.unsubscribe();
                });

            return duplicateSubject.asObservable();
        } else {
            // start the duplication process immediately, use BehaviorSubject to emit a value immediately
            const duplicationSubject = new BehaviorSubject<DuplicationStatus>({ isDuplicating: true, response: null } );

            const duplicateSubscription = duplicationSubject
                .pipe(flatMap(_ => this.handleDuplication(model)))
                .subscribe((response) => {
                    duplicationSubject.next({ isDuplicating: false, response });
                    duplicationSubject.complete();
                    duplicateSubscription.unsubscribe();
                });

            return duplicationSubject.asObservable();
        }
    }

    private handleDuplication(model: T): Observable<DMReport<T> | DMError> {
        return this.getFullModel(model)
            .pipe(
                map(fullModel => this.getNewModel(fullModel)),
                flatMap(newModel => this.convertModel(newModel)),
                map(convertedModel => this.createDMRequest(convertedModel)),
                flatMap(dmRequest => this.saveModel(dmRequest))
            );
    }

    /**
     * Uses the {@link DuplicateModelDialogComponent} to show a dialog
     * Title & message are translate keys
     */
    private showDuplicateDialog(model: T): Observable<boolean> {
        const dialogConfig: MatDialogConfig = {
            maxWidth: this.dialogMaxWidth
        };

        const { title, message } = this.getContentForDialog(model.name);

        return this.dialogService.confirm<DuplicateModelDialogComponent>(
            title,
            message,
            'alert',
            dialogConfig,
            DuplicateModelDialogComponent
        );
    }

    setDialogTextByTranslateKey(titleKey: string, messageKey: string) {
        this.dialogTitle = titleKey;
        this.dialogMessage = messageKey;
    }

    private getContentForDialog(modelName: string): { title: string, message: string } {
        return {
            title: this.translate.instant(this.dialogTitle),
            message: this.translate.instant(this.dialogMessage, { name: modelName })
        }
    }

    /**
     * Use the {@link FunxtionApiClientService.dataServices} to retrieve the full model from the server
     */
    abstract getFullModel(model: T): Observable<T>;

    /**
     * Use {@link VmDuplicationService} to create a duplicated model
     */
    abstract getNewModel(model: T): IViewModel<T>;


    /**
     * Use a implementation of the {@link AbstractVmConverter} to convert a model
     */
    abstract convertModel(newModel: IViewModel<T>): Observable<T>;

    /**
     * Use the {@link FunxtionApiClientService.dataServices} to save the model
     */
    abstract saveModel(dmRequest: DMRequest<T>): Observable<DMReport<T> | DMError>;

    createDMRequest(convertedModel: T): DMRequest<T> {
        return {
            rootModel: convertedModel,
            options: {
                isDuplication: true,
            },
        } as DMRequest<T>;
    }
}
