import { MatDateFormats } from '@angular/material/core'
import { Provider } from '@angular/core'
import { MAT_DATE_FORMATS } from 'saturn-datepicker';

/**
 * A default date format that follows designs created for the portal
 */
export const LOCALIZED_DATE_FORMAT: MatDateFormats = {
    parse: {
        dateInput: 'LL',
    },
    display: {
        dateInput: 'LL',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY'
    }
};

/**
 * Reusable provider that can be added to the {@link NgModule.providers} or {@link Component.providers} array
 * This will apply the format to date pickers like saturn-datepicker
 */
export const localizedDateFormatProvider: Provider = {
    provide: MAT_DATE_FORMATS,
    useValue: LOCALIZED_DATE_FORMAT
}
