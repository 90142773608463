import { Inject, Injectable } from "@angular/core";
import { TrainingSchemaVMService } from "@app-services/vm/training-schema/training-schema-vm.service";
import { IViewModel } from "@app-types/vm.types";
import { IExerciseGroup, ITrainingExercise } from "@app-types/vm/vm.common.types";
import { ITrainingPhase, ITrainingSchema } from "@app-types/vm/vm.schema.types";
import { compose, Lens, lensPath, lensProp, toPairs } from "ramda";
import { VM_SERVICE } from "@funxtion/portal/shared/injection-tokens/vm-tokens";


// ------------------------------------------------------------------------------
//      Lenses
// ------------------------------------------------------------------------------
//
//      Lenses are objects that bundle a 'getter' and a 'setter' function
//      for a specific location (path) within a data structure (ITrainingSchema
//      in this case). Once we have this lens and an ITrainingSchema object, we
//      can read and modify (pure) the 'focus' of the lens without having to know
//      how to navigate ITrainingSchema to get to the focused property. This is
//      very convenient for reading and updating deep and/or indexed properties.
//
//      A good write-up: https://dev.to/devinholloway/functional-lenses-in-javascript-with-ramda-4li7
//
//      Note that these lens factories are impure; they require the current schema
//      to match the given models against, and will throw an error if a given model
//      does not exist in the schema.
//
@Injectable({
    providedIn: 'root'
})
export class SchemaLensService {
    // ------------------------------------------------------------------------------
    //      Lifecycle
    // ------------------------------------------------------------------------------


    constructor(
        @Inject(VM_SERVICE) private patchService: TrainingSchemaVMService,
    ) {
    }

    private getCurrentSchema(): ITrainingSchema {
        return this.patchService.current();
    }

    /**
     * Gives back an `ITrainingSchema` lens to the the `trainingPhase` collection for given `ITrainingPhase`.
     *
     * @throws {Error} If the given reference point can not be found in the current schema revision.
     */
    public trainingGroupsCollectionLens(referencePoint: ITrainingPhase): Lens {

        const { trainingPhases } = this.getCurrentSchema();

        for (const [tpIndex, trainingPhase] of toPairs(trainingPhases)) {
            if (trainingPhase.id === referencePoint.id) {
                return lensPath(['trainingPhases', Number(tpIndex), 'trainingGroups']);
            }
        }

        this.failLensConstruction(referencePoint.id, 'ITrainingPhase');
    }

    /**
     * Gives back an `ITrainingSchema` lens to the the `trainingExercises` collection for given `IExerciseGroup`.
     *
     * @throws {Error} If the given reference point can not be found in the current schema revision.
     */
    public exerciseGroupLens(referencePoint: IExerciseGroup): Lens {

        const { trainingPhases } = this.getCurrentSchema();

        for (const [tpIndex, trainingPhase] of toPairs(trainingPhases)) {
            for (const [tgIndex, trainingGroup] of toPairs(trainingPhase.trainingGroups)) {
                if (trainingGroup.exerciseGroup.id === referencePoint.id) {
                    return lensPath([
                        'trainingPhases', Number(tpIndex),
                        'trainingGroups', Number(tgIndex),
                        'exerciseGroup',
                    ]);
                }
            }
        }

        this.failLensConstruction(referencePoint.id, 'IExerciseGroup');
    }

    /**
     * Gives back an `ITrainingSchema` lens to the the `trainingExercises` collection for given `IExerciseGroup`.
     *
     * @throws {Error} If the given reference point can not be found in the current schema revision.
     */
    public trainingExercisesCollectionLens(exerciseGroup: IExerciseGroup): Lens {

        return compose(
            this.exerciseGroupLens(exerciseGroup),
            lensProp('trainingExercises'),
        ) as Lens;
    }

    /**
     * Gives back an `ITrainingSchema` lens to given `ITrainingExercise`.
     *
     * @throws {Error} If the given reference point can not be found in the current schema revision.
     */
    public trainingExerciseLens(referencePoint: ITrainingExercise): Lens {

        const { trainingPhases } = this.getCurrentSchema();

        for (const [tpIndex, trainingPhase] of toPairs(trainingPhases)) {
            for (const [tgIndex, trainingGroup] of toPairs(trainingPhase.trainingGroups)) {
                for (const [teIndex, trainingExercise] of toPairs(trainingGroup.exerciseGroup.trainingExercises)) {
                    if (trainingExercise.id === referencePoint.id) {
                        return lensPath([
                            'trainingPhases', Number(tpIndex),
                            'trainingGroups', Number(tgIndex),
                            'exerciseGroup',
                            'trainingExercises', Number(teIndex),
                        ]);
                    }
                }
            }
        }

        this.failLensConstruction(referencePoint.id, 'ITrainingExercise');
    }

    /**
     * Gives back an `ITrainingSchema` lens to the `IExerciseSet[]` on the `IExercise` related to given `ITrainingExercise`.
     *
     * @throws {Error} If the given reference point can not be found in the current schema revision.
     */
    public exerciseSetsCollectionLens(referencePoint: ITrainingExercise): Lens {
        return compose(
            this.trainingExerciseLens(referencePoint),
            lensProp('sets'),
        ) as Lens; // Unfortunately the Ramda type definitions are not sufficient for lens composition in TS.
                   // See https://github.com/types/npm-ramda/issues/69
    }

    // ------------------------------------------------------------------------------
    //      Private
    // ------------------------------------------------------------------------------

    private failLensConstruction(id: IViewModel['id'], name: string = 'ViewModel') {
        throw new Error(`Failed to construct Lens! Reference-point '${name} # ${String(id)}' does not exist in the current schema.`);
    }
}
