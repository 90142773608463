<div class="training-phase-container">

    <!-------------------------------------------------------------------------------
    The list of training-groups for this training-phase. This is
    a drag 'n drop list that reorders the training-groups within
    the training-phase component
    -------------------------------------------------------------------------------->

    <mat-list class="tree-list training-group-list"
              cdkDropList
              cdkDropListLockAxis="y"
              [cdkDropListDisabled]="trainingGroups.length <= 1"
              (cdkDropListDropped)="moveTrainingGroup($event)"
    >

        <!-------------------------------------------------------------------------------
        For each training-group, we're gonna add a list item that contains
        an app-training-group component bound to that training-group
        -------------------------------------------------------------------------------->

        <mat-list-item *ngFor="let trainingGroup of trainingGroups; index as trainingGroupIndex; trackBy: scalarTrainingGroup"
                       class="tree-list-item training-group-list-item"
                       [ngClass]="{
                            selected: groupIsSelected(trainingGroup),
                            expanded: groupIsExpanded(trainingGroup)
                        }"
                       cdkDrag
        >

            <div class="tree-list-item-content">

                <!-------------------------------------------------------------------------------
                This component is gonna take care of
                    - Adding & deleting exercises
                    - Updating exercise and set information
                    - Visual representation of different types of groups
                -------------------------------------------------------------------------------->
                <app-training-group [trainingGroup]="trainingGroup"
                                    [isSelected]="groupIsSelected(trainingGroup)"
                                    [isExpanded]="groupIsExpanded(trainingGroup)"
                ></app-training-group>
            </div>


            <div class="actions">

                <!-------------------------------------------------------------------------------
                The drag handle implements drag 'n drop re-ordering of training groups:
                -------------------------------------------------------------------------------->

                <button *ngIf="trainingGroups.length > 1"
                        class="action actions__drag-handle"
                        cdkDragHandle
                        matTooltip="Drag to reorder"
                        matTooltipPosition="above"
                        matTooltipShowDelay="700"
                >
                    <mat-icon inline="true" color="primary" svgIcon="move_handle"></mat-icon>
                </button>

                <!-------------------------------------------------------------------------------
                A delete button for this training group
                -------------------------------------------------------------------------------->

                <button class="action actions__remove-button"
                        matTooltip="Click to remove this {{ trainingGroup?.exerciseGroup?.type?.name?.toLowerCase() || 'training group' }}"
                        matTooltipPosition="above"
                        matTooltipShowDelay="700"
                        (click)="removeTrainingGroup(trainingGroup, trainingGroupIndex)"
                >
                    <mat-icon inline="true" svgIcon="delete"></mat-icon>
                </button>
            </div>

        </mat-list-item>

        <!-------------------------------------------------------------------------------
        Also -- statically -- add one additional list item that contains
        a button to add a new training-group to the training-phase of this
        component.
        -------------------------------------------------------------------------------->

        <mat-list-item class="tree-list-item add-button-list-item">

            <!-------------------------------------------------------------------------------
            The button does not directly command the component because we
            need an exercise-group-type in order to create a new training-group.
            So instead it binds to a popup menu to select one.
            -------------------------------------------------------------------------------->
            <button [matMenuTriggerFor]="ExerciseGroupTypeSelector" mat-button class="tree-add-button">
                <span class="tree-add-button-content">
                    <mat-icon [inline]="false">add</mat-icon>
                    <span>Add</span>
                </span>
            </button>

            <!-------------------------------------------------------------------------------
            The popup menu just lists a bunch of action buttons that,
            when clicked, call addTrainingGroup on the component with
            their specific group-type.
            -------------------------------------------------------------------------------->
            <mat-menu #ExerciseGroupTypeSelector="matMenu">

                <button *ngFor="let groupType of groupTypes | async"
                        mat-menu-item
                        [matTooltip]="groupType.description"
                        matTooltipPosition="after"
                        (click)="addTrainingGroup(groupType)"
                >
                    {{ groupType.name }}
                </button>
            </mat-menu>

        </mat-list-item>

    </mat-list>

</div>
