import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatExpansionModule } from "@angular/material/expansion";
import { ViewTrainingDialogComponent } from '@app-components/dialogs/training/view-training-dialog/view-training-dialog.component';
import { LoadingSpinnerModule } from "@app-components/loading-spinner/loading-spinner.module";
import { ViewTrainingHeaderComponent } from "@app-components/sticky-headers/view-training-header/view-training-header.component";
import { ViewTrainingPlanHeaderComponent } from "@app-components/sticky-headers/view-training-plan-header/view-training-plan-header.component";
import { CreateTrainingPlanDialogComponent } from '@app-components/training-plan/create-training-plan-dialog/create-training-plan-dialog.component';
import { SelectSchemaDialogComponent } from '@app-components/training-plan/select-schema-dialog/select-schema-dialog.component';
import { CreateTrainingDialogComponent } from "@app-components/training/create-training-dialog/create-training-dialog.component";
import { PhaseComponent } from "@app-components/training/editor/phase/phase.component";
import { TrainingPhaseComponent } from "@app-components/training/editor/training-phase/training-phase.component";
import { TypesColumnPipe } from "@app-pipes/column-pipes/types-column/types-column.pipe";
import { ExerciseSelectionService } from "@app-services/exercise-selection-service/exercise-selection.service";
import { TranslateModule } from "@ngx-translate/core";
import { TrainingAttributesComponent } from "src/app/modules/training-schema/components/training-attributes/training-attributes.component";
import { ViewTrainingPhaseComponent } from "src/app/modules/training-schema/components/view-training-phase/view-training-phase.component";
import { SchemaEditComponent } from "src/app/views/training/schemas/schema-edit/schema-edit.component";
import { SchemaIndexComponent } from "src/app/views/training/schemas/schema-index/schema-index.component";
import { SchemaViewComponent } from "src/app/views/training/schemas/schema-view/schema-view.component";
import { SharedModule } from "../shared/shared.module";
import { TrainingSchemaRoutingModule } from "./training-schema-routing.module";
import { VM_FACTORY_SERVICE, VM_LENS_SERVICE, VM_SERVICE } from "@funxtion/portal/shared/injection-tokens/vm-tokens";
import { TrainingSchemaVMService } from "@app-services/vm/training-schema/training-schema-vm.service";
import { SchemaLensService } from "@app-services/vm-lens-services/schema-lens-service/schema-lens.service";
import { SchemaFactory } from "../../factories/training/schema/schema.factory";
import { DetailPaneSelectionService } from "@app-services/detail-pane-selection/detail-pane-selection.service";
import { TagsColumnPipe } from "@app-pipes/column-pipes/tags-column/tags-column.pipe";


@NgModule({
    declarations: [
        PhaseComponent,
        SchemaEditComponent,
        SchemaIndexComponent,
        TrainingAttributesComponent,
        TrainingPhaseComponent,
        CreateTrainingDialogComponent,
        ViewTrainingPhaseComponent,
        SchemaViewComponent,
        ViewTrainingDialogComponent,
        CreateTrainingPlanDialogComponent,
        SelectSchemaDialogComponent,
        ViewTrainingPlanHeaderComponent,
        ViewTrainingHeaderComponent,
        TypesColumnPipe,
        TagsColumnPipe
    ],
    imports: [
        TranslateModule,
        CommonModule,
        FormsModule,
        LoadingSpinnerModule,
        MatExpansionModule,
        ReactiveFormsModule,
        SharedModule,
        TrainingSchemaRoutingModule,
    ],
    exports: [
        ViewTrainingPhaseComponent,
        ViewTrainingDialogComponent,
        ViewTrainingPlanHeaderComponent,
        SchemaViewComponent
    ],
    providers: [
        DetailPaneSelectionService,
        {
            provide: VM_SERVICE,
            useClass: TrainingSchemaVMService
        },
        {
            provide: VM_LENS_SERVICE,
            useClass: SchemaLensService
        },
        {
            provide: VM_FACTORY_SERVICE,
            useClass: SchemaFactory
        },
    ]
})
export class TrainingSchemaModule {
}
