<div class="column" [class.hidden]="exerciseInfo">

    <!-------------------------------------------------------------------------------
    Modal close button, positioned absolutely. Then the main title.
    -------------------------------------------------------------------------------->

    <button class="close-button" (click)="cancel()">
        <mat-icon>close</mat-icon>
    </button>

    <div class="exercise-select-title" mat-dialog-title>
        {{ "exercises.search.title" | translate }}
    </div>

    <!-------------------------------------------------------------------------------
    The description row of the modal that shows the introductory paragraph, and once
    exercises are selected, shows the list of exercises instead (collapsed until hovered)
    -------------------------------------------------------------------------------->

    <div class="description-row">

        <!-------------------------------------------------------------------------------
        On mouse-enter of the left side, the selection list must be expanded (show all),
        on mouse-leave the list is collapsed again (using overflow, see CSS for details).
        This is coordinated through the component property 'selectionListExpanded'
        -------------------------------------------------------------------------------->

        <div class="description-row--left"
             (mouseenter)="selectionListExpanded = true"
             (mouseleave)="selectionListExpanded = false">

            <!-------------------------------------------------------------------------------
            While no exercises are selected, show an introductory message:
            -------------------------------------------------------------------------------->

            <p [hidden]="hasSelection" class="exercise-selection-description">
                {{ "exercises.search.description" | translate }}
            </p>

            <!-------------------------------------------------------------------------------
            Otherwise display the selected exercises list with remove buttons.
            This list is a wrapping flex container that has the height of one row of selected
            exercises. The template references are used by an intersection observer that counts
            the number of elements that overflow the list (overflow = hidden).
            -------------------------------------------------------------------------------->

            <ul #exerciseSelectionList class="exercise-selection-list"
                [hidden]="! hasSelection"
                [ngClass]="{ expanded: selectionListExpanded }"
            >
                <li *ngFor="let exercise of selectedExercises"
                    #exerciseSelectionButton
                    class="exercise-selection-item"
                >
                    <span class="name">{{ exercise.name }}</span>
                    <button class="remove-button" (click)="deselectExercise(exercise.id)">
                        <mat-icon inline>close</mat-icon>
                    </button>
                </li>

            </ul>

            <!-------------------------------------------------------------------------------
            If the intersection observer counts more than 0 elements that are out of viewport
            display a message telling the user how many are hidden.
            -------------------------------------------------------------------------------->

            <p class="hover-notice"
               [ngClass]="{ hidden: hiddenSelectionCount === 0 || selectionListExpanded }"
            >
                <mat-icon>keyboard_arrow_down</mat-icon>
                {{ "exercises.search.n-more-exercises" | translate:{ n: hiddenSelectionCount || '..' } }}.
            </p>
        </div>

        <!-----------------------------------------------------------------------------------
        On the far right of the description row is the selection submit button. Clicks
        close the modal after reporting the list of selected exercises back to the initiator.
        ------------------------------------------------------------------------------------>

        <funxtion-button [variant]="confirmButtonTheme" (click)="confirm()">
            {{ confirmButtonContent }}
        </funxtion-button>
    </div>

    <!-------------------------------------------------------------------------------
    TEXT SEARCH & FILTERS
    ---------------------
    -------------------------------------------------------------------------------->

    <form *ngIf="filterForm">
        <mat-form-field appearance="standard" class="w-100">
            <input matInput
                   placeholder="{{ 'exercises.search.query-placeholder' | translate }}..."
                   [formControl]="filterForm.get('query')"
            />
        </mat-form-field>


        <div class="row">

            <div class="col-4">
                <app-model-select-search [control]="filterForm.get('category')"
                                         [models]="(filterOptions$ | async).exerciseCategories"
                                         placeholder="{{ 'training.schemas.category' | translate }}"
                ></app-model-select-search>
            </div>

            <div class="col-4">
                <app-model-select-search [control]="filterForm.get('equipment')"
                                         [models]="(filterOptions$ | async).equipmentModels"
                                         placeholder="{{ 'training.schemas.equipment' | translate }}"
                ></app-model-select-search>
            </div>

            <div class="col-4">
                <app-model-select-search [control]="filterForm.get('muscleGroup')"
                                         [models]="(filterOptions$ | async).muscleGroups"
                                         placeholder="{{ 'training.schemas.muscleGroup' | translate }}"
                ></app-model-select-search>
            </div>
        </div>
    </form>

    <!-------------------------------------------------------------------------------
    EXERCISE SEARCH-RESULTS LIST
    ----------------------------
    The main element #exerciseResultsList is the root for the intersection observer
    that determines when to load exercises.
    -------------------------------------------------------------------------------->

    <mat-dialog-content #exerciseResultsList>

        <!-------------------------------------------------------------------------------
        The actual results are in a scrollable container. Uses an NG module to replace
        native scrollbars that conditionally occupy space in the main document flow.
        -------------------------------------------------------------------------------->

        <ng-scrollbar class="exercise-results-scrollbar"
                      compact="false"
                      shown="hover"
        >

            <!-------------------------------------------------------------------------------
            Render all pages of exercise results that have been loaded so far.
            -------------------------------------------------------------------------------->

            <div class="exercise-page" *ngFor="let page of exercisePages">
                <div class="exercise-grid">
                    <div class="exercise-grid-item" *ngFor="let exercise of page.exercises">
                        <app-exercise-selector-button [exercise]="exercise"
                                                      [selected]="selectedExerciseIds.includes(exercise.id)"
                                                      (selection)="selectExercise(exercise)"
                                                      (deselection)="deselectExercise(exercise.id)"
                                                      (detail)="showExerciseDetail($event)"
                        ></app-exercise-selector-button>
                    </div>
                </div>
            </div>

            <!-------------------------------------------------------------------------------
            While loading exercises, render a 'placeholder' page (only half a page actually)
            with exercise tiles containing a loading spinner and all, so the user already gets
            a clue where new exercises are going to be, and can already scroll there.
            -------------------------------------------------------------------------------->

            <div class="exercise-page">

                <div class="exercise-grid exercise-grid--loading" *ngIf="isLoading">

                    <div class="exercise-grid-item" *ngFor="let i of loadingGridIterable">
                        <div class="exercise-grid-item-placeholder">
                            <div class="image-placeholder">
                                <mat-spinner color="primary" diameter="20"></mat-spinner>
                            </div>
                            <div class="text-placeholder"></div>
                        </div>
                    </div>

                </div>

            </div>

            <!-------------------------------------------------------------------------------
            Exhaustion message for exercise results. If all results are loaded for the
            current filters, show the message below. It's a different message when
            'all exercises' ==== '0 exercises'.
            -------------------------------------------------------------------------------->

            <div *ngIf="isExhausted" class="exercises-exhausted-indicator">
                {{ exercisePages.length
                ? 'That\'s all we got for the current filter settings!'
                : 'Sorry! We couldn\'t find anything for the current filter settings.' }}
            </div>

            <!-------------------------------------------------------------------------------
            The loading trigger element. Whenever this element scrolls into view, a signal is
            given to the component to start loading the next page of exercises for the current
            filters -- using another intersection observer.
            -------------------------------------------------------------------------------->

            <div #loadingTrigger class="exercise-loading-trigger"></div>

        </ng-scrollbar>
    </mat-dialog-content>
</div>

<div [class.hidden]="!exerciseInfo">
    <div class="column top-bar">
        <funxtion-button [variant]="BUTTON_VARIANT.CLEAR_SMALL_ICON" [overrides]="{
            alignIcon: ALIGNMENT.LEFT,
            iconConfig: {
                iconName: 'back-arrow'
            }
        }" class="back-button" (click)="exerciseInfo = null">
            {{ 'training.schemas.backToAllExercises' | translate }}
        </funxtion-button>

        <button class="close-button" (click)="cancel()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <mat-divider class="top-bar"></mat-divider>

    <div class="column">
        <mat-dialog-content>
            <app-exercise-view *ngIf="exerciseInfo"
                               [exerciseId]="exerciseInfo.id"
                               [hasCard]="false"
                               [hasSelect]="true"
                               [selected]="selectedExerciseIds.includes(exerciseInfo.id)"
                               (exerciseSelected)="selectedExerciseIds.includes(exerciseInfo.id) ? deselectExercise($event) : selectExercise($event)"
            ></app-exercise-view>
        </mat-dialog-content>
    </div>
</div>
