import { IExerciseGroup, IExerciseGroupType, ITrainingExercise } from "@app-types/vm/vm.common.types";

// ------------------------------------------------------------------------------
//      Selection logic for the detail pane
// ------------------------------------------------------------------------------
//      In the left training-section one can select blocks of the following types.
//      The morphing between the types happens here inside DetailPaneSelection,
//      so that we have one object + interface to account for while interpreting
//      the selection (user action)

/**
 * Enum of string values that categorize types of detail-pane selection.
 */
export enum DetailPaneSelectionType {
    None = 'DPSType--none',
    ExerciseGroup = 'DPSType--exercise-group',
    TrainingExercise = 'DPSType--training-exercise',
}

/**
 *
 */
export interface DetailPaneNone {
    type: DetailPaneSelectionType.None;
}

export interface DetailPaneExerciseGroup {
    type: DetailPaneSelectionType.ExerciseGroup;
    value: IExerciseGroup;
}

export interface DetailPaneTrainingExercise {
    type: DetailPaneSelectionType.TrainingExercise;
    value: ITrainingExercise;

    // Just some contextual information we want to bring along:
    context: {
        multipleSetsAllowed: boolean,
        groupType?: IExerciseGroupType
    };
}

/**
 * The union of all types of detail-selection data. They are discriminated over their common property `type`.
 * @see DetailPaneSelectionType
 */
export type DetailPaneSelection =
    | DetailPaneNone
    | DetailPaneExerciseGroup
    | DetailPaneTrainingExercise
    ;
