import {
    IEquipment,
    IEquipmentMeasurement,
    IExerciseSetTarget,
    ITrainingExercise,
    MeasurementSlug
} from "@app-types/vm/vm.common.types";
import { TargetTypeConstraint } from "@app-types/training/editor/training-exercise-form.types";
import { path, pipe, prop, reduce, uniqBy } from "ramda";
import { GroupTargetType } from "@funxtion/ng-funxtion-api-client";

// ------------------------------------------------------------------------------
//      Helper functions for the training-exercise-form component
//      for the detail pane.
// ------------------------------------------------------------------------------
//
//      These functions do the dirty work of mapping models to one another
//      to derive data and logic from.

/**
 * Get all distinct IEquipmentMeasurement models related to the given training-exercise.
 */
export const distinctMeasurements = (trainingExercise: ITrainingExercise): IEquipmentMeasurement[] => {
    return pipe<ITrainingExercise, IEquipment[], IEquipmentMeasurement[], IEquipmentMeasurement[]>(
        path(['exercise', 'equipment']),
        reduce((acc: IEquipmentMeasurement[], eq: IEquipment) => acc.concat(eq.measurements), []),
        uniqBy(prop('id')),
    )(trainingExercise);
}

export function filterMeasurementsBySlug(measurements: IEquipmentMeasurement[], excludes?: MeasurementSlug[]): IEquipmentMeasurement[] {
    return measurements.filter(measurement => !excludes.includes(measurement.slug));
}

/**
 * Get the number of sets for given training-exercise.
 */
export const currentSetLength = (trainingExercise: ITrainingExercise): number => (
    trainingExercise.sets.length
);

/**
 * Get a default constraint object for given measurement.
 */
export const defaultConstraint = (measurement: IEquipmentMeasurement): TargetTypeConstraint => ({
    measurement, targetType: GroupTargetType.ABSOLUTE,
});

/**
 * Get the first match in search for a target within given trainingExercise that
 * belongs to given measurement. Returns null if no such target exists.
 */
export const findTargetForMeasurement = (
    trainingExercise: ITrainingExercise,
    measurement: IEquipmentMeasurement,
): IExerciseSetTarget | null => {

    for (const set of trainingExercise.sets) {
        for (const target of set.targets) {
            if (target.measurement.slug === measurement.slug) {
                return target;
            }
        }
    }
    return null;
};

/**
 * Returns a set of target-type constraints based on the available measurements and existing
 * set-targets of given trainingExercise.
 *
 * @param trainingExercise - training exercise to get the measurements from
 * @param excludes - optional array with slugs of measurements to exclude
 */
export const targetTypeConstraints = (trainingExercise: ITrainingExercise, excludes?: MeasurementSlug[]): TargetTypeConstraint[] => {

    let measurements: IEquipmentMeasurement[] = distinctMeasurements(trainingExercise);
    if (excludes) {
        measurements = filterMeasurementsBySlug(measurements, excludes);
    }

    return measurements.map((measurement) => {
            // Note: There's ambiguity in the data model & process logic here
            const firstTarget = findTargetForMeasurement(trainingExercise, measurement);

            return firstTarget ? { measurement, targetType: firstTarget.type } : defaultConstraint(measurement);
        });
};
