import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { IExerciseGroup, IExerciseGroupType, ITrainingExercise } from "@app-types/vm/vm.common.types";
import { defaultTo, head, path, pipe } from 'ramda';
import { exerciseGroupSelection, trainingExerciseSelection } from "@app-helpers/training-schema/detail-pane-selection.helpers";

import { DetailPaneSelectionService } from "@app-services/detail-pane-selection/detail-pane-selection.service";
import { ITrainingGroup } from "@app-types/vm/vm.schema.types";
import { isSingleExerciseGroup } from "../../../../views/training/schemas/schema-edit/schema-edit.pure";
import { ChallengeExerciseGroupType, FitnessExeriseGroupType } from "@funxtion/ng-funxtion-api-client";
import { MAX_AMOUNT_OF_EXERCISES } from '../../constants/training';

@Component({
    selector: 'app-training-group',
    templateUrl: './training-group.component.html',
    styleUrls: ['./training-group.component.scss'],
})
export class TrainingGroupComponent implements OnChanges {

    @Input()
    public trainingGroup?: ITrainingGroup;

    @Input()
    public exerciseGroup?: IExerciseGroup;


    get exGroup(): IExerciseGroup {
        return this.trainingGroup
            ? this.trainingGroup.exerciseGroup
            : this.exerciseGroup
                ? this.exerciseGroup
                : null;
    }

    public switches: any;

    /**
     * Whether this group's heading row must be visually selected (have the active state)
     */
    @Input()
    public isSelected: boolean = false;

    /**
     * Whether this group's sublist must be visible, expanded.
     * This is typically true when either the group itself is selected or any of its descendant exercises.
     */
    @Input()
    public isExpanded: boolean = false;

    constructor(
        public detailSelectionService: DetailPaneSelectionService,
    ) {
    }

    ngOnChanges(changes: SimpleChanges) {
        const groupType = this.exGroup.type;
        this.exGroup.type.maxExercises = this.getMaxExercises(groupType);

        const isSingleExerciseType = groupType.maxExercises === 1 || groupType.maxExercises === 0;
        const exerciseCount = this.exGroup.trainingExercises.length;
        /**
         * Just a bunch of booleans and other values derived from input to make templating easier.
         * That now no longer get called each digest cycle
         */
        this.switches = {
            type: groupType.type,
            /** asserts the group is a superset */
            superset: groupType.type === 'superset',
            /** asserts the group can only have one single exercise  */
            single: isSingleExerciseType,
            /** The actual current exercise count of the group */
            exCount: exerciseCount,
            /** asserts the group can have multiple exercises */
            multi: !isSingleExerciseType,
            /** asserts the group currently has at least one exercise */
            withExercises: exerciseCount > 0,
            /** asserts the group is currently empty of exercises */
            withoutExercises: exerciseCount === 0,
        };
    }

    public get firstTrainingExercise(): ITrainingExercise | null {

        return pipe(
            path(['trainingExercises']),
            head,
            defaultTo(null),
        )(this.exGroup);
    }

    get title(): string {
        return this.switches.single && this.switches.withExercises
            ? path(['trainingExercises', 0, 'exercise', 'name'], this.exGroup)
            : path(['type', 'name'], this.exGroup);
    }

    public showTrainingExerciseDetail(trainingExercise: ITrainingExercise): void {
        this.detailSelectionService.setSelection(
            trainingExerciseSelection(trainingExercise, isSingleExerciseGroup(this.exGroup), this.exGroup.type),
            true,
        );
    }

    public showTrainingGroupDetail(): void {
        this.detailSelectionService.setSelection(
            exerciseGroupSelection(this.exGroup),
            true,
        );
    }

    private getMaxExercises(groupType: IExerciseGroupType): number {
        if (groupType.type === ChallengeExerciseGroupType.AS_MANY_REPETITIONS_AS_POSSIBLE
            || groupType.type === ChallengeExerciseGroupType.ROUNDS_FOR_TIME
            || groupType.type === FitnessExeriseGroupType.SUPERSET) {

            return MAX_AMOUNT_OF_EXERCISES;
        } else {
            return groupType.maxExercises;
        }
    }
}
