import { ActivatedRouteSnapshot, CanActivate, NavigationExtras, Router, RouterStateSnapshot } from "@angular/router";

import { FunxtionApiClientService } from "@funxtion/ng-funxtion-api-client";
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root",
})
export class AuthGuard implements CanActivate {
    constructor(
        private funxtion: FunxtionApiClientService,
        private router: Router,
    ) {
    }

    async canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Promise<boolean> {
        const navigationExtras: NavigationExtras = {};

        if (!this.funxtion.oauth.hasValidAccessToken()) {

            if (state.url) {
                navigationExtras.queryParams = { next: state.url };
            }

            // Check to prevent redirect loop
            if (state.url.substr(0, 6) !== '/login') {
                this.router.navigate(["login"], navigationExtras);
            }

            return false;
        }

        if (!this.funxtion.user.data.id) {
            await this.funxtion.user.getUser();
        }

        if (!this.isUserAllowed()) {
            this.funxtion.oauth.logOut();
            navigationExtras.queryParams = { "not-allowed": true };
            this.router.navigate(["login"], navigationExtras);
            return false;
        }

        return true;
    }

    protected isUserAllowed(): boolean {
        return (
            this.funxtion.user.data.isAdministrator ||
            this.funxtion.user.data.isManager ||
            this.funxtion.user.data.isCoach
        );
    }
}
