<div class="exercise-group-container">

    <mat-list class="tree-list training-exercise-list"
              cdkDropList
              cdkDropListLockAxis="y"
              [cdkDropListDisabled]="exerciseGroup.trainingExercises.length <= 1"
              (cdkDropListDropped)="moveTrainingExercise($event)">

        <mat-list-item *ngFor="let trainingExercise of exerciseGroup.trainingExercises; index as i; trackBy: scalarTrainingExercise"
                       cdkDrag
                       class="tree-list-item exercise-list-item"
                       [ngClass]="{ selected: detailSelectionService.exerciseIsSelected(trainingExercise) }">

            <div class="tree-list-item-content" (click)="selectTrainingExercise(trainingExercise)">

                <app-image-list-item [multi]="false"
                                     [indent]="true"
                                     [superset]="false"
                                     [textContent]="trainingExercise.exercise.name"
                                     [imageUrl]="trainingExercise.exercise.imageSmall"
                                     [selected]="detailSelectionService.exerciseIsSelected(trainingExercise)"
                ></app-image-list-item>
            </div>

            <div class="actions">

                <!-------------------------------------------------------------------------------
                The drag handle implements drag 'n drop re-ordering of training-exercises:
                -------------------------------------------------------------------------------->

                <button *ngIf="exerciseGroup.trainingExercises.length > 1"
                        class="action actions__drag-handle"
                        cdkDragHandle
                        matTooltip="Drag to reorder"
                        matTooltipPosition="above"
                        matTooltipShowDelay="700"
                >
                    <mat-icon inline="true" color="primary" svgIcon="move_handle"></mat-icon>
                </button>

                <!-------------------------------------------------------------------------------
                A delete button for this training group
                -------------------------------------------------------------------------------->

                <button class="action actions__remove-button"
                        matTooltip="Click to remove this exercise"
                        matTooltipPosition="above"
                        matTooltipShowDelay="700"
                        (click)="removeTrainingExercise(trainingExercise, i)"
                >
                    <mat-icon inline="true" svgIcon="delete"></mat-icon>
                </button>
            </div>

        </mat-list-item>

        <mat-list-item class="add-button-list-item" *ngIf="exerciseSlotsRemaining > 0">
            <div class="tree-list-item-content">
                <button class="tree-add-button indented" (click)="addTrainingExercises()">
                        <span class="tree-add-button-content">
                            <mat-icon [inline]="false">add</mat-icon>
                            <span>{{ "training.schemas.addExercise" | translate }}</span>
                        </span>
                </button>
            </div>
        </mat-list-item>

    </mat-list>

</div>
