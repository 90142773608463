import { Component, Inject, Input, NgZone, OnChanges, OnInit, SimpleChange, SimpleChanges } from '@angular/core';
import { IExerciseGroup, ITrainingExercise } from "@app-types/vm/vm.common.types";
import { concat, flip, move, over, pipe, remove } from 'ramda';
import { exerciseGroupSelection, trainingExerciseSelection } from "@app-helpers/training-schema/detail-pane-selection.helpers";
import { isSingleExerciseGroup, writePositions } from "../../../../views/training/schemas/schema-edit/schema-edit.pure";

import { CdkDragDrop } from "@angular/cdk/drag-drop";
import { DetailPaneSelectionService } from "@app-services/detail-pane-selection/detail-pane-selection.service";
import { ExerciseSelectionService } from "@app-services/exercise-selection-service/exercise-selection.service";
import { SchemaFactory } from "../../../../factories/training/schema/schema.factory";
import { SchemaLensService } from "@app-services/vm-lens-services/schema-lens-service/schema-lens.service";
import { vmTrackFunction } from "@app-helpers/vm.helpers";
import { take } from "rxjs/operators";
import { VM_FACTORY_SERVICE, VM_LENS_SERVICE, VM_SERVICE } from "@funxtion/portal/shared/injection-tokens/vm-tokens";
import { VMService } from "@app-services/vm/vm.service";

@Component({
    selector: 'app-exercise-group',
    templateUrl: './exercise-group.component.html',
    styleUrls: ['./exercise-group.component.scss'],
})
export class ExerciseGroupComponent implements OnChanges {

    @Input()
    public exerciseGroup: IExerciseGroup;

    @Input()
    public isWorkout: boolean = false;

    public scalarTrainingExercise = vmTrackFunction<ITrainingExercise>();

    constructor(
        public detailSelectionService: DetailPaneSelectionService,
        private exerciseSelection: ExerciseSelectionService,
        @Inject(VM_SERVICE) private patchService: VMService,
        @Inject(VM_FACTORY_SERVICE) private factoryService: SchemaFactory,
        @Inject(VM_LENS_SERVICE) private lensService: SchemaLensService,
        private zone: NgZone
    ) {
    }

    ngOnChanges(changes: SimpleChanges) {
        // check if the type of the IExerciseGroup has changed (for example: RFT->AMRAP)
        // in that case UI for the selection should be updated to apply rules for that type
        const exGroup: SimpleChange = changes.exerciseGroup;
        if (!exGroup?.firstChange && exGroup?.currentValue?.type.type !== exGroup?.previousValue?.type.type) {
            // directly calling setSelection leads to problems in the digest cycle ('expression updated after checked')
            // that's why we wait until other tasks are finished and the zone is stable
            this.zone.onStable
                .pipe(take(1))
                .subscribe(() => this.detailSelectionService.setSelection(exerciseGroupSelection(exGroup.currentValue)));
        }
    }

    // ------------------------------------------------------------------------------
    //      Computed
    // ------------------------------------------------------------------------------

    public get exerciseSlotsRemaining(): number {
        const curLength = this.exerciseGroup.trainingExercises.length;
        const maxLength = this.exerciseGroup.type.maxExercises || Infinity;

        return Math.max(0, maxLength - curLength);
    }

    // ------------------------------------------------------------------------------
    //      UI actions
    // ------------------------------------------------------------------------------

    public selectTrainingExercise(trainingExercise: ITrainingExercise): void {
        this.detailSelectionService.setSelection(
            trainingExerciseSelection(trainingExercise, isSingleExerciseGroup(this.exerciseGroup), this.exerciseGroup.type),
            true,
        );
    }

    public async removeTrainingExercise(trainingExercise: ITrainingExercise, index: number): Promise<void> {

        if (this.detailSelectionService.exerciseIsSelected(trainingExercise)) {

            // If the exercise to remove is selected for detail, it must be unselected.

            // If we just select None, the containing group would close, hiding the other
            // exercises in the group that were previously visible. This makes for bad UI
            // so instead we're gonna select the containing group so that the exercises
            // remain visible:

            this.detailSelectionService.setSelection(
                exerciseGroupSelection(this.exerciseGroup),
            );
        }

        const patch = over(
            this.lensService.trainingExercisesCollectionLens(this.exerciseGroup),
            pipe(remove(index, 1), writePositions(1)),
        );

        await this.patchService.transform(patch);
    }

    public async moveTrainingExercise({ currentIndex, previousIndex }: CdkDragDrop<string[]>): Promise<void> {

        // Sort the exercises according to change
        this.exerciseGroup.trainingExercises = move(
            previousIndex, currentIndex, this.exerciseGroup.trainingExercises,
        );

        const patch = over(
            this.lensService.trainingExercisesCollectionLens(this.exerciseGroup),
            pipe(writePositions(1)),
        );

        await this.patchService.transform(patch);
    }


    public async addTrainingExercises(): Promise<void> {

        const exerciseSelectionResult = await this.exerciseSelection.select({
                maxExerciseCount: this.exerciseSlotsRemaining,
                groupTypeName: this.exerciseGroup.type.name
            });

        if (!exerciseSelectionResult?.confirmed) {
            return;
        }

        const { exercises } = exerciseSelectionResult;
        const newTrainingExercises = exercises.map((exercise, index) => this.factoryService.newTrainingExercise(
            exercise,
            this.exerciseGroup.trainingExercises.length + 1 + index,
        ));

        const patch = over(
            this.lensService.trainingExercisesCollectionLens(this.exerciseGroup),
            flip(concat)(newTrainingExercises),
        );

        await this.patchService.transform(patch);
    }
}
