import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { JsonApiModel, JsonApiQueryData, ModelType } from 'angular2-jsonapi'
import { FunxtionApiClientService } from '@funxtion/ng-funxtion-api-client'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { compareObjects } from '@app-helpers/compareObjects'
import {
    Filter,
    FilterType,
    RangeFilterConfig,
    RelationshipFilterConfig,
} from '@app-components/filter/interfaces/filter'

@Component({
    selector: "app-filter",
    templateUrl: "./filter.component.html",
    styleUrls: ["./filter.component.scss"],
})
export class FilterComponent implements OnInit {
    @Input()
    filters: Filter[] = [];

    @Output()
    results: EventEmitter<any> = new EventEmitter<any>();

    data: any[] = [];
    cypress = !!window["Cypress"];
    promises: Promise<JsonApiQueryData<JsonApiModel>>[] = [];
    form: FormGroup;
    filterTypes = FilterType;
    compareObjects = compareObjects;
    formConfig: any[] = [];

    constructor(
        private fb: FormBuilder,
        private funxtion: FunxtionApiClientService,
    ) {
    }

    ngOnInit() {
        this.filters.forEach((filter: Filter) => {
            const relConfig = filter.config as RelationshipFilterConfig<JsonApiModel>;
            const rangeConfig = filter.config as RangeFilterConfig;

            if (relConfig.type) {
                this.promises.push(
                    new Promise((resolve, reject) => {
                        this.getData(
                            relConfig.type,
                            relConfig.filter,
                            relConfig.sort,
                            relConfig.include,
                        ).then(data => {
                            this.data[filter.field] = data.getModels();
                            if (relConfig.value) {
                                this.formConfig[filter.field] = [
                                    relConfig.value,
                                    Validators.required,
                                ];
                            } else {
                                this.formConfig[filter.field] = [
                                    this.data[filter.field],
                                    Validators.required,
                                ];
                            }
                            resolve();
                        });
                    }),
                );
            }
            if (rangeConfig.min || rangeConfig.max) {
                if (rangeConfig.value) {
                    this.formConfig[filter.field] = [
                        rangeConfig.value,
                        Validators.required,
                    ];
                } else {
                    if (filter.type === this.filterTypes.NUMBERRANGE) {
                        this.formConfig[filter.field] = [
                            [rangeConfig.min, rangeConfig.max],
                            Validators.required,
                        ];
                    } else {
                        this.formConfig[filter.field] = [
                            [
                                { min: rangeConfig.min, max: rangeConfig.max },
                                Validators.required,
                            ],
                        ];
                    }
                }
            }
        });
        Promise.all(this.promises).then(() => {
            this.form = this.fb.group(this.formConfig);
        });
    }

    getData(
        type: ModelType<JsonApiModel>,
        filter = {},
        sort: string[] = [],
        include: string[] = [],
    ): Promise<JsonApiQueryData<JsonApiModel>> {
        return this.funxtion.datastore
            .findAll(type, {
                filter,
                page: {
                    size: 10000,
                },
                sort: sort.join(","),
                include: include.join(","),
            })
            .toPromise();
    }

    changeBegin(field, value) {
        const currentValue = this.form.value[field];
        currentValue[0] = parseInt(value.target.value, 10);
        this.form.controls[field].setValue(currentValue);
    }

    changeEnd(field, value) {
        const currentValue = this.form.value[field];
        currentValue[1] = parseInt(value.target.value, 10);
        this.form.controls[field].setValue(currentValue);
    }

    submit() {
        const result = this.filters.map(filter => {
            if (this.form.value[filter.field] === this.data[filter.field]) {
                filter.filterSet = false;
            } else {
                filter.config.value = this.form.value[filter.field];
                filter.filterSet = true;
            }
            return filter;
        });
        this.results.emit(result);
    }

    getRangeFilterConfig(filter: Filter): RangeFilterConfig {
        return filter.config as RangeFilterConfig;
    }

    getRelationshipFilterConfig(filter: Filter): RelationshipFilterConfig<any> {
        return filter.config as RelationshipFilterConfig<any>;
    }

    selectAll(field: string) {
        this.form.controls[field].setValue(this.data[field]);
    }

    deselectAll(field: string) {
        this.form.controls[field].setValue([]);
    }
}
