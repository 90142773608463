import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ExerciseSelectionResult, ExerciseSelectorConfig } from "@app-types/training/editor/exercise-selector-dialog.types";
import { ExerciseSelectorComponent } from "@app-components/training/editor/exercise-selector/exercise-selector.component";
import { IExerciseGroupType } from '@app-types/vm/vm.common.types';

/**
 * This service provides the exercise selection dialog. Just use select() to initiate a selection process,
 * and await the resulting list of IExercise objects.
 */
@Injectable({
    providedIn: 'root'
})
export class ExerciseSelectionService {

    constructor(private dialog: MatDialog) {
    }

    public select(groupTypeConfig: ExerciseSelectorConfig): Promise<ExerciseSelectionResult> {
        const dialogRef = this.openExerciseSelector( groupTypeConfig );
        return dialogRef.afterClosed().toPromise();
    }

    protected openExerciseSelector(
        config: ExerciseSelectorConfig,
    ): MatDialogRef<ExerciseSelectorComponent, ExerciseSelectionResult> {


        return this.dialog.open<ExerciseSelectorComponent, ExerciseSelectorConfig>(ExerciseSelectorComponent, {
            width: '790px',
            height: 'calc(100vh - 64px)',
            data: config,
        });
    }
}
