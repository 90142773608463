<div [formGroup]="group">

    <ng-container *ngIf="useHours">
        <input
            type="number"
            min="0"
            [formControl]="group.get('hours')"
            [required]="required"
            (input)="update($event)"
        />
        <span>{{ "common.units.hours_abbr" | translate }}&nbsp;:</span>
    </ng-container>

    <input
        type="number"
        min="0"
        [formControl]="group.get('minutes')"
        [required]="required"
        (input)="update($event)"
    />
    <span>{{ "common.units.minutes_abbr" | translate }}&nbsp;:</span>

    <input
        type="number"
        min="0"
        [formControl]="group.get('seconds')"
        [required]="required"
        (input)="update($event)"
    />
    <span>{{ "common.units.seconds_abbr" | translate }}</span>
</div>
