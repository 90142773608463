import { AttributeMapReport, MapperChainRecord, ModelMatchResult, RelationMap } from '@app-types/vm-conversion/vm-conversion.types';
import { zip, isEmpty, transpose, intersperse } from 'ramda';
import { DetailPaneSelection } from '@app-types/training/editor/detail-pane-selection.types';
import { environment } from '@app-environments/environment';

// tslint:disable: curly
// tslint:disable: max-line-length
// tslint:disable: no-console
// tslint:disable: no-any
// tslint:disable: only-arrow-functions

// ------------------------------------------------------------------------------
//      Logging method calls
// ------------------------------------------------------------------------------

export interface TraceLoggers {
    before: (self, args: any[]) => void;
    after: (self, args: any[], result) => void;
}

export type TraceLoggerParams = Partial<TraceLoggers>;
const colored = (content: string, color: string) => [`%c ${content} `, `color: ${color}; font-weight: bold; border: 1px solid ${color}; border-radius: 2px;`];
const badge = (name: string, bg: string = 'transparent', other: string = '') => [`%c ${name} `, `background-color: ${bg}; color: #202020; border-radius: 2px; ${other}`];
const noop = () => undefined;
const asyncAfter = (fn) => (self, args, resultP) => resultP.then((result) => fn(self, args, result));
const asyncGroupEnd = asyncAfter(() => console.groupEnd());

/**
 * Takes an object with before and after (method execution) functions to execute, and
 * returns a method decorator.
 */
export function traced({ before = noop, after = noop }: TraceLoggerParams) {

    return function(target, key, descriptor) {

        if (environment.production) {
            return descriptor;
        }

        descriptor = descriptor === undefined
            ? Object.getOwnPropertyDescriptor(target, key)
            : descriptor;

        const originalMethod = descriptor.value;

        descriptor.value = function(...args) {
            try {
                before(this, args);
            } catch (e) {
                console.warn(`Error in 'before' TraceLogger:`, e);
            }
            const result = originalMethod.apply(this, args);
            try {
                after(this, args, result);
            } catch (e) {
                console.warn(`Error in 'after' TraceLogger:`, e);
            }
            return result;
        };

        return descriptor;
    };
}

const colors = {
    chainChain: '#516afd',
    chainMap: '#1ba28a',
    mapMap: '#924961',
    red: '#b94f52',
    orange: '#ae8204',
    green: '#25ac6a',
    gray: '#787878',
    blue: '#3c97e1',
    lime: '#92af00',
    limeDark: '#7c9000',
};

export const vmSetTrace: TraceLoggerParams = {
    before: (_, [revision]) => {
        console.groupCollapsed(...badge('VM-Service :: set revision', colors.lime));
        console.log(revision);
        console.trace();
        console.groupEnd();
    },
};

export const vmTransformTrace: TraceLoggerParams = {
    before: () => {
        console.groupCollapsed(...badge('VM-Service :: transform registration', colors.lime));
        console.trace();
        console.groupEnd();
    },
};

export const vmApplyBufferTrace: TraceLoggerParams = {
    after: asyncAfter((_, __, modelChanged: boolean) => {
        const [content, styles] = transpose([
            badge('VM-Service :: buffer flushed', colors.lime),
            modelChanged
                ? colored('VM updated', colors.green)
                : colored('VM unchanged', colors.orange),
        ]);
        console.log(content.join(' %c '), ...intersperse('', styles));
    }),
};


export const unsetDetailSelectionTrace = {
    before: () => console.log(...badge('DPS-Service :: unset selection', colors.blue)),
};

export const setDetailSelectionTrace = {
    before: (_, [selection]: [DetailPaneSelection, boolean]) => {
        console.groupCollapsed(...badge('DPS-Service :: set selection', colors.blue), selection);
        console.trace();
        console.groupEnd();
    },
};

export const chainChainTrace: TraceLoggerParams = {
    before: (self, args) => console.group(...badge('MapperChain :: chain', colors.chainChain), `(${args[0]}/${args[1]}) on`, self.mappers),
    after: console.groupEnd,
};

export const mapperChainTrace: TraceLoggerParams = {
    before: (self, args) => console.log('Mapper :: chain', `(${args[0]}/${args[1]}) on`, { vm: self.vm, jam: self.jam }),
};

export const chainMapTrace: TraceLoggerParams = {
    before: (self) => console.group(...badge('MapperChain :: map children', colors.chainMap), `on`, self.mappers),
    after: asyncGroupEnd,
};

export const mapperMapTrace: TraceLoggerParams = {
    before: (self) => console.groupCollapsed(...badge('Mapper :: map', colors.mapMap), { vm: self.vm, jam: self.jam }),
    after: asyncGroupEnd,
};

export const vmConversionTrace: TraceLoggerParams = {
    before: () => console.groupCollapsed(...badge('VmConverter :: process', colors.green, 'font-weight: bold')),
    after: asyncGroupEnd,
};

export const mapperAttributesTrace: TraceLoggerParams = {

    after: (self, args, result: AttributeMapReport[]) => {

        const vmKeyLength = Math.max(...result.map(({ vmKey }) => vmKey.length));
        const jamKeyLength = Math.max(...result.map(({ jamKey }) => jamKey.length));

        console.group(`Attributes`);

        for (const { vmKey, vmValue, jamKey, jamValue, skipped, changed } of result) {
            if (skipped) {
                console.log(...colored(` Skipped `, colors.red), `Vm.${vmKey.padEnd(vmKeyLength)} => Jam.${jamKey.padEnd(jamKeyLength)} = ${JSON.stringify(jamValue)} <x ${JSON.stringify(vmValue)}`);
            } else if (changed) {
                console.log(...colored(` Written `, colors.green), `Vm.${vmKey.padEnd(vmKeyLength)} => Jam.${jamKey.padEnd(jamKeyLength)} = ${JSON.stringify(jamValue)} <= ${JSON.stringify(vmValue)}`);
            } else {
                console.log(...colored(`Unchanged`, colors.gray), `Vm.${vmKey.padEnd(vmKeyLength)} => Jam.${jamKey.padEnd(jamKeyLength)} = ${JSON.stringify(vmValue)}`);
            }
        }

        console.groupEnd();
    },
};

export const immutableRelationsTrace: TraceLoggerParams = {
    after: asyncAfter((self, [maps]: [RelationMap[]], results: ModelMatchResult[]) => {

        console.group('Immutable relationships');

        if (isEmpty(maps)) {
            console.log('None.');
        }

        for (const [{ vmKey, jamKey }, result] of zip(maps, results)) {
            console.group(`Vm.${vmKey} => Jam.${jamKey}`);

            for (const { vm, jam } of result.matches) {
                console.log(...colored('Matched', colors.gray), [vm, jam]);
            }
            for (const vm of result.unmatched.vms) {
                console.log(...colored(' Added ', colors.green), vm);
            }
            for (const jam of result.unmatched.jams) {
                console.log(...colored('Removed', colors.red), jam);
            }

            console.groupEnd();
        }

        console.groupEnd();
    }),
};

export const chainedRelationsTrace: TraceLoggerParams = {

    before: (self, [records]: [MapperChainRecord[]]) => {

        console.group('Chained relationships');

        if (isEmpty(records)) {
            console.log('None.');
        }

        for (const { relation: { vmKey, jamKey }, matchResult: result } of records) {

            console.group(`Vm.${vmKey} => Jam.${jamKey}`);

            for (const { vm, jam } of result.matches) {
                console.log(...colored('Matched', colors.gray), [vm, jam]);
            }
            for (const vm of result.unmatched.vms) {
                console.log(...colored(' Added ', colors.green), vm);
            }
            for (const jam of result.unmatched.jams) {
                console.log(...colored('Removed', colors.red), jam);
            }

            console.groupEnd();
        }
    },

    after: asyncGroupEnd,
};
