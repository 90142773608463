import { Injectable } from '@angular/core';
import { TrainingSchema } from '@funxtion/ng-funxtion-api-client';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class TrainingSchemaService {

    constructor() {
    }

    publishTrainingSchema(trainingSchema: TrainingSchema): Observable<TrainingSchema> {
        trainingSchema.publishedAt = new Date();
        return trainingSchema.save();
    }

    unpublishTrainingSchema(trainingSchema: TrainingSchema): Observable<TrainingSchema> {
        trainingSchema.publishedAt = null;
        return trainingSchema.save();
    }


}
