import { IExerciseGroup, IExerciseGroupType, ITrainingExercise } from "@app-types/vm/vm.common.types";
import {
    DetailPaneExerciseGroup,
    DetailPaneNone,
    DetailPaneSelection,
    DetailPaneSelectionType,
    DetailPaneTrainingExercise,
} from "@app-types/training/editor/detail-pane-selection.types";

/**
 * The None detail selection. It signifies that no item has been selected for detail pane presentation.
 */
export const NONE_DETAIL_SELECTION = Object.freeze<DetailPaneNone>({ type: DetailPaneSelectionType.None });

// ------------------------------------------------------------------------------
//      Factory functions
// ------------------------------------------------------------------------------

export const trainingExerciseSelection = (
    value: ITrainingExercise,
    multipleSetsAllowed: boolean,
    groupType?: IExerciseGroupType
): DetailPaneTrainingExercise => ({
    value, type: DetailPaneSelectionType.TrainingExercise, context: { multipleSetsAllowed, groupType },
});

export const exerciseGroupSelection = (value: IExerciseGroup): DetailPaneExerciseGroup => ({
    value, type: DetailPaneSelectionType.ExerciseGroup,
});

// ------------------------------------------------------------------------------
//      Assertions & type narrowing
// ------------------------------------------------------------------------------

export const isNoneSelection = (selection: DetailPaneSelection): selection is DetailPaneNone => {
    return selection.type === DetailPaneSelectionType.None;
};

export const isExerciseGroupSelection = (selection: DetailPaneSelection): selection is DetailPaneExerciseGroup => {
    return selection.type === DetailPaneSelectionType.ExerciseGroup;
};

export const isTrainingExerciseSelection = (selection: DetailPaneSelection): selection is DetailPaneTrainingExercise => {
    return selection.type === DetailPaneSelectionType.TrainingExercise;
};
