import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { Goal } from "@funxtion/ng-funxtion-api-client";

@Pipe({
    name: 'goalColumn',
})
export class GoalColumnPipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) {
    }


    transform(value: Goal): SafeHtml {
        if (!value) {
            return '';
        }
        return this.sanitizer.bypassSecurityTrustHtml(`
            <span class='goal-column' style="background-color: var(--color-goal-${value.type});">${value.name}</span>
        `);
    }

}
