import { Injectable } from '@angular/core';
import {
    BodySection,
    Equipment,
    Exercise,
    ExerciseCategory,
    FitnessExeriseGroupType,
    FitnessTrainingType,
    FunxtionApiClientService,
    Gender,
    Goal,
    Tag,
    GroupType,
    Level,
    Location,
    MuscleGroup,
    Phase,
    TrainingSchemaType,
    WorkoutCategory,
    Country
} from '@funxtion/ng-funxtion-api-client';
import { map as rxMap } from 'rxjs/operators';
import {
    ExerciseFilter,
    ExerciseFilterCache,
    ExerciseFilterOptions,
    ExercisePage,
    ScalarExerciseFilters
} from '@app-types/training/editor/exercise-selector-dialog.types';
import { forkJoin } from 'rxjs';
import { getModels, toApiFilter } from '@app-components/training/editor/exercise-selector/exercise-selector.pure';
import { IExerciseGroupType } from '@app-types/vm/vm.common.types';
import { SchemaConverterService } from '@app-services/converter-services/schema-converter/schema-converter.service';
import { IPhase } from '@app-types/vm/vm.schema.types';
import { equals, isNil } from 'ramda';
import { JsonApiQueryData } from 'angular2-jsonapi';
import {
    showBodySection,
    showExerciseGroupType,
    showGender,
    showGoal,
    showLevel,
    showLocation,
    showTrainingSchemaType,
    showWorkoutCategory,
    showTag,
    showCountry
} from '@app-services/conversion-services/immutable-conversion/generic-api-model-converters';
import { IBodySection, IGender, IGoal, ILevel, ILocation, ITrainingSchemaType, ITag, ICountry } from '@app-types/vm/vm.immutable.types';
import { IWorkoutCategory } from '@app-types/vm/vm.workout.types';
import { asPromise } from '@app-helpers/api-client.helpers';

// Note: do not delete 'unused' function signature overloads in this class.

/**
 * Shared repository of models that are considered immutable within the scope of this app.
 * The service provides methods to get promises for single models, collections and transformed data.
 */
@Injectable({ providedIn: 'root' })
export class ImmutableResourcesService {
    // ------------------------------------------------------------------------------
    //      Private data repositories
    // ------------------------------------------------------------------------------

    private groupTypes: Promise<GroupType[]>;
    private groupTypesWorkout: Promise<GroupType[]>;
    private groupTypesTrainingSchema: Promise<GroupType[]>;
    private phases: Promise<Phase[]>;
    private genders: Promise<Gender[]>;
    private goals: Promise<Goal[]>;
    private tags: Promise<Tag[]>;
    private trainingSchemaTypes: Promise<TrainingSchemaType[]>;
    private levels: Promise<Level[]>;
    private bodySections: Promise<BodySection[]>;
    private locations: Promise<Location[]>;
    private workoutCategories: Promise<WorkoutCategory[]>;
    private countries: Promise<Country[]>;

    private exerciseFilterOptions: Promise<ExerciseFilterOptions>;
    private exerciseFilterCaches: ExerciseFilterCache[] = [];

    // ------------------------------------------------------------------------------
    //      Lifecycle
    // ------------------------------------------------------------------------------

    constructor(private schemaConverter: SchemaConverterService, private funxtion: FunxtionApiClientService) {}

    /**
     * Get a page of Exercise models for given filter and page number.
     */
    public async getExercisePage(fullFilter: ExerciseFilter, page: number = 1): Promise<ExercisePage> {
        const filter = toApiFilter(fullFilter);
        const filterCache = this.getCacheForFilter(filter);

        if (isNil(filterCache)) {
            return await this.createResultSet(filter, page);
        }

        const exercisePage = filterCache.pages[page];

        if (isNil(exercisePage)) {
            return await this.addPageToFilterCache(filterCache, page);
        }

        return exercisePage;
    }

    /**
     * Get a promise for a list of exercise-group-types.
     * Returns JSON API models when called without arguments or with `false`,
     * returns `IExerciseGroupType` view-models when called with `true`.
     */
    public async getPhases(asViewModels?: false): Promise<Phase[]>;
    // noinspection JSUnusedGlobalSymbols
    public async getPhases(asViewModels: true): Promise<IPhase[]>;
    public async getPhases(asViewModels: boolean = false): Promise<Phase[] | IPhase[]> {
        if (!this.phases) {
            this.phases = this.fetchPhases();
        }

        const phases = await this.phases;

        return asViewModels ? phases.map(phase => this.schemaConverter.apiModelConverter.showPhase(phase)) : phases;
    }

    /**
     * Get a promise for a list of exercise-group-types.
     * Returns JSON API models when called without arguments or with `false`,
     * returns `IExerciseGroupType` view-models when called with `true`.
     */
    public async getGroupTypes(asViewModels?: false, trainingType?: FitnessTrainingType): Promise<GroupType[]>;
    public async getGroupTypes(asViewModels: true, trainingType?: FitnessTrainingType): Promise<IExerciseGroupType[]>;
    public async getGroupTypes(asViewModels = false, trainingType?: FitnessTrainingType): Promise<GroupType[] | IExerciseGroupType[]> {
        if (trainingType) {
            if (trainingType === FitnessTrainingType.TRAINING_SCHEMA) {
                if (!this.groupTypesTrainingSchema) {
                    this.groupTypesTrainingSchema = this.fetchAllowedForGroupTypes(trainingType);
                }

                const groupTypesTrainingSchema = await this.groupTypesTrainingSchema;

                if (asViewModels) {
                    return (
                        groupTypesTrainingSchema
                            // NOTE: FitnessExeriseGroupType.MULTIPLE_EXERCISES is being deprecated and shouldn't be added anymore in the portal
                            .filter(type => type.type !== FitnessExeriseGroupType.MULTIPLE_EXERCISES)
                            .map(type => showExerciseGroupType(type))
                    );
                } else {
                    return groupTypesTrainingSchema;
                }
            } else if (trainingType === FitnessTrainingType.WORKOUT) {
                if (!this.groupTypesWorkout) {
                    this.groupTypesWorkout = this.fetchAllowedForGroupTypes(trainingType);
                }

                const groupTypesWorkout = await this.groupTypesWorkout;

                return asViewModels ? groupTypesWorkout.map(type => showExerciseGroupType(type)) : groupTypesWorkout;
            }
        } else {
            if (!this.groupTypes) {
                this.groupTypes = this.fetchGroupTypes();
            }

            const groupTypes = await this.groupTypes;

            return asViewModels ? groupTypes.map(type => showExerciseGroupType(type)) : groupTypes;
        }
    }

    /**
     * Returns a promise for the filter-options used within exercise-search and -selection components.
     */
    public getExerciseFilterOptions(): Promise<ExerciseFilterOptions> {
        if (!this.exerciseFilterOptions) {
            this.exerciseFilterOptions = this.fetchExerciseFilterOptions();
        }

        return this.exerciseFilterOptions;
    }

    /**
     * Returns a promise for the filter-options used within exercise-search and -selection components.
     */
    public async getGenders(asViewModels?: false): Promise<Gender[]>;
    public async getGenders(asViewModels: true): Promise<IGender[]>;
    public async getGenders(asViewModels: boolean = false): Promise<Gender[] | IGender[]> {
        if (!this.genders) {
            this.genders = this.fetchGenders();
        }

        const genders = await this.genders;

        return asViewModels ? genders.map(gender => showGender(gender)) : genders;
    }

    // noinspection JSUnusedGlobalSymbols
    public async getGoals(asViewModels?: false): Promise<Goal[]>;
    public async getGoals(asViewModels: true): Promise<IGoal[]>;
    public async getGoals(asViewModels: boolean = false): Promise<Goal[] | IGoal[]> {
        if (!this.goals) {
            this.goals = this.fetchGoals();
        }

        const goals = await this.goals;

        return asViewModels ? goals.map(goal => showGoal(goal)) : goals;
    }

    // returns a promise with the list of countries
    public async getCountries(asViewModels?: false): Promise<Country[]>;
    public async getCountries(asViewModels: true): Promise<ICountry[]>;
    public async getCountries(asViewModels: boolean = false): Promise<Country[] | ICountry[]> {
        if (!this.countries) {
            this.countries = this.fetchCountries();
        }

        const countries = await this.countries;

        return asViewModels ? countries.map(country => showCountry(country)) : countries;
    }

    // noinspection JSUnusedGlobalSymbols
    public async getLevels(asViewModels?: false): Promise<Level[]>;
    public async getLevels(asViewModels: true): Promise<ILevel[]>;
    public async getLevels(asViewModels: boolean = false): Promise<Level[] | ILevel[]> {
        if (!this.levels) {
            this.levels = this.fetchLevels();
        }

        const levels = await this.levels;

        return asViewModels ? levels.map(level => showLevel(level)) : levels;
    }

    // noinspection JSUnusedGlobalSymbols
    public async getBodySections(asViewModels?: false): Promise<BodySection[]>;
    public async getBodySections(asViewModels: true): Promise<IBodySection[]>;
    public async getBodySections(asViewModels: boolean = false): Promise<BodySection[] | IBodySection[]> {
        if (!this.bodySections) {
            this.bodySections = this.fetchBodySections();
        }

        const bodySections = await this.bodySections;

        return asViewModels ? bodySections.map(bodySection => showBodySection(bodySection)) : bodySections;
    }

    // noinspection JSUnusedGlobalSymbols
    public async getLocations(asViewModels?: false): Promise<Location[]>;
    public async getLocations(asViewModels: true): Promise<ILocation[]>;
    public async getLocations(asViewModels: boolean = false): Promise<Location[] | ILocation[]> {
        if (!this.locations) {
            this.locations = this.fetchLocations();
        }

        const locations = await this.locations;

        return asViewModels ? locations.map(location => showLocation(location)) : locations;
    }

    // noinspection JSUnusedGlobalSymbols
    public async getTags(asViewModels?: false): Promise<Tag[]>;
    public async getTags(asViewModels: true): Promise<Tag[]>;
    public async getTags(asViewModels: boolean = false): Promise<Tag[] | ITag[]> {
        if (!this.tags) {
            this.tags = this.fetchTags();
        }

        const tags = await this.tags;

        return asViewModels ? tags.map(tag => showTag(tag)) : tags;
    }

    // noinspection JSUnusedGlobalSymbols
    public async getWorkoutCategories(asViewModels?: false): Promise<WorkoutCategory[]>;
    public async getWorkoutCategories(asViewModels: true): Promise<IWorkoutCategory[]>;
    public async getWorkoutCategories(asViewModels: boolean = false): Promise<WorkoutCategory[] | IWorkoutCategory[]> {
        if (!this.workoutCategories) {
            this.workoutCategories = this.fetchWorkoutCategories();
        }

        const workoutCategories = await this.workoutCategories;

        return asViewModels ? workoutCategories.map(workoutCategory => showWorkoutCategory(workoutCategory)) : workoutCategories;
    }

    // ------------------------------------------------------------------------------
    //      Lazy fetch functions
    // ------------------------------------------------------------------------------
    //
    //      These functions do the actual fetch, and return their result.

    private fetchPhases(): Promise<Phase[]> {
        return asPromise(this.funxtion.dataServices.trainingPhaseService.phases$);
    }

    private fetchGroupTypes(): Promise<GroupType[]> {
        return asPromise(this.funxtion.dataServices.groupService.types$);
    }

    private fetchAllowedForGroupTypes(trainingType: FitnessTrainingType): Promise<GroupType[]> {
        return asPromise(
            this.funxtion.dataServices.groupService.groupTypesWithOptions({
                filter: {
                    'allowed-for': trainingType
                }
            })
        );
    }

    private async fetchGenders(): Promise<Gender[]> {
        const queryData = await this.funxtion.datastore.findAll(Gender).toPromise();
        return queryData.getModels();
    }

    private async fetchGoals(): Promise<Goal[]> {
        const queryData = await this.funxtion.datastore.findAll(Goal).toPromise();
        return queryData.getModels();
    }

    private async fetchCountries(): Promise<Country[]> {
        const queryData = await this.funxtion.datastore.findAll(Country,  { page: { size: 1000, number: 1 } }).toPromise();
        return queryData.getModels();
    }

    private async fetchTrainingSchemaTypes(): Promise<TrainingSchemaType[]> {
        const queryData = await this.funxtion.datastore.findAll(TrainingSchemaType).toPromise();
        return queryData.getModels();
    }

    private async fetchLevels(): Promise<Level[]> {
        const queryData = await this.funxtion.datastore.findAll(Level).toPromise();
        return queryData.getModels();
    }

    private async fetchBodySections(): Promise<BodySection[]> {
        const queryData = await this.funxtion.datastore.findAll(BodySection).toPromise();
        return queryData.getModels();
    }

    private async fetchLocations(): Promise<Location[]> {
        const queryData = await this.funxtion.datastore.findAll(Location).toPromise();
        return queryData.getModels();
    }

    private async fetchTags(): Promise<Tag[]> {
        const queryData = await this.funxtion.datastore.findAll(Tag).toPromise();
        return queryData.getModels();
    }

    private async fetchWorkoutCategories(): Promise<WorkoutCategory[]> {
        const queryData = await this.funxtion.datastore.findAll(WorkoutCategory).toPromise();
        return queryData.getModels();
    }

    /**
     * Fetches the models from API that serve as form options for exercise filtering.
     */
    private fetchExerciseFilterOptions(): Promise<ExerciseFilterOptions> {
        const params = { page: { size: 1000, number: 1 } };

        return forkJoin(
            this.funxtion.datastore.findAll(ExerciseCategory, params).pipe(rxMap(getModels)),
            this.funxtion.datastore.findAll(Equipment, params).pipe(rxMap(getModels)),
            this.funxtion.datastore.findAll(MuscleGroup, params).pipe(rxMap(getModels))
        )
            .pipe(rxMap(([x, y, z]) => ({ exerciseCategories: x, equipmentModels: y, muscleGroups: z })))
            .toPromise();
    }

    private fetchExercises(filter: ScalarExerciseFilters, page: number): Promise<ExercisePage> {
        return this.funxtion.datastore
            .findAll(Exercise, {
                filter,
                page: { number: page, size: 24 },
                include: 'equipment.measurements'
            })
            .pipe(
                rxMap<JsonApiQueryData<Exercise>, ExercisePage>(response => ({
                    exercises: response.getModels(),
                    pageMetadata: response.getMeta().meta
                }))
            )
            .toPromise();
    }

    // ------------------------------------------------------------------------------
    //      Exercises and local (class) storage logic
    // ------------------------------------------------------------------------------
    //
    //      Note the in-place approach here! These functions maintain a mutable prop
    //      exerciseFilterResultSets. It caches exercises that were fetched earlier
    //      together with their corresponding request information, ie. filters
    //      and page number.
    //      The result-set structure is set up in such a way that it is fast to
    //      query and mutate.

    private getCacheForFilter(filter: ScalarExerciseFilters): ExerciseFilterCache | null {
        for (const filterCache of this.exerciseFilterCaches) {
            if (equals(filterCache.filter, filter)) {
                return filterCache;
            }
        }

        return null;
    }

    private async addPageToFilterCache(filterCache: ExerciseFilterCache, page: number): Promise<ExercisePage> {
        const exercisePage = await this.fetchExercises(filterCache.filter, page);

        filterCache.pages[page] = exercisePage;
        return exercisePage;
    }

    private async createResultSet(filter: ScalarExerciseFilters, page: number = 1): Promise<ExercisePage> {
        const exercisePage = await this.fetchExercises(filter, page);
        const filterCache: ExerciseFilterCache = { filter, pages: { [page]: exercisePage } };

        this.exerciseFilterCaches.push(filterCache);
        return exercisePage;
    }

    public async getTrainingSchemaType(asViewModels?: false): Promise<TrainingSchemaType[]>;
    public async getTrainingSchemaType(asViewModels: true): Promise<ITrainingSchemaType[]>;
    public async getTrainingSchemaType(asViewModels: boolean = false): Promise<TrainingSchemaType[] | ITrainingSchemaType[]> {
        if (!this.trainingSchemaTypes) {
            this.trainingSchemaTypes = this.fetchTrainingSchemaTypes();
        }

        const trainingSchemaTypes = await this.trainingSchemaTypes;

        return asViewModels
            ? trainingSchemaTypes.map((trainingSchemaType: TrainingSchemaType) => showTrainingSchemaType(trainingSchemaType))
            : trainingSchemaTypes;
    }
}
