import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { BUTTON_VARIANT } from "src/app/modules/shared/inputConfig";
import { ConfirmDialogData } from '../../../modules/shared/interfaces/confirm-dialog';

@Component({
    selector: "app-confirm-dialog",
    templateUrl: "./confirm-dialog.component.html",
    styleUrls: ["./confirm-dialog.component.scss"],
})
export class ConfirmDialogComponent {
    BUTTON_VARIANT = BUTTON_VARIANT;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData,
        private matDialog: MatDialogRef<ConfirmDialogComponent>
    ) {
    }

    done(confirmed: boolean): void {
        this.matDialog.close({ confirmed });
    }
}
