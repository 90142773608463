import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { JsonApiModel } from "angular2-jsonapi";
import { isNil, prop } from 'ramda';

@Component({
    selector: 'app-model-select-search',
    templateUrl: './model-select-search.component.html',
    styleUrls: ['./model-select-search.component.scss'],
})
export class ModelSelectSearchComponent<T extends JsonApiModel> implements OnChanges, OnInit {

    @Input()
    models: T[];

    @Input()
    placeholder: string;

    @Input()
    control: AbstractControl;

    @Input()
    filterProperty: string = 'name';

    @Input()
    useNone = true;

    @Input()
    multiple = false;

    @Input()
    required = false;

    @Input()
    modelData;

    filterCtrl: FormControl = new FormControl();
    filtered: T[];

    constructor() {
    }

    ngOnChanges(): void {
        if (this.models) {
            this.filtered = this.models.slice();
        }
    }

    ngOnInit(): void {
        this.filterCtrl.valueChanges.subscribe((value: string) => {
            this.filterModels(value);
        });
    }

    protected filterModels(value: string): void {
        if (!value || value === '') {
            this.filtered = this.models.slice();
            return;
        }
        this.filtered = this.models.filter((model) => {
            return model[this.filterProperty].toLowerCase().indexOf(value.toLowerCase()) > -1;
        });
    }

    compareModels(
        a: JsonApiModel | null | undefined,
        b: JsonApiModel | null | undefined,
    ): boolean {
        return !isNil(a)
            && !isNil(b)
            && prop('id', a) === prop('id', b);
    }
}
