import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";

@Pipe({
    name: 'intensityColumn',
})
export class IntensityColumnPipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer, private translate: TranslateService) {
    }

    transform(intensity: number): SafeHtml {
        if (!intensity) {
            return '';
        }
        const intensitySlug = this.getIntensityLevel(intensity);
        return this.sanitizer.bypassSecurityTrustHtml(`
            <span class='intensity-column'>
                <span class='intensity-value' style="background-color: var(--color-intensity-${intensitySlug}-effort);">${intensity}</span>
                ${this.translate.instant('common.intensities.' + intensitySlug)}
            </span>
        `);
    }

    getIntensityLevel(intensity: number) {
        switch (intensity) {
            case 1:
                return 'very-light';
            case 2:
            case 3:
                return 'light';
            case 4:
            case 5:
            case 6:
                return 'moderate';
            case 7:
            case 8:
                return 'vigorous';
            case 9:
                return 'very-hard';
            case 10:
                return 'max';
        }
    }
}
