import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from "@angular/platform-browser";

@Pipe({
    name: 'onlineStatus'
})
export class OnlineStatusPipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) {

    }

    transform(value: Date, args?: any): any {
        const now = new Date();
        const difference = now.getTime() - value.getTime();
        const hoursDiff = difference / 1000 / 60 / 60;
        const indicatorClass = hoursDiff < 24
            ? hoursDiff < 1
                ? 'online'
                : 'away'
            : 'offline';
        return this.sanitizer.bypassSecurityTrustHtml(`<span class="online-indicator ${indicatorClass}"></span>`);
    }

}
