import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, SatDatepickerModule } from "saturn-datepicker";
import {
    DefaultComponent,
    DefaultFullHeightComponent,
    DefaultNoHeaderComponent,
    DefaultNoHeaderFullWidthComponent
} from "src/app/components/layouts/default/default.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';

import { AgePipe } from "@app-pipes/age/age.pipe";
import { ColumnIconComponent } from "@app-components/column-icon/column-icon.component";
import { CommonModule } from "@angular/common";
import { ConfirmDialogComponent } from "src/app/components/dialogs/confirm-dialog/confirm-dialog.component";
import { CreateMemberDialogComponent } from "src/app/components/dialogs/create-member-dialog/create-member-dialog.component";
import { DateDiffPipe } from "src/app/pipes/date-diff/date-diff.pipe";
import { DeleteModelDialogComponent } from "@app-components/dialogs/delete-model-dialog/delete-model-dialog.component";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { DuplicateModelDialogComponent } from "@app-components/dialogs/duplicate-model-dialog/duplicate-model-dialog.component";
import { DurationColumnPipe } from "@app-pipes/column-pipes/duration-column/duration-column.pipe";
import { EmptyStateComponent } from "@app-components/empty-state/empty-state.component";
import { ExerciseGroupComponent } from "@app-components/training/editor/exercise-group/exercise-group.component";
import { ExerciseGroupFormComponent } from "@app-components/training/editor/exercise-group-form/exercise-group-form.component";
import { ExerciseSelectorButtonComponent } from "@app-components/training/editor/exercise-selector-button/exercise-selector-button.component";
import { ExerciseSelectorComponent } from "@app-components/training/editor/exercise-selector/exercise-selector.component";
import { ExerciseSetComponent } from "@app-components/training/editor/exercise-set/exercise-set.component";
import { ExerciseSetTargetComponent } from '@app-components/training/editor/exercise-set-target/exercise-set-target.component';
import { ExerciseViewComponent } from "../../views/training/exercises/exercise-view/exercise-view.component";
import { FilterComponent } from "src/app/components/filter/filter.component";
import { FilterDialogComponent } from "src/app/components/dialogs/filter-dialog/filter-dialog.component";
import { FormatDatePipe } from "@app-pipes/format-date.pipe";
import { FormatFileSizePipe } from "src/app/pipes/format-file-size/format-file-size.pipe";
import { FormatTimePipe } from "src/app/pipes/format-time/format-time.pipe";
import { FunxtionApiClientModule } from "@funxtion/ng-funxtion-api-client";
import { FunxtionButtonComponent } from './components/funxtion-button/funxtion-button.component';
import { FunxtionCheckboxComponent } from './components/funxtion-checkbox/checkbox.component';
import { FunxtionFormComponent } from './components/funxtion-form/form.component';
import { FunxtionFormControlErrorsComponent } from './components/funxtion-form-control-errors/form-control-errors.component';
import { FunxtionFormFieldDirective } from './directives/funxtion-form-field/funxtion-form-field.directive';
import { FunxtionIconComponent } from './components/funxtion-icon/icon.component';
import { FunxtionImageComponent } from './components/funxtion-image/image.component';
import { FunxtionInputDirective } from './directives/funxtion-input/funxtion-input.directive';
import { FunxtionRadiobuttonComponent } from './components/funxtion-radiobutton/radiobutton.component';
import { GendersColumnPipe } from "@app-pipes/column-pipes/genders-column/genders-column.pipe";
import { GetTextInputDialogComponent } from "src/app/components/dialogs/get-text-input-dialog/get-text-input-dialog.component";
import { GoalColumnPipe } from "@app-pipes/column-pipes/goal-column/goal-column.pipe";
import { HeaderContentComponent } from '@app-components/header-content/header-content.component';
import { ImageListItemComponent } from "@app-components/training/editor/image-list-item/image-list-item.component";
import { ImgDefaultDirective } from "src/app/directives/img-default/img-default.directive";
import { InputTimeComponent } from "src/app/components/input-time/input-time.component";
import { IntensityColumnPipe } from "@app-pipes/column-pipes/intensity-column/intensity-column.pipe";
import { LevelColumnPipe } from "@app-pipes/column-pipes/level-column/level-column.pipe";
import { LoadingSpinnerModule } from "src/app/components/loading-spinner/loading-spinner.module";
import { LocationColumnPipe } from "@app-pipes/column-pipes/location-column/location-column.pipe";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonModule } from "@angular/material/button";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatChipsModule } from "@angular/material/chips";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatDividerModule } from "@angular/material/divider";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatNativeDateModule } from "@angular/material/core";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSliderModule } from "@angular/material/slider";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { MatTabsModule } from "@angular/material/tabs";
import { MatTooltipModule } from "@angular/material/tooltip";
import { ModelSelectSearchComponent } from "@app-components/model-select-search/model-select-search.component";
import { NgModule, ModuleWithProviders } from "@angular/core";
import { NgScrollbarModule } from 'ngx-scrollbar';
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { OnlineStatusPipe } from "@app-pipes/online-status/online-status.pipe";
import { OwnerPickerComponent } from '../training-schema/components/owner-picker/owner-picker.component';
import { PlanDurationColumnPipe } from "@app-pipes/column-pipes/plan-duration-column/plan-duration-column.pipe";
import { PlanTableComponent } from "@app-components/tables/plan-table/plan-table.component";
import { PortalModule } from "@angular/cdk/portal";
import { ProgressRingComponent } from "@app-components/progress-ring/progress-ring.component";
import { Provider } from "@angular/compiler/src/compiler_facade_interface";
import { RouterModule } from "@angular/router";
import { SecondaryLabelComponent } from './components/secondary-label/secondary-label.component';
import { SecondsToTimePipe } from "src/app/pipes/seconds-to-time/seconds-to-time.pipe";
import { SelectTrainingPlanComponent } from "src/app/components/dialogs/select-training-plan/select-training-plan.component";
import { SideMenuComponent } from "src/app/components/menu/side-menu/side-menu.component";
import { SinglePageScrollComponent } from "src/app/components/layouts/single-page-scroll/single-page-scroll.component";
import { SliderModule } from "primeng/slider";
import { StackedTrainingImageComponent } from "@app-components/training/editor/stacked-training-image/stacked-training-image.component";
import { TableSearchFilterComponent } from "src/app/components/table-search-filter/table-search-filter.component";
import { TrainingExerciseFormComponent } from "@app-components/training/editor/training-exercise-form/training-exercise-form.component";
import { TrainingGroupComponent } from "@app-components/training/editor/training-group/training-group.component";
import { TrainingTableComponent } from "@app-components/tables/training-table/training-table.component";
import { TranslateModule } from "@ngx-translate/core";
import { VarDirective } from "src/app/directives/fx-var.directive";
import { ViewExerciseDialogComponent } from "@app-components/dialogs/training/exercises/view-exercise-dialog/view-exercise-dialog.component";
import { ViewPlanComponent } from '../../views/training/plans/view-plan/view-plan.component';
import { ViewTrainingGroupMultiComponent } from '../training-schema/components/view-training-group-multi/view-training-group-multi.component';
import { ViewTrainingGroupSingleComponent } from '../training-schema/components/view-training-group-single/view-training-group-single.component';
import { ViewTrainingPlanWeekComponent } from '@app-components/training-plan/view-training-plan-week/view-training-plan-week.component';
import { VisibilitySelectComponent } from '@app-components/header-content/visibility-select/visibility-select.component';
import { WINDOW } from '@app-helpers/injection-tokens';
import { RolePipe } from './pipes/role/role.pipe';
import { DetailPaneSelectionService } from '@app-services/detail-pane-selection/detail-pane-selection.service';
import { SchemaLensService } from '@app-services/vm-lens-services/schema-lens-service/schema-lens.service';
import { ExerciseSetLensPipe } from "@funxtion/portal/shared";

// tslint:disable-next-line:variable-name
export const pipe_providers: Provider[] = [
    { provide: SecondsToTimePipe, useClass: SecondsToTimePipe },
    { provide: FormatTimePipe, useClass: FormatTimePipe },
    { provide: FormatDatePipe, useClass: FormatDatePipe },
    { provide: FormatFileSizePipe, useClass: FormatFileSizePipe },
    { provide: DateDiffPipe, useClass: DateDiffPipe },
    { provide: OnlineStatusPipe, useClass: OnlineStatusPipe },
    { provide: DurationColumnPipe, useClass: DurationColumnPipe },
];

@NgModule({
    declarations: [
        DefaultComponent,
        DefaultNoHeaderComponent,
        DefaultNoHeaderFullWidthComponent,
        DefaultFullHeightComponent,
        SideMenuComponent,
        ConfirmDialogComponent,
        CreateMemberDialogComponent,
        SinglePageScrollComponent,
        TableSearchFilterComponent,
        SecondsToTimePipe,
        GoalColumnPipe,
        HeaderContentComponent,
        IntensityColumnPipe,
        GendersColumnPipe,
        FormatTimePipe,
        FormatDatePipe,
        DateDiffPipe,
        InputTimeComponent,
        FilterDialogComponent,
        FilterComponent,
        GetTextInputDialogComponent,
        SelectTrainingPlanComponent,
        VarDirective,
        ImgDefaultDirective,
        TrainingExerciseFormComponent,
        ExerciseGroupFormComponent,
        ExerciseSetComponent,
        DurationColumnPipe,
        LevelColumnPipe,
        PlanDurationColumnPipe,
        LocationColumnPipe,
        ColumnIconComponent,
        EmptyStateComponent,
        ExerciseSetTargetComponent,
        TrainingGroupComponent,
        ImageListItemComponent,
        ExerciseGroupComponent,
        ExerciseSelectorComponent,
        ExerciseSelectorButtonComponent,
        ModelSelectSearchComponent,
        AgePipe,
        LevelColumnPipe,
        LocationColumnPipe,
        ExerciseSelectorComponent,
        ExerciseSelectorButtonComponent,
        ModelSelectSearchComponent,
        TrainingTableComponent,
        ExerciseViewComponent,
        StackedTrainingImageComponent,
        DeleteModelDialogComponent,
        DuplicateModelDialogComponent,
        VisibilitySelectComponent,
        ViewExerciseDialogComponent,
        ProgressRingComponent,
        PlanTableComponent,
        OnlineStatusPipe,
        ExerciseSetLensPipe,
        FunxtionButtonComponent,
        FunxtionCheckboxComponent,
        FunxtionFormComponent,
        FunxtionFormControlErrorsComponent,
        FunxtionIconComponent,
        FunxtionImageComponent,
        FunxtionRadiobuttonComponent,
        SecondaryLabelComponent,
        FunxtionFormFieldDirective,
        FunxtionInputDirective,
        ViewTrainingGroupSingleComponent,
        ViewTrainingGroupMultiComponent,
        ViewPlanComponent,
        ViewTrainingPlanWeekComponent,
        OwnerPickerComponent,
        RolePipe
    ],
    imports: [
        MatButtonToggleModule,
        MatNativeDateModule,
        MatTabsModule,
        MatAutocompleteModule,
        MatSnackBarModule,
        CommonModule,
        DragDropModule,
        PortalModule,
        FormsModule,
        FunxtionApiClientModule,
        LoadingSpinnerModule,
        MatButtonModule,
        MatCardModule,
        MatCheckboxModule,
        MatChipsModule,
        MatDatepickerModule,
        MatDialogModule,
        MatDividerModule,
        MatFormFieldModule,
        MatFormFieldModule,
        MatGridListModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        MatPaginatorModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatSelectModule,
        MatSidenavModule,
        MatSliderModule,
        MatSortModule,
        MatTableModule,
        MatTooltipModule,
        MatExpansionModule,
        NgScrollbarModule,
        NgxMatSelectSearchModule,
        ReactiveFormsModule,
        LoadingSpinnerModule,
        RouterModule,
        SatDatepickerModule,
        SliderModule,
        TranslateModule,
    ],
    exports: [
        CommonModule,
        OwnerPickerComponent,
        ViewTrainingPlanWeekComponent,
        ViewPlanComponent,
        MatButtonToggleModule,
        MatNativeDateModule,
        MatTabsModule,
        MatAutocompleteModule,
        RouterModule,
        DefaultNoHeaderFullWidthComponent,
        DefaultFullHeightComponent,
        ConfirmDialogComponent,
        ExerciseViewComponent,
        CreateMemberDialogComponent,
        DateDiffPipe,
        DefaultComponent,
        DefaultNoHeaderComponent,
        DragDropModule,
        PortalModule,
        MatExpansionModule,
        ExerciseGroupFormComponent,
        ExerciseSetComponent,
        FilterComponent,
        FilterDialogComponent,
        FormatDatePipe,
        FormatTimePipe,
        GetTextInputDialogComponent,
        HeaderContentComponent,
        ImgDefaultDirective,
        InputTimeComponent,
        LoadingSpinnerModule,
        MatButtonModule,
        MatCardModule,
        MatCheckboxModule,
        MatChipsModule,
        MatDatepickerModule,
        MatDialogModule,
        MatDividerModule,
        MatFormFieldModule,
        MatGridListModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        MatPaginatorModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatSelectModule,
        MatSliderModule,
        MatSortModule,
        MatTableModule,
        MatTooltipModule,
        NgScrollbarModule,
        NgxMatSelectSearchModule,
        FormsModule,
        ReactiveFormsModule,
        SatDatepickerModule,
        SecondsToTimePipe,
        GendersColumnPipe,
        IntensityColumnPipe,
        GoalColumnPipe,
        SelectTrainingPlanComponent,
        SinglePageScrollComponent,
        SliderModule,
        TableSearchFilterComponent,
        TrainingExerciseFormComponent,
        VarDirective,
        DurationColumnPipe,
        LevelColumnPipe,
        PlanDurationColumnPipe,
        LocationColumnPipe,
        DeleteModelDialogComponent,
        DuplicateModelDialogComponent,
        ColumnIconComponent,
        EmptyStateComponent,
        ExerciseSetTargetComponent,
        TrainingGroupComponent,
        ImageListItemComponent,
        ExerciseGroupComponent,
        ExerciseSelectorComponent,
        ExerciseSelectorButtonComponent,
        ModelSelectSearchComponent,
        TrainingTableComponent,
        StackedTrainingImageComponent,
        VisibilitySelectComponent,
        AgePipe,
        ViewExerciseDialogComponent,
        ProgressRingComponent,
        PlanTableComponent,
        OnlineStatusPipe,
        TranslateModule,
        MatSnackBarModule,
        FunxtionButtonComponent,
        FunxtionCheckboxComponent,
        FunxtionFormComponent,
        FunxtionFormControlErrorsComponent,
        FunxtionIconComponent,
        FunxtionImageComponent,
        FunxtionRadiobuttonComponent,
        SecondaryLabelComponent,
        FunxtionFormFieldDirective,
        FunxtionInputDirective,
        ViewTrainingGroupSingleComponent,
        ViewTrainingGroupMultiComponent,
        RolePipe,
        ExerciseSetLensPipe
    ],
    providers: [
        ...pipe_providers,
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE],
        },
        { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
        {
            provide: WINDOW,
            useFactory: () => window
        }
    ]
})
export class SharedModule {
    // static forRoot(): ModuleWithProviders {
    //     return {
    //         ngModule: SharedModule
    //     };
    // }
}
