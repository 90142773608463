import { AbstractModelMapper } from '@app-services/conversion-services/AbstractModelMapper';
import { AttributeMap, RelationMap } from '@app-types/vm-conversion/vm-conversion.types';
import { IExerciseSet } from '@app-types/vm/vm.common.types';
import { ExerciseSet, Target } from '@funxtion/ng-funxtion-api-client';


export class ExerciseSetMapper extends AbstractModelMapper<IExerciseSet, ExerciseSet> {

    public readonly attributes: AttributeMap<IExerciseSet, ExerciseSet> = {
        position: 'position',
        restAfterwardsInSeconds: 'restAfterwardsInSeconds',
    };

    public readonly relationships: RelationMap<IExerciseSet, ExerciseSet>[] = [
        {
            mutable: true,
            type: 'many',
            vmKey: 'targets',
            jamKey: 'targets',
            jamClass: Target,
            inverse: {
                key: 'set',
                type: 'one',
            },
        },
    ];
}
