import { EmailVerificationVerify, FunxtionApiClientService } from '@funxtion/ng-funxtion-api-client';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: "app-confirm-email",
    templateUrl: "./confirm-email.component.html",
    styleUrls: ["../set-password/set-password.component.scss"],
})
export class ConfirmEmailComponent implements OnInit {

    signature: string;
    expires: string;
    confirmed = false;
    error = false;
    timeout = false;

    constructor(
        public funxtion: FunxtionApiClientService,
        protected route: ActivatedRoute,
    ) {
    }

    confirmEmail() {
        this.timeout = new Date(parseInt(this.expires, 10) * 1000) < new Date();

        if (!this.timeout) {
            const verify = this.funxtion.datastore.createRecord(EmailVerificationVerify, {
                signature: this.signature,
                expires: this.expires,
            });

            verify.save().toPromise()
                .then(() => {
                    this.confirmed = true;
                })
                .catch(() => {
                    this.error = true;
                });
        }
    }

    ngOnInit() {
        this.route.queryParams.subscribe(params => {
            this.signature = params["signature"];
            this.expires = params["expires"];
            if (this.signature && this.expires) {
                this.confirmEmail();
            }
        });
    }
}
