import { PortalEnvironmentVariables } from './EnvironmentVariables';

export const environment: PortalEnvironmentVariables = {
    production: true,
    acceptance: false,
    trainingPlanner: false,
    scope: 'dashboard',

    self: {
        schema: 'http',
        hostname: 'localhost:4200'
    },

    /**
     * Will be updated runtime by EnvironmentService
     */
    environment: 'production',
    auth: {
        clientSecret: 'goodlife',
        clientId: '2'
    },

    api: {
        schema: 'https',
        hostname: 'test.api.funxtion.com',
        namespace: 'api/v2'
    },
    websockets: {
        hostname: 'test.api.funxtion.com',
        port: 6002,
        key: 'funxtion'
    },
    apiKeys: {
        bugsnag: '0160f62eb21bf255d5c8aceb4d2cd8e6'
    }
};
