import { all } from "ramda";
import { TutValues } from "@app-types/training/editor/time-under-tension.types";
import { isNumber } from "@app-helpers/narrowing.helpers";

/**
 * Captures the 4 integer-segments of a valid TUT compound value.
 * @see https://regex101.com/r/4QWNGC/5 A regex101 snippet with explanation on this regex and TUT validation in general.
 */
export const TUT_VALIDATION_REGEX = /^([1-9]\d*)-(0|[1-9]\d*)-([1-9]\d*)-(0|[1-9]\d*)$/;

/**
 * Object with helper functions for processing "Time under tension" (TUT) values.
 */
export const TUT = {

    /**
     * Parses a valid serialized TUT value into an array of integer segments.
     */
    parse: (tutString: string): TutValues | null => (

        TUT_VALIDATION_REGEX.test(tutString)
            ? TUT_VALIDATION_REGEX.exec(tutString).slice(1).map(Number) as TutValues
            : null
    ),

    /**
     * Serializes given TUT values to the compound string representation of those values.
     */
    serialize: (tutValues: TutValues): string => tutValues.join('-'),

    /**
     * Checks if given value is a valid `TutValues` array.
     */
    validateParsed: (value): value is TutValues => {
        return Array.isArray(value)
            && value.length === 4
            && all(isNumber, value)
            && value[0] >= 1
            && value[1] >= 0
            && value[2] >= 1
            && value[3] >= 0;
    },
};
