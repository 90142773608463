<button mat-stroked-button *ngIf="config.hasText; else roundButton" class="fx-btn funxtion-button">
    <span class="inner-button">
        <ng-container *ngIf="!(config.withIcon && config.alignIcon === ALIGNMENT.LEFT); else buttonIcon"></ng-container>
        <ng-container *ngTemplateOutlet="transclude"></ng-container>
        <div class="notification" *ngIf="config.withNotification">
            <h4 class="notification-text">{{ config.notificationConfig.notificationLabel }}</h4>
        </div>
        <ng-container *ngIf="!(config.withIcon && config.alignIcon === ALIGNMENT.RIGHT); else buttonIcon"></ng-container>
    </span>
</button>

<ng-template #roundButton>
    <button mat-stroked-button *ngIf="!config.hasText" class="fx-btn funxtion-button">
        <ng-container *ngIf="!config.withIcon; else buttonIcon"></ng-container>
        <!-- <ng-container *ngTemplateOutlet="transclude"></ng-container> -->
    </button>
</ng-template>

<ng-template #buttonIcon>
    <funxtion-icon [config]="config.iconConfig" class="funxtion-icon"></funxtion-icon>
</ng-template>

<ng-template #transclude><span class="button-text"><ng-content></ng-content></span></ng-template>


