import { IViewModel } from "@app-types/vm.types";
import { DMError } from "@funxtion/ng-funxtion-api-client/lib/services/data/base.service";
import { ErrorResponse } from "angular2-jsonapi";

// ------------------------------------------------------------------------------
//      Generic type narrowing functions
// ------------------------------------------------------------------------------

// tslint:disable-next-line: no-any
type AnyFunction = (...args: any[]) => any;

export const isString = (x): x is string => typeof x === 'string';
export const isNumber = (x): x is number => typeof x === 'number';
export const isFunction = (x): x is AnyFunction => typeof x === 'function';
export const isSymbol = (x): x is symbol => typeof x === 'symbol';
export const isPlainObject = (x): x is object => Object.prototype.toString.call(x) === '[object Object]';
export const isDate = (x): x is Date => x instanceof Date;
// tslint:disable-next-line: no-any
export const isArray = (x): x is Array<any> => x instanceof Array;
export const isFile = (x): x is File => x instanceof File;

// ------------------------------------------------------------------------------
//      ViewModel type narrowing functions
// ------------------------------------------------------------------------------

export const isVMNew = (x: IViewModel): x is { id: symbol } => isSymbol(x.id);
export const isVMExisting = (x: IViewModel): x is { id: string } => !isVMNew(x);

/**
 * Reports back whether the given viewmodel can be considered a prospect (unsaved) model.
 */
export const isProspect = <T extends IViewModel>(model: T): model is T & { id: symbol } => isSymbol(model.id);

export const isDMError = (r): r is DMError => r.error instanceof ErrorResponse;
