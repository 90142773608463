import { allPass, head, intersection, isNil, mergeDeepLeft, pluck, propEq, toPairs } from 'ramda';
import { JsonApiDatastore, ModelType } from 'angular2-jsonapi';
import { MapperChain } from '@app-services/conversion-services/MapperChain';
import {
    chainedRelationsTrace,
    immutableRelationsTrace,
    mapperAttributesTrace,
    mapperChainTrace,
    mapperMapTrace,
    traced,
} from '@app-helpers/decorators/traced/traced.decorator';
import { JAM_MODFIED, JAM_PROSPECT_ID } from '@funxtion/ng-funxtion-api-client';
import {
    AttributeMap,
    AttributeMapReport,
    AttributeWriterConfig,
    IMapper,
    IMapperChain,
    Jam,
    MapperChainRecord,
    MapperClass,
    ModelMatchResult,
    MutableRelationMap,
    OneOrMany,
    RelationMap,
    RelationValue,
    ResolvesJamClass,
    Unit,
    Vm,
    WithInverse,
} from '@app-types/vm-conversion/vm-conversion.types';
import { isArray, isProspect, isSymbol } from '@app-helpers/narrowing.helpers';
import { asPromise } from '@app-helpers/api-client.helpers';


export abstract class AbstractModelMapper<T extends Vm<U> = Vm, U extends Jam = Jam> implements IMapper<T, U> {

    /**
     * Mapping of the attribute names from the vm to the jam
     */
    public abstract readonly attributes: AttributeMap<T, U>;

    /**
     * List of mappings of corresponding relationship keys from the vm
     * to the jam + additional relationship information.
     */
    public abstract readonly relationships: RelationMap<T, U>[];

    /**
     * The child mappers that were created with chain calls.
     */
    private readonly chainedRelations: MapperChainRecord<T, U>[] = [];


    private writerConfig: { attributes: AttributeWriterConfig<U> } = {
        attributes: { skip: [] },
    };


    constructor(
        private readonly vm: T,
        private readonly jam: U,
        private readonly datastore: JsonApiDatastore,
    ) {
    }


    setupAttributes(config: AttributeWriterConfig<U> = {}): this {
        this.writerConfig.attributes = mergeDeepLeft(config, { skip: [] });
        return this;
    }


    @traced(mapperChainTrace)
    chain<
        K extends keyof T,
        J extends keyof U,
        R extends Unit<T[K]> & Vm<S>,
        S extends Unit<U[J]> & Jam
        >(vmKey: K, jamKey: J, mapperClass: MapperClass<R, S>): IMapperChain<R, S> {

        const relation = this.resolveChainableRelationship(vmKey, jamKey);

        const { vms, jams } = this.normalizeRelationValues(
            this.vm[vmKey] as unknown as OneOrMany<R>,
            this.jam[jamKey] as RelationValue<S>,
        );

        const matchResult = this.matchModels(vms, jams);
        const jamClass = this.resolveJamClass<S>(relation);

        const matches = matchResult.matches.concat(
            matchResult.unmatched.vms.map((vm) => ({
                vm,
                jam: this.createProspectRecord(jamClass, vm.id as symbol),
            })),
        );

        const chain = new MapperChain(
            matches.map(({ vm, jam }) => new mapperClass(vm, jam, this.datastore)),
        );

        this.chainedRelations.push({ chain, relation, matchResult });

        return chain;
    }


    tap(f: (mapper: this) => void): this {
        f(this);
        return this;
    }


    @traced(mapperMapTrace)
    async map(): Promise<U> {

        this.markJamAsModified(false);

        if (isProspect(this.vm)) {

            Object.defineProperty(this.jam, JAM_PROSPECT_ID, {
                value: this.vm.id,
                writable: true,
                enumerable: false,
            });
        }

        void this.writeAttributes(
            this.attributes,
        );

        await this.writeImmutableRelationships(
            this.immutableRelationships(),
        );

        await this.writeChainedRelationships(
            this.chainedRelations,
        );

        return this.jam;
    }

    // ------------------------------------------------------------------------------
    //      Mapping sub-tasks
    // ------------------------------------------------------------------------------

    @traced(immutableRelationsTrace)
    private async writeImmutableRelationships(relationships: RelationMap<T, U>[]): Promise<ModelMatchResult[]> {
        return Promise.all(relationships.map(
            (relation) => this.writeImmutableRelationship(relation),
        ));
    }

    @traced(chainedRelationsTrace)
    private async writeChainedRelationships(records: MapperChainRecord<T, U>[]): Promise<void> {
        await Promise.all(
            records.map(
                (chainRecord) => this.writeChainedRelationship(chainRecord),
            ),
        );
    }

    /**
     * Writes attribute values from the view-model to the Json API model over the defined attribute map.
     */
    @traced(mapperAttributesTrace)
    private writeAttributes(attributes: AttributeMap<T, U>): AttributeMapReport[] {

        return toPairs(attributes).map(([vmKey, jamKey]) => {

            const vmValue = this.vm[vmKey];
            const jamValue = this.jam[jamKey];

            const skipped = this.writerConfig.attributes.skip.includes(jamKey);
            const changed = vmValue !== jamValue;
            const written = !skipped && changed;

            if (written) {
                this.jam[jamKey] = vmValue;
                this.markJamAsModified();
            }

            return { vmKey, vmValue, jamKey, jamValue, skipped, changed, written };
        });
    }

    /**
     * Writes the relationship of given chain record with vm data to `this.jam`.
     */
    private async writeChainedRelationship(map: MapperChainRecord<T, U>): Promise<void> {

        const { chain, relation, matchResult: { input, unmatched } } = map;

        // Do the deletions right away, but don't await just yet.
        const jamClass = this.resolveJamClass(relation);
        const deletionsP = Promise.all(
            unmatched.jams.map((jam) => this.deleteExistingRecord(jamClass, jam.id)),
        );

        const mappedJams = await chain.mapChildren();

        if (this.relationHasInverseKey(relation)) {
            mappedJams.forEach((jam) => {
                // Todo: we should technically check if the inverse relationship is a to-many.
                jam[relation.inverse.key] = this.jam;
            });
        }

        this.jam[relation.jamKey] = relation.type === 'one'
            ? head(mappedJams) as any // tslint:disable-line: no-any
            // Why is the isArray check required?
            : isArray(this.vm[relation.vmKey])
                ? this.pickJamsByVmCollection(input.vms, mappedJams)
                : undefined;


        await deletionsP;
    }

    /**
     * Writes all local properties for immutable relationships.
     */
    private async writeImmutableRelationship(relation: RelationMap<T, U>): Promise<ModelMatchResult> {

        const { vms, jams } = this.normalizeRelationValues(
            this.vm[relation.vmKey] as unknown as OneOrMany<Vm>,
            this.jam[relation.jamKey] as RelationValue,
        );

        const { matches, unmatched } = this.matchModels(vms, jams);
        const jamClass = this.resolveJamClass(relation);

        const newMatches = await Promise.all(unmatched.vms.map(async (vm) => ({
            vm,
            jam: isSymbol(vm.id) // Actually never expect this -- just safeguarding..
                ? this.createProspectRecord(jamClass, vm.id)
                : await this.findExistingRecord(jamClass, vm.id, relation.includes),
        })));

        const mergedMatches = matches.concat(newMatches);
        const mappedJams = pluck('jam', mergedMatches);

        if (unmatched.jams.length || unmatched.vms.length) {
            this.markJamAsModified();
        }

        this.jam[relation.jamKey] = relation.type === 'one'
            // tslint:disable-next-line: no-any
            ? head(mappedJams) as any
            : this.pickJamsByVmCollection(vms, mappedJams);

        return { matches, unmatched, input: { vms, jams } };
    }

    // ------------------------------------------------------------------------------
    //      Misc.
    // ------------------------------------------------------------------------------

    /**
     * Matches models by ID between given vm- and jam-collections. The result is partitioned
     * 3-way for matches, unmatched vms and unmatched jams.
     */
    private matchModels<A extends Vm<B> = Vm, B extends Jam = Jam>(vms: A[], jams: B[]): ModelMatchResult<A, B> {

        const vmIds = pluck('id', vms);
        const jamIds = pluck('id', jams);

        return {
            input: { vms, jams },
            unmatched: {
                vms: vms.filter((vm) => !jamIds.includes(vm.id as string)),
                jams: jams.filter((jam) => !vmIds.includes(jam.id)),
            },
            matches: intersection(jamIds, vmIds).map((id) => ({
                vm: vms.find(propEq('id', id)),
                jam: jams.find(propEq('id', id)),
            })),
        };
    }

    /**
     * Returns the RelationMap object for a relationship that matches both keys and is chainable in addition.
     * Throws an error if the relationship cannot be found or is not chainable.
     */
    private resolveChainableRelationship(vmKey: string, jamKey: string): RelationMap<T, U> {

        const relationship = this.resolveRelationship(vmKey, jamKey);

        if (!relationship.mutable) {
            throw new Error(`Relationship for Jam-key '${jamKey}' is immutable and therefore cannot be chained.`);
        }

        return relationship;
    }

    /**
     * Returns the RelationMap object for a relationship that matches both keys.
     * Throws an error if the relationship cannot be found.
     */
    private resolveRelationship(vmKey: string, jamKey: string): RelationMap<T, U> {

        const relationship = this.relationships.find(allPass([
            propEq('vmKey', vmKey),
            propEq('jamKey', jamKey),
        ]));

        if (isNil(relationship)) {
            throw new Error(`No relationship defined for vm-key '${vmKey}' and jam-key '${jamKey}'.`);
        }

        return relationship;
    }

    /**
     * Whether the given relation map contains a resolver function instead of a direct class reference
     * for the Json API model type.
     */
    private hasJamClassResolver(map: RelationMap<T, U>): map is RelationMap & ResolvesJamClass {
        return map.hasOwnProperty('jamClassResolver');
    }

    /**
     * Resolves the Json API model constructor for given relation map.
     */
    private resolveJamClass<A extends Jam>(map: RelationMap<T, U>): ModelType<A> {

        const clazz = this.hasJamClassResolver(map)
            ? map.jamClassResolver(this.vm, this.jam)
            : map['jamClass']; //@TODO I have no idea what this might be

        return clazz as ModelType<A>;
    }

    /**
     * Marks `this.jam` as either being modified (true, default) or pristine (false) required later to determine
     * which records to save/update to the API.
     */
    private markJamAsModified(modified: boolean = true) {

        Object.defineProperty(this.jam, JAM_MODFIED, {
            value: modified,
            writable: true,
            enumerable: false,
        });
    }

    /**
     * Creates a new record for given class, and assigns given symbol ID to a temporary
     * property required later for model lookups during the mapping phase.
     */
    private createProspectRecord<A extends Jam>(jamClass: ModelType<A>, id: symbol): A {

        const record = this.datastore.createRecord<A>(jamClass);

        Object.defineProperty(record, JAM_PROSPECT_ID, {
            value: id,
            writable: true,
            enumerable: false,
        });

        return record;
    }

    /**
     * Find an existing model record for given model constructor and id. The includes are necessary when for an
     * immutable-model association additional nested data is expected.
     *
     * @example The Exercise models must be loaded with 2 levels of additional relational data.
     * TrainingExercise -> Exercise (immutable) with ['equipment.measurements'].
     */
    private findExistingRecord<A extends Jam>(jamClass: ModelType<A>, id: string, includes: string[] = []): Promise<A> {

        const params = includes.length
            ? { includes: includes.join(',') }
            : {};

        return asPromise(
            this.datastore.findRecord(jamClass, id, params),
        );
    }

    /**
     * Delete the record of the given type of model constructor by given id.
     */
    private deleteExistingRecord(jamClass: ModelType<Jam>, id: string): Promise<Response> {
        return asPromise(
            this.datastore.deleteRecord(jamClass, id),
        );
    }

    /**
     * Normalizes the given relation property values to arrays. Arrays are returned as-is. If a given property is null
     * or undefined it transforms to an empty array, if it is anything other than that the value will be wrapped in a
     * new array that's to be returned.
     */
    private normalizeRelationValues<R extends Vm<S> = Vm,
        S extends Jam = Jam>(vms: OneOrMany<R>, jams: RelationValue<S>): { vms: R[], jams: S[] } {

        const normalize = (x) => isNil(x) ? [] : Array.isArray(x) ? x : [x];
        return { vms: normalize(vms), jams: normalize(jams) };
    }

    /**
     * Maps the given list of vms to a list of corresponding jams in the same order, taken from given pool of jams.
     * Note that this method expects all vms to have an accompanying jam. If not, that array index WILL be undefined.
     */
    private pickJamsByVmCollection<R extends Jam = Jam>(vms: Vm[], jams: R[]): R[] {
        return vms.map((vm) => jams.find((jam) => (
            jam.id === vm.id || jam[JAM_PROSPECT_ID] === vm.id
        )));
    }

    /**
     * Get a list of all `RelationMaps` that specify an immutable relationship.
     */
    private immutableRelationships(): RelationMap<T, U>[] {
        return this.relationships.filter((r) => !r.mutable);
    }

    /**
     * Tells whether for the given relation-map the remote model is specified to have a writable inverse key to
     * the given relation.
     */
    private relationHasInverseKey(relation: RelationMap<T, U>): relation is typeof relation & MutableRelationMap & WithInverse {
        return relation.mutable && relation.inverse !== false;
    }
}
