<div class="exercise-selector-button"
     role="button"
     [ngClass]="{ selected: selected }"
     (click)="toggleSelection()"
>
    <div class="image-container">
        <div class="checkbox">
            <mat-icon inline="true">check</mat-icon>
        </div>
        <img #exerciseImg
             [src]="exercise.imageSmall"
             [alt]="exercise.name"
             [ngClass]="{ loaded: isLoaded }"
             (load)="isLoaded = true"
        >
    </div>

    <div class="title">
        <div class="title-text">{{ exercise.name }}</div>
        <button class="detail-button" (click)="showDetail($event)">
            <mat-icon inline="true">info_outline</mat-icon>
        </button>
    </div>
</div>
