import { Component, HostBinding, Input } from '@angular/core';

import { DomSanitizer } from '@angular/platform-browser';
import { IconConfig } from '../../inputConfig';
import { merge } from 'ramda';

@Component({
    // tslint:disable-next-line:component-selector
  selector: 'funxtion-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss']
})
export class FunxtionIconComponent {
    @HostBinding('class') class = 'funxtion-icon';

    @HostBinding('style') get style() {
        return this.sanitizer.bypassSecurityTrustStyle(`
            ${this.config.iconSize ? `--icon-size: ${this.config.iconSize};` : ''}
            ${this.config.iconColor ? `--icon-color: ${this.config.iconColor};` : ''}
            ${this.config.iconBackground ? `--icon-background: ${this.config.iconBackground};` : ''}
        `);
    }

    _config: IconConfig;

    @Input()
    get config(): IconConfig {
        return merge(this._config, this.overrides);
    }
    set config(config: IconConfig) {
        this._config = config;
    }

    @Input()
    overrides: IconConfig;

    @Input()
    svgIcon: string;

    constructor(private sanitizer: DomSanitizer) {

    }
}
