import { ALIGNMENT, BUTTON_VARIANT, ButtonConfig } from "src/app/modules/shared/inputConfig";
import { AfterViewInit, Component, ElementRef, HostBinding, OnDestroy, OnInit, ViewChild } from "@angular/core";

import { HeaderType } from "@app-types/page-header.types";
import { PageHeaderService } from '@app-services/page-header/page-header.service';
import { not } from 'ramda';

@Component({
    selector: "app-default",
    templateUrl: "./default.component.html",
    styleUrls: ["./default.component.scss"],
    providers: [
        PageHeaderService
    ]
})
export class DefaultComponent implements OnInit, AfterViewInit, OnDestroy {
    public defaultReturnButton: ButtonConfig = {
        alignIcon: ALIGNMENT.LEFT,
        iconConfig: {
            iconName: 'back-arrow'
        }
    };

    public BUTTON_VARIANT = BUTTON_VARIANT;
    /**
     * The 'sentinel' element that is being observed to assert whether the page is scrolled.
     */
    @ViewChild('stickySentinel', /* TODO: add static flag */ {})
    public stickySentinel: ElementRef<HTMLElement>;

    /**
     * Intersection observer that observes the stickySentinel, and keeps the `this.scrolled`
     * value in sync with the current page scroll position.
     */
    private scrollThresholdIO: IntersectionObserver;

    /**
     * Whether the page has been scrolled past the threshold for collapsing the header.
     */
    public scrolled: boolean = false;


    fullWidth: boolean = false;
    fullHeight: boolean = false;
    public hasHeader: boolean = true;
    HeaderType = HeaderType;

    constructor(
        public pageHeader: PageHeaderService
    ) {
    }

    ngOnInit(): void {

        this.pageHeader.setup({
            returnLink: null,
            title: null,
            filled: true,
        }, false);
    }

    /**
     * After the view initializes the intersection observer for scroll position can
     * be created and attached.
     */
    ngAfterViewInit(): void {

        this.scrollThresholdIO = new IntersectionObserver(
            ([entry]) => this.scrolled = not(entry.isIntersecting),
        );

        this.scrollThresholdIO.observe(this.stickySentinel.nativeElement);
    }

    ngOnDestroy(): void {
        this.scrollThresholdIO.disconnect();
    }
}


@Component({
    selector: "app-default-no-header",
    templateUrl: "./default.component.html",
    styleUrls: ["./default.component.scss"],
})
export class DefaultNoHeaderComponent extends DefaultComponent {
    fullWidth: boolean = false;
    hasHeader: boolean = false;
}

@Component({
    selector: "app-default-no-header",
    templateUrl: "./default.component.html",
    styleUrls: ["./default.component.scss"],
})
export class DefaultNoHeaderFullWidthComponent extends DefaultComponent {
    fullWidth: boolean = true;
    hasHeader: boolean = false;
}

@Component({
    selector: "app-default-full-height",
    templateUrl: "./default.component.html",
    styleUrls: ["./default.component.scss"],
})
export class DefaultFullHeightComponent extends DefaultComponent {
    @HostBinding('class') class = 'full-height';

    fullHeight: boolean = true;
}


