import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";

@Pipe({
    name: 'durationColumn',
})
export class DurationColumnPipe implements PipeTransform {
    constructor(
        private translate: TranslateService
    ) {
    }


    transform(durationInSeconds: number, abbr: boolean = true): string {
        const abbrString = abbr ? '_abbr' : '';

        if (durationInSeconds === null) {
            return '';
        }
        let hours = 0;
        let minutes = 0;
        let seconds = 0;

        if (durationInSeconds > 60) {
            minutes = Math.floor(durationInSeconds / 60);
            seconds = durationInSeconds % 60;
        } else {
            seconds = durationInSeconds;
        }
        if (minutes > 60) {
            hours = Math.floor(minutes / 60);
            minutes = minutes % 60;
        }

        const hourString = this.translate.instant('common.units.hours' + abbrString, {
            value: hours
        });
        const minuteString = this.translate.instant('common.units.minutes' + abbrString, {
            value: minutes
        });
        const secondString = this.translate.instant('common.units.seconds' + abbrString, {
            value: seconds
        });

        return `${hours ? `${hours} ${hourString} ` : ''}${minutes ? `${minutes} ${minuteString} ` : ''}${seconds ? `${seconds} ${secondString}` : ''}`;
    }

}
