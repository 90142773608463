<div class="image" [ngClass]="{
    multi: multi,
    superset: superset,
    selected: selected
}">
    <div class="image--background"></div>
    <div class="image--background-stack"></div>
    <div *ngIf="hasNumber" class="number">
        {{number}}
    </div>
    <img [src]="imageUrl" alt="">
</div>
